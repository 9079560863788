import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Influencer, Nutrient, NutrientType } from '@app/shared/components/nutrients-list/nutrients';
import { DetectMobile, getCriteriaTitleTranslationKey, MeasureHelper } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RCTagTheme, RCTagSize } from '@rc/ui';
import { CriteriaDetailsPopinComponent } from '..';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-nutrients-list',
  templateUrl: './nutrients-list.component.html',
  styleUrls: ['./nutrients-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NutrientsListComponent {
  @Input() nutrients: Nutrient[];
  @Input() nutrientType: NutrientType;
  @Input() nutrientListTitle: string;

  public readonly RCTagTheme = RCTagTheme;
  public readonly RCTagSize = RCTagSize;
  public readonly IconName = IconName;

  public isMobile = DetectMobile.isMobileNotDeprecated();
  public hideCutOff = true;

  constructor(private dialog: MatDialog) {}
  public getInfluencerLabel(influencer: Influencer): string {
    return translateKey(getCriteriaTitleTranslationKey(influencer));
  }

  public getNutrientUnit(unit: string): string {
    return MeasureHelper.measureUnitToSuffix(unit);
  }

  public getNutrientLabel(nutrientCode: string, nutrientType: NutrientType): string {
    if (nutrientType === NutrientType.MACRONUTRIENTS) {
      return translateKey(`macronutrient_{{${nutrientCode}}}_label`);
    }
    if (nutrientType === NutrientType.OTHER_NUTRIENTS) {
      return translateKey(`nutrient_{{${nutrientCode}}}_label`);
    }
    return '';
  }

  showInfluencerDescriptionPopin(influencer: Influencer): void {
    this.dialog.open(CriteriaDetailsPopinComponent, {
      data: {
        selectedCriteria: influencer,
        panelClass: 'small',
      },
    });
  }
}
