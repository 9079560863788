import { NoResultsComponent } from '@app/shared/components/no-results/no-results.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@app/shared/utils/icon/icon.module';

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [NoResultsComponent],
  exports: [NoResultsComponent],
})
export class NoResultsModule {}
