import { IconName } from '@app/shared/utils/icon/icons';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeliveryAddress } from '@app/core/models';
import { DeliveryChoice } from '@app/shared/utils/enums/delivery-choice.enum';
import { isDeliveryAddressIncomplete } from '@app/shared/utils';

@Component({
  selector: 'app-rc-address-card, rc-address-card',
  templateUrl: './rc-address.component.html',
  styleUrls: ['./rc-address.component.scss'],
})
export class RcAddressComponent {
  /**
   * Variables & Properties
   */
  @Input() hideNewAddressBtn? = false;
  @Input() deliveryChoice?: DeliveryChoice;
  @Input()
  set addresses(value: DeliveryAddress[]) {
    this._addresses = value.filter((deliveryAddress: DeliveryAddress) => !!deliveryAddress);
    this.displayedAddresses = this._addresses.slice(this.pageNumber, this.LIMIT_ADDRESSES_DISPLAYED);
    if (this._addresses && this._addresses.length > this.LIMIT_ADDRESSES_DISPLAYED) {
      this.onAddressesPageChange(this.pageNumber);
    }
  }

  get addresses(): DeliveryAddress[] {
    return this._addresses;
  }
  @Input() deliveryType?: string;

  @Output() editEventEmitter = new EventEmitter<{ deliveryAddress: DeliveryAddress; index: number }>();
  @Output() deleteEventEmitter = new EventEmitter<{ deliveryAddress: DeliveryAddress; index: number }>();
  @Output() addNewAddressEventEmitter = new EventEmitter<any>();
  @Output() selectEventEmitter = new EventEmitter<{ deliveryAddress: DeliveryAddress; index: number }>();

  public readonly LIMIT_ADDRESSES_DISPLAYED = 6;
  public readonly TOTAL_LIMIT_ADDRESSES_DISPLAYED = 50;
  public readonly IconName = IconName;
  pageNumber = 0;
  displayedAddresses: DeliveryAddress[];
  public readonly DeliveryChoice = DeliveryChoice;

  private _addresses: DeliveryAddress[];

  showWarningMessage(address: DeliveryAddress): boolean {
    return address.defaultAddress && isDeliveryAddressIncomplete(address, this.deliveryChoice);
  }

  toggleAddress(address: DeliveryAddress, index: number): void {
    this.selectEventEmitter.emit({ deliveryAddress: address, index });
  }

  editAction(event, address: DeliveryAddress, index: number): void {
    event.stopPropagation();
    this.editEventEmitter.emit({ deliveryAddress: address, index });
  }

  deleteAction(event, address: DeliveryAddress, index: number): void {
    event.stopPropagation();
    this.deleteEventEmitter.emit({ deliveryAddress: address, index });
  }

  trackByFn(index) {
    return index;
  }

  addAddress(event): void {
    event.stopPropagation();
    this.addNewAddressEventEmitter.emit();
  }

  onAddressesPageChange(index: number): void {
    this.pageNumber = index;
    this.displayedAddresses = this.addresses.slice(index * this.LIMIT_ADDRESSES_DISPLAYED, (index + 1) * this.LIMIT_ADDRESSES_DISPLAYED);
  }
}
