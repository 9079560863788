<div class="weight-program">
  <div class="weight-program__information">
    <img [src]="programImage" [alt]="programTitle" />
    <p class="weight-program__title">{{ programTitle }}</p>
    <p class="weight-program__description">{{ programDesc }}</p>
  </div>
  <div class="weight-program__buttons">
    <ng-content select="[weight-program_buttons]"></ng-content>
  </div>
</div>
<div class="weight-program-footer">
  <button class="rc-button rc-button--filled-small" (click)="showShareRecommendation($event)">
    <span class="rc-button__title">{{ 'action_share_recommendation' | translateKey }}</span>
  </button>
</div>
