import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Patient, PetOwner } from '@app/core/models';
import { LoaderService, VetService } from '@app/core/services';

@Component({
  selector: 'app-delete-patient-poppin',
  templateUrl: './delete-patient-popin.component.html',
  styleUrls: ['./delete-patient-popin.component.scss'],
})
export class DeletePatientPopinComponent {
  private readonly petOwner: PetOwner;
  private readonly patient: Patient;

  /**
   * Initializer
   */
  constructor(
    private _dialogRef: MatDialogRef<DeletePatientPopinComponent>,
    private router: Router,
    private loaderService: LoaderService,
    private vetService: VetService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.patient) {
      this.patient = data.patient;
    }
    if (data && data.petOwner) {
      this.petOwner = data.petOwner;
    }
  }

  /**
   * Close modal
   */
  dismiss(): void {
    this._dialogRef.close();
  }

  stopLoaderAndDismiss(): void {
    this.loaderService.stopLoader(() => {
      this.dismiss();
      this.router.navigate(['']);
    });
  }

  deletePatient(): void {
    this.loaderService.startLoader(true);
    this.vetService.deletePatient(this.patient).subscribe(
      () => {
        this.stopLoaderAndDismiss();
      },
      () => {
        this.stopLoaderAndDismiss();
      }
    );
  }
  deletePetOwner(): void {
    this.loaderService.startLoader(true);
    this.vetService.deletePetOwner(this.petOwner.organizationId, this.petOwner.id).subscribe(
      () => {
        this.stopLoaderAndDismiss();
      },
      () => {
        this.stopLoaderAndDismiss();
      }
    );
  }

  /**
   * Close modal
   */
  continue(): void {
    if (this.petOwner && !this.patient) {
      this.deletePetOwner();
    } else if (this.patient && !this.petOwner) {
      this.deletePatient();
    }
  }
}
