import { IconName } from '@app/shared/utils/icon/icons';
import { VetFacade } from '@app/store/vet';
import { RCSelectItem } from '@rc/ui';
import { TermsService } from '@app/core/services/utils/localization/terms.service';
import { Product, TermScope } from '@app/core/models';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { IFormArray, IFormBuilder, IFormGroup } from '@rxweb/types';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { ProductsQuantityFormValues, ProductsQuantityPackagesFormValues } from './products-quantity-form';
import { ProductType } from '@app/shared/utils';
import ProductHelper from '@app/shared/utils/static-helpers/product-helper';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-products-quantity-form',
  styleUrls: ['products-quantity-form.component.scss'],
  templateUrl: './products-quantity-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsQuantityFormComponent implements OnInit {
  @Input() products: Product[];
  @Input() selectedPacks: string[];
  @Input() isPackEditable: boolean;
  @Input() showRetailPrices: boolean;
  @Input() showWholesalePrices: boolean;
  @Input() submitButtonLabel = translateKey('action_place-order');

  @Output() submitted = new EventEmitter<ProductsQuantityFormValues>();

  ProductType = ProductType;
  IconName = IconName;
  form: IFormGroup<ProductsQuantityFormValues>;
  formBuilder: IFormBuilder = new UntypedFormBuilder();

  marketTerm$ = this.termsService.legacyTerm$(TermScope.MUF);
  clinicCurrency$ = this.vetFacade.currentClinicCurrency$;

  constructor(private termsService: TermsService, private vetFacade: VetFacade) {}

  get packagesFormArray(): IFormArray<ProductsQuantityPackagesFormValues> {
    return this.form.controls.packages as IFormArray<ProductsQuantityPackagesFormValues>;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group<ProductsQuantityFormValues>({
      packages: this.formBuilder.array<ProductsQuantityPackagesFormValues>(
        this.products.map((product) => this.createFormArrayItem(product))
      ),
      consent: [false, [Validators.requiredTrue]],
    });
  }

  createFormArrayItem(product: Product | null): UntypedFormGroup {
    const currentPack = product?.packages?.find((item) => this.selectedPacks?.includes(item.sCode)) || product?.packages[0];
    return this.formBuilder.group<ProductsQuantityPackagesFormValues>({
      productId: [product?.id || '', Validators.required],
      scode: [currentPack?.sCode || '', Validators.required],
      ean: [currentPack?.ean || '', Validators.required],
      quantity: [1, Validators.required],
    });
  }

  removeFormArrayItem(index: number): void {
    this.packagesFormArray.removeAt(index);
  }

  getProductItem(index: number): Product {
    return this.products.find((item) => item.id === this.packagesFormArray.at(index).value.productId);
  }

  getProductPackages(product: Product): RCSelectItem[] {
    return product.packages.map((pack) => ({
      value: pack.sCode,
      label: pack.text,
    }));
  }

  getProductSelectedPackWeight(product: Product): string {
    const pack = product?.packages?.find((item) => this.selectedPacks?.includes(item.sCode));
    return pack?.text || product?.packages[0]?.text;
  }

  getProductPrice(product: Product, index: number): number | null {
    const currentItem = this.packagesFormArray.at(index).value;
    return ProductHelper.getPriceFromSelectedProductPack(product, currentItem.scode, currentItem.quantity);
  }

  getWholesalePrice$(index: number): Observable<number | null> {
    const currentItem = this.packagesFormArray.at(index).value;
    return this.vetFacade.wholesalePrices$.pipe(
      first(),
      map((prices) => prices[currentItem?.ean])
    );
  }

  getWholesalePricesTotal$(): Observable<number | null> {
    return this.vetFacade.wholesalePrices$.pipe(
      first(),
      map((wholesalePrices) => {
        let total = 0;
        this.packagesFormArray.value.forEach((pack) => {
          const packsPrice = wholesalePrices[pack?.ean] ? wholesalePrices[pack?.ean] * pack?.quantity : 0;
          total = total + packsPrice;
        });
        return total;
      })
    );
  }

  /*
    By default the RcSelect component will sort items alphabetically, we want to prevent this behaviour
  */
  customSelectSort(): number {
    return 1;
  }

  submit(): void {
    if (this.form.valid) {
      this.submitted.emit(this.form.getRawValue());
    }
  }
}
