import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { mixedBreedSizesOrder, PetProfileFormValues, StandardWeight } from '@app/core/models';
import { EnumsService, NutritionService } from '@app/core/services';
import { RCSelectorsType } from '@rc/ui';
import { ActivityCode, GenderCode, Helper, LifestageType, MeasureHelper, MeasurementCodeType } from '@app/shared/utils';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { BehaviorSubject, combineLatest, interval, of, Subject } from 'rxjs';
import { catchError, debounce, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { WeightFormValue } from './weight-form';
import { getActivityItems } from '@app/shared/utils/static-helpers/pet-profile-form-helper';
import { RCSelectorsItem } from '@rc/ui/lib/selectors/rc-selectors';
import { IconName } from '@app/shared/utils/icon/icons';
import { GTMService, PageBlockEnum, patientBlockDisplay } from '@app/core/services/tracking';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

export interface WeightFormComponentFormValues {
  bcs: number;
  currentWeight: number;
  targetWeight: number;
  petActivity?: ActivityCode;
}

@Component({
  selector: 'app-weight-form',
  templateUrl: './weight-form.component.html',
  styleUrls: ['./weight-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeightFormComponent implements OnInit, OnDestroy {
  @Input() readonly profile: PetProfileFormValues | null = null;
  /**
   * Minimal BCS that can be selected
   */
  @Input() minimalBcs = 1;
  @Input() defaultBcs = 5;
  @Input() showWeightFields = true;
  @Input() readonly initialTargetWeight: number | null = null;
  @Input() readonly initialPetActivity: ActivityCode | null = null;
  @Input() readonly initialBcs: number | null = null;
  @Input() readonly initialCurrentWeight: number | null = null;
  @Input() readonly currentBigMeasurementUnit: MeasurementCodeType;
  @Input() readonly currentSystemPreferenceCode: SystemPreferenceCode;

  @Output() submitted = new EventEmitter<WeightFormValue>();
  public readonly RCSelectorsType = RCSelectorsType;
  public readonly IconName = IconName;
  public isIBWValidSubject$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isTBWValidSubject$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public calculatingTargetWeight = false;
  public calculatedTargetWeight: number | null = null;
  public calculatedTargetWeightError: string | null = null;
  public youngPetMinMaxWeights$ = new BehaviorSubject<StandardWeight | null>(null);
  public activityItems = getActivityItems() as RCSelectorsItem[];

  public measureUnit!: string;

  /**
   * Weight form
   */
  public formBuilder: IFormBuilder = new UntypedFormBuilder();
  public form: IFormGroup<WeightFormComponentFormValues>;

  private destroyed$ = new Subject<void>();

  constructor(
    private nutritionService: NutritionService,
    private cdr: ChangeDetectorRef,
    private enumsService: EnumsService,
    private trackingService: GTMService
  ) {}

  get isAdult(): boolean {
    return !!this.profile && Helper.lifestageOld(this.profile.lifestage);
  }

  get isPuppyWithBreedOrAnyKitten(): boolean {
    const { lifestage } = this.profile || {};
    return !!this.profile && (lifestage === LifestageType.Kitten || this.isPuppyWithBreed);
  }

  get isPuppyWithBreed(): boolean {
    return !this.isMixedBreedPuppy;
  }

  get isMixedBreedPuppy(): boolean {
    const { lifestage, mixed } = this.profile || {};
    return !!this.profile && lifestage === LifestageType.Puppy && mixed;
  }

  /**
   * Target weight placeholder depends on isAdult
   */
  get targetWeightPlaceholderKey(): string {
    return this.isAdult ? 'form-attribute_ibw' : 'form-attribute_adult-target-weight';
  }

  /**
   * Current weight errors
   */
  get currentWeightError(): string {
    const errors: { [key: string]: string } = {
      max: `${translateKey('rc-input_should-not-exceed')} ${MeasureHelper.getWeightLimitInput(this.currentBigMeasurementUnit)} ${
        this.measureUnit
      }`,
      required: `${translateKey('form-attribute_current-weight')} ${translateKey('required-key')}`,
    };
    const firstError = this.getFirstError(this.form.controls.currentWeight);
    return (firstError && errors[firstError]) || '';
  }

  /**
   * Target weight errors
   */
  get targetWeightError(): string {
    const errors: { [key: string]: string } = {
      required: `${translateKey(this.targetWeightPlaceholderKey)} ${translateKey('required-key')}`,
      min: `${translateKey(this.targetWeightPlaceholderKey)} ${translateKey('form-error_search-default')}`,
    };
    const firstError = this.getFirstError(this.form.controls.targetWeight);
    return (firstError && errors[firstError]) || '';
  }

  /**
   * Subscribe to form value changes
   */
  ngOnInit(): void {
    this.measureUnit = MeasureHelper.measureUnitToSuffix(this.currentBigMeasurementUnit);
    this.form = this.formBuilder.group<WeightFormComponentFormValues>({
      bcs: [this.initialBcs || this.defaultBcs, Validators.required],
      currentWeight: [
        this.initialCurrentWeight,
        [Validators.required, Validators.min(0), Validators.max(MeasureHelper.getWeightLimitInput(this.currentBigMeasurementUnit))],
      ],
      targetWeight: [{ value: this.initialTargetWeight, disabled: true }, [Validators.required, Validators.min(1)]],
      petActivity: [this.initialPetActivity ? this.initialPetActivity : null, Validators.required],
    });

    this.setBreedMinMaxWeights(this.profile);
    this.setDisableActivity();

    /**
     * Watch bcs & currentWeigh changes
     * If isAdult & bcs > 3, we calculate & set targetWeight
     */
    this.calculateAndSetTargetWeight();

    /**
     * checkIBWValidity()
     */
    this.checkIBWValidity();

    /**
     * checkTBWValidity()
     */
    this.checkTBWValidity();

    /**
     * Set adult target weight for non adults
     */
    this.setAdultTargetWeight();

    this.trackingService.sendInteraction(
      patientBlockDisplay({
        block: PageBlockEnum.WEIGHT_AND_ACTIVITY_LEVEL,
        petInfo: this.profile,
      })
    );
  }

  /**
   * Unsubscribe
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    const data = this.form.getRawValue();
    this.submitted.emit({
      bcs: data.bcs,
      currentWeight: data.currentWeight,
      targetWeight: data.targetWeight,
      ibw: this.calculatedTargetWeight,
      petActivity: data.petActivity,
    });
  }

  public formatYoungPetMinMaxWeightMessage(petMinMaxValues: StandardWeight): string {
    return translateKey(
      mixedBreedSizesOrder.includes(this.profile.breed) ? 'New_DA-weight-step-puppy-kitten-2' : 'New_DA-weight-step-puppy-kitten-1',
      {
        minimum_weight: `${petMinMaxValues?.min?.measure}`,
        maximum_weight: `${petMinMaxValues?.max?.measure}`,
        measure_unit: this.measureUnit,
      }
    );
  }

  /**
   *  is adult, BCS is in [1, 2]
   */
  public isAdultWithBcs12(): boolean {
    return this.isAdultWithBcs([1, 2]);
  }

  /**
   *  is adult, BCS is in [3]
   */
  public isAdultWithBcs3(): boolean {
    return this.isAdultWithBcs([3]);
  }

  /**
   *  is adult, BCS is in [3, 4, 5]
   */
  public isAdultWithBcs345(): boolean {
    return this.isAdultWithBcs([3, 4, 5]);
  }

  /**
   *  is adult, CW set, and BCS greater than 5
   */
  public isAdultCwSetWithBcsGreaterThan5(): boolean {
    return this.isAdultWithBcsGreaterThan(5) && !!this.form.controls.currentWeight.value;
  }

  private isAdultWithBcsGreaterThan(bcsValue: number): boolean {
    return this.isAdult && this.form.controls.bcs.value && this.form.controls.bcs.value > bcsValue;
  }

  private isAdultWithBcs(bcsValues: number[]): boolean {
    return this.isAdult && this.form.controls.bcs.value && bcsValues.includes(this.form.controls.bcs.value);
  }

  private setDisableActivity(): void {
    if (!this.isAdult) {
      this.form.controls.petActivity.disable();
    }
  }

  private setBreedMinMaxWeights(pet: PetProfileFormValues): void {
    if (!this.isAdult) {
      this.enumsService
        .fetchBreed(this.profile?.breed)
        .pipe(
          map((breed) => (pet.gender === GenderCode.Male ? breed?.standardWeights?.male : breed?.standardWeights?.female)),
          filter((standardWeight: StandardWeight) => !!(standardWeight?.max && standardWeight?.min)),
          map((standardWeight: StandardWeight) =>
            this.currentSystemPreferenceCode === SystemPreferenceCode.imperialSystemCode
              ? this.convertStandardWeight(standardWeight)
              : standardWeight
          ),
          tap((standardWeight: StandardWeight) => {
            this.youngPetMinMaxWeights$.next(standardWeight);
          })
        )
        .subscribe();
    }
  }

  /**
   *
   * @returns {min: round to the upper decimal: ex 2.201 should be 2.3 ,max: round to the lower decimal: ex 6.67 should be 6.6}
   */
  private convertStandardWeight(standardWeight: StandardWeight): StandardWeight {
    const max = MeasureHelper.convertWeight(standardWeight.max.measure, MeasurementCodeType.Kilogram, MeasurementCodeType.Pound);
    const min = MeasureHelper.convertWeight(standardWeight.min.measure, MeasurementCodeType.Kilogram, MeasurementCodeType.Pound);

    return {
      max: { measure: Math.trunc(max.measure * 10) / 10, measureUnit: max.measureUnit },
      min: { measure: Math.ceil(min.measure * 10) / 10, measureUnit: min.measureUnit },
    };
  }

  /**
   * Update target weight form value
   */
  private updateTargetWeightFormValue(targetWeight: number | null) {
    this.form.controls.targetWeight.patchValue(targetWeight);
    this.calculatedTargetWeight = targetWeight;
    this.cdr.detectChanges();
  }

  /**
   * Get first error for control
   */
  private getFirstError(control: AbstractControl): string | undefined {
    return (control.touched && control.invalid && control.errors && Object.keys(control.errors)[0]) || undefined;
  }

  /**
   * Watch bcs & currentWeigh changes
   * If isAdult & bcs >= 3, we calculate & set targetWeight
   */
  private calculateAndSetTargetWeight(): void {
    combineLatest([
      this.form.controls.bcs.valueChanges.pipe(startWith(this.form.getRawValue().bcs)),
      this.form.controls.currentWeight.valueChanges.pipe(
        debounce(() => interval(500)),
        startWith(this.form.getRawValue().currentWeight)
      ),
    ])
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => {
          if (this.form.controls.currentWeight.valid) this.form.controls.targetWeight.enable();
          else this.form.controls.targetWeight.disable();
        }),
        /**
         * Filter emissions
         */
        filter(() => this.isAdult),
        filter((value) => value !== null),
        tap(([bcs]) => {
          if (bcs < 3) {
            Helper.resetFieldsValidators(this.form, ['targetWeight']);
            this.updateTargetWeightFormValue(null);
          } else {
            Helper.setFieldsValidator(this.form, ['targetWeight']);
          }
        }),
        filter(([bcs, currentWeight]) => bcs > 2 && !!currentWeight && this.form.controls.currentWeight.valid),
        /**
         * Reset error message
         */
        tap(() => (this.calculatedTargetWeightError = null)),
        tap(() => (this.calculatingTargetWeight = true)),
        /**
         * Call API if needed, display error if it fails
         */
        switchMap(([bcs, currentWeight]) =>
          this.nutritionService.getIBW(currentWeight, bcs).pipe(
            catchError((err) => {
              this.calculatedTargetWeightError = err;
              return of([0, 0]);
            })
          )
        ),
        /**
         * Update form value
         */
        tap((targetWeight: number) => targetWeight > 0 && this.updateTargetWeightFormValue(targetWeight)),
        tap(() => (this.calculatingTargetWeight = false)),
        tap(() => this.cdr.detectChanges())
      )
      .subscribe();
  }

  /**
   * Set adult target weight for non adults
   */
  private setAdultTargetWeight(): void {
    if (!this.isAdult && !!this.profile && !this.form.value.targetWeight) {
      this.nutritionService.getBreedPetProfile({ genderCode: this.profile.gender, breedCode: this.profile.breed }).subscribe((data) => {
        if (data?.adultTargetWeight) {
          const convertedWeight = MeasureHelper.convertWeightObjInPlace(data.adultTargetWeight, this.currentBigMeasurementUnit);
          // if 'lb': round to the upper decimal: ex 2.201 should be 2.3
          const targetWeightValue =
            this.currentSystemPreferenceCode === SystemPreferenceCode.imperialSystemCode
              ? Math.ceil(convertedWeight.measure * 10) / 10
              : MeasureHelper.roundWeight(convertedWeight).measure;
          this.updateTargetWeightFormValue(targetWeightValue);
        } else {
          this.updateTargetWeightFormValue(0);
        }
      });
    }
  }

  /**
   * Watch targetWeight changes
   * If isAdult & bcs > 3, and targetWeight !== null we call checkIBWValidity api
   */
  private checkIBWValidity(): void {
    if (this.isAdult) {
      combineLatest([
        this.form.controls.bcs.valueChanges.pipe(startWith(this.form.getRawValue().bcs)),
        this.form.controls.currentWeight.valueChanges.pipe(
          debounce(() => interval(500)),
          startWith(this.form.getRawValue().currentWeight)
        ),
        this.form.controls.targetWeight.valueChanges,
      ])
        .pipe(
          takeUntil(this.destroyed$),
          distinctUntilChanged(),
          tap(() => this.isIBWValidSubject$.next(true)),
          filter(
            () =>
              !!this.form.controls.bcs.value &&
              !!this.form.controls.currentWeight.value &&
              !!this.form.controls.targetWeight.value &&
              this.form.controls.bcs.valid &&
              this.form.controls.currentWeight.valid &&
              this.form.controls.targetWeight.valid
          ),
          map(() => [this.form.controls.bcs.value, this.form.controls.currentWeight.value, this.form.controls.targetWeight.value]),
          filter(([bcs, currentWeight, targetWeight]) => bcs > 3 && typeof currentWeight === 'number' && typeof targetWeight === 'number'),
          switchMap(([bcs, currentWeight, targetWeight]) => {
            return this.nutritionService.checkIBWValidity(bcs, targetWeight, currentWeight).pipe(
              tap((IBWtValidity) => {
                this.isIBWValidSubject$.next(IBWtValidity);
              })
            );
          })
        )
        .subscribe();
    }
  }

  private checkTBWValidity(): void {
    if (!this.isAdult && [LifestageType.Kitten, LifestageType.Puppy].includes(this.profile?.lifestage)) {
      this.form.controls.targetWeight.valueChanges
        .pipe(
          takeUntil(this.destroyed$),
          debounce(() => interval(500)),
          distinctUntilChanged(),
          tap(() => this.isTBWValidSubject$.next(true)),
          filter(() => !!this.form.controls.targetWeight.value && this.form.controls.targetWeight.valid),
          map(() => this.form.controls.targetWeight.value),
          switchMap((targetWeight) => {
            return this.nutritionService.checkTBWValidity(this.profile.lifestage, targetWeight).pipe(
              tap((TBWtValidity) => {
                this.isTBWValidSubject$.next(TBWtValidity);
              })
            );
          })
        )
        .subscribe();
    }
  }
}
