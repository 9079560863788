import { PanelSectionTypeEnum } from '@app/shared/utils';
import { createAction, props } from '@ngrx/store';
import { RenalDetectStep } from '../../renal-detect-step';
/**
 * Action dispatched to start a smart reco flow
 * It resets smart reco store data and consultation store data
 */
export const startRenalDetect = createAction('[Renal Detect] Start');
/**
 * Set current step
 */
export const setRenalDetectStep = createAction('[Renal Detect] Set Step', props<{ value: RenalDetectStep; isNewPet?: boolean }>());
/**
 * Reset smart reco store
 */
export const resetRenalDetect = createAction('[Renal Detect] Reset');

/**
 * Actions (navigateBackToStep and setEditFromTool) to handle going back to renal detect from ongoing product recommendation flow (SR or DA)
 */
/**
 * Navigate back to step from panel from another module (daily allowance or allowance) during consultation
 */
export const navigateBackToStep = createAction('[Renal Detect] Navigate back to step', props<{ value: PanelSectionTypeEnum }>());
/**
 * After re-creating detection and choosing again a product recommendation tool, previous filled values must be persisted
 * We need to store the current product recommendation tool to known which flow must persist its data
 * Right now, we only go back to renal detect when doing a product recommendation with the tool DA
 */
export const setEditMode = createAction('[Renal Detect] Set Edit Mode', props<{ value: boolean }>());
