import { Component, Input } from '@angular/core';
import { ListItem } from '@app/shared/components/rc-list/list-item';

@Component({
  selector: 'app-rc-list',
  templateUrl: './rc-list.component.html',
  styleUrls: ['./rc-list.component.scss'],
})
export class RcListComponent {
  @Input() highlightValue = false;
  @Input() listItems: ListItem[];
}
