import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RCTagTheme } from '@rc/ui';

@Component({
  selector: 'app-tags-selection-container',
  templateUrl: './tags-selection-container.component.html',
  styleUrls: ['./tags-selection-container.component.scss'],
})
export class TagsSelectionContainerComponent {
  @Input() selections: TagsSelector[] = [];
  @Output() clearSelection = new EventEmitter<TagsSelectedValue[]>();
  RCTagTheme = RCTagTheme;

  closeTag(item) {
    this.clearSelection.emit([item]);
  }
}

export interface TagsSelector {
  noResultLabel: string;
  categorieLabel: string;
  selected: TagsSelectedValue[];
  theme: RCTagTheme;
}

export interface TagsSelectedValue {
  value: string;
  description: string;
  isCloseable: boolean;
}
