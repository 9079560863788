<div class="warning-message">
  <app-icon class="warning-message-icon" [name]="IconName.warning"></app-icon>
  <div>
    <span>{{ description }}</span>
    <button
      *ngIf="actionLabel"
      type="button"
      class="warning-message-action rc-button__title rc-button__title--link"
      (click)="actionClicked.emit($event)"
    >
      {{ actionLabel }}
    </button>
  </div>
</div>
