import { Component, Inject } from '@angular/core';
import { Consultation } from '@app/core/models';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-medical-history-detail-popin',
  templateUrl: './medical-history-detail-popin.component.html',
  styleUrls: ['./medical-history-detail-popin.component.scss'],
})
export class MedicalHistoryDetailPopinComponent {
  title = translateKey('rd-medical-record_table-title');
  date = translateKey('table-header_date');
  creatinine = translateKey('label-blood-creatinine');
  urea = translateKey('label-blood-urea');
  urine = translateKey('label-gravity');
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Pick<Consultation, 'prediction' | 'date'>[],
    private readonly modalRef: MatDialogRef<MedicalHistoryDetailPopinComponent>
  ) {}

  close() {
    this.modalRef.close();
  }
}
