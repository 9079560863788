import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Constants } from '@app/shared/utils';

@Component({
  selector: 'app-rc-pagination',
  templateUrl: './rc-pagination.component.html',
  styleUrls: ['./rc-pagination.component.scss'],
})
export class RcPaginationComponent {
  currentPage = 1;
  private _totalPage: number;
  private _step: number = Constants.LIMIT_CONSULTATIONS_PAGE;

  /** Properties & Variables **/
  @Input() set total(value: number) {
    this._totalPage = Math.ceil(value / this.step);
  }
  get totalPage(): number {
    return this._totalPage;
  }

  @Input() set step(value: number) {
    this._step = value;
  }
  get step(): number {
    return this._step;
  }

  @Output() prevPageEvent: EventEmitter<number> = new EventEmitter();
  @Output() changePageEvent: EventEmitter<number> = new EventEmitter();
  @Output() nextPageEvent: EventEmitter<number> = new EventEmitter();

  /** Initializer **/

  get disabledLeftArrow(): boolean {
    return this.currentPage <= 1;
  }

  get disabledRightArrow(): boolean {
    return this.currentPage >= this._totalPage;
  }

  /** Methods **/

  /**
   * Go to previous page
   * @param event - Click or touch
   */
  onPrev(event: Event) {
    event.stopPropagation();

    if (this.currentPage === 1) {
      return;
    }

    this.currentPage--;
    this.prevPageEvent.emit(this.currentPage - 1);
  }

  /**
   * Change the page when the input number changed
   * @param pageNumber - string
   */
  changePageNumber(pageNumber: string) {
    if (+pageNumber > this._totalPage) {
      return;
    }

    this.currentPage = +pageNumber;
    this.changePageEvent.emit(this.currentPage - 1);
  }

  /**
   * Go to next page
   * @param event - Click or touch
   */
  onNext(event: Event) {
    event.stopPropagation();

    if (this._totalPage === this.currentPage) {
      return;
    }

    this.currentPage++;
    this.nextPageEvent.emit(this.currentPage - 1);
  }
}
