<div class="details-popin_container">
  <h1 class="details-popin_title">{{ title }}</h1>
  <div class="details-popin_content">
    <p class="text">{{ description?.long_1 }}</p>
    <p class="text">{{ description?.long_2 }}</p>
    <p class="text">{{ description?.long_3 }}</p>
    <p class="text">{{ description?.long_4 }}</p>
    <p class="text">{{ description?.long_5 }}</p>
  </div>
  <button class="rc-button rc-button--filled-small" type="submit" (click)="dismiss()">
    <span class="rc-button__title" i18n="@@action_close">close</span>
  </button>
</div>
