<div class="pet-panel">
  <!-- Name block-->
  <div class="pet-panel__fixed-title">
    <div class="pet-panel__pet-name-container">
      <img
        *ngIf="pet?.speciesCode"
        alt=""
        class="pet-panel__pet-name-container-picture"
        src="assets/other/placeholder_{{ pet?.speciesCode }}.svg"
      />
      <p data-cs-mask class="pet-panel__pet-name-container-name">{{ pet?.name }}</p>
    </div>
    <div class="divider-without-margin"></div>
  </div>

  <div class="pet-panel__scroll-container">
    <!-- Action edit pet details block-->
    <div class="pet-panel__action-details-container">
      <button [disabled]="!isEditing?.petDetails" class="rc-button" (click)="editPetDetails(PanelSection.PetDetails)">
        <app-icon class="rc-button__icon-title" [name]="IconName.edit"></app-icon>
        <span
          [matTooltipPosition]="'above'"
          matTooltip="{{ 'path_pet-details' | translateKey }}"
          matTooltipClass="tooltip-arrow-down"
          #tooltip="matTooltip"
          class="rc-button__title rc-button__title--link"
          *ngIf="displayTooltipLongerWord('path_pet-details' | translateKey); else pet_details_content"
        >
          {{ 'path_pet-details' | translateKey }}
        </span>
        <ng-template #pet_details_content>
          <span class="rc-button__title rc-button__title--link">{{ 'path_pet-details' | translateKey }}</span>
        </ng-template>
      </button>
      <span class="arrow-down" [class.reversed]="isPanelPetDetailsExpended" (click)="showPanelDetails(PanelSection.PetDetails)"
        ><app-icon [name]="IconName.chevron_down"></app-icon
      ></span>
    </div>
    <div class="divider-without-margin"></div>
    <div class="details-form-expended" [class.details-form-retracted]="!isPanelPetDetailsExpended">
      <app-rc-list [highlightValue]="true" [listItems]="petDetailsFields"></app-rc-list>
      <div class="divider-without-margin"></div>
    </div>

    <!-- Action edit pet weight block-->
    <div class="pet-panel__action-details-container">
      <button [disabled]="!isEditing?.petWeight" class="rc-button" (click)="editPetDetails(PanelSection.PetWeight)">
        <app-icon class="rc-button__icon-title" [name]="IconName.edit"></app-icon>

        <span
          [matTooltipPosition]="'above'"
          matTooltip="{{ 'label_weight-activity-level' | translateKey }}"
          matTooltipClass="tooltip-arrow-down"
          #tooltip="matTooltip"
          class="rc-button__title rc-button__title--link"
          *ngIf="displayTooltipLongerWord('label_weight-activity-level' | translateKey); else body_weight_content"
          >{{ 'label_weight-activity-level' | translateKey }}
        </span>
        <ng-template #body_weight_content>
          <span class="rc-button__title rc-button__title--link">{{ 'label_weight-activity-level' | translateKey }}</span>
        </ng-template>
      </button>
      <span class="arrow-down" [class.reversed]="isPanelPetWeightExpended" (click)="showPanelDetails(PanelSection.PetWeight)"
        ><app-icon [name]="IconName.chevron_down"></app-icon
      ></span>
    </div>
    <div class="divider-without-margin"></div>
    <div class="details-form-expended" [class.details-form-retracted]="!isPanelPetWeightExpended">
      <app-rc-list [highlightValue]="true" [listItems]="petWeightFields"></app-rc-list>
      <div class="divider-without-margin"></div>
    </div>

    <!-- Display growth chart -->
    <ng-container *ngIf="displayGrowthChart">
      <div class="pet-panel__action-details-container">
        <button class="rc-button" (click)="editGrowthChart(PanelSection.PetGrowthChart)">
          <app-icon class="rc-button__icon-title" [name]="IconName.view"></app-icon>
          <span class="rc-button__title rc-button__title--link">{{ 'label_side-panel-growth-chart' | translateKey }}</span>
        </button>
      </div>
      <div class="divider-without-margin"></div>
    </ng-container>

    <ng-container *ngIf="displayDiagnosis">
      <!-- Action edit pet diagnosis block-->
      <div class="pet-panel__action-details-container">
        <button [disabled]="!isEditing?.petDiagnosis" class="rc-button" (click)="editPetDetails(PanelSection.PetDiagnosis)">
          <app-icon class="rc-button__icon-title" [name]="IconName.edit"></app-icon>
          <span
            [matTooltipPosition]="'above'"
            matTooltip="{{ 'path_diagnosis' | translateKey }}"
            matTooltipClass="tooltip-arrow-down"
            #tooltip="matTooltip"
            class="rc-button__title rc-button__title--link"
            *ngIf="displayTooltipLongerWord('ath_diagnosis' | translateKey); else body_diagnosis_content"
            >{{ 'path_diagnosis' | translateKey }}
          </span>
          <ng-template #body_diagnosis_content>
            <span class="rc-button__title rc-button__title--link">{{ 'path_diagnosis' | translateKey }}</span>
          </ng-template>
        </button>
        <span
          class="arrow-down"
          *ngIf="pet?.pathologies?.length || pet?.sensitivities?.length"
          [class.reversed]="isPanelPetDiagnosisExpended"
          (click)="showPanelDetails(PanelSection.PetDiagnosis)"
          ><app-icon [name]="IconName.chevron_down"></app-icon
        ></span>
      </div>
      <div class="divider-without-margin"></div>
      <div class="pet-panel__disease-container details-form-expended" ngif [class.details-form-retracted]="!isPanelPetDiagnosisExpended">
        <ng-container *ngIf="pet?.pathologies?.length">
          <p class="pet-panel__disease-container-title" i18n="@@title_pathologies">Pathologies</p>
          <div class="pet-panel__disease-container-tag">
            <rc-tag
              *ngFor="let selectedItem of pet?.pathologies"
              class="pet-panel__tag"
              [size]="RCTagSize.XS"
              [text]="'pathology-' + selectedItem | translateKey"
            ></rc-tag>
          </div>
        </ng-container>

        <ng-container *ngIf="pet?.sensitivities?.length">
          <p class="pet-panel__disease-container-title" i18n="@@title_sensitivities">Other conditions</p>
          <div class="pet-panel__disease-container-tag">
            <rc-tag
              *ngFor="let selectedItem of pet?.sensitivities"
              class="pet-panel__tag"
              [size]="RCTagSize.XS"
              [text]="'sensitivity-' + selectedItem | translateKey"
            ></rc-tag>
          </div>
        </ng-container>
      </div>
      <div class="divider-without-margin"></div>
    </ng-container>

    <!-- Action go to nutritional assessment-->
    <div class="pet-panel__action-details-container" *ngIf="displayXrayLink">
      <button [disabled]="!isEditing?.petWeight" class="rc-button" (click)="editPetDetails(PanelSection.PetNutritionalAssessment)">
        <app-icon class="rc-button__icon-title" [name]="IconName.arrow_go"></app-icon>

        <span
          [matTooltipPosition]="'above'"
          matTooltip="{{ 'sr-xray_block_title' | translateKey }}"
          matTooltipClass="tooltip-arrow-down"
          #tooltip="matTooltip"
          class="rc-button__title rc-button__title--link"
          *ngIf="displayTooltipLongerWord('sr-xray_block_title' | translateKey); else nutritionalAssessment_content"
          class="rc-button__title rc-button__title--link"
          >{{ 'sr-xray_block_title' | translateKey }}
        </span>

        <ng-template #nutritionalAssessment_content>
          <span class="rc-button__title rc-button__title--link">{{ 'sr-xray_block_title' | translateKey }} </span>
        </ng-template>
      </button>
    </div>
  </div>
</div>
