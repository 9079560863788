<div class="rc-spinner-wrapper">
  <div class="rc-spinner">
    <div class="blade"></div>
    <div class="blade"></div>
    <div class="blade"></div>
    <div class="blade"></div>
    <div class="blade"></div>
    <div class="blade"></div>
    <div class="blade"></div>
    <div class="blade"></div>
    <div class="blade"></div>
    <div class="blade"></div>
    <div class="blade"></div>
    <div class="blade"></div>
  </div>
</div>
