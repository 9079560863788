import { Injectable } from '@angular/core';
import { Clinic } from '@app/core/models';
import { AppFeature, ClinicFeature, NewClinicFeature } from '@app/shared/utils/enums/feature.enum';
import { VetFacade } from '@app/store/vet';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  constructor(private vetFacade: VetFacade) {}
  /**
   * Observable that returns feature activation status
   */
  isEnabled$(featureName: ClinicFeature | AppFeature): Observable<boolean> {
    return this.vetFacade.clinic$.pipe(map((clinic) => this.isEnabled(featureName, clinic)));
  }
  /**
   * Check feature activation status for specific market & clinic
   * To be activated, a feature must be activated for the market and for the clinic
   * It must remain private so we don't do sync checks, as country can change during navigation
   */
  private isEnabled(featureName: ClinicFeature | AppFeature, clinic: Clinic): boolean {
    if (!clinic) return false;

    // TODO #339837 remove NewFeaturesMappingLastFeatures after remove ClinicFeature and AppFeature and rename NewClinicFeature to ClinicFeature
    const NewFeaturesMappingLastFeatures: Record<string, (NewClinicFeature | string)[]> = {
      [ClinicFeature.DA]: [NewClinicFeature.DA],
      [ClinicFeature.RD]: [NewClinicFeature.RD],
      [ClinicFeature.WM]: [NewClinicFeature.WM],
      [ClinicFeature.SR]: [NewClinicFeature.SR],
      [ClinicFeature.FR]: [NewClinicFeature.FR],
      [AppFeature.MUF_SK]: [NewClinicFeature.MF_SK],
      [AppFeature.MUF_PB]: [NewClinicFeature.MF_PB],
      [ClinicFeature.MUF]: [NewClinicFeature.MF_SK, NewClinicFeature.MF_PB],
    };
    return !!clinic.newFeatures.find((f) => NewFeaturesMappingLastFeatures[featureName].includes(f));
  }
}
