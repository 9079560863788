import { Component, OnInit } from '@angular/core';

import { FollowUpSelector, FollowUpAction, Weight } from '@app/core/models';
import { ModalService } from '@app/core/services';
import { ConfigInjector } from '@app/core/services/utils/injector';
import { MeasureHelper, MeasurementCodeType } from '@app/shared/utils';

import { translateKey } from '@app/shared/utils/static-helpers/translate';

@Component({
  selector: 'app-follow-up-selector-popin',
  templateUrl: './follow-up-selector-popin.component.html',
  styleUrls: ['./follow-up-selector-popin.component.scss'],
})
export class FollowUpSelectorPopinComponent implements OnInit {
  followUpSelector: FollowUpSelector;
  choiceOne: FollowUpAction;
  choiceTwo: FollowUpAction;
  title = '';
  description = '';

  constructor(public config: ConfigInjector, private modal: ModalService) {}

  ngOnInit() {
    this.followUpSelector = this.config.data.followUpSelector;
    /**
     * We need to ensure weights are in the same unit
     */
    const previousWeight: Weight = this.config.data.previousWeight;
    const targetUnit: MeasurementCodeType = this.config.data.targetUnit;
    const currentWeightMeasure: number = this.config.data.currentWeightMeasure;

    const convertedPreviousWeight = MeasureHelper.convertWeight(previousWeight.measure, previousWeight.measureUnit, targetUnit);
    /**
     * We calculate the weight diff
     * (variable is named weightLoss to match the one in the translation key)
     */
    const weightLoss = Math.abs(convertedPreviousWeight.measure - currentWeightMeasure).toFixed(2);
    /**
     * We translate keys with weight params
     * convertedPreviousWeight.unitSuffix is passed as unit since it is the translation of the targerUnit
     */
    this.title = translateKey(`${this.followUpSelector.recommendationCode}_title`, {
      weightLoss,
      unit: convertedPreviousWeight.unitSuffix,
    });
    this.description = translateKey(`${this.followUpSelector.recommendationCode}_description`, {
      weightLoss,
      unit: convertedPreviousWeight.unitSuffix,
    });
    const [firstAction, secondAction] = this.followUpSelector.actions;
    this.choiceOne = firstAction;
    this.choiceTwo = secondAction;
  }

  selectAction(action: FollowUpAction) {
    this.modal.close(action);
  }
}
