<app-rc-dialog-wrapper noClose="true">
  <div class="clinic-identified-dialog">
    <div class="clinic-identified-dialog__body">
      <!-- Image -->
      <div class="clinic-identified-dialog__body-thumb">
        <img src="assets/other/bengal_adult.png" alt="Clinic identified" />
      </div>

      <!-- Text -->
      <h3 class="rc-title-gamma" i18n="@@clinic-identified_title">Clinic identified</h3>
      <p class="rc-paragraph">
        <span i18n="@@clinic-identified_desc-account-affiliated">Your account has been affiliated with</span>
        {{ clinicName }} ({{ clinicId }})
      </p>
      <br />
      <p class="clinic-identified-dialog__paragraph rc-paragraph" i18n="@@clinic-identified_desc-contact">
        If this is not the right clinic, please retry or
      </p>
      &nbsp;
      <p
        class="clinic-identified-dialog__paragraph rc-paragraph rc-button__title rc-button__title--link pointer"
        (click)="contact()"
        i18n="@@clinic-identified_action-contact"
      >
        contact your local support.
      </p>
    </div>

    <div class="clinic-identified-dialog__footer">
      <div class="rc-button-group">
        <button class="rc-button" type="button" (click)="retry()">
          <span class="rc-button__title rc-button__title--link" i18n="@@action_retry">Retry</span>
        </button>
        <span class="rc-button__separator" i18n="@@or-key">or</span>
        <button class="rc-button rc-button--filled-small" type="button" (click)="continue()">
          <span class="rc-button__title" i18n="@@action_accept">Accept</span>
        </button>
      </div>
    </div>
  </div>
</app-rc-dialog-wrapper>
