import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RcTooltipComponent } from '../rc-tooltip/rc-tooltip.component';
import { IconName } from '@app/shared/utils/icon/icons';
import { SharedRegisterIconsModule } from '@app/shared/shared-register-icons.module';
import { SharedPipesModule } from '@app/shared/pipes/shared-pipes.module';

@Component({
  selector: 'app-wlp-tooltip',
  standalone: true,
  imports: [CommonModule, RcTooltipComponent, SharedRegisterIconsModule, SharedPipesModule],
  templateUrl: './wlp-tooltip.component.html',
  styleUrls: ['./wlp-tooltip.component.scss'],
})
export class WlpTooltipComponent {
  public readonly IconName = IconName;
}
