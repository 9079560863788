<div class="rc-gauge" (mouseover)="setMouseOver(true)" (mouseout)="setMouseOver(false)">
  <div class="rc-gauge__progress" [style.height]="height" [style.width]="fullWidth">
    <svg class="progress-svg" [attr.height]="height" [attr.width]="fullWidth" overflow="hidden">
      <defs>
        <linearGradient [id]="linearId" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" [attr.stop-color]="colorLineStart" />
          <stop offset="100%" [attr.stop-color]="colorLineEnd" />
        </linearGradient>
      </defs>
      <rect x="0" y="0" [attr.rx]="height / 2" [attr.width]="greyLineEnd" [attr.height]="height" fill="whitesmoke" />
      <rect
        x="0"
        y="0"
        [attr.rx]="height / 2"
        [attr.width]="coloredLineEnd"
        [attr.height]="height"
        [attr.fill]="'url(#' + linearId + ')'"
      />
    </svg>
  </div>
  <p class="rc-gauge__pmean">
    <ng-container *ngIf="value === -1" i18n="@@sr-product_pmean_na">NA</ng-container>
    <ng-container *ngIf="value !== -1">{{ value }}%</ng-container>
  </p>
</div>
