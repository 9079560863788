<app-rc-dialog-wrapper (closePopin)="close()">
  <div class="upload-logo-dialog-content">
    <div class="upload-logo" *ngIf="!isLoading; else loader_content">
      <h1 class="upload-logo__title rc-title-beta">
        <span i18n="@@title-upload-logo">Upload a logo</span>
      </h1>
      <p class="upload-logo__position-text" i18n="@@message-position-image">Position and size your logo</p>
      <ng-container *ngIf="!uploadSuccess; else successContent">
        <div class="upload-logo__circle" [class.is-failed]="uploadSizeFailed" (click)="inputEvent.click()">
          <em class="rc-icon rc-icon--upload" [class.is-failed]="uploadSizeFailed"></em>
        </div>
      </ng-container>
      <ng-template #successContent>
        <img class="upload-logo__image" [src]="imageUrl" alt="logo" />
      </ng-template>
      <input
        class="upload-logo__choose-file-button"
        type="file"
        name="profile_pic"
        accept=".jpg, .jpeg, .png, .gif"
        (change)="onFileSelect($event)"
        #inputEvent
      />
      <button class="upload-logo__button-container rc-button" [class.uploadCallSuccess]="uploadSuccess" (click)="inputEvent.click()">
        <app-icon class="icon-food" [name]="IconName.food_2"></app-icon>

        <span class="upload-logo__button-container-text rc-button__title rc-button__title--link" i18n="@@action_upload-picture"
          >upload picture</span
        >
      </button>

      <ng-container *ngIf="!uploadSuccess">
        <p class="upload-logo__maximum-text" i18n="@@message-file-size">Maximum size file: 2MB</p>
        <p class="upload-logo__extension-text" i18n="@@message-file-type">Allowed file type : JPEG, JPG, GIF, PNG</p>
      </ng-container>

      <div class="upload-logo__error-message-container" *ngIf="uploadSizeFailed">
        <em class="upload-logo__error-message-container-image rc-icon rc-icon--cross"></em>
        <span class="upload-logo__error-message-container-text">{{ errorMessage }}</span>
      </div>

      <div class="upload-logo__action-container" *ngIf="uploadSuccess">
        <div class="rc-button-group">
          <button class="rc-button" (click)="close()">
            <span class="rc-button__title--link" i18n="@@action_cancel">{{ 'cancel' | titlecase }}</span>
          </button>
          <span class="rc-button__separator" i18n="@@action_separator">or</span>
          <button class="rc-button rc-button--filled-small" (click)="submit()">
            <span class="rc-button__title" i18n="@@action_send">{{ 'send' | titlecase }}</span>
          </button>
        </div>
      </div>
    </div>
    <ng-template #loader_content>
      <div class="loader"></div>
    </ng-template>
  </div>
</app-rc-dialog-wrapper>
