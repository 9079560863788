import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BenefitClaim, Influencer } from '@app/shared/components/nutrients-list/nutrients';
import { DetectMobile, getCriteriaTitleTranslationKey } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RCTagTheme, RCTagSize } from '@rc/ui';
import { CriteriaDetailsPopinComponent } from '..';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-nutrients-benefits-list',
  templateUrl: './nutrients-benefits-list.component.html',
  styleUrls: ['./nutrients-benefits-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NutrientsBenefitsListComponent {
  @Input() benefits: BenefitClaim[];
  @Input() nutrientListTitle: string;

  public readonly RCTagTheme = RCTagTheme;
  public readonly RCTagSize = RCTagSize;
  public readonly IconName = IconName;
  public isMobile = DetectMobile.isMobileNotDeprecated();

  constructor(private dialog: MatDialog) {}

  public getInfluencerLabel(influencer: Influencer): string {
    return translateKey(getCriteriaTitleTranslationKey(influencer));
  }

  public getNutrientLabel(nutrientCode: string): string {
    return translateKey(`claim_{{${nutrientCode}}}_benefit_claim`);
  }

  showInfluencerDescriptionPopin(unfluencer?: Influencer): void {
    this.dialog.open(CriteriaDetailsPopinComponent, {
      data: {
        selectedCriteria: unfluencer,
        panelClass: 'small',
      },
    });
  }
}
