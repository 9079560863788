import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderModule } from '@app/core/components/loader/loader.module';
import { RcDialogWrapperComponent } from './rc-dialog-wrapper.component';
import { IconModule } from '@app/shared/utils/icon/icon.module';

@NgModule({
  imports: [CommonModule, LoaderModule, IconModule],
  declarations: [RcDialogWrapperComponent],
  exports: [RcDialogWrapperComponent],
})
export class RCDialogWrapperModule {}
