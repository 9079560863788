import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Nutrient } from '@app/shared/components/nutrients-list/nutrients';
import { MeasureHelper } from '@app/shared/utils';

@Component({
  selector: 'app-nutrients-bar',
  templateUrl: './nutrient-bar.component.html',
  styleUrls: ['./nutrient-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NutrientBarComponent implements OnInit {
  @Input() nutrients: Nutrient;
  @Input() id: string;
  public spreadValue = 4;
  public deltaTarget = 10;
  public formattedNutrients: Nutrient;

  ngOnInit(): void {
    this.formattedNutrients = this.formatNutrientsForDiagram(this.nutrients);
  }

  public getCxValue(nutrientValues: Nutrient): number {
    if (nutrientValues.targetPercentage > 100 - this.spreadValue) {
      return 100 - this.spreadValue;
    }
    if (nutrientValues.targetPercentage < this.spreadValue) {
      return this.spreadValue;
    } else {
      return nutrientValues.targetPercentage;
    }
  }

  public formatNutrientsForDiagram(nutrient: Nutrient): Nutrient {
    const minDeltaZone = nutrient.targetPercentage - this.spreadValue - this.deltaTarget;
    const minSpreadZone = nutrient.targetPercentage - this.spreadValue;
    const maxDeltaZone = nutrient.targetPercentage + this.deltaTarget + this.spreadValue;
    const maxSpreadZone = nutrient.targetPercentage + this.spreadValue;

    if (nutrient.minPercentage > minDeltaZone) {
      // if minPercentage is inside delta zone, we prioritise the display of the delta zone
      return { ...nutrient, minPercentage: minDeltaZone };
    } else if (nutrient.minPercentage > minSpreadZone) {
      // if minPercentage is inside the spread zone (gradient zone), we  prioritise the display of the gradient
      return { ...nutrient, minPercentage: minSpreadZone };
    }
    if (nutrient.maxPercentage < maxDeltaZone) {
      // if maxPercentage is inside delta zone, we prioritise the display of the delta zone
      return { ...nutrient, maxPercentage: maxDeltaZone };
    } else if (nutrient.maxPercentage < maxSpreadZone) {
      // if maxPercentage is inside the spread zone (gradient zone), we  prioritise the display of the gradient
      return { ...nutrient, maxPercentage: maxSpreadZone };
    }
    // target percentage should not big greater than 100 nor smaller than 0.
    if (nutrient.targetPercentage > 100) {
      return { ...nutrient, targetPercentage: 100 };
    }
    if (nutrient.targetPercentage < 0) {
      return { ...nutrient, targetPercentage: 0 };
    }
    return nutrient;
  }

  public getFill(index: string): string {
    return `url(#${index})`;
  }

  public getHoverZoneXValue(nutrients: Nutrient): number {
    return ((nutrients.targetPercentage - 10) / 100) * 240;
  }

  public getTooltipText(nutrients: Nutrient): string {
    return `Target: ${nutrients.targetValue?.low} - ${nutrients.targetValue?.high} ${MeasureHelper.measureUnitToSuffix(
      nutrients?.nutrient?.unit
    )}`;
  }

  public getOffsetValue(offsetNbr: number, nutrientValues: Nutrient): string {
    switch (offsetNbr) {
      case 1:
        //white zone end
        return `${nutrientValues.minPercentage - this.spreadValue}%`;
      case 2:
        //light green (min percentage zone) start
        return `${nutrientValues.minPercentage + this.spreadValue}%`;
      case 3:
        //light green (min percentage zone) end
        return `${nutrientValues.targetPercentage - this.deltaTarget - this.spreadValue}%`;
      case 4:
        //dark green (delta zone) start
        return `${nutrientValues.targetPercentage - this.deltaTarget + this.spreadValue}%`;
      case 5:
        //dark green (delta zone) end
        return `${nutrientValues.targetPercentage + this.deltaTarget - this.spreadValue}%`;
      case 6:
        //light green (max percentage zone) start
        return `${nutrientValues.targetPercentage + this.deltaTarget + this.spreadValue}%`;
      case 7:
        //light green (max percentage zone) end
        return `${nutrientValues.maxPercentage - this.spreadValue}%`;
      case 8:
        //white
        return `${nutrientValues.maxPercentage + this.spreadValue}%`;
    }
  }
}
