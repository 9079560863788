<form class="form-block" [formGroup]="settingsForm">
  <div id="settings-field-language" *ngIf="languagesItems?.length" class="field">
    <rc-select
      [id]="'user-settings-select-language'"
      formControlName="language"
      [items]="languagesItems"
      label="{{ 'form-attribute_language' | translateKey }} {{ 'form-attribute_optional' | translateKey }}"
    ></rc-select>
  </div>
  <div id="settings-field-systemPreference" *ngIf="systemPreferenceItems?.length" class="field">
    <rc-select
      [id]="'user-settings-select-system-preference'"
      formControlName="systemPreference"
      [items]="systemPreferenceItems"
      label="{{ 'form-attribute_unit' | translateKey }} {{ 'form-attribute_optional' | translateKey }}"
    >
    </rc-select>
  </div>
  <div class="submit-wrapper">
    <button (click)="cancel.emit()" id="cancel-settings" class="rc-button rc-button--primary" type="button">
      <span class="rc-button__title rc-button__title--link" i18n="@@action_cancel">Cancel</span>
    </button>
    <span class="rc-button__separator" i18n="@@action_separator">or</span>
    <button
      (click)="submitted.emit(this.settingsForm.getRawValue())"
      id="submit-settings"
      class="rc-button rc-button--filled-small"
      type="submit"
    >
      <span class="rc-button__title" i18n="@@action_save-changes">Save changes</span>
    </button>
  </div>
</form>
