<app-rc-dialog-wrapper noClose="true">
  <div class="email-popin-wrapper">
    <div class="email-popin-content">
      <div class="email-popin-content-left">
        <ng-container>
          <h3 class="rc-title-gamma" i18n="@@invite-popin_title">Invite people</h3>
          <h3 class="rc-title-gamma">{{ (clinic$ | async).legalName || '' }}</h3>
          <p class="rc-paragraph" i18n="@@invite-popin_desc">
            Send an email invitation to let a new user join your clinic space on Vet Services
          </p>
        </ng-container>
      </div>

      <div class="email-popin-content-right">
        <form class="rc-dialog-form__block" autocomplete="disabled" [formGroup]="contactForm">
          <!-- Email -->
          <div class="rc-dialog-form__input">
            <rc-field
              id="email-popin-input"
              data-cs-mask
              formControlName="email"
              type="email"
              autocomplete="off"
              [label]="contactFields.email.placeholder"
              [error]="emailErrorMessage()"
            >
            </rc-field>
          </div>
          <!-- Actions -->
          <div class="rc-dialog-form__action rc-dialog-form__action--align-bottom">
            <div class="rc-button-group">
              <button id="email-popin__cancel-button" (click)="dismiss()" class="rc-button" type="button">
                <span class="rc-button__title--link" i18n="@@action_cancel">{{ 'cancel' | titlecase }}</span>
              </button>
              <span class="rc-button__separator" i18n="@@action_separator">or</span>
              <button
                id="email-popin__send-button"
                (click)="submitForm()"
                class="rc-button rc-button--filled-small"
                [disabled]="!contactForm.valid"
              >
                <span class="rc-button__title" i18n="@@action_send_invitation">Send invitation</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-rc-dialog-wrapper>
