<div class="rc-tooltip">
  <div class="rc-tooltip-main">
    <ng-content select="[main]"></ng-content>
  </div>

  <div
    [ngClass]="{
      active: isActive,
      'rc-tooltip-content--pos-prim-bottom': primaryPosition === 'bottom',
      'rc-tooltip-content--pos-sec-left': secondaryPosition === 'left',
      'rc-tooltip-content--pos-sec-center': secondaryPosition === 'center',
      'rc-tooltip-content--pos-sec-right': secondaryPosition === 'right',
      'rc-tooltip-content--size-medium': size === 'medium',
      'rc-tooltip-content--size-large': size === 'large'
    }"
    class="rc-tooltip-content"
  >
    <ng-content select="[tooltip]"></ng-content>
  </div>
</div>
