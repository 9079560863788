import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownOption } from '@app/pages/pet-page/container/pet-page.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss'],
})
export class DropdownListComponent {
  @Input()
  selectables: DropdownOption[];
  @Input()
  dropdownOpen$: BehaviorSubject<boolean>;
  @Input()
  buttonTitle: string;

  @Output()
  eventClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  eventSelect: EventEmitter<any> = new EventEmitter<any>();

  onOpenDropdown(): void {
    this.eventClick.emit();
  }

  onSelectOption(option: any): void {
    this.eventSelect.emit(option);
  }
}
