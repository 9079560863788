export interface Nutrient {
  nutrient: NutrientId;
  influencers: Influencer[];
  minPercentage: number;
  maxPercentage: number;
  targetPercentage: number;
  targetValue: HighLowValues;
  cutOff: HighLowValues;
}

export interface HighLowValues {
  low: number;
  high: number;
}

export interface Influencer {
  type: string;
  key: string;
}

export interface NutrientId {
  id: string;
  unit: string;
}

export enum NutrientType {
  MACRONUTRIENTS = 'macronutrients',
  OTHER_NUTRIENTS = 'otherNutrients',
  BENEFITS = 'benefits',
}

export interface BenefitClaim {
  id: string;
  influencers: Influencer[];
}

export interface NutritionalAssessment {
  macroNutrients: Nutrient[];
  otherNutrients?: Nutrient[];
  benefits: BenefitClaim[];
}
