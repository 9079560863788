import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Product, ProductPriceEdit } from '@app/core/models';
import { LoaderService, ProductService } from '@app/core/services';
import { Logger } from '@app/core/services/utils/logger';
import { SpeciesCode } from '@app/shared/utils';
import { replaceInArray } from '@app/shared/utils/static-helpers/array.utils';
import { VetFacade } from '@app/store/vet';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements AfterViewInit {
  public readonly isStandalone = environment.isStandalone;

  /**
   * Properties
   */

  previousElm = null;
  productId: string;
  isSelected: boolean;
  selectButtonLabel?: string;
  product: Product;
  currentSection = 1;
  showSelectButton = false;
  placeholderBenefitImage = './assets/other/placeholder-benefits.svg';
  selectedProductsIds: [];
  selectedPackId: '';
  priceList: ProductPriceEdit[] = [];
  public showWholesalePrices$ = of(false);

  removeProduct: (data: Product) => void;
  selectProduct: (data: Product, packId: string) => void;
  close: () => void;

  /**
   * Initializer
   */
  constructor(
    public config: MatDialogConfig,
    public dialogRef: MatDialogRef<ProductDetailComponent>,
    private loaderService: LoaderService,
    private productService: ProductService,
    private logger: Logger,
    private vetFacade: VetFacade,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.productId = this.data.productId;
    this.isSelected = this.data.isSelected;
    this.selectButtonLabel = this.data.selectButtonLabel;
    this.showSelectButton = this.data.showSelectButton;

    this.selectedProductsIds = this.data.selectedProductsIds;
    this.selectProduct = this.data.isSelectProduct;
    this.removeProduct = this.data.isRemoveProduct;
    this.close = this.data.close;
  }

  ngAfterViewInit(): void {
    this.fetchProductById();
  }

  closePopin(): void {
    this.dialogRef.close();
  }

  onSelectProduct(event): void {
    this.selectedPackId = event.packId;
    this.selectProduct(event.product, this.selectedPackId);
    this.dialogRef.close();
  }

  onRemoveProduct(product: Product): void {
    this.removeProduct(product);
    this.dialogRef.close();
  }

  onPriceListChange(packagePrice: ProductPriceEdit): void {
    this.priceList = replaceInArray(this.priceList, packagePrice, (value) => value.pack.sCode, true);
  }

  displayFirstDetail(): void {
    const firstElem = document.getElementsByClassName('section-first-header')[0];
    if (firstElem) {
      this.showMoreDetail(1, { currentTarget: firstElem }, true);
    }
  }

  showMoreDetail(section: number, event: any, first = false): void {
    if (this.previousElm) {
      this.previousElm.parentElement.style.maxHeight = `${this.previousElm.offsetHeight}px`;
    }
    if (!first && this.currentSection === section) {
      this.currentSection = 0;
      this.previousElm = null;
    } else {
      this.currentSection = section;
      const currentElm = event.currentTarget;
      const parentElm = currentElm.parentElement;
      const detailElm = parentElm.querySelector('.section-detail');
      const detailElmHeight = detailElm.offsetHeight;
      this.previousElm = currentElm;
      parentElm.style.maxHeight = `${detailElmHeight + currentElm.offsetHeight}px`;
    }
  }

  fetchProductById(): void {
    this.loaderService.startLoader(false, document.getElementsByClassName('product-detail')[0]);
    combineLatest([this.productService.fetchProductById(this.productId), this.vetFacade.wholesalePrices$]).subscribe(
      ([product, wholesalePrices]: [Product, Record<string, number>]) => {
        this.product = product;
        this.placeholderBenefitImage = `./assets/other/placeholder-benefits-${
          this.product.speciesCode === SpeciesCode.Cat ? 'cat' : 'dog'
        }.svg`;
        this.loaderService.stopLoader(() => {
          setTimeout(() => {
            this.displayFirstDetail();

            this.showWholesalePrices$ = this.vetFacade.isWholesalePriceActivated$.pipe(
              map(
                (isWholesalePriceActivated) =>
                  !environment.isStandalone && isWholesalePriceActivated && !!wholesalePrices[product.packages[0].ean]
              )
            );
          }, 100);
        });
      },
      (error) => {
        this.logger.errorString('fetchProductById', error);
      }
    );
  }
}
