<app-rc-dialog-wrapper (closePopin)="closePopin()">
  <div class="product-detail-wrapper">
    <div class="product-detail">
      <!-- Left content on the popin -->
      <div class="product-detail-product" *ngIf="product">
        <app-product-card
          class="product-detail-card"
          [showSelectButton]="showSelectButton"
          [selectButtonLabel]="selectButtonLabel"
          [showRetailPrices]="!isStandalone"
          [disableDetailsButton]="data?.disableDetailsButton"
          [product]="product"
          [packId]="data?.packId"
          [excludeStarterKitPacks]="data?.excludeStarterKitPacks ?? true"
          [displayPackages]="!isStandalone"
          [selectedProductsIds]="selectedProductsIds"
          (selectProduct)="onSelectProduct($event)"
          (removeProduct)="onRemoveProduct($event)"
        ></app-product-card>
      </div>

      <!-- Right content on the popin -->
      <div class="product-detail-nav">
        <ng-container *ngIf="product">
          <!-- Product Section: Main benefits -->
          <div class="product-detail-section" [class.extended]="currentSection === 1">
            <!-- Product Section header -->
            <div class="section-header section-first-header" (click)="showMoreDetail(1, $event)">
              <h2 class="rc-title-beta rc-title--right-icon">
                <span class="product-detail-section__header--text" i18n="@@dialog-product-detail_benefit-label">Main benefits</span>
                <em class="rc-icon rc-icon--arrow-down" [class.active]="currentSection === 1"></em>
              </h2>
            </div>

            <!-- Product Detail -->
            <ng-container *ngIf="product.benefits?.length > 0; else defaultText">
              <div class="section-detail">
                <!-- Product detail: Skin Barrier -->
                <div class="section-detail-item" *ngFor="let benefit of product.benefits">
                  <div class="section-detail-header">
                    <img class="section-detail-icon" [src]="benefit.image || placeholderBenefitImage" [alt]="benefit.title || ''" />

                    <p class="section-detail-title">
                      {{ benefit.title?.toUpperCase() }}
                    </p>
                  </div>
                  <p class="section-detail-text">
                    {{ benefit.description }}
                  </p>
                </div>
              </div>
            </ng-container>
            <ng-template #defaultText>
              <div class="section-detail">
                <span>--</span>
              </div>
            </ng-template>
          </div>

          <!-- Product Section: Description -->
          <div *ngIf="product.description?.length > 0" class="product-detail-section" [class.extended]="currentSection === 2">
            <!-- Product Section header -->
            <div class="section-header" (click)="showMoreDetail(2, $event)">
              <h2 class="rc-title-beta rc-title--right-icon">
                <span class="product-detail-section__header--text" i18n="@@dialog-product-detail_description-label">Description</span>
                <em class="rc-icon rc-icon--arrow-down" [class.active]="currentSection === 2"></em>
              </h2>
            </div>

            <!-- Product Detail -->
            <div class="section-detail">
              <p class="section-detail-item" *ngFor="let descriptionItem of product.description">
                {{ descriptionItem }}
              </p>
            </div>
          </div>

          <!-- Product Section: Ingredients -->
          <div class="product-detail-section" [class.extended]="currentSection === 3">
            <!-- Product Section header -->
            <div class="section-header" (click)="showMoreDetail(3, $event)">
              <h2 class="rc-title-beta rc-title--right-icon">
                <span class="product-detail-section__header--text" i18n="@@product-book_product_information">Product Information</span>
                <em class="rc-icon rc-icon--arrow-down" [class.active]="currentSection === 3"></em>
              </h2>
            </div>

            <!-- Product Detail -->
            <div class="section-detail">
              <p class="section-detail-item" *ngFor="let compositionItem of product.composition">
                {{ compositionItem }}
              </p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <span *ngIf="showWholesalePrices$ | async">
      {{ 'rc-table_b2b-price-de-ch-precision' | translateKey }}
    </span>
  </div>
</app-rc-dialog-wrapper>
