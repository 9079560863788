<app-rc-dialog-wrapper noClose="true">
  <div class="confirmation-popin">
    <div class="image-confirmation">
      <img src="./assets/other/Group.png" alt="" />
    </div>

    <h3 class="rc-title-gamma confirmation-title">{{ 'Share-reco_pop-in_title-consultation-not-save' | translateKey }}</h3>

    <p class="rc-paragraph">
      {{ 'Share-reco_pop-in_description-save-consultation' | translateKey }}
    </p>

    <div class="rc-button-group">
      <button class="rc-button rc-button--filled-small" type="button" (click)="save()" id="bt_save-consultation">
        <span class="rc-button__title">{{ 'action_save-consultation' | translateKey }}</span>
      </button>
      <span class="rc-button__separator btn-separator" i18n="@@action_separator">or</span>
      <button class="rc-button" type="button" (click)="exitWithoutSave()" id="exit_without_saving_button">
        <span class="rc-button__title rc-button__title--link">{{ 'exit_without_saving_button' | translateKey }}</span>
      </button>
    </div>
  </div>
</app-rc-dialog-wrapper>
