<form [formGroup]="form" class="form" autocomplete="disabled">
  <div class="form-fields">
    <div class="fields-wrapper">
      <div class="field-wrapper field-wrapper-left" id="pet-owner-form-field-email">
        <rc-field
          data-cs-mask
          formControlName="email"
          [autocomplete]="'disable'"
          type="text"
          [label]="'form-attribute_email' | translateKey"
          [error]="requiredErrorMessage('email', 'form-attribute_email') || emailErrorMessage()"
        ></rc-field>
        <p class="email-message" *ngIf="emailMessage$ | async as message">
          {{ message }}
        </p>
      </div>
      <div class="field-wrapper phone-wrapper field-wrapper-right" id="pet-owner-form-field-telephone">
        <rc-phone-field
          formControlName="telephone"
          [label]="'form-attribute_phone' | translateKey"
          [label]="'form-attribute_phone' | translateKey"
          [error]="phoneErrorMessage()"
        >
        </rc-phone-field>
      </div>
    </div>
    <div class="fields-wrapper">
      <div class="field-wrapper field-wrapper-left" id="pet-owner-form-field-givenName">
        <rc-field
          data-cs-mask
          formControlName="givenName"
          [autocomplete]="'disable'"
          type="text"
          [label]="'form-attribute_first-name' | translateKey"
          [error]="requiredErrorMessage('givenName', 'form-attribute_first-name')"
        ></rc-field>
      </div>
      <div class="field-wrapper field-wrapper-right" id="pet-owner-form-field-familyName">
        <rc-field
          data-cs-mask
          formControlName="familyName"
          [autocomplete]="'disable'"
          type="text"
          [label]="'form-attribute_last-name' | translateKey"
          [error]="requiredErrorMessage('familyName', 'form-attribute_last-name')"
        ></rc-field>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="field-wrapper-checkbox">
      <div class="field-checkbox">
        <app-checkbox
          formControlName="noEmail"
          id="pohasnoemail"
          [label]="'create-patient_petowner-hasno-email-adress' | translateKey"
          [multiLine]="true"
        ></app-checkbox>
      </div>
      <div class="field-checkbox">
        <app-checkbox
          formControlName="consent"
          id="consent"
          [label]="'create-patient_legal' | translateKey"
          [multiLine]="true"
        ></app-checkbox>
      </div>
    </div>
    <button
      class="rc-button rc-button--filled-small"
      id="pet-owner-submit-button"
      type="button"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      <span class="rc-button__title">{{ submitButtonLabel }}</span>
    </button>
  </div>
</form>
