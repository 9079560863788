import { Component, Input, OnInit } from '@angular/core';
import { AttributeType, FormField, Patient } from '@app/core/models';
import { Constants, Helper, SpeciesCode } from '@app/shared/utils';
import { VetFacade } from '@app/store/vet';

@Component({
  selector: 'app-pet-profile-header',
  templateUrl: './pet-profile-header.component.html',
})
export class PetProfileHeaderComponent implements OnInit {
  Constants = Constants;

  @Input()
  set patient(patient: Patient) {
    if (patient) {
      this._setPatient(patient);
    }
  }
  get patient(): Patient {
    return this._patient;
  }

  private _patient: Patient;
  petName: string;
  petImageUrl: string;

  // Data for profile bloc
  object: any;
  petTitleAttributes: FormField[];

  public readonly currentSystemPreferenceCode$ = this.vetFacade.currentSystemPreferenceCode$;
  public readonly currentBigMeasurementUnit$ = this.vetFacade.currentBigMeasurementUnit$;

  constructor(private vetFacade: VetFacade) {}

  ngOnInit() {
    this._setAttributes();
  }

  private _setAttributes() {
    this.petTitleAttributes = [
      {
        id: 'breed',
        path: '',
        type: AttributeType.Breed,
        getter: Helper.getterPetBreedCode,
        label: $localize`:@@form-attribute_breed:`,
      },
      {
        id: 'age',
        path: 'birth.date',
        type: AttributeType.Age,
        label: $localize`:@@form-attribute_age:`,
      },
      {
        id: 'gender',
        path: 'genderCode',
        type: AttributeType.Gender,
        label: $localize`:@@form-attribute_gender:`,
      },
      {
        id: 'weight',
        path: 'currentDisplayedWeight',
        type: AttributeType.Weight,
        label: $localize`:@@form-attribute_weight:`,
      },
    ];
  }

  /**
   * Update local variable with patient object
   * @param patient - Patient object
   */
  private _setPatient(patient: Patient) {
    if (patient && patient.pet) {
      this._patient = patient;
      this.petName = patient.pet.name;

      switch (patient.pet.speciesCode) {
        case SpeciesCode.Dog:
          this.petImageUrl = Constants.placeholderImgDog;
          break;
        case SpeciesCode.Cat:
          this.petImageUrl = Constants.placeholderImgCat;
          break;
        default:
          this.petImageUrl = '';
      }
      this.object = { ...patient.pet };
    }
  }
}
