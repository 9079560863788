import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getSmartRecoNutritionalAssessment } from '@app/pages/smart-reco/store/actions/smart-reco-recommendation.actions';
import {
  selectNutritionalAssessmentBenefitClaim,
  selectNutritionalAssessmentHasError,
  selectNutritionalAssessmentLoading,
  selectNutritionalAssessmentMacronutrients,
  selectNutritionalAssessmentOtherNutrients,
} from '@app/pages/smart-reco/store/selectors/smart-reco-nutritional-assessment.selectors';
import { NutrientType } from '@app/shared/components/nutrients-list/nutrients';
import { IconName } from '@app/shared/utils/icon/icons';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { nutrientInfographyMock } from './nutritional-assessment';
import { GTMService, PageBlockEnum, patientBlockDisplay } from '@app/core/services/tracking';
import { SupportPageService } from '@app/pages/support-page/services/support-page.service';

@Component({
  selector: 'app-nutritional-assessment',
  templateUrl: './nutritional-assessment.component.html',
  styleUrls: ['./nutritional-assessment.component.scss'],
})
export class NutritionalAssessmentComponent implements OnInit {
  @Output() submitted = new EventEmitter();

  public macroNutrients$ = this.store$.select(selectNutritionalAssessmentMacronutrients);
  public otherNutrients$ = this.store$.select(selectNutritionalAssessmentOtherNutrients);
  public benefitClaim$ = this.store$.select(selectNutritionalAssessmentBenefitClaim);
  public nutritionalAssessmentHasError$ = this.store$.select(selectNutritionalAssessmentHasError);
  public nutritionalAssessmentLoading$ = this.store$.select(selectNutritionalAssessmentLoading);
  public readonly IconName = IconName;
  public readonly NutrientType = NutrientType;
  public nutrientsMock = nutrientInfographyMock;
  public xrayFAQPdfUrl$ = this.supportPageService.getXrayFAQLink$();

  constructor(private store$: Store<AppState>, private trackingService: GTMService, private supportPageService: SupportPageService) {}

  public ngOnInit(): void {
    this.trackingService.sendInteraction(
      patientBlockDisplay({
        block: PageBlockEnum.NUTRITIONAL_ASSESSMENT,
      })
    );
  }

  submitRecommendation(): void {
    this.submitted.emit();
  }

  retryGetNutritionalAssessment(): void {
    this.store$.dispatch(getSmartRecoNutritionalAssessment());
  }
}
