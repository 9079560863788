/**
 *
 * @license Royal Canin
 * @author: Amar YASSA <amar.yassa@royalcanin.com>
 */

import { FormControl } from '@angular/forms';

export enum ContactPopinFormControlNameEnum {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  PRACTICE_NAME = 'practiceName',
  EMAIL_CONTACT = 'emailContact',
  COUNTRY = 'country',
  BODY = 'body',
}

export interface ContactPopinFormValues {
  [ContactPopinFormControlNameEnum.FIRSTNAME]: FormControl<string>;
  [ContactPopinFormControlNameEnum.LASTNAME]: FormControl<string>;
  [ContactPopinFormControlNameEnum.PRACTICE_NAME]: FormControl<string>;
  [ContactPopinFormControlNameEnum.EMAIL_CONTACT]: FormControl<string>;
  [ContactPopinFormControlNameEnum.COUNTRY]: FormControl<string>;
  [ContactPopinFormControlNameEnum.BODY]: FormControl<string>;
}

export interface ContactData {
  countryCode: string;
  locale: string;
  from: string;
  subject: string;
  data: {
    countryCode: string;
    from: string;
    subject: string;
    message: string;
  };
}
