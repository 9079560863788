import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertNotification, Consultation, Patient, PatientSearchAutocomplete } from '@app/core/models';
import { VetService } from '@app/core/services';
import {
  GTMService,
  PageBlockEnum,
  patientBlockDisplay,
  sendPetIdentifiedGtm,
  startConsultationExistingPatientGtm,
} from '@app/core/services/tracking';
import { patientsAutocompleteFilter } from '@app/core/services/utils/search-filters-helper';
import { startWeightManagement } from '@app/pages/weight-management/store/actions/weight-management-steps.actions';
import { WeightManagementStep } from '@app/pages/weight-management/weight-management-step';
import { ActiveProgramPopinComponent } from '@app/shared/components/active-program-popin/active-program-popin.component';
import { Helper, SpeciesCode } from '@app/shared/utils';
import { Tool } from '@app/shared/utils/enums/tool-type.enum';
import { IconName } from '@app/shared/utils/icon/icons';
import { getMUFAvailabilityError, getRDAvailabilityError, getWLPAvailabilityError } from '@app/shared/utils/static-helpers/patient-helper';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { AppState } from '@app/store';
import { setConsultationLastConsultation, setConsultationPatient } from '@app/store/consultation/consultation.actions';
import { setTool } from '@app/store/core';

import { PatientFacade } from '@app/store/patient';
import { Store } from '@ngrx/store';
import { RCAlertType } from '@rc/ui';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-pet-identification',
  styleUrls: ['pet-identification.component.scss'],
  templateUrl: './pet-identification.component.html',
})
export class PetIdentificationComponent implements OnInit, OnDestroy {
  @Input() tool: Tool;
  @Input() forcedSpecieCode?: SpeciesCode;
  @Output() consultationInfosUpdated = new EventEmitter();
  @Output() startWithNewPet = new EventEmitter();
  @Output() alertMessage = new EventEmitter<AlertNotification>();
  @Output() closeMessages = new EventEmitter<undefined>();

  title = '';
  description = '';
  imgSrc = '';
  patients$ = this.patientFacade.selectPatientsAutocomplete$;
  search$ = new BehaviorSubject<string>('');

  showEmptyResult$ = combineLatest([this.patients$, this.patientFacade.patientsLoaded$]).pipe(
    map(([patients, loaded]) => loaded && patients.length === 0 && this.search$.value.length > 0)
  );
  public itemSize = 5;
  public itemHeight = 40;
  IconName = IconName;
  SpeciesCode = SpeciesCode;
  public valueAccessorFn = (patient: Patient) => patient.id;

  constructor(
    private patientFacade: PatientFacade,
    private vetService: VetService,
    private dialog: MatDialog,
    private trackingService: GTMService,
    private store$: Store<AppState>
  ) {}

  ngOnInit(): void {
    const specificToolInfo: {
      title: string;
      description: string;
      imgSrc: string;
    } = {
      [Tool.SmartReco]: {
        title: translateKey('home-page_product-finder-title'),
        description: translateKey('home-page_product-finder-description'),
        imgSrc: './assets/other/smart_reco_dog.png',
      },
      [Tool.WeightManagement]: {
        title: translateKey('home-page_weight-title'),
        description: translateKey('home-page_weight-description'),
        imgSrc: './assets/other/weight_management_dog.png',
      },
      [Tool.RenalDetect]: {
        title: `${translateKey('home-page_renal-detect-title')} ${translateKey('renal-detect_older_7')}`,
        description: translateKey('home-page_renal-detect-description'),
        imgSrc: './assets/other/renal_detect_cat.png',
      },
      [Tool.PersonalizedBag]: {
        title: translateKey('home-page_multi-title'),
        description: translateKey('home-page_multi-description'),
        imgSrc: './assets/other/multifunction_dog.png',
      },
      [Tool.Rationing]: {
        title: translateKey('home-page_daily-title'),
        description: translateKey('home-page_daily-description'),
        imgSrc: './assets/other/daily_allowance_cat.png',
      },
    }[this.tool];
    this.title = specificToolInfo?.title;
    this.description = specificToolInfo?.description;
    this.imgSrc = specificToolInfo?.imgSrc;
    this.patients$ =
      this.forcedSpecieCode && this.forcedSpecieCode !== SpeciesCode.Unknown
        ? this.patientFacade.selectPatientsAutocomplete$.pipe(
            map((patient) => patient?.filter((pet) => pet?.value?.pet?.speciesCode === this.forcedSpecieCode))
          )
        : this.patientFacade.selectPatientsAutocomplete$;

    this._setupSearch();
    this.trackingService.sendInteraction(
      patientBlockDisplay({
        block: PageBlockEnum.IDENTIFICATION,
      })
    );
  }

  ngOnDestroy(): void {
    this.patientFacade.clearState();
    this.search$.unsubscribe();
  }

  onNewSearch(searchStr?: string): void {
    this.search$.next(searchStr);
  }

  clearSearch() {
    this.search$.next('');
  }

  customSearchFilter() {
    return true;
  }

  /**
   * When a patient is selected, fetch the pet and last consultation information using two APIs
   * Then call a third API to calculate lifestage of pet (needed for decision making on next step)
   * Then use this data to check what is possible for this pet and eventually trigger new consultation
   */
  didSelectSearch(item: PatientSearchAutocomplete): void {
    if (item.value?.id) {
      this.vetService
        .fetchPatientInformationForNewConsultation(item.value.id)
        .pipe(take(1))
        .subscribe(
          ({ patient, lastConsultation }) => {
            this.handleConsultationLaunch(patient, lastConsultation);
          },
          (err) => {
            this.alertMessage.emit({ message: err, alertType: RCAlertType.ERROR });
          }
        );
    }
  }

  getAvailabilityError(patient: Patient): string | null {
    switch (this.tool) {
      case Tool.WeightManagement:
        return getWLPAvailabilityError(patient?.pet?.lifeStage);
      case Tool.PersonalizedBag:
        return getMUFAvailabilityError(patient?.pet?.lifeStage);
      case Tool.RenalDetect:
        return getRDAvailabilityError(patient?.pet?.speciesCode, patient?.pet?.birth?.date);
      default:
        break;
    }
  }

  createNewPet(): void {
    this.startWithNewPet.emit();
    this.closeMessages.emit();
    this.vetService.currentBigMeasurementUnit$
      .pipe(
        take(1),
        tap((currentBigMeasurementUnit) => {
          this.trackingService.sendInteraction(
            sendPetIdentifiedGtm({
              patient: null,
              lastConsultation: null,
              currentBigMeasurementUnit,
            })
          );
        })
      )
      .subscribe();
  }

  /**
   * If the consultation is not be possible for this pet, show error message (getAvailabilityError)
   * If patient selected is in a WLP (in lastConsultation) and the vet is trying to create a different consultation, show popin
   **** If "stay on the active program" ==> redirect WLP follow-up page
   **** Else if "start consultation"  ==> start new consultation
   **** Else ==> close popin
   * Else start consultation
   */
  handleConsultationLaunch(patient: Patient, lastConsultation: Consultation): void {
    const error = this.getAvailabilityError(patient);
    if (error) {
      this.alertMessage.emit({ message: error, alertType: RCAlertType.ERROR });
    } else if (this.tool !== Tool.WeightManagement && lastConsultation?.tool === Tool.WeightManagement) {
      this.dialog
        .open(ActiveProgramPopinComponent, {
          panelClass: 'small',
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe((data: any) => {
          if (data && data.stay) {
            this.stayInWLP(patient, lastConsultation);
          } else if (data && data.start) {
            this.startConsultation(patient, lastConsultation);
          }
        });
    } else {
      this.startConsultation(patient, lastConsultation);
    }
  }

  /**
   * consultationInfosUpdated indicates to the container component that he must refresh its local data
   */
  startConsultation(patient: Patient, lastConsultation: Consultation): void {
    this.consultationInfosUpdated.emit({ patient, lastConsultation });
    this.closeMessages.emit();
    this.vetService.currentBigMeasurementUnit$
      .pipe(
        take(1),
        tap((currentBigMeasurementUnit) => {
          this.trackingService.sendInteraction(
            sendPetIdentifiedGtm({
              patient: patient,
              lastConsultation: lastConsultation,
              currentBigMeasurementUnit,
            })
          );
        })
      )
      .subscribe();
  }

  /**
   * Fetch patients list for autocomplete option values
   */
  private _setupSearch(): void {
    this.search$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((search) => {
          if (!search || search.length === 0) {
            this.patientFacade.clearState();
          } else {
            this.patientFacade.loadPatients(patientsAutocompleteFilter(search));
          }
        })
      )
      .subscribe();
  }

  private stayInWLP(patient: Patient, lastConsultation: Consultation): void {
    this.store$.dispatch(setConsultationPatient({ value: patient }));
    this.store$.dispatch(setConsultationLastConsultation({ value: lastConsultation }));
    this.store$.dispatch(setTool({ tool: Tool.WeightManagement, tool_flow_id: Helper.randomId() }));
    this.store$.dispatch(startWeightManagement({ step: WeightManagementStep.FollowUp }));

    this.vetService.currentBigMeasurementUnit$
      .pipe(
        take(1),
        tap((currentBigMeasurementUnit) => {
          this.trackingService.sendInteraction(
            sendPetIdentifiedGtm({
              patient: patient,
              lastConsultation: lastConsultation,
              currentBigMeasurementUnit,
            })
          );
          this.trackingService.sendInteraction(
            startConsultationExistingPatientGtm({ tool: Tool.WeightManagement, patient, lastConsultation, currentBigMeasurementUnit })
          );
        })
      )
      .subscribe();
  }
}
