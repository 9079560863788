import { CommonModule } from '@angular/common';
import { IconModule } from '@app/shared/utils/icon/icon.module';
import { ExpansionPanelComponent } from '@app/shared/components/expansion-panel/expansion-panel.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [ExpansionPanelComponent],
  exports: [ExpansionPanelComponent],
})
export class ExpansionPanelModule {}
