<app-rc-dialog-wrapper noClose="true">
  <div class="delete-patient-dialog">
    <div class="delete-patient-dialog__body">
      <!-- Image -->
      <div class="delete-patient-dialog__body-thumb">
        <img src="assets/other/img-australian-shepherd.png" alt="Are you sure you want to delete this profile?" />
      </div>

      <!-- Text -->
      <h3 class="rc-title-gamma" i18n="@@delete-patient_title">Are you sure you want to delete this profile?</h3>
      <p class="rc-paragraph" i18n="@@delete-patient_text">All data will be erased</p>
    </div>

    <div class="delete-patient-dialog__footer">
      <div class="rc-button-group">
        <button id="action_cancel" class="rc-button" type="button" (click)="dismiss()">
          <span class="rc-button__title rc-button__title--link" i18n="@@action_cancel">Cancel</span>
        </button>
        <span class="rc-button__separator" i18n="@@action_separator">or</span>
        <button id="action_delete" class="rc-button rc-button--filled-small" type="button" (click)="continue()">
          <span class="rc-button__title" i18n="@@action_delete" id="delete-patient__delete-button">Delete</span>
        </button>
      </div>
    </div>
  </div>
</app-rc-dialog-wrapper>
