import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() transparent = false;
  @Input() withinContent = false;

  public readonly isStandalone = environment.isStandalone;
}
