<app-rc-dialog-wrapper (closePopin)="close()">
  <div class="create-patient-popin__flex-container">
    <div class="create-patient-popin__left">
      <div class="create-patient-popin__content">
        <h3 class="rc-title-gamma rc-title-gamma--thin">{{ title }}</h3>
        <p class="rc-paragraph" i18n="@@create-patient_discover-benefits">Discover the benefits:</p>
        <ul class="benefits-list">
          <li class="benefits-list__item">
            <span class="rc-paragraph" i18n="@@create-patient_individual-nutrition-plan">Individual nutritional plan</span>
          </li>
          <li class="benefits-list__item">
            <span class="rc-paragraph" i18n="@@create-patient_calculated-daily-ration">Calculated daily ration</span>
          </li>
          <li class="benefits-list__item">
            <span class="rc-paragraph" i18n="@@create-patient_patient-follow-up">Patient follow up</span>
          </li>
        </ul>
      </div>
    </div>
    <ng-container *appLet="state$ | async as state">
      <ng-container *appLet="scanningMails$ | async as scanningMails">
        <div class="create-patient-popin__right">
          <form class="create-patient-popin__form rc-dialog-form" [formGroup]="form" (submit)="submit()">
            <div class="rc-dialog-form__input">
              <rc-field
                id="create-patient-popin-field-email"
                formControlName="email"
                [label]="'form-attribute_owner-email' | translateKey"
                [error]="emailErrorMessage()"
              >
              </rc-field>
              <div *ngIf="scanningMails" class="rc-field-hint rc-input--info-message">
                <span i18n="@@create-patient_looking-for-pet-owner">We are looking for an existing pet owner...</span>
              </div>
              <div *ngIf="!scanningMails && state === CreatePatientStates.MailExists" class="rc-field-hint rc-input--ok-message">
                <span>{{ 'save-new-pet_existing-pet-owner' | translateKey }}</span>
              </div>
              <div *ngIf="!scanningMails && state === CreatePatientStates.Error" class="rc-field-hint rc-input--error-message">
                <span i18n="@@error_general_text">An error occurred</span>
              </div>
            </div>
            <div *ngIf="state === CreatePatientStates.MailFree" class="rc-dialog-form__input">
              <rc-field
                id="create-patient-popin-field-givenName"
                formControlName="givenName"
                [label]="'form-attribute_owner-first-name' | translateKey"
              >
              </rc-field>
            </div>
            <div *ngIf="state === CreatePatientStates.MailFree" class="rc-dialog-form__input">
              <rc-field
                id="create-patient-popin-field-familyName"
                formControlName="familyName"
                [label]="'form-attribute_owner-last-name' | translateKey"
              >
              </rc-field>
            </div>
            <div
              *ngIf="state === CreatePatientStates.MailFree"
              class="field-wrapper phone-wrapper field-wrapper-right"
              id="create-patient-popin-field-telephone"
            >
              <rc-phone-field formControlName="telephone" [label]="'form-attribute_phone' | translateKey" [error]="phoneErrorMessage()">
              </rc-phone-field>
            </div>
            <div class="consent">
              <app-checkbox
                formControlName="noEmail"
                id="pohasnoemail"
                [label]="'create-patient_petowner-hasno-email-adress' | translateKey"
                [multiLine]="true"
              ></app-checkbox>
              <app-checkbox
                *ngIf="state !== CreatePatientStates.Initial && state !== CreatePatientStates.Error"
                id="confirmContent"
                formControlName="consent"
                [multiLine]="true"
                [label]="'create-patient_legal' | translateKey"
              ></app-checkbox>
            </div>
            <div class="rc-dialog-form__action">
              <div class="rc-button-group">
                <button class="rc-button" type="button" (click)="close()">
                  <span class="rc-button__title--link" i18n="@@action_cancel">Cancel</span>
                </button>
                <ng-container *ngIf="state !== CreatePatientStates.Initial && state !== CreatePatientStates.Error">
                  <span class="rc-button__separator" i18n="@@or-key">or</span>
                  <button class="rc-button rc-button--filled-small" id="createPatient" [disabled]="form.invalid" type="submit">
                    <span class="rc-button__title">{{ 'action_save' | translateKey }}</span>
                  </button>
                </ng-container>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
    </ng-container>
  </div>
</app-rc-dialog-wrapper>
