import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IconName } from '@app/shared/utils/icon/icons';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-info-popin.component',
  templateUrl: './info-popin.component.html',
  styleUrls: ['./info-popin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPopinComponent {
  constructor(
    public dialogRef: MatDialogRef<InfoPopinComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; description: string; link?: { text: string; icon: IconName; href$: Observable<string> } }
  ) {}

  public closePopin(): void {
    this.dialogRef.close();
  }
}
