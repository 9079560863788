import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Product, UtmParamsContext } from '@app/core/models';
import { GTMService, productBookButton, ProductBookButtonActionGtm } from '@app/core/services/tracking';
import { DetectMobile, ProductType } from '@app/shared/utils';
import { RetailChannel, ViewType } from '@app/shared/utils/enums';
import { IconName } from '@app/shared/utils/icon/icons';
import { DialogHelpers } from '@app/shared/utils/static-helpers/dialog-helpers';
import { ProductsFacade } from '@app/store/products/products.facade';
import { VetFacade } from '@app/store/vet';
import { combineLatest, Subject } from 'rxjs';
import { filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PurchaseProductsPopinComponent } from '../purchase-products-popin/purchase-products-popin.component';
import { ShareRecoEvent } from '@app/core/services/tracking/types/share-reco-event';
import { ShareRecommendationFacade } from '@app/store/share-recommendation';
import { isNotNullOrUndefined } from '@app/shared/operators/not-null-or-undefined';
import { ConsultationFacade } from '@app/store/consultation/consultation.facade';

@Component({
  selector: 'app-product-footer',
  templateUrl: './product-footer.component.html',
  styleUrls: ['product-footer.component.scss'],
})
export class ProductFooterComponent implements OnInit, OnDestroy {
  @Input() withMobileSearch = false;
  @Input() multiSelectionAvailable = false;
  @Input() disableMultiSelection = false;
  @Input() selectedProducts: Product[] = [];
  @Input() displayPurchaseButtonInput = false;

  @Output() removeProduct: EventEmitter<Product> = new EventEmitter<Product>();
  @Output() openMoreProductsPopin: EventEmitter<Product[]> = new EventEmitter<Product[]>();
  @Output() continueAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() searchAction: EventEmitter<void> = new EventEmitter<void>();

  public readonly ProductType = ProductType;
  public readonly IconName = IconName;
  public readonly ViewType = ViewType;

  public toggleProductsSelection = false;
  public currentViewType$ = this.productsFacade.currentViewType$;
  public showEditPricesActions$ = this.productsFacade.selectShowPriceListActions$;
  public priceListUpdateLoading$ = this.productsFacade.priceListUpdateLoading$;
  public allProductsTypeAvailable = false;
  public isMobile = DetectMobile.isMobileNotDeprecated();
  public isPricesPage$ = this.route.params.pipe(map((params: Params) => params['state'] === 'prices'));

  public displayPurchaseButton$ = combineLatest([this.vetFacade.conversionConfiguration$, this.productsFacade.selectedProducts$]).pipe(
    map(([conversionConfiguration, products]) => {
      const hasOnlyMufProducts = !products.find((selectedProduct) => selectedProduct.retailChannel !== RetailChannel.MULTIFUNCTION);
      return this.displayPurchaseButtonInput && conversionConfiguration !== null && !hasOnlyMufProducts;
    })
  );

  private _destroyed$ = new Subject<void>();

  constructor(
    private modal: MatDialog,
    private vetFacade: VetFacade,
    private productsFacade: ProductsFacade,
    private shareRecoFacade: ShareRecommendationFacade,
    private route: ActivatedRoute,
    private router: Router,
    private trackingService: GTMService,
    private consultationFacade: ConsultationFacade
  ) {}

  ngOnInit(): void {
    combineLatest([this.productsFacade.hasDryProductInAllProducts$, this.productsFacade.hasWetProductInAllProducts$])
      .pipe(
        takeUntil(this._destroyed$),
        tap(([hasDryProduct, hasWetProduct]) => {
          if (hasDryProduct && hasWetProduct) {
            this.allProductsTypeAvailable = true;
          }
        })
      )
      .subscribe();
  }

  toggleProductSelection(): void {
    this.toggleProductsSelection = !this.toggleProductsSelection;
  }

  cancelEditPrices(isPricesPage: boolean): void {
    if (isPricesPage) {
      this.router.navigate(['/']);
    } else {
      this.productsFacade.resetProductsPricesState();
      this.productsFacade.setViewType(ViewType.Grid);
    }
  }

  submitEditPrices(): void {
    this.productsFacade.updatePrices();
    this.productsFacade.setViewType(ViewType.Grid);
  }

  openProductsPurchase(): void {
    this.vetFacade.conversionConfiguration$
      .pipe(
        first(),
        tap((conversionConfiguration) => {
          this.trackingService.sendInteraction(
            productBookButton(ProductBookButtonActionGtm.PURCHASE_PRODUCT, conversionConfiguration?.trackingCode)
          );
        })
      )
      .subscribe();

    combineLatest([
      this.vetFacade.clinic$,
      this.vetFacade.vet$.pipe(map(({ id: vetId, user: { countryCode } }) => ({ countryCode, vetId }))),
      this.productsFacade.selectedProducts$.pipe(
        map((products) => products.map(({ id, speciesCode }) => ({ id, speciesCode }))),
        switchMap((productsIdsAndSpeciesCodes) =>
          this.shareRecoFacade.selectRecommendationId$(productsIdsAndSpeciesCodes.map(({ id }) => id)).pipe(
            filter(isNotNullOrUndefined),
            map((recoId) => ({ productsIdsAndSpeciesCodes, recoId }))
          )
        )
      ),
      this.consultationFacade.consultationAllowanceParams$,
    ])
      .pipe(
        take(1),
        switchMap(
          ([
            { id: clinicId, companyAddress },
            { vetId, countryCode },
            { productsIdsAndSpeciesCodes, recoId },
            consultationAllowanceParams,
          ]) => {
            const shareRecoEvent: ShareRecoEvent = {
              clinicId,
              countryCode,
              eventTime: new Date(),
              eventType: 'productCatalog',
              productIds: productsIdsAndSpeciesCodes.map(({ id }) => id),
              sharedRecommendationId: recoId,
              vetId,
              speciesCode: productsIdsAndSpeciesCodes[0].speciesCode,
              tool: consultationAllowanceParams.tool,
            };
            return this.trackingService.sendShareRecoEvent(clinicId, shareRecoEvent, companyAddress?.country);
          }
        )
      )
      .subscribe();

    const dialogConfig = {
      ...DialogHelpers.defaultConfig('productPurchase'),
      data: { context: UtmParamsContext.QRCODEPURCHASEPOPIN_PRODUCTBOOK },
    };
    this.modal.open(PurchaseProductsPopinComponent, dialogConfig).afterClosed().subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
