<app-rc-dialog-wrapper (closePopin)="closePopin()">
  <div class="info-popin_content-wrapper">
    <h1 class="info-popin_title">{{ data?.title }}</h1>
    <div class="info-popin_content">
      <p class="text">{{ data?.description }}</p>
      <ng-container *ngIf="data?.link">
        <div class="link">
          <app-icon class="icon" [name]="data.link.icon"></app-icon>
          <a class="rc-button__title rc-button__title--link" rel="noopener" [href]="data.link.href$ | async" target="_blank">
            <span> {{ data.link.text }}</span>
          </a>
        </div>
      </ng-container>
    </div>
    <button class="rc-button rc-button--filled-small" type="submit" (click)="closePopin()">
      <span class="rc-button__title" i18n="@@action_close">close</span>
    </button>
  </div>
</app-rc-dialog-wrapper>
