import { Component, Input, OnInit } from '@angular/core';
import { NutritionData, NutritionDataProduct, Recommendation } from '@app/core/models';
import { PackType, ProductType } from '@app/shared/utils';
import { MeasureHelper } from '@app/shared/utils/static-helpers/measure-helper';
import { LocaleContentEnum } from '@app/shared/data';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

@Component({
  selector: 'app-daily-allowance-view',
  templateUrl: './daily-allowance-view.component.html',
  styleUrls: ['./daily-allowance-view.component.scss'],
})
export class DailyAllowanceViewComponent implements OnInit {
  /** Properties **/
  @Input() recommendation: Recommendation;
  @Input() dryMeasureSuffix: string;
  @Input() currentSystemPreferenceCode: SystemPreferenceCode;

  /** For the view **/
  ProductType = ProductType;
  LocaleContentEnum = LocaleContentEnum;
  Math = Math;
  PackType = PackType;

  products: NutritionDataProduct[];
  rationBagColumn = false;
  nbColumns = 3;
  /** Life Cycle **/
  ngOnInit() {
    this._init();
  }

  getConvertedWeight(product: NutritionDataProduct): { measure: number; unitSuffix: string; measureUnit: string } {
    return MeasureHelper.convertWeight(
      product?.productDetail?.package?.weight,
      product?.productDetail?.package?.measurementUnitCode,
      null,
      this.currentSystemPreferenceCode
    );
  }

  /** Methods **/

  private _init() {
    this.products = this.recommendation.nutritionData.map((nD) => nD.product);
    this._updateNbColumns();
  }

  private _updateNbColumns() {
    this.nbColumns = 3;

    this.recommendation.nutritionData.forEach((nutritionData: NutritionData) => {
      if (nutritionData.product && nutritionData.product.productDetail && nutritionData.product.productType === ProductType.Dry) {
        this.rationBagColumn = true;
      }
    });
    if (this.rationBagColumn) {
      this.nbColumns++;
    }
  }
}
