import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopinSize } from '@app/shared/utils';

@Component({
  selector: 'app-active-program-popin',
  templateUrl: './active-program-popin.component.html',
  styleUrls: ['./active-program-popin.component.scss'],
})
export class ActiveProgramPopinComponent {
  DialogSize = PopinSize;

  constructor(private _dialogRef: MatDialogRef<ActiveProgramPopinComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  stay() {
    this._dialogRef.close({
      stay: true,
    });
  }

  startNewConsultation() {
    this._dialogRef.close({
      start: true,
    });
  }
}
