import { Component, Input } from '@angular/core';
import { FormField, PetOwner, VetUser } from '@app/core/models';
import { MeasurementCodeType } from '@app/shared/utils';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss'],
})
export class ProfileHeaderComponent {
  @Input() imageUrl?: string;
  @Input() placeholderImageUrl?: string;
  @Input() headerTitle?: string;
  @Input() object?: VetUser | PetOwner;
  @Input() attributes?: FormField[];
  @Input() currentSystemPreferenceCode?: SystemPreferenceCode;
  @Input() currentBigMeasurementUnit?: MeasurementCodeType;
}
