import { PanelSectionTypeEnum } from '@app/shared/utils';
import { LabelEnum } from '@app/core/services/tracking';

export const mapPanelActionTypeToLabel = (panelSection: PanelSectionTypeEnum): LabelEnum =>
  ({
    [PanelSectionTypeEnum.PetDetails]: LabelEnum.EDIT_PET_DETAILS,
    [PanelSectionTypeEnum.PetDiagnosis]: LabelEnum.EDIT_DIAGNOSIS,
    [PanelSectionTypeEnum.PetWeight]: LabelEnum.EDIT_BODY_WEIGHT,
    [PanelSectionTypeEnum.PetGrowthChart]: LabelEnum.EDIT_GROWTH_CHART,
  }[panelSection]);
