import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { PopinSize } from '@app/shared/utils';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { getInputErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import { CreatePOPopinFormValues } from './create-po-popin';
import { PhoneNumber } from '@rc/ui';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface CreatePOPopinData {
  email: string;
  country: string;
}

export interface CreatePOPopinOutput {
  values: CreatePOPopinFormValues;
}

interface POPopinFormValues {
  email: string;
  givenName: string;
  familyName: string;
  telephone: PhoneNumber;
  consent: boolean;
}

@Component({
  selector: 'app-create-po-popin',
  templateUrl: './create-po-popin.component.html',
  styleUrls: ['./create-po-popin.component.scss'],
})
export class CreatePOPopinComponent implements OnInit, OnDestroy {
  PopinSize = PopinSize;

  form: IFormGroup<POPopinFormValues>;

  private _destroyed$ = new Subject<void>();

  /**
   * Initializer
   */
  constructor(private _dialogRef: MatDialogRef<CreatePOPopinComponent>, @Inject(MAT_DIALOG_DATA) public data: CreatePOPopinData) {}

  /**
   * Life Cycle
   */
  ngOnInit(): void {
    const formBuilder: IFormBuilder = new UntypedFormBuilder();
    this.form = formBuilder.group<POPopinFormValues>({
      email: formBuilder.control({ value: this.data.email, disabled: true }, { validators: Validators.required }),
      givenName: ['', [Validators.required]],
      familyName: ['', [Validators.required]],
      telephone: [{ phone: '', countryCode: this.data.country }, [Validators.maxLength(18), Validators.minLength(8)]],
      consent: [false, Validators.requiredTrue],
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  submit(): void {
    if (this.form.valid) {
      const output: CreatePOPopinOutput = {
        values: { ...this.form.getRawValue(), telephone: this.form.getRawValue().telephone?.phone },
      };
      this._dialogRef.close(output);
    }
  }

  phoneErrorMessage(): string {
    return getInputErrorMessage(this.form?.controls?.telephone?.errors, 'form-attribute_phone');
  }

  close(): void {
    this._dialogRef.close();
  }
}
