<div class="nutrient-bar-container">
  <svg viewBox="0 0 240 20" width="240" height="20" xmlns="http://www.w3.org/2000/svg">
    <mask id="myMask">
      <path d="M0,0V20H240V0H0ZM230,19H10C5.03,19,1,14.97,1,10S5.03,1,10,1H230c4.97,0,9,4.03,9,9s-4.03,9-9,9Z" fill="white" />
    </mask>
    <defs>
      <linearGradient [attr.id]="formattedNutrients.nutrient.id + id">
        <stop [attr.stop] offset="0%" stop-color="white" />
        <!-- offset = Min - Spread-->
        <stop [attr.stop] [attr.offset]="getOffsetValue(1, formattedNutrients)" stop-color="white" />
        <!-- offset =  Min + Spread-->
        <stop [attr.stop] [attr.offset]="getOffsetValue(2, formattedNutrients)" stop-color="#cbe2a4" />
        <!-- offset = Target- 10 - Spread-->
        <stop [attr.stop] [attr.offset]="getOffsetValue(3, formattedNutrients)" stop-color="#cbe2a4" />
        <!-- offset = Target - 10 + Spread-->
        <stop [attr.stop] [attr.offset]="getOffsetValue(4, formattedNutrients)" stop-color="#008900" />
        <!-- offset = Target + 10 - Spread-->
        <stop [attr.stop] [attr.offset]="getOffsetValue(5, formattedNutrients)" stop-color="#008900" />
        <!-- offset = Target + 10 + Spread-->
        <stop [attr.stop] [attr.offset]="getOffsetValue(6, formattedNutrients)" stop-color="#cbe2a4" />
        <!-- offset = Max - Spread-->
        <stop [attr.stop] [attr.offset]="getOffsetValue(7, formattedNutrients)" stop-color="#cbe2a4" />
        <!-- offset = Max + Spread-->
        <stop [attr.stop] [attr.offset]="getOffsetValue(8, formattedNutrients)" stop-color="white" />
        <stop [attr.stop] offset="100%" stop-color="white" />
      </linearGradient>
    </defs>
    <rect x="0" width="240" height="20" rx="10" fill="#D7D7D7" />
    <rect x="1" y="1" width="238" height="18" rx="9" fill="white" />
    <rect x="2" y="2" width="236" height="16" rx="8" [attr.fill]="getFill(formattedNutrients.nutrient.id + id)" />

    <circle [attr.cx]="getCxValue(formattedNutrients) + '%'" cy="10" r="4" fill="white" />
    <rect class="hitBox" x="0" width="240" height="20" rx="10" fill="#D7D7D7" mask="url(#myMask)" />
    <g
      [matTooltipPosition]="'above'"
      matTooltipHideDelay="1"
      matTooltip="{{ getTooltipText(formattedNutrients) }}"
      matTooltipClass="tooltip-arrow-down"
      #tooltip="matTooltip"
    >
      <rect
        class="targetBox"
        [attr.x]="getHoverZoneXValue(formattedNutrients)"
        width="48"
        height="16"
        y="2"
        rx="2"
        stroke-width="0"
        stroke="#333333"
        stroke-linecap="round"
        fill-opacity="0"
        stroke-dasharray="4"
      />
    </g>
  </svg>
</div>
