import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedPipesModule } from '@app/shared/pipes/shared-pipes.module';
import { QrCodeDisplayModule } from '../qr-code-display/qr-code-display.module';
import { RCDialogWrapperModule } from '../rc-dialog-wrapper/rc-dialog-wrapper.module';
import { SeePreviewPopinComponent } from './see-preview-popin.component';

@NgModule({
  imports: [CommonModule, SharedPipesModule, QrCodeDisplayModule, RCDialogWrapperModule],
  declarations: [SeePreviewPopinComponent],

  exports: [SeePreviewPopinComponent],
})
export class SeePreviewPopinModule {}
