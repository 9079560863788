<div class="calculator">
  <div class="calculator-table calculator-table--cols-{{ nbColumns }}">
    <span class="calculator-table__title" i18n="@@rc-table_product-title">Product</span>
    <span class="calculator-table__title" i18n="@@rc-table_weight-title">Weight</span>
    <span class="calculator-table__title" i18n="@@rc-table_daily-food-title">Daily Food ration</span>
    <span class="calculator-table__title" i18n="@@rc-table_day-title" *ngIf="rationBagColumn">Day / Bag</span>

    <ng-container *ngFor="let nutritionData of recommendation.nutritionData; let i = index">
      <div class="calculator-table__separator" *ngIf="i > 0"></div>
      <div class="calculator-table__cell calculator-table__product">
        <div>
          <h4 class="calculator-table__title--mobile" i18n="@@rc-table_product-title">Product</h4>
          <img
            class="calculator__product-picture"
            [src]="nutritionData.product.productDetail.details?.publicUrl | publicUrl: 100"
            alt="{{ nutritionData.product.productDetail.details?.name || '' }}"
          />
          <div class="product-detail">
            <p class="product-detail__category">
              {{ nutritionData.product.productType | translationCode: LocaleContentEnum.ProductType }}
            </p>
            <p class="product-detail__name">
              {{ nutritionData.product.productDetail?.details.name || '--' }}
            </p>
          </div>
        </div>
      </div>

      <ng-container *appLet="getConvertedWeight(nutritionData.product) as convertedWeight">
        <div class="calculator-table__cell calculator-table__package">
          <div>
            <h4 class="calculator-table__title--mobile" i18n="@@rc-table_weight-title">weight</h4>
            <span class="calculator__value">{{ convertedWeight.measure | weightPrecision }}</span>
            {{ convertedWeight.unitSuffix }}
          </div>
        </div>
      </ng-container>
      <div class="calculator-table__cell calculator-table__ration">
        <div>
          <h4 class="calculator-table__title--mobile" i18n="@@rc-table_daily-food-title">daily food ration</h4>
          <!-- Show quantity per day -->
          <span class="calculator__value">{{ nutritionData?.basicRation?.quantityPerDay }} </span>
          <!-- Prefix g/day for Dry / Prefix can(s)/day for Wet -->
          <ng-container [ngSwitch]="nutritionData.product.productType">
            <ng-container *ngSwitchCase="ProductType.Dry">
              {{ dryMeasureSuffix }}/
              <ng-container i18n="@@day">day</ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="ProductType.Wet">
              <ng-container *ngIf="nutritionData.product.productDetail.package.type === PackType.PouchStr" i18n="@@package-type_wet-pouch"
                >Pouch</ng-container
              >
              <ng-container *ngIf="nutritionData.product.productDetail.package.type !== PackType.PouchStr" i18n="@@package-type_wet-cans"
                >Can(s)</ng-container
              >
              /
              <ng-container i18n="@@day">day</ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div class="calculator-table__cell calculator-table__days" *ngIf="rationBagColumn">
        <div>
          <ng-container *ngIf="nutritionData.product.productType === ProductType.Dry">
            <h4 class="calculator-table__title--mobile" i18n="@@rc-table_day-title">days / bag</h4>
            <p class="calculator__value">
              {{ Math.floor(nutritionData.basicRation.nbDaysPerPack) }}
            </p>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Calculator: Result -->
  <div class="calculator__result">
    <div class="left">
      <div class="vertical-middle">
        <div class="rc-icon rc-icon--alert"></div>
        <p class="calculator__result-value">
          <span class="calculator__result-prefix" i18n="@@label_recommendation-allocation">
            This recommendation represent an allocation of
          </span>
          {{ recommendation.diagnostic.kCalAllocation }}
          <span class="calculator__result-prefix"> Kcal/ <ng-container i18n="@@day">day</ng-container> </span>
        </p>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</div>
