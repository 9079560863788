import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CriteriaDetailsPopinData } from '@app/core/models/criteria-details-popin';
import { criteriaModalDisplayed, GTMService } from '@app/core/services/tracking';
import { selectSmartRecoProfile } from '@app/pages/smart-reco/store/selectors/smart-reco-patient.selectors';
import { PopinSize, SpeciesCode } from '@app/shared/utils';
import { CriteriaType, criteriaTypeTradMapping } from '@app/shared/utils/enums/criteria-type.enum';
import { translateKey, translationExist } from '@app/shared/utils/static-helpers/translate';
import { AppState } from '@app/store';
import { Store } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-details-popin',
  templateUrl: './criteria-details-popin.component.html',
  styleUrls: ['./criteria-details-popin.component.scss'],
})
export class CriteriaDetailsPopinComponent implements OnInit, AfterViewInit {
  DialogSize = PopinSize;

  criteria = this.data.selectedCriteria;
  title = `${translateKey('criteria_name_pop-up_title')}`;
  species$ = this.store$.select(selectSmartRecoProfile).pipe(
    take(1),
    map((value) => value.speciesCode)
  );

  description = {
    long_1: '',
    long_2: '',
    long_3: '',
    long_4: '',
    long_5: '',
  };

  constructor(
    private _dialogRef: MatDialogRef<CriteriaDetailsPopinComponent>,
    private trackingService: GTMService,
    @Inject(MAT_DIALOG_DATA) public data: CriteriaDetailsPopinData,
    private store$: Store<AppState>
  ) {}

  ngOnInit() {
    this.species$
      .pipe(
        take(1),
        tap((specie) => {
          let criteriaLabel;
          if (criteriaTypeTradMapping[this.criteria.type] && this.criteria.type === CriteriaType.SizeLifestage) {
            criteriaLabel = criteriaTypeTradMapping[this.criteria.type] + '_';
          } else {
            criteriaLabel = criteriaTypeTradMapping[this.criteria.type] ? criteriaTypeTradMapping[this.criteria.type] + '-' : '';
          }
          this.description = {
            long_1: this.descriptionTranslation(`${criteriaLabel}${this.criteria.key}-description-long-1`, specie),
            long_2: this.descriptionTranslation(`${criteriaLabel}${this.criteria.key}-description-long-2`, specie),
            long_3: this.descriptionTranslation(`${criteriaLabel}${this.criteria.key}-description-long-3`, specie),
            long_4: this.descriptionTranslation(`${criteriaLabel}${this.criteria.key}-description-long-4`, specie),
            long_5: this.descriptionTranslation(`${criteriaLabel}${this.criteria.key}-description-long-5`, specie),
          };
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.trackingService.sendInteraction(
      criteriaModalDisplayed({
        name: this.criteria.key,
        relatedProduct: this.data.productName,
      })
    );
  }

  descriptionTranslation(key: string, specie: SpeciesCode): string {
    if (translationExist(`${key}-${specie}`)) {
      return translateKey(`${key}-${specie}`);
    } else if (translationExist(key)) {
      return translateKey(`${key}`);
    } else {
      return '';
    }
  }

  /**
   * Close modal
   */
  dismiss() {
    this._dialogRef.close();
  }
}
