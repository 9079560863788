<app-rc-dialog-wrapper (closePopin)="onAction(PopinButtonActionEnum.CLEAR)">
  <div class="popin__wrapper">
    <div class="popin">
      <div class="popin__header">
        <h3 class="popin__title">{{ data?.title }}</h3>
      </div>
      <div class="popin__content">
        <p class="popin__subtitle">{{ data?.subTitle }}</p>
      </div>
      <div class="popin__actions">
        <div class="popin__actions__wrapper">
          <ng-container *ngFor="let buttonAction of buttons; let i = index">
            <button
              type="button"
              (click)="onAction(buttonAction)"
              [ngClass]="buttonsClass[data.buttons[buttonAction]?.style] + ' ' + 'popin__actions__button--' + buttonAction"
              [disabled]="data.buttons[buttonAction]?.status === PopinButtonStatus.DISABLED"
            >
              <app-icon
                *ngIf="data.buttons[buttonAction]?.status === PopinButtonStatus.LOADING; else default"
                class="spinner disabled"
                [name]="IconName.spinner_circle"
              ></app-icon>
              <ng-template #default>
                <span
                  [ngClass]="data.buttons[buttonAction]?.style === PopinButtonStyle.LINK ? 'rc-button__title--link' : 'rc-button__title'"
                  >{{ data.buttons[buttonAction]?.name }}</span
                >
              </ng-template>
            </button>
            <span *ngIf="i === 0 && buttons.length === 2" class="popin__actions__button--separator" i18n="@@action_separator">or</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</app-rc-dialog-wrapper>
