import {
  Product,
  ProductRecommendation,
  ProductRecommendationErrorTypes,
  SignalRecommendationTechnicalStatus,
  SmartRecoApiBody,
} from '@app/core/models';
import { createAction, props } from '@ngrx/store';
import { NutritionalAssessment } from '@app/shared/components/nutrients-list/nutrients.js';

/**
 * Actions dispatched to retrieve recommended products
 */
export const getSmartRecoRecommendation = createAction('[Smart Reco] Get Recommendation');
export const getSmartRecoRecommendationSuccess = createAction(
  '[Smart Reco] Get Recommendation Success',
  props<{ recommendation: { products: ProductRecommendation[] } }>()
);
export const incrementRetryRecommendationCount = createAction('[Smart Reco] Set Retry Recommendation Count');

/**
 * Actions dispatched to retrieve nutritional assessment
 */
export const getSmartRecoNutritionalAssessment = createAction('[Smart Reco] Get Nutritional Assessment');
export const getSmartRecoNutritionalAssessmentSuccess = createAction(
  '[Smart Reco] Get Nutritional Assessment Success',
  props<{ nutritionalAssessment: NutritionalAssessment }>()
);
export const getSmartRecoNutritionalAssessmentError = createAction('[Smart Reco] Get Nutritional Assessment Error');

/**
 * Actions dispatched to retrieve catalog products for search
 */
export const getSmartRecoCatalogProducts = createAction('[Smart Reco] Get Catalog Products');
export const getSmartRecoCatalogProductsSuccess = createAction(
  '[Smart Reco] Get Catalog Products Success',
  props<{ catalogProducts: Product[] }>()
);
export const getSmartRecoCatalogProductsFail = createAction('[Smart Reco] Get Catalog Products Fail', props<{ error: string }>());
/**
 * Actions dispatched to retrieve not recommended product
 */
export const getSmartRecoNotRecommendedProduct = createAction('[Smart Reco] Get Not Recommended Product', props<{ product: Product }>());
export const getSmartRecoNotRecommendedProductSuccess = createAction(
  '[Smart Reco] Get Not Recommended Product Success',
  props<{ notRecommendedProduct: ProductRecommendation }>()
);
export const getSmartRecoNotRecommendedProductFail = createAction(
  '[Smart Reco] Get Get Not Recommended Product Fail',
  props<{ error: string }>()
);
export const removeSmartRecoNotRecommendedProduct = createAction('[Smart Reco] Remove Not Recommended Product');
/**
 * Action dispatched when a dry product is selected
 */
export const setSmartRecoDryProduct = createAction(
  '[Smart Reco] Set Dry Product',
  props<{ product: Product; packId?: string; fromTour?: boolean; isUnselect?: boolean }>()
);

export const setSmartRecoProductsCostPerDay = createAction(
  '[Smart Reco] Set Smart Reco Products Cost Per Day',
  props<{
    productsCostPerDay: number[];
  }>()
);

export const setSmartRecoNotRecommendedProductCostPerDay = createAction(
  '[Smart Reco] Set Smart Reco Not Recommended Product Cost Per Day',
  props<{ costPerDay: number }>()
);

/**
 * Action dispatched when clicking on continue button
 */
export const goToRationing = createAction('[Smart Reco] Go To Rationing');
/**
 * Actions dispatched automatically (from effects) after a dry product is set, if it has related products
 */
export const getSmartRecoRelatedWetProductsSuccess = createAction(
  '[Smart Reco] Set Wet Related Products',
  props<{ products: Product[] }>()
);
export const getSmartRecoRelatedWetProductsFail = createAction(
  '[Smart Reco] Set Wet Related Products Fail',
  props<{ products: Product[] }>()
);
/**
 * Action dispatched when a wet product is selected
 */
export const setSmartRecoWetProduct = createAction('[Smart Reco] Set Wet Product', props<{ product: Product; packId?: string }>());

/**
 * Action dispatched when a the product recommendation API failed
 */
export const setSmartRecoProductRecommendationErrorType = createAction(
  '[Smart Reco] Set Recommendation Product error type',
  props<{ value: ProductRecommendationErrorTypes }>()
);

/**
 * Action dispatched when a the product recommendation API failed
 */
export const resetSmartRecoProductRecommendationError = createAction('[Smart Reco] Reset Recommendation Product error');

/**
 * Action dispatched when a the product recommendation API failed
 */
export const setSmartRecoProductRecommendationSessionIdError = createAction(
  '[Smart Reco] Set Recommendation Product Session Id Error',
  props<{ value: string }>()
);
/**
 * Action dispatched to get retryCount for API
 */
export const setSmartRecoProductRecommendationRetry = createAction(
  '[Smart Reco] Set Recommendation Product retry',
  props<{ value: number }>()
);

export const reportSmartRecoProductRecommendationIssue = createAction(
  '[Smart Reco] Report Recommendation Product error',
  props<{
    body: SmartRecoApiBody;
    retryCount: number;
    status: SignalRecommendationTechnicalStatus;
    errorMessage: string;
  }>()
);

export const setIsSelectedIndividualisProduct = createAction(
  '[Smart Reco] Set Is Selected Individualis Product',
  props<{ select: boolean }>()
);
