<div class="tags-selection-container wrapper">
  <ng-container *ngFor="let selection of selections">
    <div class="tags-selection-item item">
      <div class="header">
        <p class="label">{{ selection.categorieLabel }}</p>

        <button (click)="clearSelection.emit(selection.selected)" class="button" [disabled]="!selection.selected.length">
          <span i18n="@@action_clear">Clear</span>
        </button>
      </div>
      <div class="tag-wrapper">
        <ng-container *ngFor="let item of selection.selected">
          <ng-container *ngIf="item?.description">
            <rc-tag
              class="tag"
              [id]="'tag-' + item?.value"
              [isCloseable]="item?.isCloseable"
              [isSelected]="true"
              [theme]="selection.theme"
              [text]="item.description | translateKey"
              (closeTag)="closeTag(item)"
            ></rc-tag>
          </ng-container>
        </ng-container>
      </div>
      <p class="no-content" *ngIf="!selection.selected.length">{{ selection.noResultLabel }}</p>
    </div>
  </ng-container>
</div>
