import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { Component, Inject, OnInit } from '@angular/core';
import { formatAddressFormValuesToAddress } from '@app/shared/utils';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { DeliveryAddress } from '@app/core/models';
import { DeliveryChoice } from '@app/shared/utils/enums/delivery-choice.enum';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { AddressFormValues } from './address-form-popin';
import { getInputErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import { CountriesService } from '@app/core/services/utils/localization/countries.service';
import { of, merge } from 'rxjs';

export interface AddressFormPopinData {
  editMode: boolean;
  address?: DeliveryAddress;
  deliveryType: DeliveryChoice;
  defaultAddressCountryCode: string;
}

@Component({
  selector: 'app-address-form-popin',
  templateUrl: './address-form-popin.component.html',
  styleUrls: ['./address-form-popin.component.scss'],
})
export class AddressFormPopinComponent implements OnInit {
  public readonly DeliveryChoice = DeliveryChoice;
  public form: IFormGroup<AddressFormValues>;
  public title = '';
  public subtitle = '';
  public submitLabel = '';

  countries$ = merge(of([]), this.countriesService.translatedCountryList$);

  constructor(
    public config: MatDialogConfig,
    public dialogRef: MatDialogRef<AddressFormPopinComponent>,
    private countriesService: CountriesService,
    @Inject(MAT_DIALOG_DATA) public data: AddressFormPopinData
  ) {
    this.title = this.data.editMode ? translateKey('add-address_update-popin-title') : translateKey('add-address_create-popin-title');
    this.subtitle = this.data.editMode ? translateKey('add-address_update-description') : translateKey('add-address_create-description');
    this.submitLabel = this.data.editMode ? translateKey('action_update') : translateKey('action_create');
  }

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm(): void {
    const currentAddress = this.data.address;
    const formBuilder: IFormBuilder = new UntypedFormBuilder();
    this.form = formBuilder.group<AddressFormValues>({
      streetAddress: [currentAddress?.streetAddress || '', [Validators.required, Validators.maxLength(35)]],
      additionalInformation: [currentAddress?.additionalInformation || '', [Validators.maxLength(15)]],
      postalCode: [currentAddress?.postalCode || '', [Validators.required, Validators.maxLength(10)]],
      addressCity: [currentAddress?.addressCity || '', [Validators.required, Validators.maxLength(50)]],
      countryCode: [currentAddress?.addressCountry || this.data.defaultAddressCountryCode, [Validators.required]],
      phone: [currentAddress?.telephone ? { phone: currentAddress?.telephone } : null, [Validators.required]],
    });

    if (this.data.deliveryType === DeliveryChoice.AtClinic) {
      this.form.addControl('name', new UntypedFormControl(currentAddress?.name || '', [Validators.required, Validators.maxLength(100)]));
    } else {
      this.form.addControl(
        'givenName',
        new UntypedFormControl(currentAddress?.givenName || '', [Validators.required, Validators.maxLength(100)])
      );
      this.form.addControl(
        'familyName',
        new UntypedFormControl(currentAddress?.familyName || '', [Validators.required, Validators.maxLength(100)])
      );
    }

    if (this.data.editMode) {
      // in edit mode, we mark all field as touched because we want to display errors (if they exist)
      // this enable for instance to highlight directly if phone number is missing for instance
      this.form.markAllAsTouched();
    }
  }

  getFieldErrorMessage(field: string, fieldKeyTrad: string): string {
    return (this.form.controls[field].touched && getInputErrorMessage(this.form.controls[field].errors, fieldKeyTrad)) || '';
  }

  closePopin(): void {
    this.dialogRef.close();
  }

  submit(event: SubmitEvent): void {
    event.stopPropagation();
    if (this.form.valid) {
      this.dialogRef.close({
        addressType: this.data.deliveryType,
        outputAddress: formatAddressFormValuesToAddress(this.form.getRawValue(), this.data.address),
      });
    }
  }
}
