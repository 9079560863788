<app-rc-dialog-wrapper (closePopin)="closePopin()">
  <div class="contact-popin">
    <div class="contact-popin__content">
      <div class="contact-popin__wrapper">
        <div class="contact-popin-left">
          <div class="contact-popin-left__content">
            <h3 class="rc-title-gamma" i18n="@@dialog-contact_title">Contact us</h3>
            <p class="rc-paragraph" i18n="@@dialog-contact_description">
              Need help or assistance? Submit your details and a brief description and we will get back to you as soon as possible.
            </p>
            <br />
            <p class="rc-paragraph contact-popin__disclaimer" i18n="@@dialog-contact_disclaimer">
              The personal data submitted via this form will be retained only for the purpose of responding to your question or concern, and
              will not be used for marketing purposes. You must be 16 years old or older to submit a form.
            </p>
          </div>
        </div>
        <div class="contact-popin-right">
          <div class="contact-popin__form-wrapper">
            <form class="contact-popin__form" (ngSubmit)="submitForm()" [formGroup]="contactForm" autocomplete="off">
              <!-- First Name -->
              <rc-field
                data-cs-mask
                id="contact-popin-firstName"
                [label]="'form-attribute_first-name' | translateKey"
                [formControlName]="ContactPopinFormControlNameEnum.FIRSTNAME"
                [error]="errorMessage(ContactPopinFormControlNameEnum.FIRSTNAME)"
              ></rc-field>

              <!-- Last Name -->
              <rc-field
                data-cs-mask
                id="contact-popin-lastName"
                [label]="'form-attribute_last-name' | translateKey"
                [formControlName]="ContactPopinFormControlNameEnum.LASTNAME"
                [error]="errorMessage(ContactPopinFormControlNameEnum.LASTNAME)"
              ></rc-field>

              <!-- Practice Name -->
              <rc-field
                data-cs-mask
                id="contact-popin-practiceName"
                [label]="'form-attribute_practice-name' | translateKey"
                [formControlName]="ContactPopinFormControlNameEnum.PRACTICE_NAME"
                [error]="errorMessage(ContactPopinFormControlNameEnum.PRACTICE_NAME)"
              ></rc-field>

              <!-- Email address -->
              <rc-field
                data-cs-mask
                id="contact-popin-email"
                type="email"
                [label]="'form-attribute_email' | translateKey"
                [formControlName]="ContactPopinFormControlNameEnum.EMAIL_CONTACT"
                [error]="errorMessage(ContactPopinFormControlNameEnum.EMAIL_CONTACT)"
              ></rc-field>

              <!-- Country -->
              <rc-select
                data-cs-mask
                [id]="'contact-popin-country'"
                [formControlName]="ContactPopinFormControlNameEnum.COUNTRY"
                [items]="countries$ | async"
                [label]="'form-attribute_country' | translateKey"
              ></rc-select>

              <!-- Body content -->
              <div>
                <rc-textarea
                  data-cs-mask
                  [id]="'contact-popin-body'"
                  [formControlName]="ContactPopinFormControlNameEnum.BODY"
                  [placeholder]="'form_contact-msg' | translateKey"
                  [maxlength]="'300'"
                ></rc-textarea>
                <div *ngIf="errorMessage(ContactPopinFormControlNameEnum.BODY)" class="body_message-error">
                  {{ errorMessage(ContactPopinFormControlNameEnum.BODY) }}
                </div>
              </div>

              <div class="contact-popin-form__action">
                <button id="contact-popin-cancel" type="button" (click)="closePopin()" class="rc-button">
                  <span class="rc-button__title--link" i18n="@@action_cancel">{{ 'cancel' | titlecase }}</span>
                </button>
                <span class="rc-button__separator" i18n="@@action_separator">or</span>
                <button
                  id="contact-popin-submit"
                  type="button"
                  [disabled]="!contactForm.valid"
                  (click)="submitForm()"
                  class="rc-button rc-button--filled-small"
                >
                  <span class="rc-button__title" i18n="@@action_send">{{ 'send' | titlecase }}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-rc-dialog-wrapper>
