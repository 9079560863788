<app-rc-dialog-wrapper (closePopin)="closePopin()">
  <div class="signal-product">
    <div *ngIf="productReco?.product" class="signal-product__product">
      <div class="signal-product__product-container">
        <img class="product__picture" [src]="productReco?.product?.image" alt="{{ productReco?.product?.name }}" />

        <p class="product__category" i18n="@@product-type_wet" *ngIf="productReco?.product?.productType === ProductType.Wet">Wet</p>
        <p class="product__category" i18n="@@product-type_dry" *ngIf="productReco?.product?.productType === ProductType.Dry">Dry</p>
        <h1 class="rc-title-beta product__title">
          {{ productReco?.product?.name }}
        </h1>
      </div>
    </div>

    <div class="signal-product__mail">
      <h1 class="signal-product__title rc-title-gamma" i18n="@@alert_signal-product-title">Something went wrong with this result.</h1>
      <p *ngIf="productReco?.product" class="signal-product__sub-title" i18n="@@alert_signal-product-description">
        It seems that this product result is not what you were waiting for.
      </p>

      <textarea
        (keyup)="changeMessage($event)"
        class="signal-product__textarea rc-input-textarea"
        placeholder="Please let us know what went wrong..."
        i18n-placeholder="@@alert_signal-placeholder"
      ></textarea>

      <div class="signal-product__button-container dialog_container__footer">
        <div class="rc-button-group">
          <button type="button" class="rc-button" (click)="closePopin()">
            <span class="rc-button__title--link" i18n="@@action_cancel">cancel</span>
          </button>
          <span class="rc-button__separator" i18n="@@action_separator">or</span>
          <button
            [disabled]="!message.length"
            class="rc-button rc-button--filled-small"
            (click)="signalRecommendationEmail($event)"
            id="action_send_to_support"
          >
            <span class="rc-button__title" i18n="@@action_send_to_support">Send to support</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-rc-dialog-wrapper>
