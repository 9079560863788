<app-rc-dialog-wrapper (closePopin)="close()">
  <div class="popin_send">
    <div class="popin_send__image">
      <svg width="232px" height="120px" viewBox="0 0 308 159" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Vet-Services_MUF_Sprint-20S6_Desktop_07" transform="translate(-566.000000, -356.000000)">
            <g id="Group" transform="translate(566.000000, 356.000000)">
              <g id="Group-2" transform="translate(46.000000, 0.000000)">
                <rect
                  id="Rectangle"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                  x="113.5"
                  y="46.5"
                  width="27"
                  height="16"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-12"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                  x="118.5"
                  y="21.5"
                  width="22"
                  height="14"
                  rx="2"
                ></rect>
                <circle id="Oval" fill="#960011" cx="125.5" cy="28.5" r="1.5"></circle>
                <circle id="Oval-Copy" fill="#960011" cx="131.5" cy="28.5" r="1.5"></circle>
                <rect
                  id="Rectangle-Copy-15"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                  x="64.5"
                  y="10.5"
                  width="31"
                  height="18"
                  rx="2"
                ></rect>
                <rect
                  id="Rectangle-Copy-27"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                  x="69.5"
                  y="38.5"
                  width="36"
                  height="21"
                  rx="2"
                ></rect>
                <circle id="Oval-Copy-3" fill="#960011" cx="80" cy="49" r="2"></circle>
                <path
                  d="M141.520064,49.5 L114.157868,50.3824556 L128.322068,61.767773 L141.520064,49.5 Z"
                  id="Rectangle-Copy-40"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                  transform="translate(128.000000, 56.000000) scale(1, -1) translate(-128.000000, -56.000000) "
                ></path>
                <circle id="Oval-Copy-4" fill="#960011" cx="88" cy="49" r="2"></circle>
                <circle id="Oval-Copy-5" fill="#960011" cx="96" cy="49" r="2"></circle>
                <rect
                  id="Rectangle-Copy-30"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                  x="7.5"
                  y="22.5"
                  width="45"
                  height="26"
                  rx="2"
                ></rect>
                <rect id="Rectangle" fill="#960011" x="15" y="29" width="30" height="3" rx="1.5"></rect>
                <rect id="Rectangle-Copy-3" fill="#960011" x="11" y="8" width="30" height="3" rx="1.5"></rect>
                <rect id="Rectangle-Copy-5" fill="#960011" x="0" y="65" width="30" height="3" rx="1.5"></rect>
                <rect id="Rectangle-Copy-6" fill="#960011" x="11" y="76" width="23" height="3" rx="1.5"></rect>
                <rect id="Rectangle-Copy-4" fill="#960011" x="33" y="14" width="21" height="3" rx="1.5"></rect>
                <rect id="Rectangle-Copy" fill="#960011" x="19" y="34" width="22" height="3" rx="1.5"></rect>
                <rect id="Rectangle-Copy-2" fill="#960011" x="16" y="40" width="28" height="3" rx="1.5"></rect>
                <rect
                  id="Rectangle-Copy-37"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                  x="42.5"
                  y="67.5"
                  width="39"
                  height="22"
                  rx="2"
                ></rect>
                <path
                  d="M141.284643,46.5 L114.498802,47.3939493 L128.491159,57.842723 L141.284643,46.5 Z"
                  id="Rectangle-Copy-41"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M80.251531,73.5 L43.4381176,74.3920229 L62.3103515,88.8411516 L80.251531,73.5 Z"
                  id="Rectangle-Copy-37"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                  transform="translate(62.000000, 81.500000) scale(1, -1) translate(-62.000000, -81.500000) "
                ></path>
                <path
                  d="M95.1868991,15.5 L65.5054655,16.3941579 L80.8076354,27.8571035 L95.1868991,15.5 Z"
                  id="Rectangle-Copy-38"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                  transform="translate(80.500000, 22.000000) scale(1, -1) translate(-80.500000, -22.000000) "
                ></path>
                <path
                  d="M196,16.5 L136.5,16.5 L136.5,69.5 L214.5,69.5 L214.5,35 C214.5,29.8913661 212.429317,25.2663661 209.081475,21.9185245 C205.733634,18.570683 201.108634,16.5 196,16.5 Z"
                  id="Rectangle"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M187.5,1.5 L167.499996,1.5 L167.499906,33.9262938 C165.878109,34.4997944 164.512573,35.6152032 163.620158,37.054595 C162.909614,38.200642 162.5,39.5525327 162.5,41 C162.5,43.0710678 163.339466,44.9460678 164.696699,46.3033009 C166.053932,47.6605339 167.928932,48.5 170,48.5 C172.071068,48.5 173.946068,47.6605339 175.303301,46.3033009 C176.660534,44.9460678 177.5,43.0710678 177.5,41 C177.5,39.5526993 177.09048,38.2009532 176.380088,37.0549907 C175.48787,35.6157182 174.122629,34.5003287 172.501145,33.9266651 L172.499922,10.5 L179,10.5 C181.34721,10.5 183.47221,9.54860509 185.010408,8.01040764 C186.548605,6.47221019 187.5,4.34721019 187.5,1.5 Z"
                  id="Combined-Shape"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#E2001A"
                ></path>
                <circle id="Oval" fill="#960011" cx="170" cy="41" r="3"></circle>
                <rect id="Rectangle" stroke="#960011" stroke-width="3" fill="#E2001A" x="172.5" y="69.5" width="6" height="88"></rect>
                <path
                  d="M80.3998355,67.5 L42.9084969,68.4057672 L62.2841553,81.9299177 L80.3998355,67.5 Z"
                  id="Rectangle-Copy-37"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M94.8103279,10.5 L65.0035817,11.4082413 L80.492951,21.947629 L94.8103279,10.5 Z"
                  id="Rectangle-Copy-39"
                  stroke="#960011"
                  stroke-width="3"
                  fill="#FFFFFF"
                ></path>
              </g>
              <rect id="Rectangle" fill="#960011" x="140" y="68" width="46" height="3" rx="1.5"></rect>
              <rect id="Rectangle" fill="#960011" x="0" y="156" width="308" height="3" rx="1.5"></rect>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <h1 class="popin_send__title rc-title-beta product__title"><span i18n="@@title_thank_you">Thank you</span>!</h1>
    <p class="popin_send__description" i18n="@@message_thank_sharing_opinion">
      We value your opinion. Thank you for sharing, we will review it.
    </p>
    <button class="rc-button rc-button--filled-small popin_send__done-button" id="action_signal_send_done" (click)="close()">
      <span class="rc-button__title" i18n="@@action_done">Done</span>
    </button>
  </div>
</app-rc-dialog-wrapper>
