import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-clinical-alliance-badge',
  templateUrl: './clinical-alliance-badge.component.html',
  styleUrls: ['./clinical-alliance-badge.component.scss'],
})
export class ClinicalAllianceBadgeComponent {
  @Input() displayHeader: boolean;
}
