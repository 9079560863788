import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weightPrecision',
})
export class WeightPrecisionPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (typeof value === 'string' && value.trim().length > 0) {
      const values = value.split(',');
      return `${values[0]}${values[1].substring(0, 1)}`;
    } else if (typeof value === 'number' && value > 0) {
      return value.toFixed(2);
    }
    throw new Error("Can't transform weight value");
  }
}
