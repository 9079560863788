import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
import { IconName } from '@app/shared/utils/icon/icons';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { VetFacade } from '@app/store/vet';
import { VERTICAL_TOP_TO_BOTTOM_LANGUAGES } from '@app/core/services/utils/localization/localization';
import { map } from 'rxjs/operators';
import { Constants } from '@app/shared/utils';
import { CommonModule } from '@angular/common';
import { SharedPipesModule } from '@app/shared/pipes/shared-pipes.module';
import { SharedRegisterIconsModule } from '@app/shared/shared-register-icons.module';
import { SharedDirectivesModule } from '@app/shared/directives/shared-directives.module';

export enum ContentWrapperSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

@Component({
  selector: 'app-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedDirectivesModule, SharedPipesModule, SharedRegisterIconsModule],
})
export class PageWrapperComponent implements OnInit {
  @Input() size = ContentWrapperSizes.SMALL;
  @Input() withPanel = true;
  @Input() withFooterMargin = false;
  @Input() panelLabel = translateKey('action_display-profile');
  @Input() withAside = false;
  @Output() panelOpenedEvent = new EventEmitter<boolean>();

  public isStandalone = environment.isStandalone;
  public contentWrapperSizes = ContentWrapperSizes;
  public iconNames = IconName;
  public panelOpened = false;
  public hasScrolledToMarsFooter$ = new BehaviorSubject(false);
  public isVerticalTopToBottomLanguage$ = this.vetFacade.language$.pipe(
    map((language) => VERTICAL_TOP_TO_BOTTOM_LANGUAGES.includes(language as LanguageCode))
  );
  showAppFooter$ = this.vetFacade.clinic$.pipe(
    map((clinic) => !this.isStandalone && Constants.COUNTRIES_WITH_FOOTER.some((country) => clinic?.companyAddress?.country === country))
  );

  constructor(private vetFacade: VetFacade) {}

  onToggle(): void {
    this.panelOpened = !this.panelOpened;
    if (this.panelOpened) {
      document.querySelector('#mars-footer-panel')?.classList.add('mars-footer--withPanelOpened');
    } else {
      document.querySelector('#mars-footer-panel')?.classList.remove('mars-footer--withPanelOpened');
    }
    this.panelOpenedEvent.emit(this.panelOpened);
  }

  ngOnInit(): void {
    // use IntersectionObserver to detect if user scrolled to bottom and reached the mars
    // https://css-tricks.com/styling-based-on-scroll-position/
    // https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry/isIntersecting
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      const observer = new IntersectionObserver((entries) => {
        this.hasScrolledToMarsFooter$.next(!!entries[0]?.isIntersecting);
      });
      const marsFooter = document.querySelector('#mars-footer-panel');
      if (marsFooter) observer.observe(marsFooter);
    }
  }
}
