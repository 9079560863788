import { GTMService } from '@app/core/services/tracking';
import { VetFacade } from '@app/store/vet';
import { Component, Inject } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PetInfo, ProductRecommendation, SignalRecommendationTechnicalStatus } from '@app/core/models';
import { ApiManagerService, EmailService } from '@app/core/services';
import { Logger } from '@app/core/services/utils/logger';
import { ActionsEnum, Constants, ProductType } from '@app/shared/utils';
import { buildSignalRecommendationBodyEmail } from '@app/shared/utils/static-helpers/emails-helper';
import { CoreFacade } from '@app/store/core/core.facade';
import { first, switchMap, catchError, tap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { CountryCode } from '@app/shared/utils/enums/country-code.enum';

export interface SignalProductPopinComponentData {
  productRecommendation: ProductRecommendation | null;
  pet: PetInfo;
  sessionId: string;
  retryCount: number;
  nbProductResults: number;
}

@Component({
  selector: 'app-signal-product-popin',
  templateUrl: './signal-product-popin.component.html',
  styleUrls: ['./signal-product-popin.component.scss'],
})
export class SignalProductPopinComponent {
  public readonly ProductType = ProductType;

  public productReco: ProductRecommendation | null;
  public petInfo: PetInfo;
  public message = '';

  constructor(
    public config: MatDialogConfig,
    public dialogRef: MatDialogRef<SignalProductPopinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SignalProductPopinComponentData,
    private emailService: EmailService,
    private _apiManagerService: ApiManagerService,
    private _logger: Logger,
    private coreFacade: CoreFacade,
    private vetFacade: VetFacade,
    private trackingService: GTMService
  ) {
    if (data && data.productRecommendation) {
      this.productReco = data.productRecommendation;
    }

    if (data && data.pet) {
      this.petInfo = data.pet;
    }
  }

  closePopin(): void {
    this.dialogRef.close();
  }

  changeMessage(event): void {
    this.message = event.target.value;
  }

  signalRecommendationEmail(event: MouseEvent): void {
    if (!this.message) return;
    event.stopPropagation();
    const apiInputProducts = this._apiManagerService.buildApiSmartRecoInput(this.petInfo);
    this.coreFacade.togglePopinLoader(true);
    combineLatest([
      this.coreFacade.toolInformation$,
      this.vetFacade.vet$,
      this.vetFacade.clinic$,
      this.vetFacade.currentBigMeasurementUnit$,
    ])
      .pipe(
        first(),
        switchMap(([{ tool_flow_id }, vet, clinic, currentBigMeasurementUnit]) =>
          this.emailService.signalRecommendationEmail({
            countryCode: CountryCode[clinic.companyAddress?.country],
            locale: Constants.DEFAULT,
            data: buildSignalRecommendationBodyEmail({
              clinic,
              vet,
              srApiBody: apiInputProducts,
              petInfo: this.petInfo,
              message: this.message,
              tool_flow_id,
              technicalInformation: {
                retryCount: this.data?.retryCount,
                sessionId: this.data?.sessionId,
                errorMessage: '',
                status: SignalRecommendationTechnicalStatus.KO,
              },
              product: this.productReco,
              currentBigMeasurementUnit,
            }),
          })
        ),
        tap(() => {
          this.coreFacade.togglePopinLoader(false);
          this.signalPopinSuccess();
        }),
        catchError((error) => {
          this.coreFacade.togglePopinLoader(false);
          this.signalPopinFailed(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * Function when signal product is success and close popin with information for open next popin
   */
  signalPopinSuccess(): void {
    this.dialogRef.close({
      nextAction: ActionsEnum.success,
    });
  }

  /**
   * Function when signal product is fail and close popin with information for open next popin
   */
  signalPopinFailed(error: Error): void {
    this._logger.error(error);
    this.dialogRef.close({
      nextAction: ActionsEnum.fail,
    });
  }
}
