import { IconName } from '@app/shared/utils/icon/icons';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PetInfo } from '@app/core/models';
import { LocaleContentEnum } from '@app/shared/data';
import { PanelSectionTypeEnum } from '@app/shared/utils/enums/PanelSectionType.enum';
import { Helper, MeasurementCodeType } from '@app/shared/utils';
import { GTMService, petPanelClick } from '@app/core/services/tracking';
import { mapPanelActionTypeToLabel } from '@app/core/services/tracking/helpers/mapPanelActionTypeToLabel';
import { environment } from '@env/environment';
import { Env } from '@app/shared/utils/enums/environment.enum';
import { RCTagSize } from '@rc/ui';
import { ListItem } from '@app/shared/components/rc-list/list-item';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { TranslationCodePipe, WeightPipe } from '@app/shared/pipes';
import { DatePipe } from '@angular/common';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

export interface EditingPanelConfig {
  petDetails: boolean;
  petWeight: boolean;
  petDiagnosis: boolean;
}

@Component({
  selector: 'app-rc-pet-summary-panel',
  templateUrl: './rc-pet-summary-panel.component.html',
  styleUrls: ['./rc-pet-summary-panel.component.scss'],
})
export class RcPetSummaryPanelComponent implements OnInit {
  LocalContentType = LocaleContentEnum;
  PanelSection = PanelSectionTypeEnum;
  IconName = IconName;
  RCTagSize = RCTagSize;
  petDetailsFields: ListItem[];
  petWeightFields: ListItem[];

  readonly MAX_LENGTH_ELLIPSIS = 27;
  private _petInfo: PetInfo;

  isPanelPetDetailsExpended = true;
  isPanelPetWeightExpended = true;
  isPanelPetDiagnosisExpended = true;

  @Input()
  set pet(value: PetInfo | null) {
    this._petInfo = value;
    this.setPetDetailsFields();
    this.setPetWeightFields();
  }

  get pet(): PetInfo | null {
    return this._petInfo;
  }

  @Input() displayDiagnosis = false;
  @Input() displayXrayLink = false;
  @Input() isEditing: EditingPanelConfig = {
    petDetails: true,
    petWeight: true,
    petDiagnosis: true,
  };

  @Input() displayGrowthChart = false;
  @Input() currentBigMeasurementUnit: MeasurementCodeType;
  @Input() currentSystemPreferenceCode: SystemPreferenceCode;

  @Output()
  editPet: EventEmitter<PanelSectionTypeEnum> = new EventEmitter<PanelSectionTypeEnum>();
  environment = environment.envName;
  EnvName = Env;

  constructor(
    private trackingService: GTMService,
    private datePipe: DatePipe,
    private weightPipe: WeightPipe,
    private translationCodePipe: TranslationCodePipe
  ) {}

  ngOnInit(): void {
    this.setPetDetailsFields();
    this.setPetWeightFields();
  }
  /**
   * Add pet details in card
   * */
  setPetDetailsFields(): void {
    this.petDetailsFields = [
      {
        key: translateKey('form-attribute_species'),
        value: this.translationCodePipe.transform(this._petInfo?.speciesCode, LocaleContentEnum.Species),
      },
      {
        key: translateKey('form-attribute_gender'),
        value: this.translationCodePipe.transform(this._petInfo?.gender, LocaleContentEnum.Gender),
      },
      { key: translateKey('form-attribute_neutered'), value: translateKey(this._petInfo?.neutered ? 'text_yes' : 'text_no') },
      { key: translateKey('form-attribute_breed'), value: this._petInfo?.breedObject?.localName || '--' },
      { key: translateKey('form-attribute_birth-date'), value: this.datePipe.transform(this._petInfo?.birthdate, 'shortDate') || '--' },
    ];
  }

  /**
   * Add pet weight in card
   */
  setPetWeightFields(): void {
    this.petWeightFields = [];
    if (this._petInfo?.bcs) {
      this.petWeightFields.push({
        key: $localize`:@@form-attribute_bcs:`,
        value: this._petInfo?.bcs.toString(),
      });
    }
    if (this._petInfo?.weight && this._petInfo?.weight > 0) {
      this.petWeightFields.push({
        key: $localize`:@@label_weight:`,
        value: this.weightPipe.transform(
          { measure: this._petInfo?.weight, measureUnit: this.currentBigMeasurementUnit },
          this.currentSystemPreferenceCode,
          2
        ),
      });
    }

    if (this.displayTargetWeight()) {
      this.petWeightFields.push({
        key: this.getTargetWeightLabel(),
        value: this.weightPipe.transform(
          { measure: this._petInfo.targetWeight, measureUnit: this.currentBigMeasurementUnit },
          this.currentSystemPreferenceCode,
          2
        ),
      });
    }

    if (this._petInfo?.petActivity) {
      this.petWeightFields.push({
        key: $localize`:@@form-attribute_activity:`,
        value: this.translationCodePipe.transform(this._petInfo?.petActivity, LocaleContentEnum.PetActivity),
      });
    }
  }

  private getTargetWeightLabel(): string {
    const isAdult = Helper.lifestageOld(this._petInfo.lifestage);
    const isOverweight = this._petInfo.bcs >= 6;
    if (isAdult && isOverweight) {
      return $localize`:@@banner_target_weight:`;
    } else if (!isAdult) {
      return $localize`:@@form-attribute_adult-target-weight:`;
    }
  }

  private displayTargetWeight(): boolean {
    const valuesDefined = !!this._petInfo?.targetWeight && !!this._petInfo?.lifestage;
    const isAdult = Helper.lifestageOld(this._petInfo?.lifestage);
    const isOverweight = this._petInfo.bcs >= 6;
    return valuesDefined && ((isAdult && isOverweight) || !isAdult);
  }

  /**
   * function to handle toggle actions from panel
   */
  showPanelDetails(panelSection: PanelSectionTypeEnum): void {
    switch (panelSection) {
      case PanelSectionTypeEnum.PetDetails:
        this.isPanelPetDetailsExpended = !this.isPanelPetDetailsExpended;
        break;
      case PanelSectionTypeEnum.PetWeight:
        this.isPanelPetWeightExpended = !this.isPanelPetWeightExpended;
        break;
      case PanelSectionTypeEnum.PetDiagnosis:
        this.isPanelPetDiagnosisExpended = !this.isPanelPetDiagnosisExpended;
        break;
    }
  }

  editPetDetails(panelSection: PanelSectionTypeEnum): void {
    this.trackingService.sendInteraction(petPanelClick(mapPanelActionTypeToLabel(panelSection)));
    this.editPet.emit(panelSection);
  }

  editGrowthChart(panelSection: PanelSectionTypeEnum): void {
    this.trackingService.sendInteraction(petPanelClick(mapPanelActionTypeToLabel(panelSection)));
    this.editPet.emit(panelSection);
  }

  displayTooltipLongerWord(word: string): boolean {
    return word.length > this.MAX_LENGTH_ELLIPSIS;
  }
}
