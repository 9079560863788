<div class="rc-counter-container">
  <button type="button" class="rc-counter-button" id="counter-button-minus" (click)="onRemove()" [disabled]="counterValue <= min">
    <app-icon class="icon" name="minus"></app-icon>
  </button>
  <input
    type="number"
    [value]="counterValue"
    (ngModelChange)="onInputChange($event)"
    [(ngModel)]="counterValue"
    [min]="min"
    [disabled]="disabled"
    class="rc-counter-input"
  />
  <button type="button" class="rc-counter-button" id="counter-button-plus" (click)="onAdd()">
    <app-icon class="icon" name="plus"></app-icon>
  </button>
</div>
