import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { getDateErrorMessage, getInputErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import { isSameDay } from 'date-fns';
import { Subject } from 'rxjs';
import { map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { Consultation, FollowUpAction, FollowUpOutput, FollowUpSelector, Patient, Weight } from '@app/core/models';
import { ApiManagerService, LoaderService, Logger, ModalService, NutritionService, VetService } from '@app/core/services';

import { FollowUpSelectorPopinComponent } from '@app/shared/components/follow-up-selector-popin/follow-up-selector-popin.component';
import { minDateValidator, pastDateValidator } from '@app/shared/directives';
import { Constants, DataSubscriber, Helper, MeasurementCodeType } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { MeasureHelper } from '@app/shared/utils/static-helpers/measure-helper';
import { CoreFacade } from '@app/store/core/core.facade';
import { RCAlertType } from '@rc/ui';

@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.scss'],
  providers: [DatePipe],
})
export class FollowUpComponent implements OnInit, OnDestroy {
  public readonly IconName = IconName;
  Constants = Constants;

  @Input() patient: Patient;
  @Input() lastConsultation: Consultation;

  @Output()
  followUpOutput: EventEmitter<FollowUpOutput> = new EventEmitter<FollowUpOutput>();

  /** Enter weight screen (first state) **/
  enterWeightForm: UntypedFormGroup;

  minWeight: string;
  maxWeight: string;
  weightSuffix$ = this.vetService.currentBigMeasurementUnit$.pipe(
    map((currentBigMeasurementUnit) => MeasureHelper.measureUnitToSuffix(currentBigMeasurementUnit))
  );
  isLoading = false;
  alertType = RCAlertType;
  error: string;

  private _destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modal: ModalService,
    private loaderService: LoaderService,
    private apiManagerService: ApiManagerService,
    private nutritionService: NutritionService,
    private vetService: VetService,
    private logger: Logger,
    private coreFacade: CoreFacade
  ) {}

  ngOnInit(): void {
    this._createForm();
    this._setCommunicator();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public WeightErrorMessage(): string {
    return (
      (this.enterWeightForm.controls.weight.touched &&
        getInputErrorMessage(this.enterWeightForm.controls.weight.errors, 'form-attribute_pet-weight')) ||
      ''
    );
  }

  public dateErrorMessage(): string {
    return (
      (this.enterWeightForm.controls.visitDate.touched &&
        this.enterWeightForm.controls.visitDate.errors &&
        getDateErrorMessage(this.enterWeightForm.controls.visitDate.errors, 'follow-up_form-label-date', true)) ||
      ''
    );
  }

  /**
   * Function checking for date change and make Api call for update next visit date
   */
  nextVisitDateUpdate() {
    this.isLoading = true;
    const updateData = Helper.buildVisitDataUpdate('next', this.enterWeightForm.get('visitDate').value);
    this.vetService
      .updateConsultationVisits(updateData, this.patient.id, this.lastConsultation.id)
      .pipe(withLatestFrom(this.vetService.currentBigMeasurementUnit$), take(1), takeUntil(this._destroyed$))
      .subscribe(([lastConsultation, currentBigMeasurementUnit]) => {
        this.lastConsultation = lastConsultation;
        this._setMinAndMax(lastConsultation, currentBigMeasurementUnit);
        this.isLoading = false;
        if (
          isSameDay(
            Helper.parseDate(lastConsultation.nextVisit.expected.visitDateTime),
            Helper.parseDate(lastConsultation.visit.visitDateTime)
          )
        ) {
          this.coreFacade.setAlert({
            message: $localize`:@@next_visit_date_error_msg:`,
            alertType: RCAlertType.WARNING,
          });
        }
      });
  }

  onSubmitConsultation(event): void {
    event.preventDefault();
    const visitDate = this.enterWeightForm.value.visitDate;
    const weight = this.enterWeightForm.value.weight;
    this.loaderService.startLoader(true);
    this.vetService.currentBigMeasurementUnit$
      .pipe(
        take(1),
        tap((currentBigMeasurementUnit) => {
          const weightObj: Weight = {
            measure: weight,
            measureUnit: currentBigMeasurementUnit,
            weightDate: visitDate,
          };
          const recommendationProgramBody = this.apiManagerService.buildRecommendationProgramBody(
            this.patient,
            this.lastConsultation,
            weightObj
          );
          const recommendationPrograms = this.nutritionService.recommendationPrograms(recommendationProgramBody);
          DataSubscriber.subscribe(
            recommendationPrograms,
            (followUpSelector) => {
              this.loaderService.stopLoader(() => {
                if (followUpSelector.actions.length < 2) {
                  this.selectUniqueAction(followUpSelector, weight, visitDate, this.lastConsultation);
                } else {
                  this.openActionsPopin(followUpSelector, weight, visitDate, this.lastConsultation, currentBigMeasurementUnit);
                }
              });
            },
            this._destroyed$,
            (error) => {
              this.logger.error(error);
              this.loaderService.stopLoader();
              this.error = $localize`:@@error_try-again-later:`;
            }
          );
        })
      )
      .subscribe();
  }

  /**
   * Function select default action
   */
  selectUniqueAction(followUpSelector: FollowUpSelector, weight: number, visitDate: Date, lastConsultation: Consultation) {
    const action: FollowUpAction = followUpSelector.actions[0];
    this.followUpOutput.emit({
      weight,
      visitDate,
      action,
      lastConsultation,
    });
  }

  /**
   * Function open popin when there is several actions
   */
  openActionsPopin(
    followUpSelector: FollowUpSelector,
    weight: number,
    visitDate: Date,
    lastConsultation: Consultation,
    currentBigMeasurementUnit: MeasurementCodeType
  ) {
    const modal = this.modal.open(FollowUpSelectorPopinComponent, {
      data: {
        followUpSelector,
        previousWeight: lastConsultation.visit.weight,
        currentWeightMeasure: weight,
        targetUnit: currentBigMeasurementUnit,
      },
    });
    modal.afterClosed.pipe(take(1), takeUntil(this._destroyed$)).subscribe((action: FollowUpAction) => {
      if (action) {
        this.followUpOutput.emit({
          weight,
          visitDate,
          action,
          lastConsultation,
        });
      }
    });
  }

  resetFormWeight(): void {
    this.enterWeightForm.reset();
  }

  private _setCommunicator() {
    this.nextVisitDateUpdate();
  }

  private _setMinAndMax(lastConsultation, currentBigMeasurementUnit: MeasurementCodeType) {
    if (lastConsultation.nextVisit && lastConsultation.nextVisit.expected) {
      const expectedWeight = lastConsultation.nextVisit.expected.weight;
      const localizeMeasure = (weight) => {
        return MeasureHelper.convertWeight(weight, expectedWeight.measureUnit, currentBigMeasurementUnit).measure.toFixed(2);
      };
      this.minWeight = localizeMeasure(expectedWeight.minWeight);
      this.maxWeight = localizeMeasure(expectedWeight.maxWeight);
    }
  }

  private _createForm() {
    this.vetService.maxPetWeight$
      .pipe(
        take(1),
        tap((maxPetWeight) => {
          this.enterWeightForm = this.formBuilder.group({
            visitDate: [
              new Date(),
              [
                Validators.required,
                pastDateValidator,
                minDateValidator(this.lastConsultation.visit.visitDateTime),
                minDateValidator(this.patient.pet.birth.date),
              ],
            ],
            weight: [null, [Validators.required, Validators.max(maxPetWeight)]],
          });
        })
      )
      .subscribe();
  }
}
