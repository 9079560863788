<div id="loader" class="rc-loader" [class.transparent]="transparent" [class.withinContent]="withinContent">
  <div class="rc-loader__container">
    <div class="rc-loader__container__circular"></div>
    <img
      *ngIf="!isStandalone"
      class="rc-loader__container__logo"
      src="https://d1a19ys8w1wkc1.cloudfront.net/logo--crown.png?v=8-8-3"
      alt="loader-logo"
    />
  </div>
</div>
