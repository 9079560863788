<div class="rc-address-cards-wrapper">
  <div class="rc-address-card-wrapper" *ngFor="let address of displayedAddresses; let index = index; trackBy: trackByFn">
    <ng-container *appLet="showWarningMessage(address) as showWarning">
      <div
        class="rc-address-card rc-address-item"
        [ngClass]="{
          'rc-address-card--is-active': address.defaultAddress,
          'rc-address-card--is-warning': showWarning
        }"
        (click)="toggleAddress(address, index)"
      >
        <!-- RC Address information : Name & Address -->
        <div class="rc-address-card__information">
          <p class="rc-address-card__name" data-cs-mask>{{ address.name }} {{ address.givenName }} {{ address.familyName }}</p>
          <p data-cs-mask>
            {{ address.streetNumberAddress }} <br />
            {{ address.streetAddress }} - {{ address.additionalInformation }} <br />
            {{ address.postalCode }} {{ address.addressCity }} <br />
            {{ address.telephone }}
          </p>
        </div>

        <!-- RC Address footer : Action Button & Check mark -->
        <div class="rc-address-card__footer">
          <div class="left">
            <ng-container *ngIf="!address.type || address.type !== 'company'">
              <button class="rc-button rc-button--link edit-action">
                <span class="rc-button__title rc-button__title--link" (click)="editAction($event, address, index)" i18n="@@action_edit"
                  >Edit</span
                >
              </button>
              <button class="rc-button rc-button--link delete-action">
                <span class="rc-button__title rc-button__title--link" (click)="deleteAction($event, address, index)" i18n="@@action_delete"
                  >Delete</span
                >
              </button>
            </ng-container>
          </div>
          <div class="rc-address-card__check-wrapper">
            <span *ngIf="!showWarning" class="rc-address-card__check"></span>
            <app-icon *ngIf="showWarning" [name]="IconName.exclamation" class="rc-address-card__warning-icon"></app-icon>
          </div>
          <div class="clear"></div>
        </div>
      </div>

      <div *ngIf="showWarning" class="rc-address-card-warning">
        <app-warning-message
          description="{{ 'muf_warning_delivery' | translateKey }}"
          actionLabel="{{ 'action-update-delivery' | translateKey }}"
          (actionClicked)="editAction($event, address, index)"
        ></app-warning-message>
      </div>
    </ng-container>
  </div>
</div>

<!-- Add new address -->
<div class="rc-address-card-wrapper" *ngIf="!hideNewAddressBtn && addresses.length < TOTAL_LIMIT_ADDRESSES_DISPLAYED && pageNumber <= 1">
  <div class="rc-address-card rc-address-card-new rc-address-card--dashed" (click)="addAddress($event)">
    <div class="rc-address-card__add-body">
      <em class="rc-icon rc-icon--minidirectory"></em>
      <a class="rc-button__title rc-button__title--link add-address-btn" i18n="@@add_new_clinic_address">Add a new clinic address</a>
    </div>
  </div>
</div>

<!-- Pagination -->
<ng-container *ngIf="addresses.length > LIMIT_ADDRESSES_DISPLAYED && addresses.length < TOTAL_LIMIT_ADDRESSES_DISPLAYED">
  <div class="pagination">
    <app-rc-pagination
      [total]="addresses.length"
      (prevPageEvent)="onAddressesPageChange($event)"
      (changePageEvent)="onAddressesPageChange($event)"
      (nextPageEvent)="onAddressesPageChange($event)"
    >
    </app-rc-pagination>
  </div>
</ng-container>

<!-- Limit message -->
<ng-container *ngIf="addresses.length >= TOTAL_LIMIT_ADDRESSES_DISPLAYED">
  <div class="rc-address-card__warning-limit">
    <span class="rc-icon rc-icon--trash rc-address-card__warning-limit-icon"></span>
    <p class="rc-address-card__warning-limit-msg" i18n="@@warning-limit-addresses">
      You have reached the maximum number of address stored, please delete an address to add a new one.
    </p>
  </div>
</ng-container>
