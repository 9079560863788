import { Injectable } from '@angular/core';
import { PatientSearchAutocomplete, PatientSearchFilters } from '@app/core/models';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '..';
import { clearPatientState, getOwners, getPatients } from './patient.actions';
import {
  selectPatients,
  selectPatientsAndOwnersLoading,
  selectPatientsAutocomplete,
  selectPatientsLoaded,
  selectPatientsPetRecord,
  selectPatientStateError,
} from './patient.selectors';

@Injectable()
export class PatientFacade {
  selectPatientStateError$ = this.store.pipe(select(selectPatientStateError));
  patients$ = this.store.pipe(select(selectPatients));
  patientsLoaded$ = this.store.pipe(select(selectPatientsLoaded));
  patientsAndOwnersLoading$ = this.store.pipe(select(selectPatientsAndOwnersLoading));
  selectPatientsAutocomplete$: Observable<PatientSearchAutocomplete[]> = this.store.pipe(select(selectPatientsAutocomplete));
  selectPatientsPetRecord$ = this.store.pipe(select(selectPatientsPetRecord));
  constructor(private store: Store<AppState>) {}

  loadPatients(filters: PatientSearchFilters): void {
    this.store.dispatch(getPatients({ filters }));
  }

  loadOwners(contactIds: string[]): void {
    this.store.dispatch(getOwners({ contactIds }));
  }

  clearState(): void {
    this.store.dispatch(clearPatientState());
  }
}
