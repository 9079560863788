import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-expansion-panel-step-header',
  templateUrl: './expansion-panel-step-header.component.html',
  styleUrls: ['./expansion-panel-step-header.component.scss'],
})
export class ExpansionPanelStepHeaderComponent {
  @Input() active = false;
  @Input() completed = false;
  @Input() index = 0;
  @Input() label = '';
  @Input() action = '';

  @Output() actionClicked = new EventEmitter<boolean>();
}
