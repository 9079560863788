<app-rc-dialog-wrapper (closePopin)="cancel()">
  <div class="dialog_container">
    <div class="dialog_container__body">
      <!-- Image -->

      <div class="dialog_container__thumbs">
        <img src="assets/svg/wet-products.svg" alt="" />
      </div>

      <!-- Text -->
      <p class="dialog_container__body__message-1 rc-title-gamma">
        {{ 'product-reco_we-found-x-related-products' | translateKey: { x: product_count } }}
      </p>
      <p class="dialog_container__body__message-2" i18n="@@product-reco_combine-question">
        Do you want to combine a dry and a wet product in order for your pet to enjoy the benefits of mix feeding?
      </p>
    </div>

    <div class="dialog_container__footer">
      <div class="rc-button-group">
        <button class="rc-button rc-button--filled-small" id="goForMixFeeding" (click)="goForMixFeeding()">
          <span class="rc-button__title" i18n="@@action_yes_mix-feeding">Yes, I want a mix feeding solution</span>
        </button>
        <span class="rc-button__separator" i18n="@@action_separator">or</span>
        <button class="rc-button" (click)="cancel()">
          <span class="rc-button__title--link" i18n="@@action_no_thanks">No, thank you</span>
        </button>
      </div>
    </div>
  </div>
</app-rc-dialog-wrapper>
