import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedPipesModule } from '@app/shared/pipes/shared-pipes.module';
import { IconModule } from '@app/shared/utils/icon/icon.module';
import { ClinicalAllianceBadgeComponent } from './clinical-alliance-badge.component';

@NgModule({
  imports: [CommonModule, IconModule, SharedPipesModule],
  declarations: [ClinicalAllianceBadgeComponent],
  exports: [ClinicalAllianceBadgeComponent],
})
export class ClinicalAllianceBadgeModule {}
