import { environment } from '@env/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Package, Product } from '@app/core/models';
import { LocaleContentEnum } from '@app/shared/data';
import { PackType } from '@app/shared/utils';
import ProductHelper from '@app/shared/utils/static-helpers/product-helper';
import { VetFacade } from '@app/store/vet';
import { RCSelectItem } from '@rc/ui';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { GTMService, productDetailsDisplayed } from '@app/core/services/tracking';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() product?: Product;
  @Input() displayPackages?: boolean;
  @Input() showSelectButton?: boolean;
  @Input() disableDetailsButton?: boolean = false;
  @Input() selectButtonLabel?: string;
  @Input() selectedProductsIds?: string[] = [];
  @Input() showRetailPrices? = true;
  @Input() packId?: string;
  @Input() excludeStarterKitPacks? = true;

  @Output() selectProduct = new EventEmitter<{ product: Product; packId: string }>();
  @Output() removeProduct = new EventEmitter<Product>();
  @Output() showDetailPopinEvent = new EventEmitter<undefined>();

  public readonly LocaleContentEnum = LocaleContentEnum;

  public singleProductForm?: UntypedFormGroup;
  public clinicCurrency$ = this.vetFacade.currentClinicCurrency$;
  public showRetailPrices$ = of(false);
  public showWholesalePrices$ = of(false);
  public packageItems: RCSelectItem[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private vetFacade: VetFacade,
    protected modal: MatDialog,
    private trackingService: GTMService
  ) {}

  ngOnInit(): void {
    const filteredPackages = this.excludeStarterKitPacks
      ? this.product?.packages.filter((pack) => pack.type !== PackType.StarterKit)
      : this.product?.packages.filter((pack) => pack.type === PackType.StarterKit);

    const initialPack = this.packId || filteredPackages[0]?.sCode;
    this.singleProductForm = this.formBuilder.group({
      packId: initialPack,
    });

    this.packageItems = this.getPackageItems(filteredPackages);

    this.showRetailPrices$ = this.vetFacade.isRetailPriceActivated$.pipe(
      map((isRetailPriceActivated) => this.showRetailPrices && !environment.isStandalone && isRetailPriceActivated)
    );
    this.showWholesalePrices$ = this.vetFacade.isWholesalePriceActivated$.pipe(
      map((isWholesalePriceActivated) => !environment.isStandalone && isWholesalePriceActivated)
    );
    this.trackingService.sendInteraction(productDetailsDisplayed(this.product));
  }

  getProductPrice = (): number | null =>
    ProductHelper.getPriceFromSelectedProductPack(this.product, this.singleProductForm?.get('packId')?.value);

  getWholesalePrice$ = (packId?: string): Observable<number | null> => {
    const currentPack = this.product?.packages?.find((pack) => pack.sCode === packId);
    return this.vetFacade.wholesalePrices$.pipe(
      first(),
      map((wholesalePrices) => wholesalePrices[currentPack?.ean] || null)
    );
  };

  showDetailPopin(): void {
    this.showDetailPopinEvent.emit();
  }

  didSelectProduct(product: Product): void {
    if (this.isSelected(product)) return this.removeProduct.emit(product);
    this.selectProduct.emit({ product, packId: this.singleProductForm.controls.packId.value });
  }

  isSelected = (product: Product): boolean => !!this.selectedProductsIds?.find((id: string) => id === product.id);

  /*
    By default the RcSelect component will sort items alphabetically, we want to prevent this behaviour
  */
  customSelectSort(): number {
    return 1;
  }

  private getPackageItems(packages: Package[] = []): RCSelectItem[] {
    return packages.map((pack) => {
      return {
        label: pack.text,
        value: pack.sCode,
      };
    });
  }
}
