<div class="nutritional-assessment">
  <p class="description">{{ 'sr-xray_block_title_more_infos' | translateKey }}</p>
  <div class="nutrients-infography">
    <h4 class="nutrients-infography-title">{{ 'sr-xray_nutrient_bar_definition_title' | translateKey }}</h4>

    <div class="nutrients-infography-details">
      <app-nutrients-bar [id]="'desktop'" [nutrients]="nutrientsMock"></app-nutrients-bar>
      <div>
        <p class="nutrients-infography-definition">
          {{ 'sr-xray_nutrient_bar_definition' | translateKey }}
        </p>

        <div class="nutritional-assessment-link-container">
          <app-icon class="icon" [name]="IconName.documents"></app-icon>
          <a class="rc-button__title rc-button__title--link" rel="noopener" [href]="xrayFAQPdfUrl$ | async" target="_blank">
            <span>{{ 'sr-xray_download-complete-faq' | translateKey }} [PDF]</span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="(nutritionalAssessmentLoading$ | async) === false; else loading">
    <ng-container *ngIf="(nutritionalAssessmentHasError$ | async) === false">
      <app-nutrients-list
        [nutrients]="macroNutrients$ | async"
        [nutrientType]="NutrientType.MACRONUTRIENTS"
        [nutrientListTitle]="'sr-xray_column_title_macronutrients' | translateKey"
      ></app-nutrients-list>
      <app-nutrients-list
        [nutrients]="otherNutrients$ | async"
        [nutrientType]="NutrientType.OTHER_NUTRIENTS"
        [nutrientListTitle]="'sr-xray_2nd_block_title' | translateKey"
      ></app-nutrients-list>
      <app-nutrients-benefits-list
        [benefits]="benefitClaim$ | async"
        [nutrientListTitle]="'sr-xray_3rd_block_title' | translateKey"
      ></app-nutrients-benefits-list>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div class="loader">
      <app-rc-spinner></app-rc-spinner>
    </div>
  </ng-template>
  <ng-container *ngIf="nutritionalAssessmentHasError$ | async">
    <div class="no-results">
      <app-no-results
        [title]="'sr-xray_error_message_title' | translateKey"
        [description]="'sr-xray_error_message_retry' | translateKey"
        [actionLabel]="'action_retry' | translateKey"
        (actionOutput)="retryGetNutritionalAssessment()"
      ></app-no-results>
    </div>
  </ng-container>
  <footer class="footer">
    <button class="rc-button rc-button--filled-small" id="get-recommendation" (click)="submitRecommendation()">
      <span class="rc-button__title">{{ 'rd-title-nutritional-recommendations' | translateKey }}</span>
    </button>
  </footer>
</div>
