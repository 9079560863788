<app-rc-popin-content [isSingleColumn]="true" [size]="PopinSize.Calendar">
  <!-- Popin Form -->
  <div *ngIf="form" class="rc-popin__form mypopin__content">
    <div class="rc-popin__center rc-popin__wrapper">
      <div class="mypopin__title" popin_title-content>
        <h3 class="rc-title-gamma">{{ title }}</h3>
        <p class="rc-paragraph mypopin__title-desc">{{ subtitle }}</p>
      </div>

      <form (ngSubmit)="onSubmitForm($event)" [formGroup]="form" autocomplete="off" class="mypopin__form">
        <ng-container *appLet="dateErrorMessage() as error">
          <div class="datepicker-wrapper">
            <div class="datepicker" [class.error-state]="error">
              <input
                formControlName="visitDate"
                autocomplete="off"
                matInput
                [min]="data?.minDate"
                [max]="data?.maxDate"
                [matDatepicker]="picker"
                id="datepicker-input"
                class="error-input"
                placeholder="{{ 'form-attribute_select-date' | translateKey }}"
              />
              <app-icon
                [class.error]="error"
                [name]="IconName.calendar_small"
                class="datepicker-icon"
                (click)="picker.opened ? picker.close() : picker.open()"
              ></app-icon>
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <span *ngIf="error" class="datepicker-error">{{ error }}</span>
          </div>
        </ng-container>
      </form>
    </div>

    <!-- Popin Footer -->
    <ng-container *ngIf="data.id !== 'init-date'; else initFooter">
      <div class="rc-popin__center rc-popin__footer">
        <button (click)="dismiss()" class="rc-button rc-button--primary">
          <span class="rc-button__title rc-button__title--link" i18n="@@action_cancel">{{ 'cancel' | titlecase }}</span>
        </button>
        <span class="mypopin__footer-separator" i18n="@@action_separator">or</span>
        <button (click)="onSubmitForm($event)" [disabled]="form.invalid" class="rc-button rc-button--filled-small mypopin__button">
          <span class="rc-button__title" i18n="@@action_update">{{ 'update' | titlecase }}</span>
        </button>

        <div class="mypopin__footer-text" popin_footer-content>
          <p>{{ footerText }}</p>
        </div>
      </div>
    </ng-container>
    <ng-template #initFooter>
      <button
        (click)="onSubmitForm($event)"
        [disabled]="form.invalid"
        class="rc-button rc-button--filled-small mypopin__button"
        id="plan-date"
      >
        <span class="rc-button__title" i18n="@@wm_plan_next_visit_pop_in-button">Plan</span>
      </button>
      <div class="mypopin__footer-text" popin_footer-content>
        <p>{{ footerText }}</p>
      </div>
    </ng-template>
  </div>
</app-rc-popin-content>
