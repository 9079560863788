<div class="container">
  <div class="container-content">
    <h2 class="title">{{ title }}</h2>
    <p class="description">{{ description }}</p>
    <div [id]="'identification_search_pet_' + tool" class="autocomplete">
      <rc-autocomplete
        [id]="'identification-search-pet'"
        data-cs-mask
        (clear)="clearSearch()"
        (lastClickedItem)="didSelectSearch($event)"
        (searched)="onNewSearch($event)"
        [customFilter]="customSearchFilter"
        [emptyTemplate]="emptyTemplate"
        [itemSize]="itemSize"
        [itemHeight]="itemHeight"
        [items]="patients$ | async"
        [attr.data-testid]="'identification_search_pet'"
        [placeholder]="'searchbypo_or_pet' | translateKey"
        [showClear]="true"
        [showEmptyTemplate]="showEmptyResult$ | async"
        [showItemPrefix]="true"
        [valueAccessorFn]="valueAccessorFn"
      >
        <ng-template let-item>
          <app-icon
            *ngIf="item?.value?.pet?.speciesCode"
            [name]="item.value.pet.speciesCode === SpeciesCode.Dog ? IconName.specie_dog : IconName.specie_cat"
            class="specie-icon"
          ></app-icon>
        </ng-template>
        <ng-template #emptyTemplate let-listItem>
          <div class="rc-autocomplete__list">
            <p class="rc-autocomplete__list__item empty-template" i18n="@@product-catalogue-empty_title_no_result">No result found</p>
            <button (click)="createNewPet()" class="rc-button rc-autocomplete__list__item empty-template-action" type="button ">
              <span class="rc-icon rc-icon--add-people"></span>
              <span class="rc-button__title rc-button__title--link" i18n="@@action_create-new-pet">Create a new pet</span>
            </button>
          </div>
        </ng-template>
      </rc-autocomplete>
    </div>
    <div class="footer">
      <span class="footer-info" i18n="@@identification_not_registered"> Not registered yet ? </span>
      <button (click)="createNewPet()" [id]="'identification_new_pet_' + tool" class="rc-button" type="button">
        <span class="rc-button__title rc-button__title--link" i18n="@@identification_start_new_pet">Start with a new pet</span>
      </button>
    </div>
  </div>
  <div class="container-image">
    <img [src]="imgSrc" class="image" loading="lazy" [alt]="'consultation_image_' + tool" />
  </div>
</div>
