import { DeliveryOrderFormValues } from '@app/shared/components/delivery-order/delivery-order';
import { PanelSectionTypeEnum } from '@app/shared/utils';
import { createAction, props } from '@ngrx/store';
import { MufPersonalisedBagStep } from '../../muf-personalised-bag-step';
/**
 * Action dispatched to start a daily allowance flow
 * It resets daily allowance store data and consultation store data
 */
export const startMufPersonalisedBag = createAction(
  '[Muf Personalised Bag] Start',
  props<{ renew?: boolean; deliveryValues?: DeliveryOrderFormValues }>()
);
/**
 * Set current step
 */
export const setMufPersonalisedBagStep = createAction('[Muf Personalised Bag] Set Step', props<{ value: MufPersonalisedBagStep }>());
export const setMufPersonalisedBagIsRenewal = createAction('[Muf Personalised Bag] Set Is Renewal', props<{ isRenewal: boolean }>());
/**
 * Reset daily allowance store
 */
export const resetMufPersonalisedBag = createAction('[Muf Personalised Bag] Reset');
/**
 * Navigate back to step from panel from another module (allowance) during consultation
 */
export const navigateBackToStep = createAction('[Muf Personalised Bag] Navigate back to step', props<{ value: PanelSectionTypeEnum }>());
