import { CountriesService } from '@app/core/services/utils/localization/countries.service';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { EmailService } from '@app/core/services';
import { Constants } from '@app/shared/utils';
import { EmailPopinType } from '@app/shared/utils/enums/email-popin-type';
import { CoreFacade } from '@app/store/core/core.facade';
import { RCAlertType } from '@rc/ui';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactData, ContactPopinFormControlNameEnum, ContactPopinFormValues } from './contact-popin.types';
import { getInputErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import { customEmailValidator } from '@app/shared/validators/email.validator';
import { merge, of } from 'rxjs';

@Component({
  selector: 'app-contact-popin',
  templateUrl: './contact-popin.component.html',
  styleUrls: ['./contact-popin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPopinComponent implements OnInit, OnDestroy {
  public readonly AlertType = RCAlertType;
  public readonly ContactPopinFormControlNameEnum = ContactPopinFormControlNameEnum;
  public contactForm!: FormGroup<ContactPopinFormValues>;
  public submitted = false;
  public countries$ = merge(of([]), this.countriesService.translatedCountryList$);
  public readonly fieldKeyTradError: Record<ContactPopinFormControlNameEnum, string> = {
    [ContactPopinFormControlNameEnum.FIRSTNAME]: 'form-attribute_first-name',
    [ContactPopinFormControlNameEnum.LASTNAME]: 'form-attribute_last-name',
    [ContactPopinFormControlNameEnum.PRACTICE_NAME]: 'form-attribute_practice-name',
    [ContactPopinFormControlNameEnum.EMAIL_CONTACT]: 'form-attribute_email',
    [ContactPopinFormControlNameEnum.COUNTRY]: 'form-attribute_country',
    [ContactPopinFormControlNameEnum.BODY]: 'form_contact-msg',
  };

  private errorMsg: string;

  /**
   * Initializer
   */
  constructor(
    private emailService: EmailService,
    private coreFacade: CoreFacade,
    private countriesService: CountriesService,
    public dialogRef: MatDialogRef<ContactPopinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; description: string; country: string }
  ) {}

  /**
   * FormControl getters
   */
  get firstNameControl(): AbstractControl<string, string> {
    return this.contactForm.get(ContactPopinFormControlNameEnum.FIRSTNAME);
  }
  get lastNameControl(): AbstractControl<string, string> {
    return this.contactForm.get(ContactPopinFormControlNameEnum.LASTNAME);
  }
  get practiceNameControl(): AbstractControl<string, string> {
    return this.contactForm.get(ContactPopinFormControlNameEnum.PRACTICE_NAME);
  }
  get emailContactControl(): AbstractControl<string, string> {
    return this.contactForm.get(ContactPopinFormControlNameEnum.EMAIL_CONTACT);
  }
  get countryControl(): AbstractControl<string, string> {
    return this.contactForm.get(ContactPopinFormControlNameEnum.COUNTRY);
  }
  get bodyControl(): AbstractControl<string, string> {
    return this.contactForm.get(ContactPopinFormControlNameEnum.BODY);
  }

  ngOnInit(): void {
    this.setupForm();
  }

  ngOnDestroy(): void {
    this.coreFacade.removeAlert({ message: this.errorMsg, alertType: null });
  }

  public errorMessage(field: ContactPopinFormControlNameEnum): string {
    if (this.contactForm.controls[field].touched) {
      return getInputErrorMessage(this.contactForm.controls[field].errors, this.fieldKeyTradError[field]);
    }
    return '';
  }

  public closePopin(): void {
    this.errorMsg = null;
    this.dialogRef.close();
  }

  /**
   * Submit form
   * *1* Build Contact object
   * *2* Check form validity
   * *3* Send Contact data
   * *4* Catch error
   */
  submitForm(): void {
    /*1*/
    const subject = `Contact Us: ${this.firstNameControl.value} ${this.lastNameControl.value} - ${this.practiceNameControl.value}`;
    const contactData: ContactData = {
      countryCode: this.countryControl.value,
      locale: Constants.DEFAULT,
      from: this.emailContactControl.value,
      subject,
      data: {
        countryCode: this.countryControl.value,
        from: this.emailContactControl.value,
        subject,
        message: `${this.bodyControl.value}`,
      },
    };
    /*2*/
    if (this.firstNameControl.invalid || this.lastNameControl.invalid || this.emailContactControl.invalid || this.bodyControl.invalid) {
      this.errorMsg = $localize`:@@support-landing_form_warning:Please fill out the form:`;
      this.coreFacade.setAlert({ message: this.errorMsg, alertType: RCAlertType.ERROR });
      return;
    }
    /*3*/
    if (contactData) {
      this.submitted = true;
      this.dialogRef.close(this.submitted);
      this.emailService.sendByEmail(EmailPopinType.Contact, undefined, contactData);
    }
  }

  private setupForm() {
    this.contactForm = new FormGroup<ContactPopinFormValues>({
      [ContactPopinFormControlNameEnum.FIRSTNAME]: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      [ContactPopinFormControlNameEnum.LASTNAME]: new FormControl('', { validators: [Validators.required], nonNullable: true }),
      [ContactPopinFormControlNameEnum.PRACTICE_NAME]: new FormControl('', {
        validators: [Validators.required, Validators.minLength(6)],
        nonNullable: true,
      }),
      [ContactPopinFormControlNameEnum.EMAIL_CONTACT]: new FormControl('', {
        validators: [Validators.required, customEmailValidator],
        nonNullable: true,
      }),
      [ContactPopinFormControlNameEnum.COUNTRY]: new FormControl(this.data.country, {
        validators: [Validators.required],
        nonNullable: true,
      }),
      [ContactPopinFormControlNameEnum.BODY]: new FormControl('', {
        validators: [Validators.required, Validators.maxLength(300)],
        nonNullable: true,
      }),
    });
  }
}
