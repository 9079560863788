import { Component, Output, EventEmitter, Attribute } from '@angular/core';
import { IconName } from '@app/shared/utils/icon/icons';
import { CoreFacade } from '@app/store/core/core.facade';

@Component({
  selector: 'app-rc-dialog-wrapper',
  templateUrl: './rc-dialog-wrapper.component.html',
  styleUrls: ['./rc-dialog-wrapper.component.scss'],
})
export class RcDialogWrapperComponent {
  @Output() closePopin = new EventEmitter();

  noClose: boolean;
  withStickyFooter: boolean;
  showPopinLoader$ = this.coreFacade.showPopinLoader$;
  IconName = IconName;

  constructor(
    private coreFacade: CoreFacade,
    @Attribute('noClose') noClose: boolean,
    @Attribute('withStickyFooter') withStickyFooter: boolean
  ) {
    this.noClose = noClose;
    this.withStickyFooter = withStickyFooter;
  }

  close(event: MouseEvent): void {
    event.preventDefault();
    this.closePopin.emit();
  }
}
