import { FractionCupPipe } from '@app/shared/pipes';
import { RCListModule } from './components/rc-list/rc-list.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, CurrencyPipe, LowerCasePipe, TitleCasePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { LoaderModule } from '@app/core/components/loader/loader.module';
import { PhraseService } from '@app/core/services/phrase/phrase.service';
import {
  NutrientsBenefitsListComponent,
  NutrientsListComponent,
  ProductFooterComponent,
  SwitchWlpPopinComponent,
} from '@app/shared/components';
import { NutrientBarComponent } from '@app/shared/components/nutrient-bar/nutrient-bar.component';
import { NutritionalAssessmentComponent } from '@app/shared/components/nutritional-assessment/nutritional-assessment.component';
import {
  RCAccordionModule,
  RcAlertModule,
  RCAutocompleteModule,
  RcChipModule,
  RCDigitCounterModule,
  RCFieldModule,
  RCFooterModule,
  RcMultiSelectModule,
  RCPhoneFieldModule,
  RCSelectModule,
  RCSelectorsModule,
  RcTagModule,
  RCTextareaModule,
  RcTourModule,
} from '@rc/ui';
import { CookieService } from 'ngx-cookie-service';
import {
  ActiveProgramPopinComponent,
  AddressComponent,
  AddressFormPopinComponent,
  BcsComponent,
  CircleCharComponent,
  ClinicIdentifiedPopinComponent,
  CombineProductsPopinComponent,
  ContactPopinComponent,
  CreatePatientPopinComponent,
  CreatePOPopinComponent,
  CriteriaDetailsPopinComponent,
  DailyAllowanceViewComponent,
  DeletePatientPopinComponent,
  DeliveryOrderComponent,
  DigitCounterComponent,
  DropdownListComponent,
  EmailPoppinComponent,
  EmailResponsePopinComponent,
  FollowUpComponent,
  FollowUpSelectorComponent,
  FollowUpSelectorPopinComponent,
  InfoPopinComponent,
  LogginInformationPopinComponent,
  MedicalHistoryDetailPopinComponent,
  MultifunctionPopinComponent,
  PageWrapperComponent,
  PetIdentificationComponent,
  PetOwnerFormComponent,
  PetProfileFormComponent,
  PetProfileHeaderComponent,
  ProductDetailComponent,
  ProductRecommendationCardComponent,
  ProductsQuantityFormComponent,
  ProfileHeaderComponent,
  RcAddressComponent,
  RcAttributeComponent,
  RcBlockComponent,
  RcChartComponent,
  RcDropdownComponent,
  RcExitPopinComponent,
  RcGaugeComponent,
  RcNoContentComponent,
  RcOrderStatusComponent,
  RcPaginationComponent,
  RcPetSummaryPanelComponent,
  RcPopinContentComponent,
  RcPopinUploadLogoComponent,
  RcRadioImageComponent,
  RcSegmentedControlComponent,
  RcStepperComponent,
  RcTableComponent,
  RcTabsComponent,
  RcTooltipComponent,
  RenalGraphComponent,
  RenalValuesComponent,
  SelectedProductsPopinComponent,
  SignalPopinSendComponent,
  SignalProductPopinComponent,
  SlideComponent,
  TableCellComponent,
  TableComponent,
  TableRowComponent,
  UpdateDatePopinComponent,
  WarningMessageComponent,
  WeightLossPlanComponent,
  WholesalePriceTotalComponent,
} from './components';
import { ActivityTooltipComponent } from './components/activity-tooltip/activity-tooltip.component';
import { CheckboxModule } from './components/checkbox/checkbox.module';
import { ExpansionPanelStepHeaderComponent } from './components/expansion-panel-step-header/expansion-panel-step-header.component';
import { ExpansionPanelModule } from './components/expansion-panel/expansion-panel.module';
import { HyperlinkComponent } from './components/hyperlink/hyperlink.component';
import { NoResultsModule } from './components/no-results/no-results.module';
import { PathologyComponent } from './components/pathology/pathology.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { RCDialogWrapperModule } from './components/rc-dialog-wrapper/rc-dialog-wrapper.module';
import { RcSpinnerModule } from './components/rc-spinner/rc-spinner.module';
import { TagsSelectionContainerComponent } from './components/tags-selection-containers/tags-selection-container.component';
import { UserSettingsFormComponent } from './components/user-settings-form/user-settings-form.component';
import { WeightFormComponent } from './components/weight-form/weight-form.component';
import { MarketGuardDirective } from './directives';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import {
  ArianeTranslationPipe,
  BreedPipe,
  ConverterHtmlToText,
  NeuteredPipe,
  PossessivePipe,
  RoundPipe,
  TextToLinkPipe,
  TruncatePipe,
  WeightPipe,
} from './pipes';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { TranslationCodePipe } from './pipes/translation-code.pipe';
import { WeightPrecisionPipe } from './pipes/weight-precision.pipe';
import { SharedRegisterIconsModule } from './shared-register-icons.module';
import { IconModule } from './utils/icon/icon.module';
import { PurchaseProductsPopinModule } from './components/purchase-products-popin/purchase-products-popin.module';
import { QrCodeDisplayModule } from './components/qr-code-display/qr-code-display.module';
import { SeePreviewPopinModule } from './components/see-preview-popin/see-preview-popin.module';
import { WlpTooltipComponent } from './components/wlp-tooltip/wlp-tooltip.component';
import { PreventExitPopinComponent } from './components/prevent-exit-popin/prevent-exit-popin.component';
import { ClinicalAllianceBadgeModule } from './components/clinical-alliance-badge/clinical-alliance-badge.module';

@NgModule({
  imports: [
    /* Angular Modules */
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    RCAutocompleteModule,
    RCFieldModule,
    RCSelectModule,
    RcChipModule,
    RCFooterModule,
    RcTourModule,
    RCTextareaModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatDateFnsModule,
    MatTableModule,
    RcAlertModule,
    RcMultiSelectModule,
    RcTagModule,
    MatDialogModule,
    RCAccordionModule,
    RCSelectorsModule,
    RCPhoneFieldModule,
    RCDigitCounterModule,
    MatInputModule,
    NoResultsModule,
    PurchaseProductsPopinModule,
    SeePreviewPopinModule,
    QrCodeDisplayModule,
    ExpansionPanelModule,
    CheckboxModule,
    SharedPipesModule,
    SharedDirectivesModule,
    RcSpinnerModule,
    RCDialogWrapperModule,
    LoaderModule,
    SharedRegisterIconsModule,
    RCListModule,
    PageWrapperComponent,
    RcTooltipComponent,
    SwitchWlpPopinComponent,
    WlpTooltipComponent,
    ClinicalAllianceBadgeModule,
  ],
  declarations: [
    /* Directive */
    MarketGuardDirective,
    /*Pipes*/
    WeightPrecisionPipe,
    BreedPipe,
    WeightPipe,
    ConverterHtmlToText,
    RoundPipe,
    PossessivePipe,
    TruncatePipe,
    ArianeTranslationPipe,
    NeuteredPipe,
    TextToLinkPipe,
    /* Other Components */
    ContactPopinComponent,
    EmailPoppinComponent,
    EmailResponsePopinComponent,
    ProfileHeaderComponent,
    PetIdentificationComponent,
    PetProfileFormComponent,
    PetOwnerFormComponent,
    ProductsQuantityFormComponent,
    PetProfileHeaderComponent,
    ProductFooterComponent,
    ProductDetailComponent,
    CreatePatientPopinComponent,
    CreatePOPopinComponent,
    WarningMessageComponent,
    WholesalePriceTotalComponent,
    BcsComponent,
    WeightLossPlanComponent,
    DailyAllowanceViewComponent,
    UpdateDatePopinComponent,
    WeightLossPlanComponent,
    DailyAllowanceViewComponent,
    FollowUpComponent,
    UpdateDatePopinComponent,
    FollowUpSelectorComponent,
    FollowUpSelectorPopinComponent,
    WeightPrecisionPipe,
    SignalProductPopinComponent,
    ProductRecommendationCardComponent,
    MultifunctionPopinComponent,
    DeletePatientPopinComponent,
    ClinicIdentifiedPopinComponent,
    TableComponent,
    TableCellComponent,
    TableRowComponent,
    DeliveryOrderComponent,
    AddressComponent,
    AddressFormPopinComponent,
    SelectedProductsPopinComponent,
    DropdownListComponent,
    ActiveProgramPopinComponent,
    CombineProductsPopinComponent,
    InfoPopinComponent,
    RenalGraphComponent,
    SlideComponent,
    MedicalHistoryDetailPopinComponent,
    RenalValuesComponent,
    CriteriaDetailsPopinComponent,
    PreventExitPopinComponent,
    /* RC Components */
    RcPopinContentComponent,
    RcDropdownComponent,
    RcTabsComponent,
    RcAttributeComponent,
    RcBlockComponent,
    RcSegmentedControlComponent,
    RcPaginationComponent,
    RcTableComponent,
    RcSegmentedControlComponent,
    RcNoContentComponent,
    RcRadioImageComponent,
    RcChartComponent,
    RcGaugeComponent,
    RcAddressComponent,
    RcOrderStatusComponent,
    AddressFormPopinComponent,
    LogginInformationPopinComponent,
    SignalPopinSendComponent,
    SelectedProductsPopinComponent,
    RcPopinUploadLogoComponent,
    RcStepperComponent,
    ActiveProgramPopinComponent,
    RcPetSummaryPanelComponent,
    RcExitPopinComponent,
    CircleCharComponent,
    RenalGraphComponent,
    SlideComponent,
    MedicalHistoryDetailPopinComponent,
    RenalValuesComponent,
    PathologyComponent,
    ActivityTooltipComponent,
    HyperlinkComponent,
    WeightFormComponent,
    NutrientBarComponent,
    NutrientsListComponent,
    NutrientsBenefitsListComponent,
    NutritionalAssessmentComponent,
    ExpansionPanelStepHeaderComponent,
    ProductCardComponent,
    TagsSelectionContainerComponent,
    DigitCounterComponent,
    UserSettingsFormComponent,
  ],
  exports: [
    SharedRegisterIconsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatDateFnsModule,
    RCAutocompleteModule,
    RcMultiSelectModule,
    RcTagModule,
    RCFieldModule,
    RCSelectModule,
    IconModule,
    RcChipModule,
    RCFooterModule,
    RcTourModule,
    RCTextareaModule,
    RcAlertModule,
    RCSelectorsModule,
    RCPhoneFieldModule,
    NoResultsModule,
    PurchaseProductsPopinModule,
    SeePreviewPopinModule,
    QrCodeDisplayModule,
    ExpansionPanelModule,
    CheckboxModule,
    SharedPipesModule,
    SharedDirectivesModule,
    RcSpinnerModule,
    RCDialogWrapperModule,
    LoaderModule,
    RCListModule,
    /* Directive */
    MarketGuardDirective,

    /*Pipes*/
    ConverterHtmlToText,
    WeightPipe,
    RoundPipe,
    PossessivePipe,
    TruncatePipe,
    BreedPipe,
    ArianeTranslationPipe,
    NeuteredPipe,
    TextToLinkPipe,
    /* Other Components */
    EmailPoppinComponent,
    EmailResponsePopinComponent,
    WarningMessageComponent,
    WholesalePriceTotalComponent,
    ProfileHeaderComponent,
    PetProfileHeaderComponent,
    PetIdentificationComponent,
    PetProfileFormComponent,
    PetOwnerFormComponent,
    ProductsQuantityFormComponent,
    ProductFooterComponent,
    BcsComponent,
    WeightLossPlanComponent,
    DailyAllowanceViewComponent,
    UpdateDatePopinComponent,
    FollowUpSelectorComponent,
    FollowUpSelectorPopinComponent,
    WeightLossPlanComponent,
    DailyAllowanceViewComponent,
    FollowUpComponent,
    SignalProductPopinComponent,
    ProductRecommendationCardComponent,
    DeletePatientPopinComponent,
    ClinicIdentifiedPopinComponent,
    ProductRecommendationCardComponent,
    TableComponent,
    TableCellComponent,
    TableRowComponent,
    DeliveryOrderComponent,
    AddressComponent,
    AddressFormPopinComponent,
    SelectedProductsPopinComponent,
    DropdownListComponent,
    ActiveProgramPopinComponent,
    CombineProductsPopinComponent,
    RenalGraphComponent,
    SlideComponent,
    MedicalHistoryDetailPopinComponent,
    RenalValuesComponent,
    PathologyComponent,
    ActivityTooltipComponent,
    HyperlinkComponent,
    WeightFormComponent,
    NutrientsListComponent,
    NutrientsBenefitsListComponent,
    NutritionalAssessmentComponent,
    NutrientBarComponent,
    ExpansionPanelStepHeaderComponent,
    /* RC Components */
    RcPopinContentComponent,
    RcAttributeComponent,
    RcDropdownComponent,
    RcTooltipComponent,
    RcTabsComponent,
    RcBlockComponent,
    RcSegmentedControlComponent,
    RcTableComponent,
    RcGaugeComponent,
    RcPaginationComponent,
    RcNoContentComponent,
    RcChartComponent,
    RcRadioImageComponent,
    RcGaugeComponent,
    RcAddressComponent,
    RcOrderStatusComponent,
    LogginInformationPopinComponent,
    SignalPopinSendComponent,
    RcPopinUploadLogoComponent,
    RcStepperComponent,
    RcPetSummaryPanelComponent,
    PageWrapperComponent,
    RcExitPopinComponent,
    CircleCharComponent,
    CriteriaDetailsPopinComponent,
    TagsSelectionContainerComponent,
    DigitCounterComponent,
    UserSettingsFormComponent,
    ProductCardComponent,
    WlpTooltipComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        CookieService,
        PhraseService,
        TranslationCodePipe,
        FractionCupPipe,
        TitleCasePipe,
        LowerCasePipe,
        PossessivePipe,
        RoundPipe,
        WeightPipe,
        TruncatePipe,
        ArianeTranslationPipe,
        NeuteredPipe,
        TextToLinkPipe,
        CurrencyPipe,
        MatDatepickerToggle,
        MatDialog,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        {
          provide: MatDialogRef,
          useValue: {},
        },
        { provide: MatDialogConfig, useValue: {} },
        MatDatepickerModule,
        MatDateFnsModule,
      ],
    };
  }
}
