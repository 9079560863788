import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-rc-tooltip',
  templateUrl: './rc-tooltip.component.html',
  styleUrls: ['./rc-tooltip.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [CommonModule],
})
export class RcTooltipComponent implements OnInit {
  @Input() primaryPosition = 'bottom';
  @Input() secondaryPosition = 'center';
  @Input() size = 'medium';
  isActive = false;
  onTooltip = false;

  constructor(private eRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    fromEvent(this.eRef.nativeElement.getElementsByClassName('rc-tooltip-main'), 'mouseenter').subscribe((value) => {
      this.isActive = true;
      this.cdr.detectChanges();
    });

    fromEvent(this.eRef.nativeElement.getElementsByClassName('rc-tooltip-main'), 'mouseleave')
      .pipe(
        debounceTime(250),
        filter((value) => !this.onTooltip)
      )
      .subscribe((value) => {
        this.isActive = false;
        this.cdr.detectChanges();
      });

    fromEvent(this.eRef.nativeElement.getElementsByClassName('rc-tooltip-content'), 'mouseenter').subscribe((value) => {
      this.onTooltip = true;
    });

    fromEvent(this.eRef.nativeElement.getElementsByClassName('rc-tooltip-content'), 'mouseleave').subscribe((value) => {
      this.onTooltip = false;
      this.isActive = false;
      this.cdr.detectChanges();
    });
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    this.isActive = !!this.eRef.nativeElement.contains(event.target);
  }
}
