import { Product, ProductPriceEdit, SelectedProduct } from '@app/core/models';
import {
  ProductCatalogFilterBreedSizevalues,
  ProductCatalogFilterLifestageValues,
  ProductCatalogFilterValues,
  getInitialFilterBreedSizeValues,
} from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { ProductPillar, SpeciesCode, ViewType } from '@app/shared/utils/enums';
import { RangeType } from '@app/shared/utils/enums/rangeType.enum';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';

export interface ProductsState {
  allProductList: Product[];
  filteredProductList: Record<string, Product[]>;
  filteredProductLength: number;
  filtersValues: ProductCatalogFilterValues & ProductCatalogFilterBreedSizevalues;
  selectedCategories: (TerritoryType | RangeType)[];
  showPriceListActions: boolean;
  priceListToUpdate: ProductPriceEdit[];
  priceListUpdateLoading: boolean;
  currentViewType: ViewType;
  currentPillar: ProductPillar;
  getAllProductsFail: boolean;
  productsLoading: boolean;
  specificSpecieCode: SpeciesCode | null; // her foe the disabled view and the reset
  specificLifestageType: ProductCatalogFilterLifestageValues | null; // disabled view and the reset
  selectedProducts: SelectedProduct[] | null;
}

export const initialProductsState: ProductsState = {
  allProductList: [],
  filteredProductList: {},
  filteredProductLength: 0,
  showPriceListActions: false,
  priceListToUpdate: [],
  priceListUpdateLoading: false,
  selectedCategories: [],
  currentViewType: ViewType.Grid,
  currentPillar: ProductPillar.VET,
  filtersValues: {
    pillar: ProductPillar.VET,
    specie: SpeciesCode.Dog,
    search: null,
    dry: false,
    wet: false,
    birthAndGrowth: false,
    mature: false,
    adult: false,
    ...getInitialFilterBreedSizeValues(),
  },
  getAllProductsFail: false,
  productsLoading: false,
  specificSpecieCode: null,
  specificLifestageType: null,
  selectedProducts: [],
};
