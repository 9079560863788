import { getPriceListMergedWithNewPrices } from '@app/shared/utils/static-helpers/product-helper';
import { createReducer, on } from '@ngrx/store';
import {
  addSelectedCategories,
  getAllProducts,
  getAllProductsFail,
  getAllProductsSuccess,
  removeSelectedCategories,
  resetProductsData,
  resetProductsPricesState,
  setFilteredProductsSuccess,
  setPriceListToUpdate,
  setProductFilters,
  setForcedProductFilters,
  setProductTypeFilters,
  updateSelectedCategories,
  updateSelectedCatalogViewType,
  addPricesToUpdate,
  setShowPriceListActions,
  updatePrices,
  resetSelectedProduct,
  setSelectedProduct,
  setSelectedDryProductPackId,
  resetAllSelectedProducts,
  setSelectedWetProductPackId,
  setSelectedProducts,
} from './products.actions';
import { initialProductsState } from './products.state';
import { replaceInArray } from '@app/shared/utils/static-helpers/array.utils';
import { ProductType } from '@app/shared/utils';
import { getInitialFilterValues } from '@app/shared/utils/static-helpers/product-filters-helper';

export const productsReducer = createReducer(
  initialProductsState,
  on(setPriceListToUpdate, (state, { priceListToUpdate }) => ({
    ...state,
    priceListToUpdate,
  })),
  on(updatePrices, (state) => {
    return {
      ...state,
      priceListUpdateLoading: true,
    };
  }),
  on(addPricesToUpdate, (state, { prices }) => {
    return {
      ...state,
      priceListToUpdate: getPriceListMergedWithNewPrices(state.priceListToUpdate, prices),
    };
  }),
  on(setShowPriceListActions, (state, { value }) => {
    return {
      ...state,
      showPriceListActions: value,
    };
  }),
  on(resetProductsPricesState, (state) => ({
    ...state,
    priceListToUpdate: initialProductsState.priceListToUpdate,
    showPriceListActions: initialProductsState.showPriceListActions,
    priceListUpdateLoading: initialProductsState.priceListUpdateLoading,
  })),
  on(getAllProductsSuccess, (state, { allProductList }) => ({
    ...state,
    allProductList,
    productsLoading: false,
  })),
  on(getAllProducts, (state) => ({
    ...state,
    getAllProductsFail: false,
    productsLoading: true,
  })),
  on(getAllProductsFail, (state) => ({
    ...state,
    getAllProductsFail: true,
    productsLoading: false,
  })),
  on(setProductFilters, (state, { filtersValues }) => {
    return {
      ...state,
      filtersValues,
    };
  }),
  on(setForcedProductFilters, (state, { specieCode, lifestageType }) => {
    return {
      ...state,
      specificSpecieCode: specieCode || null,
      specificLifestageType: lifestageType || null,
      filtersValues: getInitialFilterValues(state.filtersValues, { specieCode, lifestageType }),
    };
  }),
  on(setProductTypeFilters, (state, { dry, wet }) => {
    return {
      ...state,
      filtersValues: {
        ...state.filtersValues,
        wet: wet,
        dry: dry,
      },
    };
  }),
  on(setFilteredProductsSuccess, (state, { filteredProductList, filteredProductLength }) => ({
    ...state,
    filteredProductList,
    filteredProductLength,
  })),
  on(updateSelectedCategories, (state, { selectedCategories }) => {
    return {
      ...state,
      selectedCategories,
    };
  }),
  on(addSelectedCategories, (state, { category }) => {
    return { ...state, selectedCategories: state?.selectedCategories?.length ? [...state?.selectedCategories, category] : [category] };
  }),
  on(removeSelectedCategories, (state, { category }) => {
    return { ...state, selectedCategories: state?.selectedCategories?.filter((v) => v !== category) };
  }),
  on(updateSelectedCatalogViewType, (state, { currentViewType }) => ({
    ...state,
    currentViewType,
  })),
  on(resetProductsData, () => initialProductsState),
  on(setSelectedProducts, (state, { products }) => ({
    ...state,
    selectedProducts: products.map((product) => ({ product: product, packId: product?.packages[0]?.sCode })),
  })),
  on(setSelectedProduct, (state, action) => {
    const newPackId = action.selectedProduct?.packId || action.selectedProduct?.product?.packages[0]?.sCode || null;
    const existingPackId = state.selectedProducts.find((item) => item.product?.id === action.selectedProduct?.product?.id)?.packId || null;
    const packIdToSet = action.setProductOnly ? existingPackId : newPackId;
    const newProduct = { product: action?.selectedProduct?.product, packId: packIdToSet };

    return action.allowMultipleSelection
      ? {
          ...state,
          selectedProducts: [...state?.selectedProducts, newProduct],
        }
      : {
          ...state,
          selectedProducts: replaceInArray(state?.selectedProducts, newProduct, (item) => item?.product?.productType, true),
        };
  }),
  on(resetSelectedProduct, (state, action) => {
    return {
      ...state,
      selectedProducts: state?.selectedProducts?.filter((item) => item?.product?.id !== action.productId),
    };
  }),
  on(resetAllSelectedProducts, (state) => ({ ...state, selectedProducts: initialProductsState.selectedProducts })),
  on(setSelectedDryProductPackId, (state, { packId }) => {
    const currentSelectedDryProduct = state?.selectedProducts?.find((item) => item?.product?.productType === ProductType.Dry)?.product;

    return currentSelectedDryProduct
      ? {
          ...state,
          selectedProducts: replaceInArray(
            state?.selectedProducts,
            {
              product: currentSelectedDryProduct,
              packId: packId,
            },
            (item) => item?.product?.productType,
            true
          ),
        }
      : state;
  }),
  on(setSelectedWetProductPackId, (state, { packId }) => {
    const currentSelectedWetProduct = state?.selectedProducts?.find((item) => item?.product?.productType === ProductType.Wet)?.product;

    return currentSelectedWetProduct
      ? {
          ...state,
          selectedProducts: replaceInArray(
            state?.selectedProducts,
            {
              product: currentSelectedWetProduct,
              packId: packId,
            },
            (item) => item?.product?.productType,
            true
          ),
        }
      : state;
  })
);
