import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Picture } from '@app/core/models';
import { LoaderService, Logger, UploadService } from '@app/core/services';
import { DataSubscriber } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-rc-popin-uploade-logo',
  templateUrl: './rc-popin-upload-logo.component.html',
  styleUrls: ['./rc-popin-upload-logo.component.scss'],
})
export class RcPopinUploadLogoComponent {
  public readonly IconName = IconName;

  uploadSizeFailed = false;
  uploadSuccess = false;
  imageUrl: string | ArrayBuffer = '';
  file: File;
  isLoading = false;
  errorMessage = '';
  private _destroyed$ = new Subject<boolean>();

  constructor(
    private _uploadService: UploadService,
    private _logger: Logger,
    private dialogRef: MatDialogRef<RcPopinUploadLogoComponent>,
    private _loaderService: LoaderService
  ) {}

  /**
   * Function for select picture and validation of the size
   */
  onFileSelect(event): void {
    event.preventDefault();
    this.file = event.target.files[0];
    if (this.file) {
      const allowedExtension = ['png', 'jpg', 'jpeg', 'gif'];
      const currentExtension = this.file.name.split('.')?.pop()?.toLowerCase();
      if (this.convertByteToMegaByte(this.file.size) > 2) {
        this.uploadSizeFailed = true;
        this.uploadSuccess = false;
        this.imageUrl = '';
        this.errorMessage = $localize`:@@error-file-size:`;
        return null;
      }
      if (allowedExtension.indexOf(currentExtension) === -1) {
        this.uploadSizeFailed = true;
        this.uploadSuccess = false;
        this.imageUrl = '';
        this.errorMessage = $localize`:@@message-file-type:`;
        return null;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = reader.result;
        this.uploadSizeFailed = false;
        this.uploadSuccess = true;
      };
      reader.readAsDataURL(this.file);
    }
  }

  /**
   * Submit request for picture
   */
  submit(): void {
    this.isLoading = true;
    this._loaderService.startLoader(false, document.querySelector('.upload-logo-dialog-content'));
    DataSubscriber.subscribe(
      this._uploadService.uploadPicture(this.file),
      (picture: Picture) => {
        this.uploadSizeFailed = false;
        this.uploadSuccess = true;
        this._loaderService.stopLoader(() => {
          this.isLoading = false;
        });
        this.dialogRef.close(picture.pictureUrl);
      },
      this._destroyed$,
      (err) => {
        this.uploadSizeFailed = true;
        this.uploadSuccess = false;
        this.imageUrl = '';
        this.errorMessage = $localize`:@@error_try-again-later:`;
        this._loaderService.stopLoader(() => {
          this.isLoading = false;
        });
        this._logger.error(err);
      }
    );
  }

  /**
   * Function for return size of file in MB
   */
  convertByteToMegaByte(value: number): number {
    return parseFloat((value / 1048576).toFixed(1));
  }

  close(): void {
    this.dialogRef.close();
  }
}
