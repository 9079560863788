<div class="pathology">
  <div class="search">
    <p class="section-title" i18n="@@search">Search</p>
    <form class="filters" [formGroup]="filterForm" (keydown.enter)="$event.preventDefault()">
      <div class="rc-input-container rc-input-container--search">
        <app-icon [name]="IconName.search"></app-icon>
        <input
          data-cs-mask
          type="text"
          formControlName="search"
          class="rc-input rc-input--underlined"
          placeholder="Browse disorders & pred..."
          i18n-placeholder="@@action_pathologies"
        />
        <button type="button" (click)="resetSearch()" *ngIf="filterForm.controls.search.value.length > 0">
          <app-icon [name]="IconName.close"></app-icon>
        </button>
      </div>

      <div class="fieldsets">
        <fieldset class="field--category">
          <legend i18n="@@filter_category">Filter by category</legend>
          <rc-multi-select
            formControlName="categories"
            [options]="categoriesOptions"
            [selectPlaceholder]="'sr-selection-dropdown-select-options' | translateKey"
            [allSelectPlaceholder]="'all' | translateKey"
            [selectedItemNumber]="'sr-selection-dropdown-selected' | translateKey"
          ></rc-multi-select>
        </fieldset>

        <fieldset class="field--type">
          <legend i18n="@@filter_type">Filter By type</legend>
          <div class="by_type">
            <app-checkbox
              [label]="'title_pathologies' | translateKey"
              formControlName="pathologies"
              [id]="'checkbox-pathologies'"
            ></app-checkbox>
            <app-checkbox
              [label]="'title_sensitivities' | translateKey"
              formControlName="sensitivities"
              [id]="'checkbox-sensitivities'"
            ></app-checkbox>
          </div>
        </fieldset>
      </div>

      <div class="pathologies-list-field">
        <ng-container *ngIf="pathologyItems.length === 0 || categories.length === 0; else categoryPanel">
          <app-no-results
            [title]="'product-catalogue-empty_title_no_result' | translateKey"
            [description]="'sr-selection-empty_state' | translateKey"
            [actionLabel]="'product-catalogue-empty_button_clear' | translateKey"
            (actionOutput)="resetAllFilters()"
          ></app-no-results>
        </ng-container>
        <ng-template #categoryPanel>
          <div class="category-panel" *ngFor="let category of categories; trackBy: trackByCategory">
            <ng-container *ngIf="isCategoryNotEmpty(category)">
              <app-expansion-panel
                [withExpandIcon]="true"
                [expanded]="expandedPanel"
                [toggle]="true"
                [id]="'panel-' + category"
                [shouldScrollIntoView]="false"
              >
                <ng-container header>
                  <div class="panel-header">
                    <div class="border" [class.active]="getSelectedItemCountForCategory(category) > 0">
                      <app-icon [name]="$any(category)" class="icon"></app-icon>
                      <div class="badge" *ngIf="getSelectedItemCountForCategory(category) as count">
                        {{ count }}
                      </div>
                    </div>
                    <span class="title">{{ category | translateKey }}</span>
                  </div>
                </ng-container>

                <ng-container content>
                  <div class="panel-content">
                    <div class="checkbox" *ngFor="let pathologyItem of getCategoryPathologyItems(category); trackBy: trackByItemKey">
                      <rc-tag
                        id="'checkbox-'{{ pathologyItem.id }}"
                        class="tag"
                        [isInteractive]="true"
                        [isSelected]="isSelected(pathologyItem.value)"
                        [disabled]="!!pathologyItem.disabled"
                        [theme]="pathologyItem?.value?.isRiskFactor ? RCTagTheme.COOLER6 : RCTagTheme.COOLER2"
                        [text]="pathologyItem?.label"
                        (click)="togglePathologyValue(pathologyItem?.value)"
                      ></rc-tag>
                    </div>
                  </div>
                </ng-container>
              </app-expansion-panel>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </form>
  </div>
  <div class="selection footer-mobile-deep">
    <header>
      <div class="title">
        <p class="section-title" i18n="@@sr-pathology_sensitivity_selection">Selection</p>
        <span class="selection-number">{{ value.length }}</span>
      </div>
      <button class="dropdown-button" (click)="openSelectionDropdown = !openSelectionDropdown">
        <span *ngIf="openSelectionDropdown" class="label">{{ 'action_close' | translateKey }}</span>
        <span *ngIf="!openSelectionDropdown" class="label">{{ 'action_open' | translateKey }}</span>
        <app-icon class="icon" [class.rotate]="!openSelectionDropdown" [name]="IconName.chevron_down"></app-icon>
      </button>
    </header>
    <div class="content" [class.openSelectionDropdown]="openSelectionDropdown">
      <app-tags-selection-container (clearSelection)="clearValueItems($event)" [selections]="tagsSelections"></app-tags-selection-container>
    </div>
  </div>
</div>

<!--
  Smart reco footer to launch reco
-->

<footer class="footer footer-mobile-deep">
  <a class="footer-link" (click)="submitPathologies()">{{ 'sr-xray_go_reco' | translateKey }}</a>
  <span class="rc-button__separator" i18n="@@action_separator">or</span>
  <button class="rc-button rc-button--filled-small" id="get-recommendation" (click)="goToNextStep()">
    <span class="rc-button__title">{{ 'action_continue' | translateKey }}</span>
  </button>
</footer>
