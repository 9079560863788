<ng-container *ngIf="!!benefits?.length">
  <section *ngIf="!isMobile" class="nutrient-benefits-list">
    <header class="nutrient-benefits-list__header">
      <p class="nutrient-benefits-list__title">{{ nutrientListTitle }}</p>

      <div class="nutrient-benefits-list__tags-legend">
        <span class="nutrient-benefits-list__tags-legend__item">
          <span>{{ 'sr-xray_column_title_Main_influencers' | translateKey }}</span>
          <span
            class="nutrient-benefits-list__info-icon"
            [matTooltipPosition]="'above'"
            matTooltipHideDelay="1"
            matTooltip="{{ 'sr-xray_main_influencers_popin' | translateKey }}"
            matTooltipClass="tooltip-arrow-down-light"
            #tooltip="matTooltip"
          >
            <app-icon class="nutrient-benefits-list__icon" [name]="IconName.info"></app-icon>
          </span>
        </span>
      </div>
    </header>
    <div class="nutrient-benefits-list__list-container">
      <div class="nutrient-benefits-list__block" *ngFor="let benefit of benefits">
        <div class="nutrient-benefits-list__item">
          <div class="nutrient-benefits-list__item__left">
            <span class="nutrient-benefits-list__bullet"></span>
            <p class="nutrient-benefits-list__benefit-label">{{ getNutrientLabel(benefit.id) }}</p>
          </div>

          <div class="nutrient-benefits-list__tag-list">
            <div *ngFor="let influencer of benefit.influencers" class="nutrient-benefits-list__tag-item">
              <ng-container *appLet="getInfluencerLabel(influencer) as influencerLabel">
                <span
                  class="nutrient-list__info-icon"
                  [matTooltipPosition]="'above'"
                  matTooltipHideDelay="1"
                  matTooltip="{{ 'description-criteria_consult' | translateKey }} {{ influencerLabel }} "
                  matTooltipClass="tooltip-arrow-down"
                  #tooltip="matTooltip"
                >
                  <rc-tag
                    *ngIf="!!influencerLabel"
                    [size]="RCTagSize.XS"
                    [text]="influencerLabel"
                    [isInteractive]="true"
                    (click)="showInfluencerDescriptionPopin(influencer)"
                  ></rc-tag>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
        <hr class="nutrient-benefits-list__divider" />
      </div>
    </div>
  </section>

  <!--mobile display-->

  <section *ngIf="isMobile" class="nutrient-benefits-list">
    <p class="nutrient-benefits-list__title__mobile">{{ nutrientListTitle }}</p>
    <div class="nutrient-benefits-list__list-container__mobile">
      <div class="nutrient-benefits-list__item-mobile" *ngFor="let benefit of benefits">
        <div class="nutrient-benefits-list__item__left-mobile">
          <span class="nutrient-benefits-list__bullet"></span>
          <p class="nutrient-benefits-list__benefit-label-mobile">{{ getNutrientLabel(benefit.id) }}</p>
        </div>
        <div class="nutrient-benefits-list__tags-legend-mobile">
          <span>
            {{ 'sr-xray_column_title_Main_influencers' | translateKey }}
          </span>
        </div>
        <div class="nutrient-benefits-list__tag-list-mobile" *appLet="benefit.influencers as influencers">
          <ng-container *ngIf="influencers">
            <div *ngFor="let influencer of influencers" class="nutrient-benefits-list__tag-item">
              <ng-container *appLet="getInfluencerLabel(influencer) as influencerLabel">
                <rc-tag *ngIf="!!influencerLabel" [size]="RCTagSize.XS" [text]="influencerLabel"></rc-tag>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <hr class="nutrient-benefits-list__divider" />
      </div>
    </div>
  </section>
</ng-container>
