<ng-container *appLet="currentViewType$ | async as currentViewType">
  <ng-container *appLet="showEditPricesActions$ | async as showEditPricesActions">
    <ng-container *appLet="isPricesPage$ | async as isPricesPage">
      <ng-container *appLet="displayPurchaseButton$ | async as displayPurchaseButton">
        <div
          class="product-footer"
          [ngClass]="{
            'product-footer--with-price': isPricesPage,
            'product-footer--with-purchase': displayPurchaseButton
          }"
          *ngIf="showEditPricesActions || (currentViewType === ViewType.Grid && selectedProducts.length > 0) || isMobile || isPricesPage"
        >
          <ng-container>
            <div class="product-footer-header" *ngIf="currentViewType !== ViewType.Edit && selectedProducts.length > 0">
              <button class="rc-button align-row" (click)="toggleProductSelection()">
                <div class="align-row">
                  <p class="text" i18n="@@product-book_selection">selection</p>
                  <div class="badge">
                    <span>{{ selectedProducts.length }}</span>
                  </div>
                </div>
                <div class="align-row">
                  <p class="text">{{ toggleProductsSelection ? ('action_open' | translateKey) : ('action_close' | translateKey) }}</p>
                  <app-icon class="icon" [class.toggle-icon]="toggleProductsSelection" [name]="IconName.chevron_down"></app-icon>
                </div>
              </button>
            </div>
          </ng-container>

          <div class="align-row product-footer-wrapper">
            <div class="product-footer-content">
              <ng-container *ngIf="currentViewType !== ViewType.Edit && selectedProducts.length > 0">
                <div class="product-footer-items" [class.toggle-content]="toggleProductsSelection">
                  <ng-container *ngFor="let product of selectedProducts | slice: 0:2">
                    <div class="product-item">
                      <div class="product-item-content">
                        <img class="image" [src]="product.image | publicUrl" [alt]="product.name || ''" />
                        <div>
                          <p class="type">
                            {{
                              product.productType === ProductType.Dry
                                ? ('product-type_dry' | translateKey)
                                : ('product-type_wet' | translateKey)
                            }}
                          </p>
                          <p class="name">{{ product.name }}</p>
                        </div>
                      </div>
                      <button class="rc-button close" (click)="removeProduct.emit(product)">
                        <app-icon class="close-icon" [name]="IconName.close"></app-icon>
                      </button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="selectedProducts.length === 1 && !disableMultiSelection">
                    <div class="product-item--empty">
                      <div class="message-information tooltip-arrow-down" *ngIf="allProductsTypeAvailable">
                        <div class="tooltip-content">{{ ('po-letter_feedingAdvice2Subtitle' | translateKey).toLowerCase() }}</div>
                      </div>

                      <p
                        class="text"
                        i18n="@@select-wet-product"
                        *ngIf="
                          !multiSelectionAvailable && selectedProducts?.length === 1 && selectedProducts[0]?.productType === ProductType.Dry
                        "
                      >
                        Select a wet product
                      </p>
                      <p
                        class="text"
                        i18n="@@select-dry-product"
                        *ngIf="
                          !multiSelectionAvailable && selectedProducts?.length === 1 && selectedProducts[0]?.productType === ProductType.Wet
                        "
                      >
                        Select a dry product
                      </p>
                      <p class="text" i18n="@@select-other-products" *ngIf="multiSelectionAvailable">Select a product</p>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="selectedProducts?.length > 2 && multiSelectionAvailable">
                    <button class="rc-button" (click)="openMoreProductsPopin.emit(selectedProducts)">
                      <p class="rc-paragraph rc-link-footer">
                        <ng-container i18n="@@and">and</ng-container>
                        {{ selectedProducts.length - 2 }}
                        <ng-container i18n="@@product-cart_more-products">more product(s)</ng-container>
                      </p>
                    </button>
                  </ng-container>
                </div>
              </ng-container>

              <div class="product-footer-action">
                <ng-container *ngIf="currentViewType !== ViewType.Edit && selectedProducts.length > 0; else search_product">
                  <div class="search-action" *ngIf="withMobileSearch">
                    <button class="rc-button" (click)="searchAction.emit()">
                      <span class="rc-button__title--link">{{ 'search' | translateKey }}</span>
                    </button>
                    <span *ngIf="!displayPurchaseButton" class="rc-button__separator" i18n="@@action_separator">or</span>
                  </div>
                  <button
                    id="productFooterPurchaseAction"
                    *ngIf="displayPurchaseButton"
                    class="purchase-product-button rc-button rc-button--outline-small"
                    (click)="openProductsPurchase()"
                  >
                    <span class="rc-button__title">{{ 'new-DAC-flow_purchase_button' | translateKey }}</span>
                  </button>
                  <button
                    id="productFooterContinueAction"
                    class="rc-button product-footer-action__continue rc-button--filled-small"
                    (click)="continueAction.emit()"
                  >
                    <span class="rc-button__title">{{ 'action_continue' | translateKey }}</span>
                  </button>
                </ng-container>

                <ng-template #search_product>
                  <ng-container *ngIf="isMobile">
                    <button id="productFooterSearchAction" class="rc-button rc-button--filled-small" (click)="searchAction.emit()">
                      <span class="rc-button__title">{{ 'product-book_search_product' | translateKey }}</span>
                    </button>
                  </ng-container>
                </ng-template>

                <ng-container *ngIf="showEditPricesActions || isPricesPage">
                  <button
                    id="footer-cancel-edit-prices"
                    class="rc-button secondary-action product-footer-action__cancel"
                    (click)="cancelEditPrices(isPricesPage)"
                  >
                    <span class="rc-button__title--link">{{
                      (isPricesPage ? 'action_exit' : 'exit_without_saving_button') | translateKey
                    }}</span>
                  </button>
                  <span *ngIf="!isPricesPage" class="rc-button__separator" i18n="@@action_separator">or</span>
                  <button
                    id="footer-submit-edit-prices"
                    class="rc-button rc-button--filled-small product-footer-action__submit"
                    [disabled]="(priceListUpdateLoading$ | async) || !showEditPricesActions"
                    (click)="submitEditPrices()"
                  >
                    <app-icon
                      *ngIf="priceListUpdateLoading$ | async; else save"
                      class="spinner disabled"
                      [name]="IconName.spinner_circle"
                    ></app-icon>
                    <ng-template #save>
                      <span class="rc-button__title">{{ 'action_save-changes' | translateKey }}</span>
                    </ng-template>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
