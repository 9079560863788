<div class="expansion-panel-step-header" [class.active]="active" [class.completed]="completed">
  <span class="index">{{ index }}</span>
  <div class="label-container">
    <span *ngIf="label" class="label">{{ label }}</span>
    <ng-content class="label" select="[label]"></ng-content>
    <ng-content select="app-rc-tooltip"></ng-content>
  </div>
  <span *ngIf="completed" class="rc-button__title rc-button__title--link action" (click)="actionClicked.emit(true)">{{ action }}</span>
  <ng-content select="[actions]"></ng-content>
</div>
