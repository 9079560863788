import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SmartRecoState } from '@app/pages/smart-reco/store/smart-reco.state';
import { smartRecoFeatureKey } from '@app/pages/smart-reco/store/smart-reco-feature-key';

const selectSmartReco = createFeatureSelector<SmartRecoState>(smartRecoFeatureKey);

export const selectNutritionalAssessmentMacronutrients = createSelector(
  selectSmartReco,
  (state) => state?.nutritionalAssessment?.macroNutrients
);

export const selectNutritionalAssessmentOtherNutrients = createSelector(
  selectSmartReco,
  (state) => state?.nutritionalAssessment?.otherNutrients
);

export const selectNutritionalAssessmentBenefitClaim = createSelector(selectSmartReco, (state) => state?.nutritionalAssessment?.benefits);

export const selectNutritionalAssessmentHasError = createSelector(selectSmartReco, (state) => !!state?.nutritionalAssessmentHasError);
export const selectNutritionalAssessmentLoading = createSelector(selectSmartReco, (state) => state?.nutritionalAssessmentLoading);
