import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Pathology, PathologyCategory } from '@app/core/models/pathology';
import { PATHOLOGIES } from '@app/shared/data/pathologies';
import { Helper, SpeciesCode } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RCTagTheme } from '@rc/ui';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { TagsSelectedValue, TagsSelector } from '../tags-selection-containers/tags-selection-container.component';
import { GTMService, PageBlockEnum, patientBlockDisplay } from '@app/core/services/tracking';

export interface PathologyItem {
  label: string;
  value: Pathology;
  disabled: boolean;
  id: string;
}

@Component({
  selector: 'app-pathology',
  templateUrl: './pathology.component.html',
  styleUrls: ['./pathology.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathologyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() readonly speciesCode?: SpeciesCode;
  @Input() readonly initialValue: Pathology[] | null = null;

  /**
   * Emits all full selected pathologies on selection change
   */
  @Output() changed = new EventEmitter<Pathology[]>();
  @Output() submitted = new EventEmitter<Pathology[]>();
  @Output() nextStep = new EventEmitter<Pathology[]>();

  public readonly IconName = IconName;
  public readonly RCTagTheme = RCTagTheme;

  categories = Object.values(PathologyCategory);
  pathologyItems: PathologyItem[] = [];
  initialPathology: PathologyItem[] = [];
  value: Pathology[] = [];
  categoriesOptions = [];
  tagsSelections: TagsSelector[] = [];
  openSelectionDropdown = false;

  filterForm = new UntypedFormGroup({
    search: new UntypedFormControl(''),
    pathologies: new UntypedFormControl(true),
    sensitivities: new UntypedFormControl(true),
    categories: new UntypedFormControl([]),
  });
  expandedPanel = false;
  private _destroyed$ = new Subject<void>();

  constructor(private trackingService: GTMService) {}

  /**
   * Set initial value
   */
  ngOnInit(): void {
    this.categories.forEach((cate) => {
      this.categoriesOptions.push({ value: cate, label: translateKey(cate) });
    });
    this.filterForm.controls.categories.patchValue(this.categoriesOptions.map((item) => item.value));
    this.initialValue && this.writeValue([...this.initialValue]);
    this.filterPathologies();
    this.trackingService.sendInteraction(
      patientBlockDisplay({
        block: PageBlockEnum.PATHOLOGIES,
      })
    );
  }

  /**
   * Set pathology items on speciesCode input change;
   * clear value from unavailable items
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.speciesCode && changes.speciesCode.currentValue !== changes.speciesCode.previousValue) {
      this.setPathologyItems();
      this.initialPathology = this.pathologyItems;
      this.writeValue(this.value.filter((pathology) => !!this.pathologyItems.find((i) => this.areSamePathology(i.value, pathology))));
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Hide categorie if with filters this one contains no more pathologies to display.
   */
  isCategoryNotEmpty(category: PathologyCategory): boolean {
    return this.pathologyItems.some((patho) => patho.value.categories.includes(category));
  }

  /**
   * Writes a new item to the element.
   */
  writeValue(value: Pathology[]): void {
    this.value = value;

    /**
     * Update the tag selection component on value change.
     */
    this.tagsSelections = [
      {
        theme: RCTagTheme.COOLER2,
        noResultLabel: translateKey('sr-selection_no_pathology'),
        categorieLabel: translateKey('title_pathologies'),
        selected: this.value
          .filter((p) => !p.isRiskFactor)
          .map((p) => {
            return { value: p.value, description: this.getPathologyLabelKey(p), isCloseable: !this.isBCSItem(p) };
          }),
      },
      {
        theme: RCTagTheme.COOLER6,
        noResultLabel: translateKey('sr-selection_no_sensitivity'),
        categorieLabel: translateKey('title_sensitivities'),
        selected: this.value
          .filter((p) => p.isRiskFactor)
          .map((p) => {
            return { value: p.value, description: this.getPathologyLabelKey(p), isCloseable: !this.isBCSItem(p) };
          }),
      },
    ];
  }

  /**
   * Clear all but BCS items
   */
  clearValue = (): void => {
    this.writeValue(this.value.filter((p) => this.isBCSItem(p)));
  };

  clearValueItems = (items: (TagsSelectedValue | PathologyItem)[]): void => {
    const newValues = this.value.filter((val) => {
      return (
        this.isBCSItem(val) ||
        !items.find((item) => {
          return item.value === val.value;
        })
      );
    });
    this.writeValue(newValues);
  };
  /**
   * Method to check if value can be cleared;
   * returns true if at least one non BCS item is present
   */
  canClearValue = (): boolean => this.value.some((p) => !this.isBCSItem(p));
  /**
   * Get pathology items applicable to specified category and sort isRiskFactor at the end
   */
  getCategoryPathologyItems = (category: PathologyCategory): PathologyItem[] => {
    const riskfactor = this.pathologyItems
      .filter((item) => item.value.isRiskFactor && item.value.categories.includes(category))
      .sort((a, b) => Helper.alphabeticalOrder(a.label, b.label));
    const pathologies = this.pathologyItems
      .filter((item) => !item.value.isRiskFactor && item.value.categories.includes(category))
      .sort((a, b) => Helper.alphabeticalOrder(a.label, b.label));
    return pathologies.concat(riskfactor);
  };
  /**
   * Add pathology to value if not already added;
   * remove it if already added
   */
  togglePathologyValue = (pathology: Pathology): void => {
    this.writeValue(
      this.isSelected(pathology) ? this.value.filter((p) => !this.areSamePathology(p, pathology)) : [...this.value, pathology]
    );
  };
  /**
   * Get pathology label key;
   * risk factors have specific translation key;
   * this is needed as some risk factors & some pathologies have the same value (ie. tartar)
   */
  getPathologyLabelKey = (pathology: Pathology): string =>
    this.isRiskFactor(pathology) ? `sensitivity-${pathology.value}` : `pathology-${pathology.value}`;
  /**
   * Method to check if pathology has been selected
   */
  isSelected = (pathology: Pathology): boolean => !!this.value.find((p) => this.areSamePathology(p, pathology));
  /**
   * Get selected item count for a category;
   * used within the badge
   */
  getSelectedItemCountForCategory = (category: PathologyCategory): number =>
    this.value.filter((p) => p.categories.includes(category)).length;
  /**
   * Method to check if pathology is a BCS item;
   * used to disable BCS items
   */
  isBCSItem = (pathology: Pathology): boolean => pathology.value.includes('_bcs_');
  /**
   * *ngFor performance
   */
  trackByCategory = (i: number, item: PathologyCategory): PathologyCategory => item;
  trackByItemKey = (i: number, item: PathologyItem): string => this.getPathologyLabelKey(item.value);
  trackByPathologyKey = (i: number, item: Pathology): string => this.getPathologyLabelKey(item);

  filterPathologies(): void {
    combineLatest([
      this.filterForm.controls.search.valueChanges.pipe(startWith('')),
      this.filterForm.controls.pathologies.valueChanges.pipe(startWith(true)),
      this.filterForm.controls.sensitivities.valueChanges.pipe(startWith(true)),
      this.filterForm.controls.categories.valueChanges.pipe(startWith(this.categoriesOptions.map((item) => item.value))),
    ])
      .pipe(
        distinctUntilChanged(),
        tap(() => (this.pathologyItems = this.initialPathology)),
        tap(([search, pathologies, sensitivities, categories]) => {
          // auto open categories dropdown when user use pathologies filters
          if (search.length || !pathologies || !sensitivities || categories.length !== this.categoriesOptions.length) {
            this.expandedPanel = true;
          } else {
            this.expandedPanel = false;
          }
        }),
        map(([search, pathologies, sensitivities, categories]) => {
          this.categories = categories;
          this.pathologyItems = this.pathologyItems.filter((pathos) => {
            const filteredCategories = pathos.value.categories.some((cat) => categories.indexOf(cat) >= 0);
            if (pathologies && !sensitivities) {
              return filteredCategories && !pathos.value.isRiskFactor;
            } else if (sensitivities && !pathologies) {
              return filteredCategories && pathos.value.isRiskFactor;
            } else {
              return filteredCategories;
            }
          });
          if (search && search.length) {
            this.pathologyItems = this.pathologyItems.filter((patho) => patho.label.toLowerCase().search(search.toLowerCase()) >= 0);
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  resetAllFilters(): void {
    this.filterForm.patchValue({ pathologies: true }, { emitEvent: true });
    this.filterForm.patchValue({ sensitivities: true }, { emitEvent: true });
    this.filterForm.controls.categories.patchValue(this.categoriesOptions.map((item) => item.value));

    this.resetSearch();
  }

  resetSearch(): void {
    this.filterForm.patchValue({ search: '' }, { emitEvent: true });
    this.filterPathologies();
  }

  submitPathologies(): void {
    this.submitted.emit(this.value);
  }

  goToNextStep(): void {
    this.nextStep.emit(this.value);
  }

  /**
   * Set pathology items applicable to speciesCode
   */
  private setPathologyItems = (): void => {
    this.pathologyItems = PATHOLOGIES.reduce((prev: PathologyItem[], curr: Pathology) => {
      return !curr.speciesCode || curr.speciesCode === this.speciesCode
        ? [
            ...prev,
            {
              label: translateKey(this.getPathologyLabelKey(curr)),
              value: curr,
              disabled: this.isBCSItem(curr),
              id: this.getPathologyLabelKey(curr),
            },
          ]
        : prev;
    }, []);
  };

  /**
   * Pathologies with category "other conditions" are risk factors for smart algo
   */
  private isRiskFactor = (pathology: Pathology): boolean => !!pathology.isRiskFactor;
  /**
   * Check if pathologies are the same;
   * we need this as pathology codes are not unique;
   * to be identified as unique, we need pathology value + isRiskFactor
   */
  private areSamePathology = (a: Pathology, b: Pathology): boolean => a.value === b.value && this.isRiskFactor(a) === this.isRiskFactor(b);
}
