import { Component, Inject, OnInit } from '@angular/core';
import { PopinSize } from '@app/shared/utils';
import { Clinic } from '@app/core/models';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/shared/utils/enums/routes.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-clinic-identified-poppin',
  templateUrl: './clinic-identified-popin.component.html',
  styleUrls: ['./clinic-identified-popin.component.scss'],
})
export class ClinicIdentifiedPopinComponent implements OnInit {
  private readonly clinic: Clinic;
  clinicName: string;
  clinicId: string;

  DialogSize = PopinSize;

  /**
   * Initializer
   */
  constructor(
    private _dialogRef: MatDialogRef<ClinicIdentifiedPopinComponent>,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.clinic = data.clinic;
      this.clinicName = data.clinic.legalName;
      this.clinicId = data.clinic.customerId;
    }
  }

  /**
   * Life Cycle
   */
  ngOnInit() {
    this._overrideOnCloseListener();
  }

  private _overrideOnCloseListener(): void {
    const closeElement: Element = document.getElementsByClassName('rc-dialog-close')[0];
    if (closeElement) {
      closeElement.addEventListener('click', () => {
        this.dismiss();
      });
    }
  }

  /**
   * Close modal
   */
  dismiss() {
    this._dialogRef.close({ retry: true });
  }

  contact() {
    this._router.navigate([AppRoutes.REGISTER, AppRoutes.CONTACT]);
    this._dialogRef.close();
  }

  /**
   * Close modal
   */
  continue() {
    this._dialogRef.close({ clinic: this.clinic });
  }

  retry() {
    this.dismiss();
  }
}
