import { selectSelectedProducts } from '@app/store/products/products.selectors';
import { Injectable } from '@angular/core';
import { Product } from '@app/core/models';
import { FetchProductDataFilters } from '@app/core/models/fetch-product-data-filters';
import { ProductService } from '@app/core/services';
import { GTMService, productSavePrices } from '@app/core/services/tracking';
import { ProductCatalogFilterValues } from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { ProductType, Tool } from '@app/shared/utils';
import { ProductPillar } from '@app/shared/utils/enums/pillar.enum';
import { TerritoryType } from '@app/shared/utils/enums/territoryType';
import {
  filterCatalogProducts,
  filterCatalogProductsRenalDetect,
  filterProductPackagesByType,
  filterProductsByCatalogFilters,
  formatProductsByCategory,
} from '@app/shared/utils/static-helpers/product-filters-helper';
import { getProductsMergedWithNewPrices, getProductMergedWithNewPrices } from '@app/shared/utils/static-helpers/product-helper';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { AppState } from '@app/store';
import { setAlert, toggleLoader, selectToolInfo } from '@app/store/core';
import { selectIsSolProductsInVetEnable } from '@app/store/vet';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { RCAlertType } from '@rc/ui';
import { catchError, map, switchMap, withLatestFrom, filter } from 'rxjs/operators';
import { getAllProducts, getAllProductsSuccess, setSelectedProduct, resetSelectedProduct } from '..';
import {
  getAllProductsFail,
  resetProductsPricesState,
  setFilteredProducts,
  setFilteredProductsSuccess,
  setProductFilters,
  setProductTypeFilters,
  setForcedProductFilters,
  updatePrices,
  resetAllSelectedProducts,
} from '../products.actions';
import {
  selectAllProductsSuccess,
  selectFiltersValues,
  selectPriceListToUpdate,
  selectSelectedDryProduct,
  selectSelectedWetProduct,
} from '../products.selectors';

@Injectable()
export class ProductsEffects {
  UpdateProductsPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePrices),
      withLatestFrom(
        this.store$.select(selectPriceListToUpdate),
        this.store$.select(selectAllProductsSuccess),
        this.store$.select(selectSelectedDryProduct),
        this.store$.select(selectSelectedWetProduct)
      ),
      switchMap(([{ withTracking }, priceListToUpdate, products, selectedDryProduct, selectedWetProduct]) => {
        return this.productService.updateClinicPrices(priceListToUpdate).pipe(
          switchMap(() => {
            if (withTracking) {
              this.trackingService.sendInteraction(productSavePrices());
            }
            const actions: Action[] = [
              getAllProductsSuccess({ allProductList: getProductsMergedWithNewPrices(products, priceListToUpdate) }),
              setFilteredProducts({ openFilledCategories: true }),
              resetProductsPricesState(),
              setAlert({ alert: { message: translateKey('prices_have_been_well_saved_message'), alertType: RCAlertType.SUCCESS } }),
            ];
            if (selectedDryProduct) {
              actions.push(
                setSelectedProduct({
                  selectedProduct: {
                    product: getProductMergedWithNewPrices(selectedDryProduct, priceListToUpdate),
                  },
                  setProductOnly: true,
                })
              );
            }
            if (selectedWetProduct) {
              actions.push(
                setSelectedProduct({
                  selectedProduct: {
                    product: getProductMergedWithNewPrices(selectedWetProduct, priceListToUpdate),
                  },
                  setProductOnly: true,
                })
              );
            }
            return actions;
          }),
          catchError(() => [
            resetProductsPricesState(),
            setAlert({
              alert: { message: translateKey('edit_prices_error_message'), alertType: RCAlertType.ERROR },
            }),
          ])
        );
      })
    )
  );

  GetAllProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllProducts),
      switchMap((action) => {
        return this.productService.fetchProducts([ProductPillar.VET, ProductPillar.SPTRETAIL], action.tool).pipe(
          map((products): [Product[], FetchProductDataFilters, boolean] => {
            return [filterCatalogProducts(products, action.filterParams), action.filterParams, action.openFilledCategories];
          }),
          map(([products, filterParams, openFilledCategories]): [Product[], FetchProductDataFilters, boolean] => {
            return filterParams?.packType
              ? [filterProductPackagesByType(products, filterParams?.packType), filterParams, openFilledCategories]
              : [products, filterParams, openFilledCategories];
          }),
          map(([products, filterParams, openFilledCategories]): [Product[], boolean] => {
            return filterParams?.renalDetectRecommendationPredictionStatus
              ? [
                  filterCatalogProductsRenalDetect(
                    products,
                    filterParams?.renalDetectRecommendationPredictionStatus,
                    filterParams?.lastConsultationPredictionStatus
                  ),
                  openFilledCategories,
                ]
              : [products, openFilledCategories];
          }),
          switchMap(([products, openFilledCategories]) => {
            return [
              getAllProductsSuccess({ allProductList: products }),
              setFilteredProducts({ openFilledCategories }),
              toggleLoader({ value: false }),
            ];
          }),

          catchError(() => [getAllProductsFail(), toggleLoader({ value: false })])
        );
      })
    )
  );

  SetProductFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setProductFilters),
      map(() => setFilteredProducts({}))
    )
  );

  SetFilteredProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFilteredProducts),
      withLatestFrom(
        this.store$.select(selectFiltersValues),
        this.store$.select(selectAllProductsSuccess),
        this.store$.select(selectIsSolProductsInVetEnable),
        this.store$.select(selectSelectedProducts)
      ),
      map(([action, filters, allProducts, enableSolProductsInVet, selectedProducts]): [
        Product[],
        ProductCatalogFilterValues,
        boolean,
        boolean
      ] => {
        return [
          filterProductsByCatalogFilters(allProducts, filters, enableSolProductsInVet, selectedProducts),
          filters,
          action.openFilledCategories,
          action.skipUpdateSelectedCategories,
        ];
      }),
      map(([products, filters, openFilledCategories, skipUpdateSelectedCategories]): [
        Record<TerritoryType | string, Product[]>,
        number,
        boolean,
        boolean
      ] => {
        const formattedProductsByCategory = formatProductsByCategory(products, filters.pillar);
        return [formattedProductsByCategory, products ? products.length : 0, openFilledCategories, skipUpdateSelectedCategories];
      }),
      switchMap(([products, filteredProductLength, openFilledCategories, skipUpdateSelectedCategories]) => {
        return skipUpdateSelectedCategories
          ? [setFilteredProductsSuccess({ filteredProductList: products, filteredProductLength })]
          : [
              setFilteredProductsSuccess({ filteredProductList: products, filteredProductLength }),
              // updateSelectedCategories({
              //   selectedCategories: openFilledCategories ? (Object.keys(products) as (TerritoryType | RangeType)[]) : [],
              // }),
            ];
      })
    )
  );

  FilterProductsOnSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[setSelectedProduct, resetSelectedProduct, resetAllSelectedProducts]),
      withLatestFrom(this.store$.select(selectSelectedProducts), this.store$.select(selectToolInfo)),
      filter(([, , tool]) => tool.tool !== Tool.StarterKit),
      switchMap(([, selectedProducts, { tool }]) => {
        if (tool && tool !== Tool.Rationing) {
          return [
            setProductTypeFilters({
              dry: selectedProducts.every((product) => product?.productType !== ProductType.Dry),
              wet: selectedProducts.every((product) => product?.productType !== ProductType.Wet),
            }),
            setFilteredProducts({ skipUpdateSelectedCategories: true }),
          ];
        } else {
          return [
            setProductTypeFilters({
              dry: selectedProducts.every((product) => product?.productType !== ProductType.Dry),
              wet: selectedProducts.every((product) => product?.productType !== ProductType.Wet),
            }),
            setForcedProductFilters({ specieCode: selectedProducts.length ? selectedProducts[0]?.speciesCode : null }),
            setFilteredProducts({ skipUpdateSelectedCategories: true }),
          ];
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private store$: Store<AppState>,
    private trackingService: GTMService
  ) {}
}
