<nav class="rc-pagination" *ngIf="totalPage > 1">
  <!-- Left Button -->
  <div
    class="rc-pagination__item rc-pagination__item--disabled"
    [class.rc-pagination__item--disabled]="disabledLeftArrow"
    (click)="onPrev($event)"
  >
    <svg
      width="24"
      height="44"
      viewBox="0 0 12 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icons/Chevron-Left/32" fill="#767676">
          <path
            d="M10.1704,22 C9.9144,22 9.6584,21.902 9.4634,21.707 L0.8774,13.121 C-0.2926,11.952 -0.2926,10.048 0.8774,8.879 L9.4634,0.293 C9.8544,-0.098 10.4864,-0.098 10.8774,0.293 C11.2684,0.684 11.2684,1.316 10.8774,1.707 L2.2914,10.293 C1.9014,10.683 1.9014,11.317 2.2914,11.707 L10.8774,20.293 C11.2684,20.684 11.2684,21.316 10.8774,21.707 C10.6824,21.902 10.4264,22 10.1704,22"
            id="Fill-1"
          />
        </g>
      </g>
    </svg>
  </div>

  <!-- Jumper -->
  <div class="rc-pagination__jumper">
    <input
      class="rc-pagination__control"
      type="text"
      id="pageNumber"
      name="pageNumber"
      [value]="currentPage"
      (change)="changePageNumber(pageNumber.value)"
      #pageNumber
    />
    <span class="rc-paragraph">of {{ totalPage }}</span>
  </div>

  <!-- Right Button -->
  <div class="rc-pagination__item" [class.rc-pagination__item--disabled]="disabledRightArrow" (click)="onNext($event)">
    <svg
      width="24"
      height="52.8"
      viewBox="0 0 10 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icons/Chevron-Right/32" fill="#767676">
          <path
            d="M0.9996,22.0003 C0.7666,22.0003 0.5326,21.9193 0.3426,21.7533 C-0.0734,21.3913 -0.1164,20.7593 0.2466,20.3433 L7.7356,11.7573 C8.0876,11.3543 8.0876,10.6463 7.7356,10.2433 L0.2466,1.6573 C-0.1164,1.2413 -0.0734,0.6093 0.3426,0.2473 C0.7586,-0.1167 1.3906,-0.0737 1.7536,0.3433 L9.2426,8.9293 C10.2556,10.0903 10.2556,11.9103 9.2436,13.0723 L1.7536,21.6573 C1.5556,21.8843 1.2786,22.0003 0.9996,22.0003"
            id="Fill-1"
          />
        </g>
      </g>
    </svg>
  </div>
</nav>
