import { NutrientCode } from '../nutrients-list/nutrients-mock';
import { MeasurementCodeType } from '@app/shared/utils';

export const nutrientInfographyMock = {
  nutrient: {
    id: NutrientCode.RN_109,
    unit: MeasurementCodeType.GramPerMegacalory,
  },
  influencers: [{ key: 'obesity_bcs_7_(out_of_9)', type: 'diseases' }],
  minPercentage: 20,
  maxPercentage: 80,
  targetPercentage: 50,
  cutOff: {
    high: 80,
    low: 20,
  },
  targetValue: {
    high: 80,
    low: 20,
  },
};
