<div class="follow-up-selector-popin">
  <app-rc-popin-content [isSeparatorDisplayed]="true">
    <div class="follow-up-selector-popin__title-content" popin_title-content>
      <h1 class="popin-title rc-title-gamma">{{ title }}</h1>
      <p class="rc-paragraph popin-sub-title">
        {{ description }}
      </p>
    </div>

    <div class="follow-up-selector-popin__left-content" popin_left-content>
      <app-follow-up-selector
        id="choice-one"
        [action]="choiceOne"
        [recommendedActionCode]="followUpSelector.recommendedActionCode"
        (selectAction)="selectAction($event)"
      ></app-follow-up-selector>
    </div>

    <div class="follow-up-selector-popin__right-content" popin_right-content>
      <app-follow-up-selector
        id="choice-two"
        [action]="choiceTwo"
        [recommendedActionCode]="followUpSelector.recommendedActionCode"
        (selectAction)="selectAction($event)"
      ></app-follow-up-selector>
    </div>
  </app-rc-popin-content>
</div>
