import { VetFacade } from '@app/store/vet';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Helper, Programs } from '@app/shared/utils';

@Component({
  selector: 'app-weight-loss-plan',
  templateUrl: './weight-loss-plan.component.html',
  styleUrls: ['./weight-loss-plan.component.scss'],
})
export class WeightLossPlanComponent {
  @Input() loading: boolean;

  /** Variables & Properties **/
  programTitle: string;
  programImage: string;
  programDesc: string;

  @Input()
  set programs(program: Programs) {
    const programTexts = Helper.getProgramTexts(program);
    this.programTitle = programTexts.title;
    this.programImage = programTexts.imagePath;
    this.programDesc = programTexts.description;
  }

  @Output() displayShareRecommendationView: EventEmitter<any> = new EventEmitter();

  /** Initializer **/
  constructor(private vetFacade: VetFacade) {}

  /** Methods **/

  showShareRecommendation(event: Event) {
    if (!this.loading) {
      event.stopPropagation();
      this.displayShareRecommendationView.emit();
    }
  }
}
