<div
  id="product-reco-select-{{ index }}"
  class="product-recommendation-card"
  [ngClass]="{
    'product-outline': isSelected,
    'product-highlighted': isProductHighlighted,
    'product-notRecommended': isProductNotRecommended,
    'other-products': isOtherProductsSelectionCard,
    'product-selected': isSelected,
    'product-wet': isWetProduct
  }"
>
  <button
    id="product-reco-select-{{ index }}-btn"
    class="product-recommendation-card__button"
    [attr.aria-labelledby]="productRecommendation?.product?.name?.replace(' ', '')"
    (click)="onSelectProduct(productRecommendation)"
  ></button>

  <ng-container *ngIf="isOtherProductsSelectionCard">
    <button class="other-products-button rc-button" type="button" (click)="showSearchProductPopin()">
      <app-icon [name]="IconName.address_book"></app-icon>
      <span class="rc-button__title rc-button__title--link">{{ 'sr-result-browse-product' | translateKey }}</span>
    </button>
  </ng-container>
  <div
    class="product-recommendation-card__content"
    *ngIf="!!productRecommendation"
    [id]="'product-id-' + productRecommendation?.product?.id"
  >
    <ng-container *ngIf="!isProductNotRecommendedOverlay && !isOtherProductsSelectionCard">
      <ng-container *appLet="productRecommendation.coverage?.criterias as criterias">
        <ng-container *appLet="getNotRecommendedStatus() as notRecommendedProductStatus">
          <span class="product-recommendation-card__highlighted-icon" *ngIf="isSelected">
            <app-icon [name]="IconName.success"></app-icon>
          </span>
          <span
            class="product-recommendation-card__warning-icon"
            *ngIf="
              (isProductNotRecommended && notRecommendedProductStatus === NotRecommendedProductStatus.REQUIREMENTS_PARTIAL) ||
              atLeastNotRecommendedCriteria(criterias)
            "
          >
            <app-icon [name]="IconName.alert"></app-icon>
          </span>

          <!-- Product details -->
          <ng-container>
            <div class="product-recommendation-card__header">
              <button (click)="showDetailPopin(productRecommendation)" class="rc-button">
                <span class="rc-button__title rc-button__title--link" i18n="@@action_detail">Details</span>
              </button>
              <app-clinical-alliance-badge
                *ngIf="isClinicalAllianceEnabled$ | async"
                [displayHeader]="productRecommendation.product.clinicalAlliance"
              ></app-clinical-alliance-badge>
            </div>

            <img
              class="product-recommendation-card__product-picture"
              [src]="productRecommendation.product.image | publicUrl"
              alt="{{ productRecommendation.product.name }}"
            />
            <p
              [id]="productRecommendation.product.name.replace(' ', '')"
              class="product-recommendation-card-title"
              [matTooltipPosition]="'below'"
              matTooltipClass="tooltip-arrow-up"
              matTooltipHideDelay="1"
              matTooltip="{{ productRecommendation.product.name }}"
              #tooltip="matTooltip"
            >
              {{ productRecommendation.product.name | truncate }}
            </p>
            <p class="product-recommendation-card__product-category">
              {{ productRecommendation.product.productType | translationCode: LocaleContentEnum.ProductType }}
            </p>
            <!-- Display product criterias for recommended product or not recommended products that at least partially meet requirements -->
            <ng-container *ngIf="criterias?.length">
              <div class="product-recommendation-card__product-detail">{{ 'sr-result-nutritional-need' | translateKey }}</div>
              <ul class="product-recommendation-card__product-criterias">
                <li *ngFor="let criteria of criterias" class="product-recommendation-card__criteria">
                  <span
                    class="product-recommendation-card__criteria--not-recommended"
                    *ngIf="criteria.isNotRecommended; else recommendedCriteria"
                    >Not recommended for this criteria</span
                  >

                  <ng-template #recommendedCriteria>
                    <app-rc-gauge
                      id="rc-gauge-{{ criteria.key }}"
                      [value]="roundSmartReco(criteria.pmean)"
                      [title]="getCriteriaTitle(criteria)"
                      [hasIcon]="true"
                    ></app-rc-gauge>
                  </ng-template>
                </li>
              </ul>
            </ng-container>
            <ng-container *appLet="costPerDay$ | async as costPerDay">
              <div *ngIf="!isWetProduct" class="product-recommendation-card__costPerDay">
                <span *ngIf="!!costPerDay"> {{ costPerDay }}</span>
              </div>
            </ng-container>
            <!-- Action buttons for a not recommended product -->
            <div *ngIf="isProductNotRecommended" class="notRecommended-actions">
              <!-- Action buttons in case requirements are met -->
              <div>
                <button class="rc-button" (click)="removeProduct()">
                  <span class="rc-button__title rc-button__title--link" i18n="@@action-remove">Remove</span>
                </button>
              </div>
            </div>

            <!-- Button to send feedback for a recommended product -->
            <div *ngIf="!isProductNotRecommended && !isWetProduct" class="product-recommendation-card__signal-button">
              <button class="rc-button" (click)="openSignalPopin(productRecommendation)">
                <span class="rc-button__title rc-button__title--link" i18n="@@sr-result-send-feedback">Send Feedback</span>
              </button>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="showNotRecommendedOverlay">
  <ng-container *appLet="getNotRecommendedStatus() as notRecommendedProductStatus">
    <div class="product-recommendation-card--overlay">
      <ng-container
        *ngIf="isProductNotRecommended && notRecommendedProductStatus === NotRecommendedProductStatus.REQUIREMENTS_KO; else showWarningCard"
      >
        <div class="product-recommendation-card--overlay--content notRecommended-ko-details">
          <div class="notRecommended-ko-details--title">
            <app-icon [name]="IconName.alert"></app-icon>
            <h4 i18n="@@information-mkt_filter">Information</h4>
          </div>
          <p>{{ 'sr_searchwarning_belowforty' | translateKey }}</p>
          <button id="button-remove" class="rc-button" (click)="removeProduct()">
            <span class="rc-button__title rc-button__title--link" i18n="@@action-remove">Remove</span>
          </button>
        </div>
      </ng-container>

      <ng-template #showWarningCard>
        <div class="product-recommendation-card--overlay--content notRecommended-mkf-explanations">
          <span class="notRecommended-mkf-explanations-title">
            <app-icon [name]="IconName.alert"></app-icon>
            <h4 i18n="@@information-mkt_filter">Information</h4>
          </span>
          <p class="notRecommended-mkf-explanations-warning" id="mkf-explanations-warning">
            {{ 'sr_searchwarning_betweenfortyandsixtyfour' | translateKey }}
          </p>
          <p *ngIf="productRecommendation.notRecommendedReasons.includes(NotRecommendedReasons.TERRITORY)" id="mkf-explanations-territory">
            {{ mkfTerritoryExplanation | async }}
          </p>
          <p *ngIf="productRecommendation.notRecommendedReasons.includes(NotRecommendedReasons.LIFESTAGE)" id="mkf-explanations-lifestage">
            {{ mkfLifestageExplanation | async }}
          </p>
          <p *ngIf="productRecommendation.notRecommendedReasons.includes(NotRecommendedReasons.SIZE)" id="mkf-explanations-size">
            {{ mkfSizeExplanation | async }}
          </p>
          <p *ngIf="productRecommendation.notRecommendedReasons.includes(NotRecommendedReasons.NEUTERED)" id="mkf-explanations-neutered">
            {{ mkfNeuteredExplanation | async }}
          </p>
          <button class="rc-button" type="button" (click)="hideNotRecommendedOverlay()">
            <span class="rc-button__title--link product-recommendation-card--overlay--button">I understand</span>
          </button>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
