<app-rc-tooltip class="wlp-tooltip" secondaryPosition="right" size="medium">
  <div main>
    <app-icon class="wlp-tooltip__icon-info" [name]="IconName.info"> </app-icon>
  </div>
  <div tooltip class="wlp-tooltip__card-bubble">
    <p>{{ 'wm-summary_info-reco' | translateKey }}</p>
    <p class="wlp-tooltip__bold">{{ 'wm-summary_info-dog' | translateKey }}</p>
    <p>{{ 'wm-summary_info-dog-body-weight' | translateKey }}</p>
    <p class="wlp-tooltip__bold">{{ 'wm-summary_info-cat' | translateKey }}</p>
    <p>{{ 'wm-summary_info-cat-body-weight' | translateKey }}</p>
    <p class="wlp-tooltip__bold">{{ 'wm-summary_info-goal' | translateKey }}</p>
  </div>
</app-rc-tooltip>
