import { first, map } from 'rxjs/operators';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { environment } from '@env/environment';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Criteria, Package, PetProfileFormValues, ProductActionType, ProductOutput, ProductRecommendation } from '@app/core/models';
import { lifestageTypeTranslationCodes, LocaleContentEnum, sizesTranslationCodes } from '@app/shared/data';
import {
  getCriteriaTitleTranslationKey,
  getNotRecommendedProductStatus,
  NotRecommendedProductStatus,
  NotRecommendedReasons,
  PackType,
  ProductType,
  roundSmartReco,
  SpeciesCode,
} from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { VetFacade } from '@app/store/vet';
import { CoreFacade } from '@app/store/core/core.facade';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import {
  selectSmartRecoNotRecommendedCostPerDay,
  selectSmartRecoProductsCostPerDay,
} from '@app/pages/smart-reco/store/selectors/smart-reco-recommendation.selectors';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-product-recommendation-card',
  templateUrl: './product-recommendation-card.component.html',
  styleUrls: ['./product-recommendation-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRecommendationCardComponent implements OnInit {
  @Input() productRecommendation: ProductRecommendation;
  @Input() isSelected: boolean;
  @Input() index: number;
  @Input() isProductHighlighted: boolean;
  @Input() isProductNotRecommended: boolean;
  @Input() isProductNotRecommendedOverlay: boolean;
  @Input() isOtherProductsSelectionCard: boolean;
  @Input() isWetProduct: boolean;
  @Input() profile: PetProfileFormValues;

  costPerDay$: Observable<string> = combineLatest([
    this.store$.select(selectSmartRecoProductsCostPerDay),
    this.store$.select(selectSmartRecoNotRecommendedCostPerDay),
    this.vetFacade.currentClinicCurrency$,
  ]).pipe(
    map(([recommendedProductsCostPerDay, notRecommendedProductCostPerDay, currency]) => {
      if (this.isProductNotRecommended) {
        return notRecommendedProductCostPerDay
          ? translateKey('sr-result_from-cost-per-day', {
              cost: `${this.currencyPipe.transform(notRecommendedProductCostPerDay, currency)}`,
            })
          : null;
      } else {
        return !!recommendedProductsCostPerDay && recommendedProductsCostPerDay[this.index]
          ? translateKey('sr-result_from-cost-per-day', {
              cost: `${this.currencyPipe.transform(recommendedProductsCostPerDay[this.index], currency)}`,
            })
          : null;
      }
    })
  );

  @Output()
  productOutput: EventEmitter<ProductOutput> = new EventEmitter<ProductOutput>();

  @Output() remove = new EventEmitter<ProductRecommendation>();
  @Output() displayMoreProductsPopin: EventEmitter<void> = new EventEmitter<void>();
  isStandalone = environment.isStandalone;
  showNotRecommendedOverlay: boolean;
  ProductType = ProductType;
  public readonly IconName = IconName;
  public readonly NotRecommendedProductStatus = NotRecommendedProductStatus;
  public readonly NotRecommendedReasons = NotRecommendedReasons;
  public readonly LocaleContentEnum = LocaleContentEnum;

  packages: Package[];
  showNotRecommendedDetails = false;
  clinicCurrency$ = this.vetFacade.currentClinicCurrency$;
  mkfTerritoryExplanation = new BehaviorSubject('');
  mkfSizeExplanation = new BehaviorSubject('');
  mkfNeuteredExplanation = new BehaviorSubject('');
  mkfLifestageExplanation = new BehaviorSubject('');
  roundSmartReco = roundSmartReco;
  isClinicalAllianceEnabled$ = this.vetFacade.isClinicalAllianceEnabled$;

  constructor(
    private vetFacade: VetFacade,
    private coreFacade: CoreFacade,
    private store$: Store<AppState>,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.packages = this.productRecommendation?.product?.packages.filter((pack) => pack.type !== PackType.StarterKit);
    if (!this.packages || this.packages?.length === 0) return;

    this.setMarketingFilterExplanations();
    this.showNotRecommendedOverlay =
      this.isProductNotRecommended &&
      (getNotRecommendedProductStatus(this.productRecommendation) === NotRecommendedProductStatus.REQUIREMENTS_KO ||
        getNotRecommendedProductStatus(this.productRecommendation) === NotRecommendedProductStatus.REQUIREMENTS_PARTIAL);
  }

  removeProduct(): void {
    this.remove.emit(this.productRecommendation);
  }

  public getCriteriaTitle(criteria: Criteria): string {
    return translateKey(getCriteriaTitleTranslationKey(criteria));
  }

  toggleNotRecommendedDetails(): void {
    this.showNotRecommendedDetails = !this.showNotRecommendedDetails;
  }

  getNotRecommendedStatus(): NotRecommendedProductStatus {
    return getNotRecommendedProductStatus(this.productRecommendation);
  }

  setMarketingFilterExplanations(): void {
    this.coreFacade.breeds$
      .pipe(
        first(),
        map((breeds) => {
          const { speciesCode, lifestage, breed, neutered } = this.profile;
          const specie = speciesCode === SpeciesCode.Dog ? translateKey('specie-dog') : translateKey('specie-cat');
          const lifestageTranslation = lifestageTypeTranslationCodes.find((item) => lifestage === item.code);
          const fullBreed = breeds.find((item) => item.breedCode === breed);
          const breedTranslation = fullBreed && sizesTranslationCodes.find((item) => item.code === fullBreed.sizeCategory);
          this.mkfTerritoryExplanation.next(translateKey('mkt_filter_territory', { specie }));
          this.mkfLifestageExplanation.next(translateKey('mkt_filter_lifestage', { lifestage: translateKey(lifestageTranslation?.value) }));
          this.mkfNeuteredExplanation.next(
            translateKey(neutered ? 'mkt_filter_neutered_2' : 'mkt_filter_neutered', {
              specie,
              lifestage: translateKey(lifestageTranslation?.value),
            })
          );
          this.mkfSizeExplanation.next(translateKey('mkt_filter_size', { size: translateKey(breedTranslation?.value) }));
        })
      )
      .subscribe();
  }

  /**
   * Show product detail pop-in
   */
  showDetailPopin(productRecommendation: ProductRecommendation): void {
    this.productOutput.emit({
      productRecommendation,
      action: ProductActionType.ShowDetails,
    });
  }

  showCriteriaDescriptionPopin(selectedCriteria: Criteria): void {
    this.productOutput.emit({
      selectedCriteria,
      action: ProductActionType.ShowCriterias,
      productName: this.productRecommendation?.product.name,
    });
  }

  showSearchProductPopin(): void {
    this.displayMoreProductsPopin.emit();
  }

  /**
   * Select product detail pop-in
   */
  onSelectProduct(productRecommendation: ProductRecommendation): void {
    this.productOutput.emit({
      productRecommendation,
      action: ProductActionType.SelectedProduct,
    });
  }

  /**
   * Select product detail pop-in
   */
  hideNotRecommendedOverlay(): void {
    this.showNotRecommendedOverlay = false;
  }

  /**
   * Show signal pop-in
   */
  openSignalPopin(productRecommendation: ProductRecommendation): void {
    this.productOutput.emit({
      productRecommendation,
      action: ProductActionType.SignalProduct,
    });
  }

  /*
    By default the RcSelect component will sort items alphabetically, we want to prevent this behaviour
  */
  customSelectSort(): number {
    return 1;
  }

  atLeastNotRecommendedCriteria(criterias: Criteria[]): boolean {
    return criterias?.some((item) => item.isNotRecommended);
  }
}
