<div class="rc-list-container">
  <ng-container *ngFor="let item of listItems">
    <div class="rc-list-item">
      <span>{{ item.key }}</span>
      <span [class.bold]="highlightValue" data-cs-mask>
        {{ item.value }}
      </span>
    </div>
  </ng-container>
</div>
