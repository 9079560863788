import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-digit-counter',
  templateUrl: './digit-counter.component.html',
  styleUrls: ['./digit-counter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DigitCounterComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitCounterComponent implements ControlValueAccessor {
  @Input()
  disabled!: boolean;

  @Input()
  min = 0;

  counterValue = 0;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => undefined;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (_: unknown) => void = () => undefined;

  onInputChange(value: number) {
    this.onChange(value);
    this.onTouched();
  }

  onAdd() {
    this.counterValue += 1;
    this.onChange(this.counterValue);
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onRemove() {
    this.counterValue -= 1;
    this.onChange(this.counterValue);
    this.onTouched();
  }

  writeValue(value: number): void {
    this.counterValue = value;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}
