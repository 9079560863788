import { LocaleData } from 'i18n-iso-countries';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Country } from '@app/core/models';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
import { mapToCountries } from '@app/shared/utils/static-helpers/countries-helper';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private cache: Record<string, Country[]> = {};

  private readonly SPECIAL_COUNTRIES = ['zh-TW'];

  private readonly FALLBACK_LOCALE = LanguageCode.enGB;

  constructor(private httpClient: HttpClient, @Inject(LOCALE_ID) protected locale: string) {}

  get translatedCountryList$(): Observable<Country[]> {
    return this.getTranslatedCountries(this.locale);
  }

  getTranslatedCountries(locale: string): Observable<Country[]> {
    if (this.cache[locale]) {
      return of(this.cache[locale]);
    }
    return this.getFromApi(locale).pipe(
      catchError(() => {
        // In case the specified language is not available, fallback to english.
        if (locale !== this.FALLBACK_LOCALE) {
          return this.getFromApi(this.FALLBACK_LOCALE);
        }
        return of({ locale: this.FALLBACK_LOCALE, countries: {} });
      }),
      map(mapToCountries),
      tap((countries) => (this.cache[locale] = countries))
    );
  }

  private getFromApi(locale: string): Observable<LocaleData> {
    return this.httpClient.get<LocaleData>(this.buildCountriesUrl(locale));
  }

  private buildCountriesUrl(locale: string): string {
    if (this.SPECIAL_COUNTRIES.includes(locale)) {
      // In the special countries folder,
      // the filename is based on the locale like: `zh-TW.json`.
      return `assets/special-countries/${locale}.json`;
    }
    // We are using the NPM package "i18n-iso-countries".
    // This package provides JSON files like: `en.json`, `fr.json`, ...
    // So, the filename is based only on the language (not the locale).
    const language = locale.split('-')[0];
    return `assets/countries/${language}.json`;
  }
}
