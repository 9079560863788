import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Consultation, PetInfo, Weight } from '@app/core/models';
import { NextVisitData } from '@app/core/models/visit-next';
import { GTMService, WeightLossPlanButtonActionGtm, weightLossPlanButton } from '@app/core/services/tracking';
import { AllowanceFacade } from '@app/pages/allowance/store/allowance.facade';
import { SharedDirectivesModule } from '@app/shared/directives/shared-directives.module';
import { futureDateValidator, minDateValidator } from '@app/shared/directives/validators';
import { SharedPipesModule } from '@app/shared/pipes/shared-pipes.module';
import { SharedRegisterIconsModule } from '@app/shared/shared-register-icons.module';
import { Helper, POLetterFrom, Tool } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { getDateErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import { ConsultationFacade } from '@app/store/consultation/consultation.facade';
import { CoreFacade } from '@app/store/core/core.facade';
import { Subject, of } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { RCDialogWrapperModule } from '../rc-dialog-wrapper/rc-dialog-wrapper.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { WlpTooltipComponent } from '../wlp-tooltip/wlp-tooltip.component';
import { VetFacade } from '@app/store/vet';

export interface SwitchWlpPopinComponentData {
  petInfo: PetInfo;
  lastConsultation: Consultation;
}

@Component({
  selector: 'app-switch-wlp-popin',
  templateUrl: './switch-wlp-popin.component.html',
  styleUrls: ['./switch-wlp-popin.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedPipesModule,
    SharedRegisterIconsModule,
    SharedDirectivesModule,
    RCDialogWrapperModule,
    MatDatepickerModule,
    WlpTooltipComponent,
  ],
})
export class SwitchWlpPopinComponent implements OnInit, OnDestroy {
  public readonly iconName = IconName;
  public stepPlanNextVisit = false;
  public currentDataWeight!: Weight;
  public diffPercent!: string;
  public nextVisitForm: FormGroup<{ visitDate: FormControl<Date> }>;
  public dataApi: NextVisitData;
  public dataError = false;

  private _destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    public config: MatDialogConfig,
    public dialogRef: MatDialogRef<SwitchWlpPopinComponent>,
    private coreFacade: CoreFacade,
    private consultationFacade: ConsultationFacade,
    private allowanceFacade: AllowanceFacade,
    private trackingService: GTMService,
    private vetFacade: VetFacade,
    @Inject(MAT_DIALOG_DATA) public data: SwitchWlpPopinComponentData
  ) {}

  ngOnInit(): void {
    this.trackingService.sendDialogView('Weight loss plan');

    if (this.data?.petInfo) {
      this.consultationFacade.getExpectedNextVisit(this.data.petInfo);
      this.getData();
    } else {
      this.dataError = true;
    }
  }

  getData() {
    this.coreFacade.togglePopinLoader(true);
    this.vetFacade.currentBigMeasurementUnit$
      .pipe(
        take(1),
        tap((currentBigMeasurementUnit) => {
          this.currentDataWeight = {
            measure: +this.data.petInfo.weight.toFixed(2),
            measureUnit: currentBigMeasurementUnit,
            weightDate: new Date(),
          };
        })
      )
      .subscribe();

    this.consultationFacade.selectExpectedNextVisit$
      .pipe(
        filter((data) => !!data),
        take(1),
        tap((data) => {
          if (!data) {
            this.dataError = true;
          } else {
            this.initData(data);
            this.initDatePicker();
          }
          this.coreFacade.togglePopinLoader(false);
        }),

        catchError((error) => {
          console.error(error);
          this.dataError = true;
          this.coreFacade.togglePopinLoader(false);
          return of(null);
        })
      )
      .subscribe();
  }

  initData(data: NextVisitData) {
    this.dataApi = data;

    this.diffPercent =
      Math.abs(Helper.getPercentageDifference(this.currentDataWeight.measure, this.dataApi?.intermediateVisit?.measure)).toFixed(2) + '%';
  }

  initDatePicker() {
    this.nextVisitForm = this.formBuilder.group({
      visitDate: [
        new Date(this.dataApi?.intermediateVisit?.weightDate),
        [Validators.required, futureDateValidator, minDateValidator(this.data?.petInfo.birthdate)],
      ],
    });

    this.nextVisitForm.controls.visitDate.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        tap((plannedNextVisitDate) => {
          this.coreFacade.togglePopinLoader(true);
          this.consultationFacade.getExpectedNextVisit(this.data.petInfo, plannedNextVisitDate);
          this.coreFacade.togglePopinLoader(false);
        }),
        switchMap(() =>
          this.consultationFacade.selectExpectedNextVisit$.pipe(
            filter((data) => !!data),
            tap((data) => this.initData(data)),
            catchError((error) => {
              console.error(error);
              this.dataError = true;
              return of(null);
            })
          )
        )
      )
      .subscribe();
  }

  planNextVisit() {
    this.trackingService.sendInteraction(weightLossPlanButton(WeightLossPlanButtonActionGtm.PLAN_NEXT_VISIT));
    this.stepPlanNextVisit = true;
  }

  public dateErrorMessage(): string {
    return (
      (this.nextVisitForm.controls.visitDate.touched &&
        this.nextVisitForm.controls.visitDate.errors &&
        getDateErrorMessage(this.nextVisitForm.controls.visitDate.errors, 'wm_plan_next_visit_pop_in-title', true)) ||
      ''
    );
  }

  confirmNextVisit() {
    this.consultationFacade.convertConsultationFlow(Tool.WeightManagement); // convert consultation's flow & reset consultation expected next visit
    this.consultationFacade.setExpectedNextVisit({
      ...this.dataApi,
      intermediateVisit: {
        ...this.dataApi.intermediateVisit,
        weightDate: this.nextVisitForm.value.visitDate,
      },
    });

    this.trackingService.sendInteraction(weightLossPlanButton(WeightLossPlanButtonActionGtm.CONTINUE));
    this.allowanceFacade.shareAllowanceRecommendation(POLetterFrom.FlowAllowanceWeightBlock);
    this.closePopin();
  }

  shareWithoutWlp() {
    this.trackingService.sendInteraction(weightLossPlanButton(WeightLossPlanButtonActionGtm.SHARE_WITHOUT_WLP));
    this.allowanceFacade.shareAllowanceRecommendation(POLetterFrom.FlowAllowanceRationBlock);
    this.closePopin();
  }

  closePopin(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
