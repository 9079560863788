<div class="pet-profile-form__wrapper">
  <ng-container *appLet="lifestageError$ | async as lifestageError">
    <ng-container *appLet="dateOfBirthErrorMessage() as dateOfBirthErrorMessage">
      <form [formGroup]="form" class="pet-profile-form__container">
        <div *ngIf="isNewPet" class="pet-profile-form__field-wrapper">
          <app-rc-radio-image
            [formControlName]="PetProfileFormValueKeysEnum.SPECIES_CODE"
            [items]="speciesItems"
            [label]="placeholders[PetProfileFormValueKeysEnum.SPECIES_CODE]"
            [error]="requiredErrorMessage(PetProfileFormValueKeysEnum.SPECIES_CODE, 'form-attribute_species')"
          ></app-rc-radio-image>
        </div>
        <div class="pet-profile-form__field-wrapper" *ngIf="isNewPet">
          <rc-field
            id="pet-profile-form-field-name"
            data-cs-mask
            [formControlName]="PetProfileFormValueKeysEnum.NAME"
            type="text"
            [label]="'pet-page_patient-name' | translateKey"
            [error]="requiredErrorMessage(PetProfileFormValueKeysEnum.NAME, 'form-attribute_name')"
          ></rc-field>
        </div>
        <div class="pet-profile-form__field-wrapper" *ngIf="isNewPet || (initalBreedInvalid$ | async)">
          <p class="pet-profile-form__field-label" [ngClass]="form.controls.mixed.disabled ? 'disabled' : ''">
            {{ 'form-attribute_breed' | translateKey }}
          </p>
          <rc-selectors
            [id]="'pet-profile-form-field-mixed'"
            class="pet-profile-form__selectors"
            [formControlName]="PetProfileFormValueKeysEnum.MIXED"
            [mode]="'grouped'"
            [items]="breedItems"
          ></rc-selectors>
        </div>
        <div
          *ngIf="isNewPet || (initalBreedInvalid$ | async)"
          class="pet-profile-form__field-wrapper__breed"
          [ngClass]="
            form.controls.mixed.value === null || breedInputNotNeeded
              ? 'pet-profile-form__field-wrapper__hide'
              : 'pet-profile-form__field-wrapper'
          "
        >
          <rc-autocomplete
            [id]="'pet-profile-form-field-breed'"
            [attr.data-testid]="'pet-profile-form-field-breed'"
            *ngIf="breeds$ | async as breeds"
            [formControlName]="PetProfileFormValueKeysEnum.BREED"
            [placeholder]="placeholders[PetProfileFormValueKeysEnum.BREED]"
            [error]="requiredErrorMessage(PetProfileFormValueKeysEnum.BREED, 'form-attribute_breed')"
            [items]="breeds"
            [itemHeight]="itemHeight"
            [customSort]="customSortAutocomplete()"
          ></rc-autocomplete>
        </div>
        <ng-container *ngIf="isNewPet || (initalBreedInvalid$ | async)">
          <div class="pet-profile-form__field-wrapper datepicker-wrapper">
            <div class="field-wrapper" id="pet-profile-form-field-birthdate" [class.is-disabled]="form.controls.birthdate.disabled">
              <p class="pet-profile-form__field-label" *ngIf="!!form.controls.birthdate.value">
                {{ placeholders[PetProfileFormValueKeysEnum.BIRTHDATE] }}
              </p>
              <div class="datepicker" [class.error-state]="lifestageError || dateOfBirthErrorMessage">
                <input
                  [attr.data-testid]="'pet-profile-form-field-birthdate'"
                  [formControlName]="PetProfileFormValueKeysEnum.BIRTHDATE"
                  matInput
                  [max]="maxDate"
                  [matDatepicker]="picker"
                  id="datepicker-input"
                  class="error-input datepicker-input"
                  autocomplete="off"
                  [placeholder]="placeholders[PetProfileFormValueKeysEnum.BIRTHDATE]"
                />
                <app-icon
                  class="datepicker-icon"
                  [class.error]="lifestageError || dateOfBirthErrorMessage"
                  [name]="IconName.calendar_small"
                  (click)="picker.opened ? picker.close() : picker.open()"
                ></app-icon>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <div *ngIf="dateOfBirthErrorMessage" class="datepicker-error-message">{{ dateOfBirthErrorMessage }}</div>
            </div>
          </div>

          <app-checkbox
            [formControlName]="PetProfileFormValueKeysEnum.UNKNOWN_BIRTHDATE"
            class="pet-profile-form__unknown-birthdate-checkbox"
            [label]="'patient-page_unknown-birthdate' | translateKey"
            [id]="'unknown-birthdate-checkbox'"
          ></app-checkbox>

          <ng-container *ngIf="isCheckedUnknownBirthdate">
            <div
              class="pet-profile-form__digit-counter"
              [class.pet-profile-form__digit-counter--is-disabled]="form.controls[PetProfileFormValueKeysEnum.UNKNOWN_BIRTHDATE].disabled"
            >
              <rc-digit-counter
                [counterAriaLabel]="'aria_counter_field_number' | translateKey"
                [minusButtonAriaLabel]="'aria_counter_button_decrement' | translateKey"
                [plusButtonAriaLabel]="'aria_counter_button_increment' | translateKey"
                [formControlName]="PetProfileFormValueKeysEnum.AGE_COUNTER"
                [min]="minAgeCounter"
                [max]="maxAgeCounter"
              ></rc-digit-counter>
              <rc-select
                [id]="'age-field-select'"
                class="pet-profile-form__age-field-select"
                [items]="AgeUnitItems"
                [customSort]="customSelectSort"
                [formControlName]="PetProfileFormValueKeysEnum.AGE_UNIT"
                [hideLabel]="true"
              ></rc-select>
            </div>
          </ng-container>
          <span *ngIf="lifestageError" class="datepicker-error-message">{{ lifestageError }}</span>

          <p
            *ngIf="tool === Tool.RenalDetect"
            [class.is-disabled]="form.controls.birthdate.disabled && form.controls.unknownBirthdate.disabled"
            [ngClass]="lifestageError || dateOfBirthErrorMessage ? 'datepicker-info__error' : 'datepicker-info'"
          >
            {{ 'rd_warning_pet-too-young' | translateKey }}
          </p>

          <div id="lifestage" class="lifestage" *ngIf="lifestageMessage$ | async as message">
            <app-icon class="lifestage-icon" [name]="IconName.alert"></app-icon>
            <span class="lifestage-info"> {{ message }} </span>
          </div>
        </ng-container>
        <div class="pet-profile-form__field-wrapper" *ngIf="isNewPet || (initialGenderInvalid$ | async)">
          <p class="pet-profile-form__field-label" [ngClass]="form.controls.gender.disabled ? 'disabled' : ''">
            {{ placeholders[PetProfileFormValueKeysEnum.GENDER] }}
          </p>
          <rc-selectors
            [id]="'pet-profile-form-field-gender'"
            class="pet-profile-form__selectors"
            [formControlName]="PetProfileFormValueKeysEnum.GENDER"
            [mode]="'grouped'"
            [items]="genderItems"
          >
            <ng-template let-item> <app-icon [name]="IconName[item.value]" class="selectors-icon"></app-icon></ng-template>
          </rc-selectors>
        </div>
        <div class="pet-profile-form__field-wrapper">
          <p class="pet-profile-form__field-label" [ngClass]="form.controls.neutered.disabled ? 'disabled' : ''">
            {{ placeholders[PetProfileFormValueKeysEnum.NEUTERED] }}
          </p>

          <rc-selectors
            [id]="'pet-profile-form-field-neutered'"
            class="pet-profile-form__selectors"
            [formControlName]="PetProfileFormValueKeysEnum.NEUTERED"
            [mode]="'grouped'"
            [items]="neuteredItems"
          >
            <ng-template let-item>
              <app-icon [name]="item.value === 'true' ? IconName.success : IconName.fail" class="selectors-icon"></app-icon>
            </ng-template>
          </rc-selectors>
        </div>
        <div
          class="pet-profile-form__field-wrapper"
          *ngIf="
            displayPostSterilizationFeature &&
            (isPostSterilizationEnabled$ | async) &&
            form.controls.neutered.value === 'true' &&
            !patient?.pet?.neutered
          "
        >
          <p class="pet-profile-form__field-label">
            {{ placeholders[PetProfileFormValueKeysEnum.NEUTERED_TODAY] }}
          </p>

          <rc-selectors
            [id]="'pet-profile-form-field-neutered-today'"
            class="pet-profile-form__selectors"
            [formControlName]="PetProfileFormValueKeysEnum.NEUTERED_TODAY"
            [mode]="'grouped'"
            [items]="neuteredTodayItems"
          >
            <ng-template let-item>
              <app-icon [name]="item.value === 'true' ? IconName.success : IconName.fail" class="selectors-icon"></app-icon>
            </ng-template>
          </rc-selectors>
          <p id="pet-profile-form-neutered-today-disclaimer" class="pet-profile-form__post-sterilization-info-sentence">
            <app-icon [name]="IconName.info"></app-icon> {{ 'informational_sentence_post-sterilization' | translateKey }}
          </p>
        </div>
        <div
          class="pet-profile-form__field-wrapper"
          *ngIf="form.controls.neuteredToday.value === 'true' && form.controls.gender.value === GenderCode.Female"
        >
          <p class="pet-profile-form__field-label">
            {{ placeholders[PetProfileFormValueKeysEnum.STERILIZATION_TYPE] }}
          </p>

          <rc-selectors
            [id]="'pet-profile-form-field-surgery-type'"
            class="pet-profile-form__selectors"
            [formControlName]="PetProfileFormValueKeysEnum.STERILIZATION_TYPE"
            [mode]="'grouped'"
            [items]="sterilizationTypeItems"
          >
          </rc-selectors>
        </div>
        <div class="pet-profile-form__field-wrapper" *ngIf="shouldDisplayReproductionStatus">
          <p class="pet-profile-form__field-label" [ngClass]="form.controls.reproductionStatus.disabled ? 'disabled' : ''">
            {{ 'pet-page_reproduction-cycle' | translateKey }}
          </p>

          <rc-selectors
            [id]="'pet-profile-form-field-reproduction'"
            class="pet-profile-form__selectors"
            [formControlName]="PetProfileFormValueKeysEnum.REPRODUCTION_STATUS"
            [mode]="'grouped'"
            [items]="reproductionStatusItems"
            [maxNumberGroupedItemsInHorizontal]="3"
          ></rc-selectors>
        </div>
      </form>
      <div class="footer">
        <button
          class="rc-button rc-button--filled-small"
          id="validatePetInformation"
          type="button"
          [disabled]="(petProfileInvalidForTool$ | async) || form.invalid"
          (click)="submit()"
        >
          <span class="rc-button__title">{{ submitButtonLabel }}</span>
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
