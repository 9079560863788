import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConsultationNotSavedButtonActionGtm, GTMService, consultationNotSavedButton } from '@app/core/services/tracking';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export enum PreventExitPopinAction {
  CANCEL = 'cancel',
  NO_SAVE = 'no_save',
  SAVE = 'save',
}

@Component({
  selector: 'app-prevent-exit-popin',
  templateUrl: './prevent-exit-popin.component.html',
  styleUrls: ['./prevent-exit-popin.component.scss'],
})
export class PreventExitPopinComponent implements OnDestroy, OnInit {
  private _destroyed$ = new Subject<void>();

  constructor(private matDialogRef: MatDialogRef<PreventExitPopinComponent>, private trackingService: GTMService) {}

  ngOnInit(): void {
    this.trackingService.sendDialogView('Consultation not saved');
    this.listenOnBackdropClick();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  save() {
    this.trackingService.sendInteraction(consultationNotSavedButton(ConsultationNotSavedButtonActionGtm.SAVE_CONSULTATION));
    this.matDialogRef.close(PreventExitPopinAction.SAVE);
  }

  exitWithoutSave() {
    this.trackingService.sendInteraction(consultationNotSavedButton(ConsultationNotSavedButtonActionGtm.EXIT_WITHOUT_SAVING));
    this.matDialogRef.close(PreventExitPopinAction.NO_SAVE);
  }

  closePopin() {
    this.trackingService.sendInteraction(consultationNotSavedButton(ConsultationNotSavedButtonActionGtm.CLOSE));
    this.matDialogRef.close(PreventExitPopinAction.CANCEL);
  }

  private listenOnBackdropClick() {
    this.matDialogRef
      .backdropClick()
      .pipe(
        takeUntil(this._destroyed$),
        tap(() => this.closePopin())
      )
      .subscribe();
  }
}
