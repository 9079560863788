import { Product } from '@app/core/models';
import { FetchProductDataFilters } from '@app/core/models/fetch-product-data-filters';
import {
  ProductCatalogFilterBreedSizevalues,
  ProductCatalogFilterLifestageValues,
  ProductCatalogFilterValues,
} from '@app/shared/components/product-catalog-filters/product-catalog-filters';
import { Constants, Helper, LifestageType, PackType, RenalDetectStatusEnum } from '@app/shared/utils';
import { BreedSize, ProductPillar, ProductType, RangeType, SpeciesCode } from '../enums';
import { TerritoryType } from '../enums/territoryType';

export function filterCatalogProducts(products: Product[], filterData: FetchProductDataFilters = {}): Product[] {
  const { program, retailChannel, speciesCode, productType, packType, solProducts } = filterData;
  return products.filter((product) => {
    const boxPacks = product.packages.filter((pack) => {
      return pack.type === PackType.Box;
    });

    const packTypePacks = product.packages.filter((pack) => {
      return pack.type === packType;
    });
    // filter product by pack type
    if (boxPacks.length !== 0) {
      return false;
    }
    if (packType && packTypePacks.length === 0) {
      return false;
    }

    // filter product by program
    if (program && !product.programs.includes(program)) return false;
    // filter product by retail channel
    if (retailChannel && product.retailChannel !== retailChannel) return false;
    // filter product by specie
    if (speciesCode && product.speciesCode !== speciesCode) return false;
    // filter product by type
    if (productType && product.productType !== productType) return false;

    /* For the product catalog, the following filters can be ignored. As of now, the "solProducts" filter is only used for the smart reco search
     * indeed, if sol products are activated, we want to display all veterinary products AND all SOL products in the smart reco result search
     * in the API, the SOL products are represented by the field territory that must be "birth and growth" AND pillar that must be "spt_retail"
     * in addition, products with range fhn/shn/fhnw/shnw have to be excluded
     * the logic applied here is the same as the logic applied in the filterProductsByCatalogFilters function
     */
    // first make sure to remove all SPT products without a B&G territory
    if (solProducts && isSptProductToExcludeInVet(product)) return false;
    // than make sure to remove unexpected SPT products regarding their range
    if (solProducts && isUnexpectedSolProduct(product)) return false;

    return true;
  });
}

function isUnexpectedSolProduct(product: Product): boolean {
  return (
    product.pillar === ProductPillar.SPTRETAIL &&
    product.territory === TerritoryType.BirthAndGrowth &&
    product.range !== RangeType.FHN &&
    product.range !== RangeType.SHN
  );
}

export function filterCatalogProductsRenalDetect(
  products: Product[],
  currentRenalStatus: RenalDetectStatusEnum,
  prevRenalStatus: RenalDetectStatusEnum
): Product[] {
  if (currentRenalStatus === RenalDetectStatusEnum.NotAtRisk) {
    return prevRenalStatus === RenalDetectStatusEnum.AtRisk ? filterProductsAtRisk(products) : products;
  } else if (currentRenalStatus === RenalDetectStatusEnum.AtRisk) {
    return filterProductsAtRisk(products);
  } else if (currentRenalStatus === RenalDetectStatusEnum.CKD) {
    return products.filter((product) => Constants.PRODUCT_CDK.includes(product.mainItemId));
  } else {
    return products;
  }
}

function filterProductsAtRisk(products: Product[]): Product[] {
  const productsAtRiskEarly = products.filter((product) => Constants.PRODUCT_AT_RISK_EARLY.includes(product.mainItemId));
  return productsAtRiskEarly.length > 0
    ? productsAtRiskEarly
    : products.filter((product) => Constants.PRODUCT_AT_RISK.includes(product.mainItemId));
}

export function filterProductPackagesByType(products: Product[], type: PackType): Product[] {
  return products.map((product) => ({
    ...product,
    packages: product.packages.filter((pack) => pack.type === type),
  }));
}

export function filterProductsByCatalogFilters(
  products: Product[],
  filters: ProductCatalogFilterValues & ProductCatalogFilterBreedSizevalues,
  enableSolProductsInVet: boolean,
  selectedProducts?: Product[]
): Product[] {
  const productsList = products.filter((product) => {
    const isSelectedProduct = selectedProducts?.some((select) => select.id === product.id);
    if (isSelectedProduct) return true;

    if (enableSolProductsInVet && filters.pillar === ProductPillar.VET) {
      if (isSptProductToExcludeInVet(product) || isUnexpectedSolProduct(product)) return false;
    } else {
      if (filters.pillar !== product.pillar) return false;
    }

    if (filters.specie !== product.speciesCode) return false;

    if (isSizeToExclude(product, filters)) return false;

    if (isProductTypeToExclude(product, filters)) return false;

    if (isNameToExclude(product, filters.search)) return false;

    if (isLifestageToExclude(product, filters)) return false;

    return true;
  });
  const dryList = filterByProductTypeAndSort(productsList, ProductType.Dry);
  const wetList = filterByProductTypeAndSort(productsList, ProductType.Wet);
  return dryList.concat(wetList);
}

function isSizeToExclude(product: Product, filters: ProductCatalogFilterValues & ProductCatalogFilterBreedSizevalues): boolean {
  const sizes = [];
  if (filters.giant) {
    sizes.push(BreedSize.XLarge);
  }
  if (filters.maxi) {
    sizes.push(BreedSize.Large);
  }
  if (filters.medium) {
    sizes.push(BreedSize.Medium);
  }
  if (filters.mini) {
    sizes.push(BreedSize.Small);
  }
  if (filters['extra-small']) {
    sizes.push(BreedSize.XSmall);
  }
  return (
    sizes.length > 0 &&
    sizes.length !== Object.values(BreedSize).length &&
    sizes.every((size) => !product.weightCategoriesCode?.includes(size))
  );
}

function isSptProductToExcludeInVet(product: Product): boolean {
  return product.territory !== TerritoryType.BirthAndGrowth && product.pillar !== ProductPillar.VET;
}

function isProductTypeToExclude(product: Product, filters: ProductCatalogFilterValues): boolean {
  const currentTypes: string[] = [];
  if (filters.wet) currentTypes.push(ProductType.Wet);
  if (filters.dry) currentTypes.push(ProductType.Dry);
  return currentTypes.length && !currentTypes.includes(product.productType);
}

function isNameToExclude(product: Product, search: string): boolean {
  return (
    search &&
    search.length > 0 &&
    !search
      ?.toLowerCase()
      .split(' ')
      .every((searchWord) =>
        product.name
          ?.toLowerCase()
          .split(' ')
          .some((namePart) => namePart.startsWith(searchWord))
      )
  );
}

function isLifestageToExclude(product: Product, filters: ProductCatalogFilterValues): boolean {
  const currentLifestages: string[] = [];
  if (filters.adult) currentLifestages.push(LifestageType.Adult);
  if (filters.mature) currentLifestages.push(LifestageType.Mature, LifestageType.Senior);
  if (filters.birthAndGrowth) currentLifestages.push(LifestageType.Baby, LifestageType.Puppy, LifestageType.Junior, LifestageType.Kitten);
  return currentLifestages.length && !product.lifestagesCode?.some((item) => currentLifestages.includes(item));
}

export function formatProductsByCategory(products: Product[], pillar: ProductPillar): Record<TerritoryType | RangeType, Product[]> {
  return products.reduce((prev, curr) => {
    const key = pillar === ProductPillar.VET ? curr?.territory : curr?.range;
    pushProductInCategory(key, prev, curr);

    if (pillar === ProductPillar.SPTRETAIL && curr?.territory === TerritoryType.BirthAndGrowth) {
      pushProductInCategory(TerritoryType.BirthAndGrowth, prev, curr);
    }

    return prev;
  }, {} as Record<TerritoryType | RangeType, Product[]>);
}

function pushProductInCategory(key: string, prev: Record<TerritoryType | RangeType, Product[]>, curr: Product) {
  if (!prev[key]) {
    prev[key] = [];
  }
  prev[key].push(curr);
}

export function filterByProductTypeAndSort(productsList: Product[], productType: ProductType): Product[] {
  return productsList
    .filter((prod) => prod.productType === productType)
    .sort((a, b) => Helper.alphabeticalOrder(a.name.toLowerCase(), b.name.toLowerCase()));
}

export function getInitialFilterValues(
  filtersValues: ProductCatalogFilterValues & ProductCatalogFilterBreedSizevalues,
  overrideValues: { specieCode: SpeciesCode; lifestageType: ProductCatalogFilterLifestageValues }
): ProductCatalogFilterValues & ProductCatalogFilterBreedSizevalues {
  const newFilterValues = { ...filtersValues };
  if (overrideValues?.specieCode) {
    newFilterValues.specie = overrideValues?.specieCode;
  }
  if (overrideValues?.lifestageType) {
    newFilterValues[overrideValues?.lifestageType] = true;
  }

  return newFilterValues;
}
