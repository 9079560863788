import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseProductsPopinComponent } from './purchase-products-popin.component';
import { SharedPipesModule } from '@app/shared/pipes/shared-pipes.module';
import { QrCodeDisplayModule } from '../qr-code-display/qr-code-display.module';
import { RCDialogWrapperModule } from '../rc-dialog-wrapper/rc-dialog-wrapper.module';

@NgModule({
  imports: [CommonModule, SharedPipesModule, QrCodeDisplayModule, RCDialogWrapperModule],
  declarations: [PurchaseProductsPopinComponent],

  exports: [PurchaseProductsPopinComponent],
})
export class PurchaseProductsPopinModule {}
