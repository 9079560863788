import { Segmented } from '@app/core/models';
import { BreedSize, LifestageType, ProductPillar, SpeciesCode } from '@app/shared/utils';
import { IconName } from '@app/shared/utils/icon/icons';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RCSelectorsItem } from '@rc/ui';

// TODO: every interface and type and function in this file should be in its own file in a shared folder on a higher level
export interface ProductCatalogFilterValues {
  pillar: ProductPillar;
  specie: SpeciesCode;
  search: string;
  dry: boolean;
  wet: boolean;
  birthAndGrowth: boolean;
  mature: boolean;
  adult: boolean;
}

export type ProductCatalogFilterBreedSizevalues = { [k in BreedSize]: boolean };
export type FormFilters = {
  searchInGroup: SearchInGroup;
  searchGroup: SearchGroup;
  advancedFiltersGroup: AdvancedFiltersGroup;
};

type SearchInGroup = Pick<ProductCatalogFilterValues, 'pillar' | 'specie'>;
type SearchGroup = Pick<ProductCatalogFilterValues, 'search'>;
type AdvancedFiltersGroup = Omit<ProductCatalogFilterValues, 'pillar' | 'specie' | 'search'> & BreedFiltersGroup;
type BreedFiltersGroup = { breedFiltersGroup: ProductCatalogFilterBreedSizevalues };

export const getInitialFilterBreedSizeValues = () =>
  Object.entries(BreedSize).reduce((acc, [, breedSize]) => {
    return {
      ...acc,
      ...{ [breedSize]: false },
    };
  }, {}) as ProductCatalogFilterBreedSizevalues;

export type ProductCatalogFilterLifestageValues = 'birthAndGrowth' | LifestageType.Mature | LifestageType.Adult;

export const getSpeciesItems = (specie_code?: SpeciesCode): Segmented[] => {
  return [
    {
      label: translateKey('specie-dog'),
      icon: IconName.specie_dog,
      value: SpeciesCode.Dog,
      disabled: specie_code === SpeciesCode.Cat,
    },
    {
      label: translateKey('specie-cat'),
      icon: IconName.specie_cat,
      value: SpeciesCode.Cat,
      disabled: specie_code === SpeciesCode.Dog,
    },
  ];
};

export const getPillarItems = (): RCSelectorsItem[] => [
  {
    label: translateKey('product-book_search_veterinary'),
    value: ProductPillar.VET,
  },
  {
    label: translateKey('product-book_search_health'),
    value: ProductPillar.SPTRETAIL,
  },
];

export const getBreedSizeLabelTranslated = (breedSize: BreedSize): string => {
  switch (breedSize) {
    case BreedSize.XLarge:
      return translateKey('size-giant');
    case BreedSize.Large:
      return translateKey('size-maxi');
    case BreedSize.Medium:
      return translateKey('size-medium');
    case BreedSize.Small:
      return translateKey('size-mini');
    case BreedSize.XSmall:
      return translateKey('size-x-small');
    default:
      return '';
  }
};
