import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, EMPTY, merge, Subject } from 'rxjs';
import { catchError, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { VetService } from '@app/core/services';
import { PetInfo, PetOwner } from '@app/core/models';
import { CreatePatientStates, Helper, PopinSize, Tool } from '@app/shared/utils';
import { Logger } from '@app/core/services/utils/logger';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { customEmailValidator } from '@app/shared/validators/email.validator';
import { getEmailErrorMessage, getInputErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { selectConsultationPetOwner } from '@app/store/consultation/consultation.selectors';
import { CreatePatientPopinFormValues } from './create-patient-popin';
import { PhoneNumber } from '@rc/ui';
import { GtmEventsService } from '@app/core/services/tracking';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { translateKey } from '@app/shared/utils/static-helpers/translate';

export interface CreatePatientPopinData {
  petInfo: PetInfo;
  title: string;
  tool: Tool;
  country: string;
}

export interface CreatePatientPopinOutput {
  values: CreatePatientPopinFormValues;
}

interface PatientPopinFormValues {
  email: string;
  givenName: string;
  familyName: string;
  telephone: PhoneNumber;
  consent: boolean;
  noEmail: boolean;
  existingOwner: PetOwner | null;
}

@Component({
  selector: 'app-create-patient-popin',
  templateUrl: './create-patient-popin.component.html',
  styleUrls: ['./create-patient-popin.component.scss'],
})
export class CreatePatientPopinComponent implements OnInit, AfterViewInit, OnDestroy {
  CreatePatientStates = CreatePatientStates;
  PopinSize = PopinSize;

  title = '';
  state$ = new BehaviorSubject(CreatePatientStates.Initial);
  scanningMails$ = new BehaviorSubject(false);
  form: IFormGroup<PatientPopinFormValues>;

  private _destroyed$ = new Subject<void>();

  /**
   * Initializer
   */
  constructor(
    private gtmEventService: GtmEventsService,
    private _dialogRef: MatDialogRef<CreatePatientPopinComponent>,
    private logger: Logger,
    private vetService: VetService,
    @Inject(MAT_DIALOG_DATA) public data: CreatePatientPopinData,
    private store$: Store<AppState>
  ) {}

  /**
   * Life Cycle
   */
  ngOnInit(): void {
    this.title = this.data.title || translateKey('title_save-the-consultation');

    const formBuilder: IFormBuilder = new UntypedFormBuilder();
    this.store$
      .select(selectConsultationPetOwner)
      .pipe(
        first(),
        tap((petOwner) => {
          this.form = formBuilder.group<PatientPopinFormValues>({
            email: [petOwner?.email || '', [Validators.required, customEmailValidator]],
            givenName: [''],
            familyName: [''],
            telephone: [{ phone: '', countryCode: this.data.country }, [Validators.maxLength(18), Validators.minLength(8)]],
            noEmail: [false],
            consent: [false, Validators.requiredTrue],
            existingOwner: [null],
          });

          this.listenEmailAndDetectExistingOwner();
          this.listenNoEmailCheckbox();
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.gtmEventService.sendCreatePatientPopinView(this.data.tool);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  submit(): void {
    if (this.form.valid) {
      const output: CreatePatientPopinOutput = {
        values: { ...this.form.getRawValue(), telephone: this.form.getRawValue().telephone?.phone },
      };
      this._dialogRef.close(output);
    }
  }

  emailErrorMessage(): string {
    return getEmailErrorMessage(this.form?.controls?.email?.errors, 'form-attribute_email');
  }

  phoneErrorMessage(): string {
    return getInputErrorMessage(this.form?.controls?.telephone?.errors, 'form-attribute_phone');
  }

  listenNoEmailCheckbox(): void {
    this.form?.controls?.noEmail?.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        tap((checked) => {
          if (checked) {
            // when the no email checkbow is checked, we generate en email and prefill the field
            this.form.controls.email.patchValue(Helper.generatePetEmailAdress(this.data?.petInfo?.birthdate, this.data?.petInfo?.name));
            // we need to disable the email field and re-enable all the other fields
            // indeed they might have been disabled by the different use cases in listenEmailAndDetectExistingOwner
            this.form.controls.email.disable({ emitEvent: false });
          } else {
            // when the checkbox is unchecked, we must reset and re-enable the email field
            this.form.controls.email.reset();
            this.form.controls.email.enable();
            this.form.markAllAsTouched();
          }
        })
      )
      .subscribe();
  }

  listenEmailAndDetectExistingOwner(): void {
    const petOwnerEmail$ = this.store$.select(selectConsultationPetOwner).pipe(
      filter((petOwner) => !!petOwner),
      map((petOwner) => petOwner.email)
    );
    const emitOnEmailValid = merge(petOwnerEmail$, this.form.controls.email.valueChanges).pipe(
      tap(() => {
        this.form.controls.existingOwner.reset();
        this.form.controls.consent.reset();
        if (this.state$.value === CreatePatientStates.MailExists) {
          this.form.controls.familyName.reset();
          this.form.controls.givenName.reset();
        }
        this.state$.next(CreatePatientStates.Initial);
      }),
      filter(() => this.form.controls.email.valid),
      tap(() => this.scanningMails$.next(true))
    );
    emitOnEmailValid
      .pipe(
        takeUntil(this._destroyed$),
        switchMap((email) =>
          this.vetService.apiContactsByEmail(email).pipe(
            tap((owners: PetOwner[]) => {
              this.scanningMails$.next(false);

              const owner = owners[0];
              if (owner) {
                this.form.patchValue(
                  { existingOwner: owner, familyName: owner?.familyName, givenName: owner?.givenName },
                  { onlySelf: true, emitEvent: false }
                );
                this.state$.next(CreatePatientStates.MailExists);
              } else {
                this.form.controls.givenName.setValidators(Validators.required);
                this.form.controls.familyName.setValidators(Validators.required);
                this.state$.next(CreatePatientStates.MailFree);
              }
            }),
            catchError((error) => {
              this.scanningMails$.next(false);
              this.state$.next(CreatePatientStates.Error);
              this.logger.errorString('Create Patient Popin: Error while verifying mail:', error);
              return EMPTY;
            })
          )
        )
      )
      .subscribe();
  }

  close(): void {
    this._dialogRef.close();
  }
}
