<app-rc-dialog-wrapper (closePopin)="close()">
  <span class="title">{{ title }}</span>
  <div class="container">
    <table aria-describedby="medical-history-table">
      <tbody class="table">
        <tr class="header">
          <th id="medical-history-date">{{ date }}</th>
          <th id="medical-history-creatinine">{{ creatinine }}</th>
          <th id="medical-history-urea">{{ urea }}</th>
          <th id="medical-history-urine">{{ urine }}</th>
        </tr>
        <tr *ngFor="let element of data; let index = index">
          <th [id]="'medical-history-date-value-' + index">{{ element.date | date: 'shortDate' }}</th>
          <th [id]="'medical-history-creatinine-value-' + index">
            {{ element.prediction?.creatinine?.value }} {{ element.prediction?.creatinine?.unit }}
          </th>
          <th [id]="'medical-history-urea-value-' + index">{{ element.prediction?.urea?.value }} {{ element.prediction?.urea?.unit }}</th>
          <th [id]="'medical-history-urine-value-' + index">{{ element.prediction?.urine?.value }}</th>
        </tr>
      </tbody>
    </table>
  </div>
</app-rc-dialog-wrapper>
