import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributeType, FormFields, InputType, InvitationTemplateData } from '@app/core/models';
import { ShareRecommendationData } from '@app/core/models/share-recommendation-data';
import { EmailService } from '@app/core/services';
import { PopinSize } from '@app/shared/utils';
import { EmailPopinType } from '@app/shared/utils/enums/email-popin-type';
import { getInputErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import { getTranslateCivility } from '@app/shared/utils/static-helpers/vet-helper';
import { customEmailValidator } from '@app/shared/validators/email.validator';
import { VetFacade } from '@app/store/vet';
import { environment } from '@env/environment';
import { RCAlertType } from '@rc/ui';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take, tap } from 'rxjs/operators';

export interface EmailPoppinComponentCollaboratorsData {
  emailPopintype: EmailPopinType.Collaborators;
  defaultEmail: string;
}

@Component({
  selector: 'app-email-poppin',
  templateUrl: './email-poppin.component.html',
  styleUrls: ['./email-poppin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailPoppinComponent implements OnInit {
  PopinSize = PopinSize;
  AlertType = RCAlertType;

  contactForm: FormGroup<{ email: FormControl<string> }>;
  contactFields: FormFields;
  submitted = false;
  conditionAccepted = false;
  isRecommendation = true;
  clinic$ = this.vetFacade.clinic$;
  base64ChartImage = new BehaviorSubject<string>('');

  constructor(
    private emailService: EmailService,
    private vetFacade: VetFacade,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EmailPoppinComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ShareRecommendationData | EmailPoppinComponentCollaboratorsData
  ) {}

  /**
   * Life Cycle
   */
  ngOnInit(): void {
    this.isRecommendation = this.data.emailPopintype === EmailPopinType.Recommendation;

    this._setFields();
    this._createForm();
  }

  /**
   * Close modal
   */
  dismiss() {
    this.dialogRef.close(this.submitted);
  }

  checkValue() {
    this.conditionAccepted = !this.conditionAccepted ? true : null;
  }

  emailErrorMessage(): string {
    return this.contactForm.dirty && this.contactForm.touched
      ? getInputErrorMessage(this.contactForm?.controls?.email?.errors, 'form-attribute_email')
      : null;
  }

  /**
   * Submit form
   * Set data depend on type of email
   */
  submitForm(): void {
    const { email } = this.contactForm.getRawValue();
    combineLatest([this.vetFacade.vet$, this.vetFacade.clinic$])
      .pipe(
        take(1),
        tap(([vet, clinic]) => {
          const templateData: InvitationTemplateData = {
            clinic_name: clinic && clinic.legalName ? clinic.legalName : '',
            clinic_id: clinic && clinic.id ? clinic.id : '',
            vet_title: vet && vet.user && vet.user.title ? getTranslateCivility(vet.user.title) : '',
            vet_name: `${vet.user.firstName || ''} ${vet.user.lastName || ''}`,
            url: environment.ciam.redirectCallback,
          };
          this.emailService.sendByEmail(this.data.emailPopintype, email, {
            clinicId: clinic.id,
            data: {
              email,
              data: templateData,
            },
          });
        })
      )
      .subscribe(() => {
        this.submitted = true;
        this.dialogRef.close(this.submitted);
      });
  }

  private _createForm() {
    this.contactForm = this.formBuilder.group({
      email: [this.data.defaultEmail || '', [Validators.required, customEmailValidator]],
    });
  }

  /**
   * Function for visit fields
   */
  private _setFields() {
    this.contactFields = {
      email: {
        id: 'email',
        placeholder: this.isRecommendation ? $localize`:@@form-attribute_owner-email:` : $localize`:@@form-attribute_email:`,
        required: true,
        inputType: InputType.Text,
        type: AttributeType.Email,
        disableAutocomplete: true,
      },
    };
  }
}
