<form class="follow-up" *ngIf="enterWeightForm" [formGroup]="enterWeightForm" (submit)="onSubmitConsultation($event)">
  <div class="follow-up-fields">
    <div class="follow-up-fields-wrapper">
      <div class="follow-up-field follow-up-field--visit-date">
        <ng-container *appLet="dateErrorMessage() as dateError">
          <div class="datepicker-wrapper">
            <label class="datepicker-label">
              <span>{{ 'follow-up_form-label-date' | translateKey }}</span>
            </label>
            <div class="datepicker" [class.error-state]="dateError">
              <input
                formControlName="visitDate"
                [formControl]="$any(enterWeightForm.get('visitDate'))"
                autocomplete="off"
                matInput
                [min]="this.lastConsultation?.visit.visitDateTime"
                [matDatepicker]="picker"
                id="visitDate"
                class="error-input datepicker-input"
                placeholder="{{ 'follow-up_form-label-date' | translateKey }}"
              />
              <app-icon
                [class.error]="dateError"
                [name]="IconName.calendar_small"
                class="datepicker-icon"
                (click)="picker.opened ? picker.close() : picker.open()"
              ></app-icon>
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <span *ngIf="dateError" class="datepicker-error">{{ dateError }}</span>
          </div>
        </ng-container>
      </div>

      <div class="follow-up-field follow-up-field--weight">
        <rc-field
          id="weight"
          formControlName="weight"
          type="number"
          [forceTypeNumber]="true"
          [error]="WeightErrorMessage()"
          autocomplete="off"
          [label]="'form-attribute_pet-weight' | translateKey"
        >
          <ng-container suffix>
            <span class="kilo-label"> {{ weightSuffix$ | async }} </span>
          </ng-container>
        </rc-field>
      </div>
    </div>

    <div class="clear"></div>

    <div class="follow-up-field--information">
      <div class="rc-icon rc-icon--alert follow-up-field--information_icon"></div>
      <span class="follow-up-field--information_label">
        <ng-container i18n="@@follow-up_info-label">Weight should be between</ng-container>
        <span class="kilo-label" *ngIf="!isLoading"> {{ minWeight }} </span>
        <span [class.loading]="isLoading"></span>
        <span class="kilo-label"> {{ weightSuffix$ | async }} </span>
        <ng-container i18n="@@and">and</ng-container>
        <span class="kilo-label" *ngIf="!isLoading"> {{ maxWeight }} </span>
        <span [class.loading]="isLoading"></span>
        <span class="kilo-label"> {{ weightSuffix$ | async }} </span>
      </span>
    </div>
  </div>

  <div class="follow-up-footer">
    <button class="rc-button rc-button--filled-small" id="saveFollowUpChanges" type="submit" [disabled]="enterWeightForm.invalid">
      <span class="rc-button__title" i18n="@@action_save-changes">Save changes</span>
    </button>
  </div>
</form>
