import { VetService } from '@app/core/services/network/vet/vet.service';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { BehaviorSubject, Subject } from 'rxjs';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { getEmailErrorMessage, getPhoneErrorMessage, getRequiredErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import { PetOwnerFormValues } from './pet-owner-form';
import { customEmailValidator } from '@app/shared/validators/email.validator';
import { Helper } from '@app/shared/utils';
import { PhoneNumber } from '@rc/ui';
import { PetOwner } from '@app/core/models';

interface PatientPopinFormValues {
  email: string;
  givenName: string;
  familyName: string;
  telephone: PhoneNumber;
  consent: boolean;
  noEmail: boolean;
  existingOwner: PetOwner | null;
}

@Component({
  selector: 'app-pet-owner-form',
  styleUrls: ['pet-owner-form.component.scss'],
  templateUrl: './pet-owner-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PetOwnerFormComponent implements OnInit, OnDestroy {
  @Input() petBirthdate: Date;
  @Input() petName: string;
  @Input() submitButtonLabel = translateKey('action_create');
  @Input() initialValues?: PetOwnerFormValues | null = null;
  @Input() editMode = false;

  @Output() submitted = new EventEmitter<PetOwnerFormValues>();

  form: IFormGroup<PatientPopinFormValues>;
  emailMessage$ = new BehaviorSubject<string | null>(null);

  private _destroyed$ = new Subject<void>();

  constructor(private vetService: VetService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setupForm();

    this.listenNoEmailCheckbox();
    if (!this.editMode) {
      this.listenEmailAndDetectExistingOwner();
    }
  }

  setupForm(): void {
    this.vetService.currentClinicCountry$
      .pipe(
        take(1),
        tap((countryCode) => {
          const formBuilder: IFormBuilder = new UntypedFormBuilder();

          const phone = this.initialValues?.telephone ? { phone: this.initialValues?.telephone } : { phone: '', countryCode };

          this.form = formBuilder.group<PatientPopinFormValues>({
            givenName: [this.initialValues?.givenName || null, [Validators.required, Validators.maxLength(100)]],
            familyName: [this.initialValues?.familyName || null, [Validators.required, Validators.maxLength(100)]],
            email: [this.initialValues?.email || null, [Validators.required, customEmailValidator]],
            telephone: [phone, [Validators.maxLength(18), Validators.minLength(8)]],
            noEmail: [false],
            consent: [this.initialValues?.consent || false, [Validators.requiredTrue]],
            existingOwner: [(!this.editMode && this.initialValues?.existingOwner) || null],
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  listenNoEmailCheckbox(): void {
    this.form.controls.noEmail.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        tap((checked) => {
          if (checked) {
            // when the no email checkbow is checked, we generate en email and prefill the field
            this.form.controls.email.patchValue(Helper.generatePetEmailAdress(this.petBirthdate, this.petName), {
              onlySelf: true,
              emitEvent: false,
            });
            // we need to disable the email field and re-enable all the other fields
            // indeed they might have been disabled by the different use cases in listenEmailAndDetectExistingOwner
            this.form.controls.email.disable();
            this.form.controls.familyName.enable();
            this.form.controls.givenName.enable();
            this.form.controls.telephone.enable();
            this.emailMessage$.next('');
          } else {
            // when the checkbox is unchecked, we must reset and re-enable the email field
            this.form.controls.email.reset();
            this.form.controls.email.enable();
            this.form.markAllAsTouched();
          }
        })
      )
      .subscribe();
  }

  listenEmailAndDetectExistingOwner(): void {
    const emitOnEmailValid = this.form.controls.email.valueChanges.pipe(
      startWith(this.initialValues?.email),
      tap(() => {
        // when the user updates the email from a detected existing pet owner
        // others fields were disabled so we need to re-enable them
        if (this.form.controls.familyName.disabled) {
          this.form.controls.existingOwner.reset();
          this.form.controls.familyName.enable();
          this.form.controls.telephone.enable();
          this.form.controls.givenName.enable();
          this.emailMessage$.next('');
        }
      }),
      filter(() => this.form.controls.email.valid)
    );
    emitOnEmailValid
      .pipe(
        takeUntil(this._destroyed$),
        switchMap((email) => this.vetService.apiContactsByEmail(email)),
        // the owner is considered to be existing if he has at least one patient
        // we take the owner related to the first patient found (this is buggy and will be challenged)
        filter((owners) => !!owners.length),
        tap((owners) => {
          const owner = owners[0];
          // if the owner exists, we update the form with its values
          // and we disable all the fields except the email
          this.form.patchValue(
            {
              existingOwner: owner,
              givenName: owner.givenName,
              familyName: owner.familyName,
              telephone: { phone: owner.telephone },
            },
            { onlySelf: true, emitEvent: false }
          );
          this.form.controls.familyName.disable();
          this.form.controls.telephone.disable();
          this.form.controls.givenName.disable();
          // we display a message to the user to explain that this owner exists
          this.emailMessage$.next(translateKey('save-new-pet_existing-pet-owner'));
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  requiredErrorMessage(field: keyof PetOwnerFormValues, fieldKeyTrad: string): string {
    return (this.form.controls[field].touched && this.form.controls[field].errors?.required && getRequiredErrorMessage(fieldKeyTrad)) || '';
  }

  phoneErrorMessage(): string {
    return getPhoneErrorMessage(this.form.controls.telephone.errors, 'form-attribute_phone');
  }

  emailErrorMessage(): string {
    return getEmailErrorMessage(this.form.controls.email.errors, 'form-attribute_email');
  }

  submit(): void {
    if (this.form.valid) {
      this.submitted.emit({ ...this.form.getRawValue(), telephone: this.form.getRawValue().telephone?.phone });
    }
  }
}
