<form
  [formGroup]="singleProductForm"
  class="rc-card product-card"
  [class.rc-card--selected]="isSelected(product)"
  [id]="'product-id-' + product.id"
>
  <img class="picture" (click)="showDetailPopin()" [src]="product.image | publicUrl" alt="{{ product.name || '' }}" />
  <p class="category">
    {{ product.productType | translationCode: LocaleContentEnum.ProductType }}
  </p>
  <div class="title-wrapper">
    <h1
      class="rc-title-beta title"
      [matTooltipPosition]="'below'"
      matTooltipHideDelay="1"
      matTooltip="{{ product.name }}"
      #tooltip="matTooltip"
      matTooltipClass="tooltip-arrow-up"
      (click)="showDetailPopin()"
    >
      {{ product.name || '--' }}
    </h1>
  </div>
  <button *ngIf="showSelectButton" class="rc-button detail-button" (click)="showDetailPopin()">
    <span class="rc-button__title rc-button__title--link" id="show-product-detail" i18n="@@action_product-detail">Product details</span>
  </button>
  <div class="packages">
    <div
      class="rc-select"
      [class.package-sk]="!displayPackages"
      [class.product__selector]="displayPackages"
      [class.rc-select]="displayPackages"
      [class.left]="displayPackages"
    >
      <rc-select
        *ngIf="displayPackages"
        [id]="'product-card-select-' + product.id"
        formControlName="packId"
        [customSort]="customSelectSort"
        [items]="packageItems"
        [hideLabel]="true"
      ></rc-select>
      <ng-container *ngIf="!displayPackages && product.packages[0]">
        <span class="product__selector--disabled left">{{ product.packages[0].text }}</span>
        <p *ngIf="showWholesalePrices$ | async" class="price" id="productPrice--{{ product.id }}">
          {{ getWholesalePrice$(product.packages[0].sCode) | async | currency: (clinicCurrency$ | async) }}
        </p>
      </ng-container>
    </div>
    <p *ngIf="showRetailPrices$ | async" class="price" id="productPrice--{{ product.id }}">
      {{ getProductPrice() | currency: (clinicCurrency$ | async) }}
    </p>

    <p
      *ngIf="
        displayPackages &&
        (showWholesalePrices$ | async) &&
        (getWholesalePrice$(singleProductForm?.get('packId')?.value) | async) as wholesalePrice
      "
      class="price"
      id="productPrice--{{ product.id }}"
    >
      {{ wholesalePrice | currency: (clinicCurrency$ | async) }}
      <span>*</span>
    </p>
  </div>

  <ng-container *ngIf="!showSelectButton; else showSelect">
    <button *ngIf="!disableDetailsButton" (click)="showDetailPopin()" class="product__submit-button rc-button rc-button--outline-small">
      <span class="rc-button__title" i18n="@@action_detail">Details</span>
    </button>
  </ng-container>

  <ng-template #showSelect>
    <button
      id="select-product-{{ product.id }}"
      class="rc-button product__submit-button rc-button--filled-small"
      type="submit"
      (click)="didSelectProduct(product)"
      [className]="
        isSelected(product)
          ? 'rc-button product__submit-button rc-button--outline-small'
          : ' rc-button product__submit-button rc-button--filled-small'
      "
    >
      <span *ngIf="!isSelected(product)" class="rc-button__title btn-title">
        {{ selectButtonLabel || 'action_select' | translateKey }}
      </span>
      <span *ngIf="isSelected(product)" class="rc-button__title btn-title" i18n="@@action_deselect"> Deselect </span>
    </button>
  </ng-template>
</form>
