<app-rc-dialog-wrapper (closePopin)="closePopin()">
  <div class="switch-wlp-popin">
    <div class="switch-wlp-popin__content">
      <h3 class="rc-title-gamma">{{ 'WLP_popin_title' | translateKey }}</h3>
      <p class="rc-paragraph">{{ 'WLP_popin_sentence_patient-overweight-create-WLP' | translateKey }}</p>

      <div *ngIf="!dataError" class="weight-circles-container">
        <div class="weight-circle weight-circle--current">
          <div class="circle-title">{{ 'form-attribute_current-weight' | translateKey }}</div>
          <div class="weight-value">{{ currentDataWeight?.measure + currentDataWeight?.measureUnit }}</div>
          <div class="weight-date">{{ currentDataWeight?.weightDate | date: 'shortDate' }}</div>
        </div>

        <div class="wlp-arrow-current">
          <ng-container *ngTemplateOutlet="wlpArrow"></ng-container>
        </div>

        <div class="intermediate-target-container">
          <ng-container *ngIf="!stepPlanNextVisit; else planNextVisitTemplate">
            <div class="weight-circle weight-circle--intermediate-target">
              <div class="circle-info">
                <app-wlp-tooltip></app-wlp-tooltip>
              </div>
              <div class="circle-title">{{ 'WLP-intermediate-target' | translateKey }}</div>
              <div class="weight-value">{{ dataApi?.intermediateVisit?.measure + dataApi?.intermediateVisit?.measureUnit }}</div>
              <div class="target-percent">
                <app-icon class="target-arrow" [name]="iconName.arrow_go"></app-icon>
                {{ diffPercent }}
              </div>
            </div>
          </ng-container>
        </div>

        <div class="wlp-arrow-target">
          <ng-container *ngTemplateOutlet="wlpArrow"></ng-container>
        </div>

        <div class="weight-circle weight-circle--target">
          <div class="circle-title">{{ 'banner_target_weight' | translateKey }}</div>
          <div class="weight-value">{{ dataApi?.targetVisit?.measure + dataApi?.targetVisit?.measureUnit }}</div>
          <div class="weight-date">{{ dataApi?.targetVisit?.weightDate | date: 'shortDate' }}</div>
        </div>
      </div>

      <div *ngIf="dataError">
        {{ 'error_general_title' | translateKey }}
      </div>

      <div class="switch-wlp-popin__footer">
        <div class="rc-button-group">
          <button
            *ngIf="!stepPlanNextVisit; else confirmNextVisitBt"
            class="rc-button rc-button--outline-regular"
            type="button"
            (click)="planNextVisit()"
            id="plan_next_visit_bt"
          >
            <span class="rc-button__title">{{ 'wm_plan_next_visit_pop_in-title' | translateKey }}</span>
          </button>
          <span class="rc-button__separator btn-separator" i18n="@@action_separator">or</span>
          <button class="rc-button" type="button" (click)="shareWithoutWlp()" id="share_without_wlp">
            <span class="rc-button__title rc-button__title--link">{{ 'WLP-popin_share-without-WLP' | translateKey }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-rc-dialog-wrapper>

<ng-template #wlpArrow>
  <svg class="wlp-arrow" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 27a.999.999 0 0 1-.753-1.657l7.488-8.586c.352-.403.352-1.11 0-1.514l-7.489-8.586a.999.999 0 1 1 1.507-1.314l7.49 8.586c1.012 1.161 1.012 2.981 0 4.143l-7.49 8.585A.997.997 0 0 1 12 27"
    ></path>
  </svg>
</ng-template>

<ng-template #planNextVisitTemplate>
  <form [formGroup]="nextVisitForm" autocomplete="off">
    <ng-container *appLet="dateErrorMessage() as error">
      <div class="datepicker-wrapper">
        <label class="datepicker-label">
          <span>{{ 'wm_plan_next_visit_pop_in-title' | translateKey }}</span>
        </label>
        <div class="datepicker" [class.error-state]="error">
          <input
            formControlName="visitDate"
            autocomplete="off"
            [min]="currentDataWeight.weightDate"
            matInput
            [matDatepicker]="picker"
            id="datepicker-input"
            class="error-input"
            placeholder="{{ 'wm_plan_next_visit_pop_in-title' | translateKey }}"
          />
          <app-icon
            [class.error]="error"
            [name]="iconName.calendar_small"
            class="datepicker-icon"
            (click)="picker.opened ? picker.close() : picker.open()"
          ></app-icon>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <span *ngIf="error" class="datepicker-error">{{ error }}</span>
        <span *ngIf="!error" class="datepicker-footer-label">{{
          'wm_plan_next_visit_pop_in-text'
            | translateKey
              : {
                  value: 2
                }
        }}</span>
      </div>
    </ng-container>
  </form>
</ng-template>

<ng-template #confirmNextVisitBt>
  <button (click)="confirmNextVisit()" [disabled]="nextVisitForm.invalid" class="rc-button rc-button--filled-small mypopin__button">
    <span class="rc-button__title">{{ 'action_continue' | translateKey }}</span>
  </button>
</ng-template>
