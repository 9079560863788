import { createFeatureSelector, createSelector } from '@ngrx/store';
import { smartRecoFeatureKey } from '../smart-reco-feature-key';
import { SmartRecoState } from '../smart-reco.state';
import { CriteriaType } from '@app/shared/utils/enums/criteria-type.enum';
import { filterAndFormatCoverageCriterias, isIndividualis, ProductPillar, ProductType } from '@app/shared/utils';
import { selectSmartRecoDeprecatedPetInfo } from '@app/pages/smart-reco/store/selectors/smart-reco-steps.selectors';
import { Product } from '@app/core/models';
import { selectAllProductsSuccess } from '@app/store/products/products.selectors';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

/**
 * Number of products we want to display
 */
const DRY_PRODUCTS_MAX_LENGTH = 3;
const WET_PRODUCTS_MAX_LENGTH = 5;

const selectSmartReco = createFeatureSelector<SmartRecoState>(smartRecoFeatureKey);

export const selectSmartRecoProductsWithoutFilterCriteria = createSelector(selectSmartReco, (state) => {
  return state.recommendation?.products?.slice(0, DRY_PRODUCTS_MAX_LENGTH);
});

export const selectRecoHasIndividualis = (currentSystemPreferenceCode: SystemPreferenceCode) =>
  createSelector(selectSmartRecoProductsWithoutFilterCriteria, selectSmartRecoDeprecatedPetInfo, (products, petInfo) => {
    return isIndividualis(products, petInfo, currentSystemPreferenceCode);
  });

export const selectSmartRecoProducts = createSelector(selectSmartReco, selectSmartRecoDeprecatedPetInfo, (state, petInfo) => {
  return state.recommendation?.products
    ?.map((product) => ({
      ...product,
      coverage: filterAndFormatCoverageCriterias(product.coverage, petInfo),
    }))
    ?.slice(0, DRY_PRODUCTS_MAX_LENGTH);
});

export const selectIsIndividualisSelected = createSelector(selectSmartReco, (state) => state.isIndividualisSelected);

/**
 * Emits true if the recommendation is accurate enough,
 * meaning the covergage is good enough
 */
export const selectSmartRecoAccuracy = createSelector(selectSmartRecoProducts, (productsRecommendation) =>
  productsRecommendation?.every((product) => {
    const idealPmeans = 0.65;
    const diseasesCriterias = product?.coverage?.criterias?.filter((criteria) => criteria.type === CriteriaType.Diseases) || [];
    const pmeansCriteriasIsOk = diseasesCriterias.every((diseaseCriteria) => diseaseCriteria.pmean >= idealPmeans);
    const pmeansProductsIsOk = product.coverage.pmean >= idealPmeans;
    return pmeansProductsIsOk && pmeansCriteriasIsOk;
  })
);

export const selectSmartRecoProductsCostPerDay = createSelector(selectSmartReco, (state) => state.productsCostPerDay);

export const selectSmartRecoNotRecommendedCostPerDay = createSelector(selectSmartReco, (state) => state.notRecommendedProductCostPerDay);
/**
 * Selects catalog products
 */
export const selectSmartRecoCatalogProductsAutocomplete = createSelector(selectSmartReco, (state) =>
  state.catalogProducts.map((product) => ({
    label: product.name,
    value: product,
  }))
);
/**
 * Selects not recommended product
 */
export const selectSmartRecoNotRecommendedProduct = createSelector(selectSmartReco, (state) => state.notRecommendedProduct);

export const selectSmartRecoProductRecommendationErrorType = createSelector(selectSmartReco, (state) => state.recommendationErrorType);
export const selectSmartRecoProductRecommendationRetryCount = createSelector(selectSmartReco, (state) => state.retryCount);

export const selectSmartRecoProductRecommendationSessionIdError = createSelector(
  selectSmartReco,
  (state) => state.recommendationSessionIdError
);

/**
 * Selects recommended and not recommended products
 */
export const selectSmartRecoProductsAndNotRecommendedProduct = createSelector(
  selectSmartRecoProducts,
  selectSmartRecoNotRecommendedProduct,
  (recommendedProducts, notRecommendedProduct) =>
    notRecommendedProduct ? [...recommendedProducts, notRecommendedProduct] : recommendedProducts
);

export const selectRelatedWetProducts = (dryProduct: Product) =>
  createSelector(selectAllProductsSuccess, (allProductList: Product[]) => {
    return allProductList
      .filter(() => !!dryProduct?.territory)
      .filter((product: Product) => {
        return dryProduct?.relatedProductsCode?.includes(product.id) ?? [];
      })
      .filter((product: Product) => product.productType === ProductType.Wet)
      .filter((product: Product) => {
        const pillars: ProductPillar[] =
          dryProduct.territory == 'birth and growth' ? [ProductPillar.VET, ProductPillar.SPTRETAIL] : [ProductPillar.VET];
        return pillars.includes(product.pillar);
      })
      .slice(0, WET_PRODUCTS_MAX_LENGTH);
  });
