<app-rc-dialog-wrapper (closePopin)="close()">
  <div class="create-po-popin__flex-container">
    <div class="create-po-popin__left">
      <div class="create-po-popin__content">
        <h3 class="rc-title-gamma rc-title-gamma--thin">{{ 'create-po_create-profile' | translateKey }}</h3>
        <p class="create-po-popin__paragraph rc-paragraph">{{ 'share-reco_vet-source-paragraph-new-po' | translateKey }}</p>
      </div>
    </div>

    <div class="create-po-popin__right">
      <form class="create-po-popin__form rc-dialog-form" [formGroup]="form" class="" (submit)="submit()">
        <rc-field
          id="create-po-popin-field-email"
          formControlName="email"
          class="rc-dialog-email-input"
          [readonly]="true"
          [label]="'form-attribute_owner-email' | translateKey"
        >
        </rc-field>
        <rc-field
          id="create-po-popin-field-givenName"
          formControlName="givenName"
          [label]="'form-attribute_owner-first-name' | translateKey"
        >
        </rc-field>
        <rc-field
          id="create-po-popin-field-familyName"
          formControlName="familyName"
          [label]="'form-attribute_owner-last-name' | translateKey"
        >
        </rc-field>
        <div id="create-po-popin-field-telephone">
          <rc-phone-field
            class="field-wrapper phone-wrapper field-wrapper-right"
            formControlName="telephone"
            [label]="'form-attribute_phone' | translateKey"
            [error]="phoneErrorMessage()"
          >
          </rc-phone-field>
        </div>
        <div class="consent">
          <app-checkbox
            id="confirmConsent"
            formControlName="consent"
            [multiLine]="true"
            [label]="'create-patient_legal' | translateKey"
          ></app-checkbox>
        </div>
        <div class="rc-dialog-form__action">
          <div class="rc-button-group">
            <button class="rc-button" type="button" (click)="close()">
              <span class="rc-button__title--link" i18n="@@action_cancel">Cancel</span>
            </button>
            <ng-container>
              <span class="rc-button__separator" i18n="@@or-key">or</span>
              <button class="rc-button rc-button--filled-small" id="createPO" [disabled]="form.invalid" type="submit">
                <span class="rc-button__title" i18n="@@action_save-and-send">Save & Send</span>
              </button>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  </div>
</app-rc-dialog-wrapper>
