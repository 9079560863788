import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RetailChannel } from '@app/shared/utils';
import { generateQrCode, getPurchaseLink } from '@app/shared/utils/static-helpers/emails-helper';
import ProductHelper from '@app/shared/utils/static-helpers/product-helper';
import { ProductsFacade } from '@app/store/products/products.facade';
import { VetFacade } from '@app/store/vet';
import { combineLatest, from, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ShareRecommendationFacade } from '@app/store/share-recommendation';

@Component({
  selector: 'app-purchase-products-popin',
  templateUrl: './purchase-products-popin.component.html',
  styleUrls: ['./purchase-products-popin.component.scss'],
})
export class PurchaseProductsPopinComponent {
  public products$ = this.productsFacade.selectedProducts$;
  public conversionConfiguration$ = this.vetFacade.conversionConfiguration$;

  public poLetterQrCode$ = combineLatest([
    this.vetFacade.vet$,
    this.vetFacade.clinic$,
    this.conversionConfiguration$,
    this.productsFacade.selectedPackIds$,
    this.products$,
  ]).pipe(
    switchMap(([vet, clinic, conversionConfiguration, selectedPackIds, products]) => {
      // no purchase for MUF products
      const filteredProducts = products.filter((product) => product.retailChannel !== RetailChannel.MULTIFUNCTION);

      const nutritionDataProducts = filteredProducts.map((filteredProduct) =>
        ProductHelper.formatProductIntoNutritionDataProduct(filteredProduct, selectedPackIds)
      );

      const ids = nutritionDataProducts.map((product) => product.productDetail.id);
      this.shareRecommendationFacade.requestGenerateRandomRecommendationId(ids);
      return this.shareRecommendationFacade.selectRecommendationId$(ids).pipe(
        filter((randomId) => !!randomId),
        map((randomId) => {
          return {
            randomId,
            nutritionDataProducts,
            vet,
            clinic,
            conversionConfiguration,
          };
        })
      );
    }),
    switchMap(({ vet, clinic, conversionConfiguration, nutritionDataProducts, randomId }) => {
      const purchaseLink = getPurchaseLink(
        true,
        conversionConfiguration,
        nutritionDataProducts,
        clinic,
        vet,
        false,
        this.data.context,
        randomId
      );
      return purchaseLink ? from(generateQrCode(purchaseLink)) : of('');
    })
  );

  constructor(
    public dialogRef: MatDialogRef<PurchaseProductsPopinComponent>,
    private vetFacade: VetFacade,
    private productsFacade: ProductsFacade,
    private shareRecommendationFacade: ShareRecommendationFacade,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  /**
   * Close modal
   */
  dismiss(): void {
    this.dialogRef.close();
  }
}
