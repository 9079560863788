<app-rc-dialog-wrapper (closePopin)="closePopin()">
  <div class="address-form-wrapper">
    <div class="wrapper-left">
      <h3 class="title">{{ title }}</h3>
      <p class="description">
        {{ subtitle }}
      </p>
    </div>
    <form data-cs-mask [formGroup]="form" (ngSubmit)="submit($event)" class="wrapper-form">
      <div *ngIf="data.deliveryType === DeliveryChoice.AtClinic" class="wrapper-input">
        <rc-field
          id="address-form-name-input"
          formControlName="name"
          type="text"
          [label]="'form-attribute_legal-name' | translateKey"
          [error]="getFieldErrorMessage('name', 'form-attribute_legal-name')"
        ></rc-field>
      </div>
      <div *ngIf="data.deliveryType === DeliveryChoice.AtPetOwnerPlace" class="wrapper-input">
        <rc-field
          id="address-form-givenName-input"
          formControlName="givenName"
          type="text"
          [label]="'form-attribute_first-name' | translateKey"
          [error]="getFieldErrorMessage('givenName', 'form-attribute_first-name')"
        ></rc-field>
      </div>
      <div *ngIf="data.deliveryType === DeliveryChoice.AtPetOwnerPlace" class="wrapper-input">
        <rc-field
          id="address-form-familyName-input"
          formControlName="familyName"
          type="text"
          [label]="'form-attribute_last-name' | translateKey"
          [error]="getFieldErrorMessage('familyName', 'form-attribute_last-name')"
        ></rc-field>
      </div>
      <div class="wrapper-input">
        <rc-phone-field
          id="address-form-phone-input"
          formControlName="phone"
          [label]="'form-attribute_phone' | translateKey"
          [error]="getFieldErrorMessage('phone', 'form-attribute_phone')"
        ></rc-phone-field>
      </div>
      <div class="wrapper-input">
        <rc-field
          id="address-form-streetAddress-input"
          formControlName="streetAddress"
          type="text"
          [label]="'form-attribute_address' | translateKey"
          [error]="getFieldErrorMessage('streetAddress', 'form-attribute_address')"
        ></rc-field>
      </div>
      <div class="wrapper-input">
        <rc-field
          id="address-form-additionalInformation-input"
          formControlName="additionalInformation"
          type="text"
          [label]="'form-attribute_complementary-address' | translateKey"
          [error]="getFieldErrorMessage('additionalInformation', 'form-attribute_complementary-address')"
        ></rc-field>
      </div>
      <div class="wrapper-input">
        <rc-field
          id="address-form-postalCode-input"
          formControlName="postalCode"
          type="text"
          [label]="'form-attribute_zip-code' | translateKey"
          [error]="getFieldErrorMessage('postalCode', 'form-attribute_zip-code')"
        ></rc-field>
      </div>
      <div class="wrapper-input">
        <rc-field
          id="address-form-addressCity-input"
          formControlName="addressCity"
          type="text"
          [label]="'form-attribute_city' | translateKey"
          [error]="getFieldErrorMessage('addressCity', 'form-attribute_city')"
        ></rc-field>
      </div>
      <div class="wrapper-input">
        <rc-select
          [id]="'address-form-countryCode-input'"
          formControlName="countryCode"
          [label]="'form-attribute_country' | translateKey"
          [items]="countries$ | async"
          [error]="getFieldErrorMessage('countryCode', 'form-attribute_country')"
        ></rc-select>
      </div>

      <div class="wrapper-form-actions">
        <button (click)="closePopin()" class="rc-button rc-button--link">
          <span class="rc-button__title rc-button__title--link" i18n="@@action_cancel">{{ 'cancel' | titlecase }}</span>
        </button>
        <span class="rc-button__separator" i18n="@@action_separator">or</span>
        <button id="address-form-submit" [disabled]="form.invalid" type="submit" class="rc-button rc-button--filled-small">
          <span class="rc-button__title">{{ submitLabel }}</span>
        </button>
      </div>
    </form>
  </div>
</app-rc-dialog-wrapper>
