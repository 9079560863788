import { Injectable } from '@angular/core';
import { VetFacade } from '@app/store/vet';
import { VetUser } from '@app/core/models';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { FileExtension } from '@app/pages/support-page/enums/file-extension';

@Injectable({
  providedIn: 'root',
})
export class SupportPageService {
  private readonly vet$ = this.vetFacade.vet$;

  private readonly XRAY_FAQ_TRANSLATIONS = ['DE', 'EN', 'ES', 'FR', 'NL', 'RU', 'TH'];
  private readonly XRAY_FAQ_TRANSLATION_FALLBACK = 'EN';
  private readonly GROWTH_CHART_FAQ_TRANSLATIONS = ['EN'];
  private readonly GROWTH_CHART_FAQ_TRANSLATION_FALLBACK = 'EN';

  constructor(private vetFacade: VetFacade) {}

  getXrayFAQLink$() {
    return this.vet$.pipe(map((vet) => this.getXrayFAQLink(vet.user)));
  }
  getCompleteGrowthChartFAQLink$() {
    return this.vet$.pipe(map((vet) => this.getCompleteGrowthChartFAQLink(vet.user)));
  }
  getKittensGrowthChartFAQLink$() {
    return this.vet$.pipe(map((vet) => this.getKittensGrowthChartFAQLink(vet.user)));
  }
  getPuppiesGrowthChartFAQLink$() {
    return this.vet$.pipe(map((vet) => this.getPuppiesGrowthChartFAQLink(vet.user)));
  }
  getClientUseGrowthChartFAQLink$() {
    return this.vet$.pipe(map((vet) => this.getClientUseGrowthChartFAQLink(vet.user)));
  }

  private getXrayFAQLink(vetUser: VetUser): string {
    return this.getFAQLink(
      vetUser,
      this.XRAY_FAQ_TRANSLATIONS,
      this.XRAY_FAQ_TRANSLATION_FALLBACK,
      'xrayFAQ',
      'XRay_FAQ',
      FileExtension.PDF
    );
  }

  private getCompleteGrowthChartFAQLink(vetUser: VetUser): string {
    return this.getFAQLink(
      vetUser,
      this.GROWTH_CHART_FAQ_TRANSLATIONS,
      this.GROWTH_CHART_FAQ_TRANSLATION_FALLBACK,
      'growth_chart_FAQ/complete',
      'Growth_Chart_Complete_FAQ',
      FileExtension.PDF
    );
  }
  private getKittensGrowthChartFAQLink(vetUser: VetUser): string {
    return this.getFAQLink(
      vetUser,
      this.GROWTH_CHART_FAQ_TRANSLATIONS,
      this.GROWTH_CHART_FAQ_TRANSLATION_FALLBACK,
      'growth_chart_FAQ/kittens',
      'Growth_Chart_Kittens_FAQ',
      FileExtension.PDF
    );
  }
  private getPuppiesGrowthChartFAQLink(vetUser: VetUser): string {
    return this.getFAQLink(
      vetUser,
      this.GROWTH_CHART_FAQ_TRANSLATIONS,
      this.GROWTH_CHART_FAQ_TRANSLATION_FALLBACK,
      'growth_chart_FAQ/puppies',
      'Growth_Chart_Puppies_FAQ',
      FileExtension.PDF
    );
  }

  private getClientUseGrowthChartFAQLink(vetUser: VetUser): string {
    return this.getFAQLink(
      vetUser,
      this.GROWTH_CHART_FAQ_TRANSLATIONS,
      this.GROWTH_CHART_FAQ_TRANSLATION_FALLBACK,
      'growth_chart_FAQ/client_use',
      'Growth_Chart_Client_Use_FAQ',
      FileExtension.PDF
    );
  }

  private getFAQLink(
    vetUser: VetUser,
    availableTranslations: string[],
    fallbackTranslation: string,
    folderName: string,
    fileName: string,
    fileExtension: FileExtension
  ): string {
    const userPreferredLanguageCode = vetUser?.preferredLanguage?.split('-')?.at(-1);
    const languageCode = availableTranslations.includes(userPreferredLanguageCode) ? userPreferredLanguageCode : fallbackTranslation;
    return `${environment.blobUrl}/${folderName}/${fileName}_${languageCode}.${fileExtension}?${environment.blobQueryParams}`;
  }
}
