import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopinSize } from '@app/shared/utils';
import { ActionsEnum } from '@app/shared/utils/enums/actions.enum';

@Component({
  selector: 'app-combine-products-popin',
  templateUrl: './combine-products-popin.component.html',
  styleUrls: ['./combine-products-popin.component.scss'],
})
export class CombineProductsPopinComponent {
  DialogSize = PopinSize;
  product_count: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _dialogRef: MatDialogRef<CombineProductsPopinComponent>) {
    if (data && data.wet_count_product) {
      this.product_count = data.wet_count_product;
    }
  }

  cancel() {
    this._dialogRef.close({
      button: ActionsEnum.no,
    });
  }

  goForMixFeeding() {
    this._dialogRef.close({
      button: ActionsEnum.yes,
    });
  }
}
