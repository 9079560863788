<ng-container *ngIf="nutrients?.length > 0">
  <section *ngIf="!isMobile" class="nutrient-list">
    <header class="nutrient-list__header">
      <p class="nutrient-list__title">{{ nutrientListTitle }}</p>
      <div class="nutrient-list__bar-legend">
        <span class="nutrient-list__bar-legend__item">
          <app-icon class="nutrient-list__icon" [name]="IconName.minus"></app-icon>
        </span>
        <span class="nutrient-list__bar-legend__item">
          <app-icon class="nutrient-list__icon" [name]="IconName.plus"></app-icon>
        </span>
      </div>
      <div class="nutrient-list__tags-legend">
        <span class="nutrient-list__tags-legend__item">
          <span>{{ 'sr-xray_column_title_Main_influencers' | translateKey }}</span>
          <span
            class="nutrient-list__info-icon"
            [matTooltipPosition]="'above'"
            matTooltipHideDelay="1"
            matTooltip="{{ 'sr-xray_main_influencers_popin' | translateKey }}"
            matTooltipClass="tooltip-arrow-down-light"
            #tooltip="matTooltip"
          >
            <app-icon class="nutrient-list__icon" [name]="IconName.info"></app-icon>
          </span>
        </span>
      </div>
    </header>
    <div class="nutrient-list__list-container">
      <div class="nutrient-list__item" *ngFor="let nutrient of nutrients">
        <div class="nutrient-list__item__left">
          <p class="nutrient-list__label">
            {{ getNutrientLabel(nutrient.nutrient.id, nutrientType) }}
            <span class="nutrient-list__label__unit">{{ getNutrientUnit(nutrient.nutrient.unit) }}</span>
          </p>
          <div class="nutrient-list__nutrient-bar-block">
            <p class="nutrient-list__nutrient-bar-block__value-left">{{ hideCutOff ? null : nutrient.cutOff.low }}</p>
            <app-nutrients-bar class="nutrient-list__nutrient-bar" [id]="'desktop'" [nutrients]="nutrient"></app-nutrients-bar>
            <p class="nutrient-list__nutrient-bar-block__value-right">{{ hideCutOff ? null : nutrient.cutOff.high }}</p>
          </div>
        </div>
        <div class="nutrient-list__tag-list">
          <div *ngFor="let influencer of nutrient.influencers" class="nutrient-list__tag-item">
            <ng-container *appLet="getInfluencerLabel(influencer) as influencerLabel">
              <span
                class="nutrient-list__info-icon"
                [matTooltipPosition]="'above'"
                matTooltipHideDelay="1"
                matTooltip="{{ 'description-criteria_consult' | translateKey }} {{ influencerLabel }} "
                matTooltipClass="tooltip-arrow-down"
                #tooltip="matTooltip"
              >
                <rc-tag
                  *ngIf="!!influencerLabel"
                  [size]="RCTagSize.XS"
                  [text]="influencerLabel"
                  [isInteractive]="true"
                  (click)="showInfluencerDescriptionPopin(influencer)"
                ></rc-tag>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--mobile display-->

  <section *ngIf="isMobile" class="nutrient-list">
    <p class="nutrient-list__title__mobile">{{ nutrientListTitle }}</p>
    <div class="nutrient-list__list-container__mobile">
      <div class="nutrient-list__item-mobile" *ngFor="let nutrient of nutrients">
        <p class="nutrient-list__label-mobile">
          {{ getNutrientLabel(nutrient.nutrient.id, nutrientType) }}
          <span class="nutrient-list__label__unit">{{ getNutrientUnit(nutrient.nutrient.unit) }}</span>
        </p>
        <div class="nutrient-list__nutrient-bar-wrapper-mobile">
          <div class="nutrient-list__nutrient-bar-mobile">
            <div class="nutrient-list__bar-legend-mobile">
              <span>
                <app-icon class="nutrient-list__icon" [name]="IconName.minus"></app-icon>
              </span>
              <span>
                <app-icon class="nutrient-list__icon" [name]="IconName.plus"></app-icon>
              </span>
            </div>
            <div class="nutrient-list__nutrient-bar-block">
              <p class="nutrient-list__nutrient-bar-block__value-left">{{ hideCutOff ? null : nutrient.cutOff.low }}</p>
              <app-nutrients-bar [id]="'mobile'" [nutrients]="nutrient"></app-nutrients-bar>
              <p class="nutrient-list__nutrient-bar-block__value-right">{{ hideCutOff ? null : nutrient.cutOff.high }}</p>
            </div>
          </div>
        </div>

        <div class="nutrient-list__tags-legend-mobile">
          <span>
            {{ 'sr-xray_column_title_Main_influencers' | translateKey }}
          </span>
        </div>
        <div class="nutrient-list__tag-list-mobile">
          <div *ngFor="let influencer of nutrient.influencers" class="nutrient-list__tag-item">
            <ng-container *appLet="getInfluencerLabel(influencer) as influencerLabel">
              <rc-tag *ngIf="!!influencerLabel" [size]="RCTagSize.XS" [text]="influencerLabel"></rc-tag>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
