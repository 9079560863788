import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  AlertNotification,
  Breed,
  LifestageInfo,
  mixedBreedSizesOrder,
  PetProfileFormValueKeys,
  PetProfileFormValues,
  PET_PROFILE_NOTIFICATION_ID,
} from '@app/core/models';
import { PetProfileComponentFormValues } from '@app/shared/components/pet-profile-form/pet-profile-form.component';
import { lifestageTypeTranslationCodes } from '@app/shared/data';
import { GenderCode, Helper, LifestageType, ReproductionStatusCode, SpeciesCode, Tool } from '@app/shared/utils';
import { ActivityCode } from '@app/shared/utils/enums/activity-code.enum';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { RCAlertType, RCAutocompleteItem, RCSelectItem } from '@rc/ui';
import { RCSelectorsItem } from '@rc/ui/lib/selectors/rc-selectors';
import { getMUFAvailabilityError, getRDAvailabilityError, getWLPAvailabilityError, getWLPAvailabilityWarning } from './patient-helper';
import { Sterilization } from '../types/sterilization.enum';

export function getPlaceholders(): Record<string, string> {
  return {
    [PetProfileFormValueKeys.NAME]: translateKey('form-attribute_name'),
    [PetProfileFormValueKeys.SPECIES_CODE]: translateKey('form-attribute_species'),
    [PetProfileFormValueKeys.GENDER]: translateKey('form-attribute_gender'),
    [PetProfileFormValueKeys.BREED]: translateKey('form-attribute_breed'),
    [PetProfileFormValueKeys.MIXED]: translateKey('form-attribute_select-unknown-or-mixed'),
    [PetProfileFormValueKeys.BIRTHDATE]: translateKey('form-attribute_birth-date'),
    [PetProfileFormValueKeys.NEUTERED]: translateKey('form-attribute_neutered'),
    [PetProfileFormValueKeys.NEUTERED_TODAY]: translateKey('form-attribute_sterilization-today'),
    [PetProfileFormValueKeys.STERILIZATION_TYPE]: translateKey('form-attribute_surgery-type'),
    [PetProfileFormValueKeys.REPRODUCTION_STATUS]: `${translateKey('form-attribute_reproduction-status')} ${translateKey(
      'form-attribute_optional'
    )}`,
    [PetProfileFormValueKeys.PET_ACTIVITY]: translateKey('form-attribute_select-activity'),
  };
}

export function getGenderItems(): RCSelectorsItem[] | RCSelectItem[] {
  return [
    {
      label: $localize`:@@gender-male:`,
      value: GenderCode.Male,
    },
    {
      label: $localize`:@@gender-female:`,
      value: GenderCode.Female,
    },
  ];
}

export function getActivityItems(): RCSelectItem[] {
  return [
    {
      label: $localize`:@@activity-low:`,
      value: ActivityCode.Low,
    },
    {
      label: $localize`:@@activity-moderate:`,
      value: ActivityCode.Moderate,
    },
    {
      label: $localize`:@@activity-high:`,
      value: ActivityCode.High,
    },
  ];
}

export function isLifestageAdult(lifestageType?: LifestageType): boolean {
  return !!(lifestageType && Helper.OLD_LIFESTAGES.includes(lifestageType));
}

export function sortMixedBreeds(breed1: RCAutocompleteItem<string>, breed2: RCAutocompleteItem<string>): number {
  const order = mixedBreedSizesOrder;
  return order.indexOf(breed1.value) - order.indexOf(breed2.value);
}

export function formatAndFilterBreeds(breeds: Breed[], speciesCode?: SpeciesCode, mixed?: boolean): RCAutocompleteItem<string>[] {
  return breeds
    .filter((breed) => (!speciesCode || speciesCode === breed.speciesCode) && (!mixed || Helper.isMixed(breed)))
    .map((b) => ({
      label: b.localName,
      value: b.breedCode,
      id: b.breedCode,
    }));
}

export function getMessagesToEmit(
  tool: Tool,
  {
    lifestage,
    reproductionStatus,
    speciesCode,
    birthDate,
  }: {
    lifestage: LifestageType | null;
    reproductionStatus: ReproductionStatusCode | null;
    speciesCode?: SpeciesCode | null;
    birthDate?: Date | null;
  }
): { messages: AlertNotification[]; messageIdsToRemove: PET_PROFILE_NOTIFICATION_ID[]; isBlocking: boolean } {
  const messages: AlertNotification[] = [];
  const messageIdsToRemove: PET_PROFILE_NOTIFICATION_ID[] = [];

  switch (tool) {
    case Tool.WeightManagement: {
      const error = lifestage ? getWLPAvailabilityError(lifestage) : '';
      const warning = reproductionStatus ? getWLPAvailabilityWarning(reproductionStatus) : '';
      if (error) {
        messages.push({ message: error, alertType: RCAlertType.ERROR, id: PET_PROFILE_NOTIFICATION_ID.WM_LIFESTAGE });
      } else {
        messageIdsToRemove.push(PET_PROFILE_NOTIFICATION_ID.WM_LIFESTAGE);
      }
      if (warning) {
        messages.push({
          message: warning,
          alertType: RCAlertType.WARNING,
          id: PET_PROFILE_NOTIFICATION_ID.WM_REPRODUCTION_STATUS,
        });
      } else {
        messageIdsToRemove.push(PET_PROFILE_NOTIFICATION_ID.WM_REPRODUCTION_STATUS);
      }
      break;
    }
    case Tool.RenalDetect: {
      const error = speciesCode && birthDate ? getRDAvailabilityError(speciesCode, birthDate) : '';
      if (error) {
        messages.push({
          message: error,
          alertType: RCAlertType.ERROR,
          id: PET_PROFILE_NOTIFICATION_ID.RD_SPECIE_BIRTHDATE,
        });
      } else {
        messageIdsToRemove.push(PET_PROFILE_NOTIFICATION_ID.RD_SPECIE_BIRTHDATE);
      }
      break;
    }
    case Tool.PersonalizedBag: {
      const error = speciesCode && birthDate ? getMUFAvailabilityError(lifestage) : '';
      if (error) {
        messages.push({
          message: error,
          alertType: RCAlertType.ERROR,
          id: PET_PROFILE_NOTIFICATION_ID.MUF_PB_SPECIE_BIRTHDATE,
        });
      } else {
        messageIdsToRemove.push(PET_PROFILE_NOTIFICATION_ID.MUF_PB_SPECIE_BIRTHDATE);
      }
      break;
    }
    default:
      break;
  }

  return { messages, messageIdsToRemove, isBlocking: !!messages.find((item) => item.alertType === RCAlertType.ERROR) };
}

export function getAdditionalLifestageInfo(lifestage?: LifestageType): LifestageInfo {
  if (!lifestage) {
    return { code: null, message: null, error: translateKey('label_life-stage-msg-error') };
  } else if (lifestage === LifestageType.NotAvailable) {
    return { code: null, message: null, error: translateKey('error_lifetsage-not-available') };
  } else {
    const lifestageTranslation = lifestageTypeTranslationCodes.find((item) => lifestage === item.code);
    return {
      code: lifestage,
      message: `${translateKey('label_life-stage-msg')} ${translateKey(lifestageTranslation?.value) || ''}`,
      error: null,
    };
  }
}

export function filterFormValues(values: PetProfileComponentFormValues): PetProfileFormValues {
  const isLifesageAdult = isLifestageAdult(values.lifestage);
  const neutered = values.neutered === 'true';
  const neuteredToday = neutered ? values.neuteredToday === 'true' : null;
  return {
    mixed: values.mixed === 'true',
    neutered,
    neuteredToday,
    sterilizationType: neuteredToday ? (values.sterilizationType as Sterilization) ?? Sterilization.CASTRATION : null,
    reproductionStatus: values.gender !== GenderCode.Male && isLifesageAdult ? values.reproductionStatus : null,
    name: values.name,
    gender: values.gender,
    speciesCode: values.speciesCode,
    breed: values.breed,
    lifestage: values.lifestage,
    birthdate: values.unknownBirthdate ? Helper.getBirthdateFromAge(values.ageCounter, values.ageUnit) : values.birthdate,
  };
}

export const genderValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }
  return control.value.toString() === GenderCode.Unknown ? { gender: { value: control.value } } : null;
};
