/**
 *
 * @license Royal Canin
 * @author: Amar YASSA <amar.yassa@royalcanin.com>
 */

import { Component, Inject, EventEmitter, OnDestroy, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopinButtonActionEnum, ExitPopinInfoData, PopinButtonStatus, PopinButtonStyle } from '@app/core/models';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IconName } from '@app/shared/utils/icon/icons';

@Component({
  selector: 'app-rc-exit-popin',
  templateUrl: './rc-exit-popin.component.html',
  styleUrls: ['./rc-exit-popin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RcExitPopinComponent implements OnDestroy, OnInit {
  public readonly PopinButtonActionEnum = PopinButtonActionEnum;
  public readonly PopinButtonStatus = PopinButtonStatus;
  public readonly PopinButtonStyle = PopinButtonStyle;
  public readonly IconName = IconName;
  public readonly buttonsClass = {
    [PopinButtonStyle.PRIMARY]: `rc-button rc-button--filled-small`,
    [PopinButtonStyle.OUTLINE]: `rc-button rc-button--outline-small`,
    [PopinButtonStyle.LINK]: `rc-button`,
  };
  public buttons: [PopinButtonActionEnum.PRIMARY?, PopinButtonActionEnum.SECONDARY?] = [];
  public clicked = new EventEmitter<PopinButtonActionEnum>();
  private _destroyed$ = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<RcExitPopinComponent>, @Inject(MAT_DIALOG_DATA) public data: ExitPopinInfoData) {}

  public ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.handleButtonsOrder();
    this.listenOnBackdropClick();
  }

  public ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onAction(buttonAction: PopinButtonActionEnum): void {
    if (buttonAction === PopinButtonActionEnum.PRIMARY || buttonAction === PopinButtonActionEnum.SECONDARY) {
      if (this.data?.buttons[buttonAction].status === PopinButtonStatus.DEFAULT) {
        this.clicked.emit(buttonAction);
        if (this.data?.buttons[buttonAction]?.autoCloseAfterClick) {
          this.dialogRef.close(buttonAction);
        }
      }
    } else {
      this.clicked.emit(buttonAction);
      if (this.data?.buttons[buttonAction]?.autoCloseAfterClick) {
        this.dialogRef.close(buttonAction);
      }
    }
  }

  public updateButtonStatus(button: PopinButtonActionEnum.PRIMARY | PopinButtonActionEnum.SECONDARY, status: PopinButtonStatus): void {
    if (this.data?.buttons[button]?.status) this.data.buttons[button].status = status;
  }

  private handleButtonsOrder() {
    const buttonsArray = this.data?.inverseButtons
      ? [PopinButtonActionEnum.SECONDARY, PopinButtonActionEnum.PRIMARY]
      : [PopinButtonActionEnum.PRIMARY, PopinButtonActionEnum.SECONDARY];

    buttonsArray.forEach((button: PopinButtonActionEnum.PRIMARY | PopinButtonActionEnum.SECONDARY) => {
      if (this.data.buttons && this.data.buttons[button]) this.buttons.push(button);
    });
  }

  private listenOnBackdropClick() {
    this.dialogRef
      .backdropClick()
      .pipe(
        takeUntil(this._destroyed$),
        tap(() => this.onAction(PopinButtonActionEnum.BACKDROP))
      )
      .subscribe();
  }
}
