import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  AlertNotification,
  Breed,
  LifestageInfo,
  Patient,
  PetProfileFormValueKeys,
  PetProfileFormValues,
  Segmented,
} from '@app/core/models';
import { NutritionService } from '@app/core/services';
import { GTMService, PageBlockEnum, patientBlockDisplay } from '@app/core/services/tracking';
import { pastDateValidator } from '@app/shared/directives';
import { GenderCode, Helper, LifestageType, ReproductionStatusCode, SpeciesCode, Tool } from '@app/shared/utils';
import { AgeUnitsEnum } from '@app/shared/utils/enums/age-unit.enum';
import { IconName } from '@app/shared/utils/icon/icons';
import { getDateErrorMessage, getRequiredErrorMessage } from '@app/shared/utils/static-helpers/form-errors-helper';
import {
  filterFormValues,
  formatAndFilterBreeds,
  genderValidator,
  getAdditionalLifestageInfo,
  getGenderItems,
  getMessagesToEmit,
  getPlaceholders,
  isLifestageAdult,
  sortMixedBreeds,
} from '@app/shared/utils/static-helpers/pet-profile-form-helper';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { Sterilization } from '@app/shared/utils/types/sterilization.enum';
import { CoreFacade } from '@app/store/core/core.facade';
import { VetFacade } from '@app/store/vet';
import { RCAutocompleteItem, RCSelectItem, RCSelectorsType } from '@rc/ui';
import { RCSelectorsItem } from '@rc/ui/lib/selectors/rc-selectors';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { addYears } from 'date-fns';
import { asyncScheduler, BehaviorSubject, combineLatest, debounce, interval, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface PetProfileComponentFormValues {
  name: string;
  gender: GenderCode;
  speciesCode: SpeciesCode;
  breed: string;
  birthdate: Date;
  mixed: string;
  neutered: string;
  neuteredToday?: string;
  sterilizationType?: string;
  reproductionStatus: ReproductionStatusCode;
  lifestage: LifestageType;
  unknownBirthdate?: boolean;
  ageCounter?: number;
  ageUnit?: AgeUnitsEnum;
}
const RENALD_DETECT_MIN_YEARS = 7;
const INIT_MIN_YEARS_MONTHS = 1;
const MAX_YEARS_MONTHS = 36;
@Component({
  selector: 'app-pet-profile-form',
  styleUrls: ['pet-profile-form.component.scss'],
  templateUrl: './pet-profile-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PetProfileFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() tool: Tool;
  @Input() submitButtonLabel = translateKey('action_continue');
  @Input() forcedSpecieCode: SpeciesCode | null = null;
  @Input() isNewPet = true;
  @Input() patient: Patient | null = null;
  @Input() displayPostSterilizationFeature = false;
  @Output() submitValues = new EventEmitter<{ values: PetProfileFormValues; selectedFullBreed: Breed }>();
  @Output() alertMessage = new EventEmitter<AlertNotification>();
  @Output() closeMessages = new EventEmitter<string[]>();

  public readonly RCSelectorsType = RCSelectorsType;
  public PetProfileFormValueKeysEnum = PetProfileFormValueKeys;
  public speciesItems: Segmented[] = this.getSpeciesItems();
  public placeholders = getPlaceholders();
  public form: IFormGroup<PetProfileComponentFormValues>;
  public maxDate = new Date();
  public IconName = IconName;
  public GenderCode = GenderCode;
  public Tool = Tool;
  public petProfileInvalidForTool$ = new BehaviorSubject<boolean>(false);
  public lifestageMessage$ = new BehaviorSubject<string | null>(null);
  public lifestageError$ = new BehaviorSubject<string | null>(null);
  public initalBreedInvalid$ = this.coreFacade.breeds$.pipe(
    map((breeds) => this.initialFormValues?.breed && !breeds.find((i) => i.breedCode === this.initialFormValues?.breed))
  );
  public breedInputNotNeeded = false;
  public breeds$: Observable<RCAutocompleteItem<string>[]> = of([]);
  public itemHeight = 44;
  public fullBreeds: Breed[] = [];
  public breedItems: RCSelectorsItem[] = this.getIsMixedSelectorItems();
  public genderItems = getGenderItems() as RCSelectorsItem[];
  public reproductionStatusItems = this.getReproductionStatusItems();
  public neuteredItems: RCSelectorsItem[] = this.getNeuteredItems();
  public neuteredTodayItems: RCSelectorsItem[] = this.getNeuteredTodayItems();
  public sterilizationTypeItems: RCSelectorsItem[] = this.getSterilizationTypeItems();
  public initialGenderInvalid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public shouldDisplayReproductionStatus = false;
  public maxAgeCounter = MAX_YEARS_MONTHS;
  public minAgeCounter = INIT_MIN_YEARS_MONTHS;
  public initAgeUnit = AgeUnitsEnum.MONTH;
  public isPostSterilizationEnabled$ = this.vetFacade.isPostSterilizationEnabled$;
  private _destroyed$ = new Subject<void>();
  private initialFormValues: PetProfileComponentFormValues | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private nutritionService: NutritionService,
    private coreFacade: CoreFacade,
    private vetFacade: VetFacade,
    private trackingService: GTMService
  ) {}

  get isCheckedUnknownBirthdate(): boolean {
    return this.form.get([PetProfileFormValueKeys.UNKNOWN_BIRTHDATE])?.value ?? false;
  }

  get AgeUnitItems(): RCSelectItem[] {
    return this.tool === Tool.RenalDetect
      ? [{ label: translateKey('word_years'), value: AgeUnitsEnum.YEAR }]
      : [
          { label: translateKey('word_months'), value: AgeUnitsEnum.MONTH },
          { label: translateKey('word_years'), value: AgeUnitsEnum.YEAR },
        ];
  }

  @Input() set initialValues(values: PetProfileFormValues) {
    this.initialFormValues = {
      ...values,
      mixed: typeof values?.mixed === 'boolean' ? `${values.mixed}` : '',
      neutered: typeof values?.neutered === 'boolean' ? `${values.neutered}` : '',
      reproductionStatus: values?.reproductionStatus,
      unknownBirthdate: null,
      ageCounter: null,
      ageUnit: null,
      neuteredToday: null,
      sterilizationType: null,
    };
  }

  ngOnInit(): void {
    this.initialGenderInvalid$.next(
      this.initialFormValues ? ![GenderCode.Female, GenderCode.Male].includes(this.initialFormValues?.gender) : false
    );

    this.minAgeCounter = this.tool === Tool.RenalDetect ? RENALD_DETECT_MIN_YEARS : INIT_MIN_YEARS_MONTHS;
    this.initAgeUnit = this.tool === Tool.RenalDetect ? AgeUnitsEnum.YEAR : AgeUnitsEnum.MONTH;

    // form setup
    this.setupForm();
    // set breed items and handle prefill/reset of breed field
    this.setAutocompleteBreedItemsAndControls();
    // set lifestage based on breed and date of birth
    this.setLifestage();
    this.setDisabledName();
    this.setDisabledBreed();
    this.setDisabledDateOfBirth();
    this.setDisabledGender();
    this.setDisabledNeuterd();
    this.setReproductionStatusControls();
    this.handleMessagesToEmit();

    combineLatest([this.form.valueChanges, this.vetFacade.isPostSterilizationEnabled$])
      .pipe(
        takeUntil(this._destroyed$),
        filter(([_, isPostSterilizationEnabled]) => isPostSterilizationEnabled && this.displayPostSterilizationFeature),
        tap(([value]) => {
          if (value?.neutered === 'true') {
            this.form.controls.neuteredToday.setValidators(Validators.required);
          } else {
            this.form.patchValue({ neuteredToday: null, sterilizationType: null }, { emitEvent: false });
            this.form.controls.neuteredToday.clearValidators();
            this.form.controls.sterilizationType.clearValidators();
          }

          if ((value?.gender === GenderCode.Male && value?.neuteredToday === 'true') || value?.neuteredToday === 'false') {
            this.form.controls.sterilizationType.clearValidators();
            this.form.controls.sterilizationType.patchValue(null, { emitEvent: false });
          }

          if (value?.gender === GenderCode.Female && value?.neuteredToday === 'true') {
            this.form.controls.sterilizationType.setValidators(Validators.required);
          }
          this.form.controls.sterilizationType.updateValueAndValidity({ emitEvent: false });
          this.form.controls.neuteredToday.updateValueAndValidity({ emitEvent: false });
        })
      )
      .subscribe();

    this.trackingService.sendInteraction(
      patientBlockDisplay({
        block: PageBlockEnum.PATIENT_PROFILE,
      })
    );
  }

  ngAfterViewInit(): void {
    // make all fields as touched if initial values are passed (triggers fields validation and errors for srs instance)
    if (this.initialFormValues) {
      Object.keys(this.initialFormValues).forEach((key: keyof PetProfileFormValues) => {
        if (this.initialFormValues[key]) {
          this.form.controls[key].markAsTouched();
        }
      });
    }
    if (this.forcedSpecieCode) this.form.controls.speciesCode.patchValue(this.forcedSpecieCode);
    if (this.tool === Tool.RenalDetect) this.form.controls.speciesCode.patchValue(SpeciesCode.Cat);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  requiredErrorMessage(field: PetProfileFormValueKeys, fieldKeyTrad: string): string {
    return (this.form.controls[field].touched && this.form.controls[field].errors?.required && getRequiredErrorMessage(fieldKeyTrad)) || '';
  }

  dateOfBirthErrorMessage(): string {
    return (
      this.form.controls.birthdate.touched &&
      this.form.controls.birthdate.errors &&
      getDateErrorMessage(this.form.controls.birthdate.errors, 'form-attribute_birth-date', true)
    );
  }

  /*
  By default the RcSelect component will sort items alphabetically, we want to prevent this behaviour
*/
  customSelectSort(): number {
    return 1;
  }

  customSortAutocomplete(): undefined | ((breed1: RCAutocompleteItem<string>, breed2: RCAutocompleteItem<string>) => number) {
    return this.form.controls.speciesCode.value === SpeciesCode.Dog && this.form.controls.mixed.value ? sortMixedBreeds : undefined;
  }

  /*
      When we submit, we want to send two informations : the form values and the full breed information
      The full breed information is retrieved from the list of breeds with the value selected be the user
      (NB: selectedFullBreed only exists for a new pet profile, indeed for an existing pet the breed input
      is not even displayed and thus the this.breeds observable not subscribed)
  */
  submit(): void {
    if (this.form.valid) {
      const selectedFullBreed = this.fullBreeds.find((item) => item.breedCode === this.form.controls.breed.value);
      this.submitValues.emit({ values: filterFormValues(this.form.getRawValue()), selectedFullBreed });
    }
  }

  private setupForm(): void {
    const formBuilder: IFormBuilder = new UntypedFormBuilder();
    this.maxDate = this.tool === Tool.RenalDetect ? addYears(new Date(), -7) : new Date();
    this.form = formBuilder.group<PetProfileComponentFormValues>({
      [PetProfileFormValueKeys.NAME]: [this.initialFormValues?.name || null, Validators.required],
      speciesCode: [
        {
          value: this.initialFormValues?.speciesCode || null,
          disabled: this.tool === Tool.RenalDetect || !!this.forcedSpecieCode,
        },
        Validators.required,
      ],
      [PetProfileFormValueKeys.GENDER]: [
        { value: this.initialFormValues?.gender || null, disabled: true },
        [Validators.required, genderValidator],
      ],
      [PetProfileFormValueKeys.NEUTERED]: [{ value: this.initialFormValues?.neutered || null, disabled: true }, Validators.required],
      [PetProfileFormValueKeys.NEUTERED_TODAY]: [{ value: this.initialFormValues?.neuteredToday, disabled: false }],
      [PetProfileFormValueKeys.STERILIZATION_TYPE]: [{ value: this.initialFormValues?.sterilizationType, disabled: false }],
      [PetProfileFormValueKeys.REPRODUCTION_STATUS]: [
        { value: this.initialFormValues?.reproductionStatus || ReproductionStatusCode.None, disabled: false },
      ],
      [PetProfileFormValueKeys.BREED]: [{ value: this.initialFormValues?.breed || null, disabled: true }, Validators.required],
      [PetProfileFormValueKeys.MIXED]: [{ value: this.initialFormValues?.mixed || null, disabled: true }],
      [PetProfileFormValueKeys.BIRTHDATE]: [
        {
          value: this.initialFormValues?.birthdate || null,
          disabled: true,
        },
        [Validators.required, pastDateValidator],
      ],
      [PetProfileFormValueKeys.LIFESTAGE]: [null, Validators.required],
      [PetProfileFormValueKeys.UNKNOWN_BIRTHDATE]: [{ value: null, disabled: true }],
      [PetProfileFormValueKeys.AGE_COUNTER]: [
        { value: this.minAgeCounter, disabled: true },
        [Validators.max(this.maxAgeCounter), Validators.min(this.minAgeCounter), Validators.required],
      ],
      [PetProfileFormValueKeys.AGE_UNIT]: [{ value: this.initAgeUnit, disabled: true }, Validators.required],
    });
  }

  /*
    NAME is linked to SPECIES_CODE
    - if SPECIES is not set and NAME is not set, NAME is disabled
  */
  private setDisabledName(): void {
    this.fieldUpdated$<SpeciesCode>(PetProfileFormValueKeys.SPECIES_CODE)
      .pipe(
        tap((speciesCode) => {
          const nameInput = this.form.controls.name;

          if (!speciesCode && nameInput.enabled && !nameInput.value) {
            nameInput.disable();
          } else if (speciesCode && nameInput.disabled) {
            nameInput.enable();
          }
        }),

        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
    BREED and MIXED fields are linked to NAME and SPECIES_CODE
    - if BREED is not set and NAME and SPECIES_CODE are not set, BREED and MIXED are disabled
  */
  private setDisabledBreed(): void {
    combineLatest([
      this.fieldUpdated$<string>(PetProfileFormValueKeys.NAME),
      this.fieldUpdated$<SpeciesCode>(PetProfileFormValueKeys.SPECIES_CODE),
    ])
      .pipe(
        tap(([name, speciesCode]) => {
          const breedInput = this.form.controls.breed;
          const mixedInput = this.form.controls.mixed;

          if ((!name || !speciesCode) && breedInput.enabled && !breedInput.value) {
            breedInput.disable();
            mixedInput.disable();
          } else if (name && speciesCode && breedInput.disabled) {
            breedInput.enable();
            mixedInput.enable();
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
    Whenever the breed or the birthdate updates, we need to fetch the lifestage
    The lifestage is used for several things :
    - Display error message below the date of birth input (lifestageError$)
    - Display the lifestage message information below the date of birth input (lifestageMessage$)
    - Use the lifestage value (LifestageType) when submitting the form
    - Use the lifestage value (LifestageType) to disable or not other inputs
  */
  private setLifestage(): void {
    combineLatest([
      this.fieldUpdated$<string>(PetProfileFormValueKeys.BREED),
      this.fieldUpdated$<Date>(PetProfileFormValueKeys.BIRTHDATE),
      this.fieldUpdated$<boolean>(PetProfileFormValueKeys.UNKNOWN_BIRTHDATE),
      this.fieldUpdated$<number>(PetProfileFormValueKeys.AGE_COUNTER).pipe(
        // We use debounce(() => interval(500)) instead of debounceTime because jest captures setInterval and rxjs uses date.now instead of setinterval for debouncetime
        debounce(() => interval(500)),
        distinctUntilChanged()
      ),
      this.fieldUpdated$<AgeUnitsEnum>(PetProfileFormValueKeys.AGE_UNIT),
    ])
      .pipe(
        switchMap(([breed, birthDate, unknownBirthdate, ageCounter, AgeUnit]) => {
          // if the users clears some fields (like the breed when selecting a different species), clear the lifestage information

          const dateOfBirth = unknownBirthdate ? Helper.getBirthdateFromAge(ageCounter, AgeUnit) : birthDate;

          if (!breed || !dateOfBirth) return of<LifestageInfo>({ code: null, message: null, error: null });
          // if the breed or dateOfBirth fetch the new lifestage information
          return this.nutritionService
            .getBreedPetProfile({
              breedCode: breed,
              dateOfBirth: dateOfBirth,
            })
            .pipe(
              // is the API is successful, get the lifestage code but also the message to display or the error
              map(({ lifestage }) => getAdditionalLifestageInfo(lifestage)),
              catchError(() => {
                // in case the API failed, make sure to emit a value with an error by returning this observable
                // this is to prevent the observable from completing and make sure that the API can be called again
                // if the user changes the inputs BREED and BIRTHDATE again
                return of<LifestageInfo>({
                  code: null,
                  message: null,
                  error: $localize`:@@label_life-stage-msg-error:`,
                });
              })
            );
        }),
        tap((lifestageInfo) => {
          this.lifestageMessage$.next(lifestageInfo?.message);
          this.lifestageError$.next(lifestageInfo?.error);
          this.form.patchValue({ lifestage: lifestageInfo?.code });
          if (lifestageInfo?.code) this.cdr.detectChanges();
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
    BIRTHDATE and unknownBirthdate field are linked to BREED
    - if BREED is not set, BIRTHDATE & unknownBirthdate are disabled
  */

  private setDisabledDateOfBirth(): void {
    combineLatest([
      this.fieldUpdated$<string>(PetProfileFormValueKeys.BREED),
      this.initalBreedInvalid$,
      this.fieldUpdated$<boolean>(PetProfileFormValueKeys.UNKNOWN_BIRTHDATE),
    ])
      .pipe(
        tap(([breed, initalBreedInvalid, unknownBirthdate]) => {
          const dateOfBirthInput = this.form.controls.birthdate;
          const unknownBirthdateInput = this.form.controls.unknownBirthdate;
          if (!breed || initalBreedInvalid) {
            dateOfBirthInput.disable();
            unknownBirthdateInput.disable();
          } else if (breed && !initalBreedInvalid) {
            unknownBirthdateInput.enable();
            if (unknownBirthdate) {
              dateOfBirthInput.setValue(null);
              dateOfBirthInput.disable();
            } else {
              dateOfBirthInput.enable();
            }
          }

          if (unknownBirthdateInput.enabled) {
            this.setDisabledAge(unknownBirthdate);
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  private setDisabledAge(unknownBirthDate: boolean): void {
    const ageCounterControl = this.form.get(PetProfileFormValueKeys.AGE_COUNTER);
    const ageUnitControl = this.form.get(PetProfileFormValueKeys.AGE_UNIT);
    if (unknownBirthDate) {
      ageCounterControl.enable();
      ageUnitControl.enable();
    } else {
      ageCounterControl.disable();
      ageUnitControl.disable();
    }
  }

  /*
    - if there is no LIFESTAGE yet, GENDER is disabled
  */
  private setDisabledGender(): void {
    this.fieldUpdated$<LifestageType>(PetProfileFormValueKeys.LIFESTAGE)
      .pipe(
        tap((lifestage) => {
          const genderInput = this.form.controls.gender;
          if (!lifestage && genderInput.enabled) {
            genderInput.disable();
          } else if (lifestage && genderInput.disabled) {
            genderInput.enable();
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
    - if there is no Gender yet, NEUTERED is disabled
  */
  private setDisabledNeuterd(): void {
    this.fieldUpdated$<string>(PetProfileFormValueKeys.GENDER)
      .pipe(
        tap((gender) => {
          const neuteredInput = this.form.controls.neutered;
          if (!gender && neuteredInput.enabled) {
            neuteredInput.disable();
          } else if (gender && neuteredInput.disabled) {
            neuteredInput.enable();
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
  First we need to fetch the breeds from the API
  Then we format and emit all the breeds received
  When users update the species and/or check the mixed checkbox
  we filter and emit the new breed list to match the requirements
*/
  private setAutocompleteBreedItemsAndControls(): void {
    this.breeds$ = this.coreFacade.breeds$.pipe(
      filter((breeds) => !!breeds),
      concatMap((breeds) =>
        this.initalBreedInvalid$.pipe(
          switchMap((initalBreedInvalid) =>
            combineLatest([
              this.fieldUpdated$<SpeciesCode>(PetProfileFormValueKeys.SPECIES_CODE),
              this.fieldUpdated$<string>(PetProfileFormValueKeys.MIXED),
              this.initalBreedInvalid$,
            ]).pipe(
              // Update placeholder depending on mixed checkbox ticked
              tap(([speciesCode, mixed]) => {
                this.breedInputNotNeeded = speciesCode === SpeciesCode.Cat && mixed === 'true';
                this.placeholders[PetProfileFormValueKeys.BREED] =
                  mixed === 'true' ? translateKey('form-attribute_size') : translateKey('form-attribute_breed');
              }),
              map(([speciesCode, mixed]) => {
                this.fullBreeds = breeds;
                return formatAndFilterBreeds(breeds, speciesCode, mixed === 'true');
              }),
              // If there is only one value, we prefill the breed field
              // else if mixed input was manually set we reset breed input
              tap((breedItems) => {
                asyncScheduler.schedule(() => this.updateBreedInput(breedItems, initalBreedInvalid));
              })
            )
          )
        )
      )
    );
  }

  private updateBreedInput(breedItems: RCAutocompleteItem<string>[], initalBreedInvalid: boolean): void {
    const breedInput = this.form.controls.breed;
    if (breedItems.length === 1) {
      breedInput.patchValue(breedItems[0].value);
    } else if (
      this.form.controls.mixed.dirty ||
      this.form.controls.speciesCode.dirty ||
      (initalBreedInvalid && breedInput.value === this.initialFormValues?.breed)
    ) {
      breedInput.reset();
    }
  }

  /*
    Small helper to listen to field updates
    - startWith is needed to catch the initial value of the field (which is needed when opening the form for an existing pet)
    and also needed because of combineLatest behaviour (does not emit until all values have emitted once)
    - distinctUntilChanged is here to make sure we catch only new values (make sure that we don't unecessary call the lifestage API for instance)
  */
  private fieldUpdated$<T extends PetProfileComponentFormValues[keyof PetProfileComponentFormValues]>(
    key: keyof PetProfileComponentFormValues
  ): Observable<T> {
    const controlValueChanges = this.form.controls[key].valueChanges as Observable<T>;
    const initialValue = this.initialFormValues && (this.initialFormValues[key] as T);
    return controlValueChanges.pipe(startWith(initialValue || null), distinctUntilChanged());
  }

  /*
    REPRODUCTION_STATUS field is linked to LIFESTAGE and NEUTERED
    - if NEUTERED is checked, REPRODUCTION_STATUS is cleared and disabled
    - if LIFESTAGE is too young, REPRODUCTION_STATUS is disabled
  */
  private setReproductionStatusControls(): void {
    combineLatest([
      this.fieldUpdated$<string>(PetProfileFormValueKeys.NEUTERED),
      this.fieldUpdated$<string>(PetProfileFormValueKeys.GENDER),
      this.fieldUpdated$<LifestageType>(PetProfileFormValueKeys.LIFESTAGE),
    ])
      .pipe(
        tap(([neutered, gender, lifestage]) => {
          const reproductionStatusInput = this.form.controls.reproductionStatus;
          this.shouldDisplayReproductionStatus = false;
          if (neutered === 'false' && gender === GenderCode.Female && isLifestageAdult(lifestage)) {
            reproductionStatusInput.enable();
            reproductionStatusInput.setValue(this.initialFormValues?.reproductionStatus ?? ReproductionStatusCode.None);

            this.shouldDisplayReproductionStatus = true;
          } else {
            if (neutered === 'true') reproductionStatusInput.reset();
            reproductionStatusInput.disable();
            this.shouldDisplayReproductionStatus = false;
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  /*
    Depending on the tool used and the pet information filled (BIRTHDATE, SPECIES_CODE, REPRODUCTION_STATUS, LIFESTAGE)
    We have to emit a message to the parent component to display a warning or an error
    And in case it is a blocking error, we prevent the user from submitting (petProfileInvalidForTool$)
    Example : for renal detect, the pet must be older than 7 years old
  */
  private handleMessagesToEmit() {
    combineLatest([
      this.fieldUpdated$<Date>(PetProfileFormValueKeys.BIRTHDATE),
      this.fieldUpdated$<SpeciesCode>(PetProfileFormValueKeys.SPECIES_CODE),
      this.fieldUpdated$<ReproductionStatusCode>(PetProfileFormValueKeys.REPRODUCTION_STATUS),
      this.fieldUpdated$<LifestageType>(PetProfileFormValueKeys.LIFESTAGE),
      this.fieldUpdated$<boolean>(PetProfileFormValueKeys.UNKNOWN_BIRTHDATE),
      this.fieldUpdated$<number>(PetProfileFormValueKeys.AGE_COUNTER),
      this.fieldUpdated$<AgeUnitsEnum>(PetProfileFormValueKeys.AGE_UNIT),
    ])
      .pipe(
        tap(
          ([birthDate, speciesCode, reproductionStatus, lifestage, unknownBirthdate, ageCounter, AgeUnit]: [
            Date,
            SpeciesCode,
            ReproductionStatusCode,
            LifestageType,
            boolean,
            number,
            AgeUnitsEnum
          ]) => {
            const dateOfBirth = unknownBirthdate ? Helper.getBirthdateFromAge(ageCounter, AgeUnit) : birthDate;

            const { messages, isBlocking, messageIdsToRemove } = getMessagesToEmit(this.tool, {
              birthDate: dateOfBirth,
              speciesCode,
              reproductionStatus,
              lifestage,
            });

            if (messages.length) {
              messages.forEach((message) => {
                this.alertMessage.emit(message);
              });
            }
            if (messageIdsToRemove.length) {
              this.closeMessages.emit(messageIdsToRemove);
            }
            this.petProfileInvalidForTool$.next(isBlocking);
          }
        ),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  private getSterilizationTypeItems(): RCSelectorsItem[] {
    return [
      { label: translateKey(`form-attribute_select-surgery-one`), value: Sterilization.OVARIECTOMY },
      { label: translateKey(`form-attribute_select-surgery-two`), value: Sterilization.OVARIOHYSTERECTOMY },
    ];
  }

  private getNeuteredItems(): RCSelectorsItem[] {
    return [
      {
        label: $localize`:@@text_yes:`,
        value: 'true',
      },
      {
        label: $localize`:@@text_No:`,
        value: 'false',
      },
    ];
  }
  private getNeuteredTodayItems(): RCSelectorsItem[] {
    return [
      {
        label: $localize`:@@text_yes:`,
        value: 'true',
      },
      {
        label: $localize`:@@text_No:`,
        value: 'false',
      },
    ];
  }

  private getReproductionStatusItems(): RCSelectorsItem[] {
    return [
      {
        label: $localize`:@@status-gestation:`,
        value: ReproductionStatusCode.Gestation,
      },
      {
        label: $localize`:@@status-lactation:`,
        value: ReproductionStatusCode.Lactation,
      },
      {
        label: $localize`:@@status-none:`,
        value: ReproductionStatusCode.None,
      },
    ];
  }

  private getIsMixedSelectorItems(): RCSelectorsItem[] {
    return [
      {
        label: $localize`:@@form-attribute_breed:`,
        value: 'false',
      },
      {
        label: $localize`:@@form-attribute_select-unknown-or-mixed:`,
        value: 'true',
      },
    ];
  }

  private getSpeciesItems(): Segmented[] {
    return [
      {
        label: $localize`:@@specie-dog:`,
        icon: IconName.dog,
        value: SpeciesCode.Dog,
      },
      {
        label: $localize`:@@specie-cat:`,
        icon: IconName.cat,
        value: SpeciesCode.Cat,
      },
    ];
  }
}
