<div class="follow-up-selector">
  <div class="follow-up-selector__illustration">
    <img [src]="actionImage" [alt]="title" />
  </div>

  <div class="follow-up-selector__option">
    <h3 class="rc-title-delta">{{ title }}</h3>
    <p class="description rc-paragraph">{{ description }}</p>
  </div>

  <div class="follow-up-selector__footer">
    <button class="rc-button" [ngClass]="isRecommended ? 'rc-button--filled-small' : 'rc-button--outline-small'" (click)="onClick()">
      <span class="rc-button__title">{{ actionLabel | titlecase }}</span>
    </button>
  </div>
</div>
