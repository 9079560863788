import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FollowUpAction } from '@app/core/models';
import { Constants, Programs } from '@app/shared/utils';

import { translateKey } from '@app/shared/utils/static-helpers/translate';
@Component({
  selector: 'app-follow-up-selector',
  templateUrl: './follow-up-selector.component.html',
  styleUrls: ['./follow-up-selector.component.scss'],
})
export class FollowUpSelectorComponent implements OnInit {
  isRecommended = false;
  title = '';
  description = '';
  actionLabel = '';
  actionImage = '';

  @Input() recommendedActionCode;
  @Input() action: FollowUpAction;

  @Output()
  selectAction: EventEmitter<FollowUpAction> = new EventEmitter<FollowUpAction>();

  ngOnInit() {
    this.isRecommended = this.action.actionCode === this.recommendedActionCode;
    this.title = translateKey(`${this.action.actionCode}_title`);
    this.description = translateKey(`${this.action.actionCode}_description`);
    this.actionLabel = translateKey(`${this.action.actionCode}_actionText`);

    switch (this.action.targetProgram) {
      case Programs.WEIGHT_LOSS:
        this.actionImage = Constants.imgWeightProgramLoss;
        break;
      case Programs.WEIGHT_STABILISATION_STEP_1:
      case Programs.WEIGHT_STABILISATION_STEP_2:
        this.actionImage = Constants.imgWeightProgramStabilization;
        break;
      default:
        console.error('Program not handled:', this.action.targetProgram);
    }
  }

  onClick() {
    this.selectAction.emit(this.action);
  }
}
