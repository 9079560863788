import { BenefitClaim, Nutrient, NutritionalAssessment } from '@app/shared/components/nutrients-list/nutrients';

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum NutrientCode {
  RN_105 = 'sr-xray_macronut_protein',
  RN_106 = 'sr-xray_macronut_protein',
  RN_109 = 'sr-xray_macronut_fat',
  RN_110 = 'sr-xray_macronut_fat',
  RN_431 = 'sr-xray_macronut_fiber',
  RN_432 = 'sr-xray_macronut_fiber',
  NU_170 = 'sr-xray_macronut_energy',
  NU_201 = 'sr-xray_macronut_energy',
}
/* eslint-enable @typescript-eslint/no-duplicate-enum-values */

export const nutrientsMock: Nutrient[] = [
  {
    nutrient: {
      id: NutrientCode.RN_105,
      unit: '',
    },
    influencers: [{ key: 'obesity_bcs_8_(out_of_9)', type: '' }],
    minPercentage: 39.77,
    maxPercentage: 99.61,
    targetPercentage: 77.5,
    cutOff: {
      high: 80,
      low: 20,
    },
    targetValue: {
      high: 80,
      low: 20,
    },
  },
  {
    nutrient: {
      id: NutrientCode.RN_109,
      unit: '',
    },
    influencers: [
      { key: 'obesity_bcs_8_(out_of_9)', type: '' },
      { key: 'obesity_bcs_8_(out_of_9)', type: '' },
      { key: 'obstipation', type: '' },
      { key: 'obesity_bcs_8_(out_of_9)', type: '' },
    ],
    minPercentage: 20,
    maxPercentage: 80,
    targetPercentage: 50,
    cutOff: {
      high: 80,
      low: 20,
    },
    targetValue: {
      high: 80,
      low: 20,
    },
  },
  {
    nutrient: {
      id: NutrientCode.RN_431,
      unit: '',
    },
    influencers: [
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
      { key: 'gastrointestinal_sensitivity', type: 'diseases' },
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
    ],
    minPercentage: 70,
    maxPercentage: 100,
    targetPercentage: 100,
    cutOff: {
      high: 80,
      low: 20,
    },
    targetValue: {
      high: 80,
      low: 20,
    },
  },
  {
    nutrient: {
      id: NutrientCode.NU_170,
      unit: '',
    },
    influencers: [
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
      { key: 'oxalate_urolithiasis', type: 'diseases' },
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
    ],
    minPercentage: 0,
    maxPercentage: 50,
    targetPercentage: 7,
    cutOff: {
      high: 80,
      low: 20,
    },
    targetValue: {
      high: 80,
      low: 20,
    },
  },
];

export const benefitClaimMock: BenefitClaim[] = [
  {
    id: 'AN_8_2',
    influencers: [
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
      { key: 'oxalate_urolithiasis', type: 'diseases' },
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
    ],
  },
  {
    id: 'RN_539',
    influencers: [
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
      { key: 'oxalate_urolithiasis', type: 'diseases' },
      { key: 'obesity_bcs_8_(out_of_9)', type: 'diseases' },
    ],
  },
];

export const nutritionalAssessmentMock: NutritionalAssessment = {
  macroNutrients: nutrientsMock,
  otherNutrients: nutrientsMock,
  benefits: benefitClaimMock,
};

export const backendAssessmentMock = {
  macroNutrients: [
    {
      nutrient: {
        id: 'RN_106',
        unit: null,
      },
      influencers: [
        {
          name: 'Prone to weight gain',
          code: 'risk_factor:weight_gain',
        },
      ],
      minPercentage: 66.66666666666667,
      maxPercentage: 97.54048,
      targetPercentage: null,
      targetZone: '',
      targetValue: {
        low: 99.75,
        high: 110.25,
      },
      cutOff: {
        low: 55,
        high: 125,
      },
    },
    {
      nutrient: {
        id: 'RN_110',
        unit: 'g/Mcal',
      },
      influencers: [
        {
          name: 'Prone to weight gain',
          code: 'risk_factor:weight_gain',
        },
      ],
      minPercentage: 21.020000000000003,
      maxPercentage: 38,
      targetPercentage: 24,
      targetZone: '',
      targetValue: {
        low: 26.6,
        high: 29.4,
      },
      cutOff: {
        low: 20,
        high: 60,
      },
    },
    {
      nutrient: {
        id: 'RN_432',
        unit: 'g/Mcal',
      },
      influencers: [
        {
          name: 'Prone to weight gain',
          code: 'risk_factor:weight_gain',
        },
      ],
      minPercentage: 56.66666666666667,
      maxPercentage: 86.06060606060606,
      targetPercentage: 80.60606060606061,
      targetZone: '',
      targetValue: {
        low: 45.9,
        high: 56.1,
      },
      cutOff: {
        low: 5,
        high: 83,
      },
    },
    {
      nutrient: {
        id: 'NU_201',
        unit: 'kcal/kg',
      },
      influencers: [
        {
          name: 'Prone to weight gain',
          code: 'risk_factor:weight_gain',
        },
      ],
      minPercentage: 2.9430000000000067,
      maxPercentage: 52.40601503759399,
      targetPercentage: 18,
      targetZone: '',
      targetValue: {
        low: 3065.2,
        high: 3254.8,
      },
      cutOff: {
        low: 2800,
        high: 4450,
      },
    },
  ],
  otherNutrients: {
    rangeDisplay: [
      {
        nutrient: {
          id: 'RN_123',
          unit: 'g/Mcal',
        },
        influencers: [
          {
            name: 'Prone to weight gain',
            code: 'risk_factor:weight_gain',
          },
        ],
        minPercentage: 43.63636363636364,
        maxPercentage: 75.81818181818183,
        targetPercentage: 63.63636363636364,
        targetZone: '',
        targetValue: {
          low: 2.88,
          high: 3.5200000000000005,
        },
        cutOff: {
          low: 0.5,
          high: 4.5,
        },
      },
    ],
    textDisplay: [
      {
        claim: 'NU_717',
        influencers: [
          {
            name: 'Prone to weight gain',
            code: 'risk_factor:weight_gain',
          },
        ],
      },
    ],
  },
};
