<app-rc-dialog-wrapper noClose="true">
  <div class="content__header">
    <div class="rc-title-delta header__title">
      <ng-container i18n="@@msg__selected">You have selected</ng-container>
      {{ selectedProducts.length }}
      <ng-container i18n="@@title_products">{{ 'product(s)' | lowercase }}</ng-container>
    </div>
    <p class="rc-paragraph header__paragraph" i18n="@@starter-kit__popin__add-other-products">
      Do you want to add other products or continue to your order?
    </p>
  </div>

  <div class="content__products">
    <ng-container *ngIf="selectedProducts.length > 0">
      <div class="product-cart__content product-cart__content--list-vertical">
        <div class="product-cart__item product-cart__item--list-vertical" *ngFor="let product of selectedProducts">
          <!-- Product Cart : Product thumb -->
          <img [alt]="product.name || ''" [src]="product.image | publicUrl" class="product-cart__item-thumb image" />

          <!-- Product Cart : Description -->
          <div class="product-cart__description">
            <p class="rc-paragraph type">
              {{ product.productType | translationCode: LocaleContentEnum.ProductType }}
            </p>
            <h6 class="rc-paragraph product-cart__item-name name">
              {{ product.name || '' | titlecase }}
            </h6>
          </div>

          <!-- Product Cart : Remove Item -->
          <button (click)="removeItem(product)" class="product-cart__item-remove rc-button">
            <em class="rc-icon rc-icon--close"></em>
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="content__footer">
    <div class="rc-dialog-form__action">
      <button class="rc-button" (click)="closeAndAddProducts()">
        <span class="rc-button__title--link" i18n="@@starter-kit__popin__add-products">{{ 'add products' | titlecase }}</span>
      </button>
      <span class="rc-button__separator" i18n="@@action_separator">or</span>
      <button (click)="continue()" class="rc-button rc-button--filled-small">
        <span class="rc-button__title" i18n="@@action_continue">{{ 'continue' | titlecase }}</span>
      </button>
    </div>
  </div>
</app-rc-dialog-wrapper>
