import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { WindowService } from '@app/core/services';
import { IconName } from '@app/shared/utils/icon/icons';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  animations: [
    trigger('slide', [
      state('out', style({ height: 0 })),
      state('in', style({ height: '*' })),
      transition('in => out', [animate(250, style({ height: 0 }))]),
      transition('out => in', [animate(250, style({ height: '*' }))]),
    ]),
  ],
})
export class ExpansionPanelComponent {
  iconName = IconName;

  constructor(private element: ElementRef, private windowService: WindowService) {}

  // we use 20 by default because 90% of the time expansion panel is used in block from consultation flows pages
  // each blocks are separated by 20px paddings and we want to scroll to the blocks with this padding still visible
  @Input() offsetPaddingTop = 20;

  @Input() disabled = false;

  @Input() expanded = false;
  /**
   * Enable toggle behavior when header is clicked;
   * Display toggle icon
   */
  @Input() toggle = false;

  @Input() withExpandIcon = false;
  /**
   * Scroll into view after expanded
   */
  @Input() shouldScrollIntoView = true;
  /**
   * Smooth scroll into view
   */
  /**
   * Emits when item is clicked
   */
  @Output() clicked = new EventEmitter();

  toggled(): void {
    if (!this.disabled) {
      this.clicked.emit();
      if (this.toggle) {
        this.expanded = !this.expanded;
      }
    }
  }

  scrollIntoView(event: AnimationEvent): void {
    if (this.shouldScrollIntoView && event.toState === 'in') {
      this.windowService.scrollY(this.element.nativeElement.offsetTop - this.offsetPaddingTop);
    }
  }
}
