import { Component, Inject, OnInit } from '@angular/core';
import { Product } from '@app/core/models';
import { LocaleContentEnum } from '@app/shared/data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SelectedProductsPopinData {
  selectedProducts: Product[];
  continue(selectedProducts: Product[]): void;
}

@Component({
  selector: 'app-selected-products-popin',
  templateUrl: './selected-products-popin.component.html',
  styleUrls: ['./selected-products-popin.component.scss'],
})
export class SelectedProductsPopinComponent implements OnInit {
  public readonly LocaleContentEnum = LocaleContentEnum;

  selectedProducts: Product[];

  constructor(
    private _dialogRef: MatDialogRef<SelectedProductsPopinComponent>,
    @Inject(MAT_DIALOG_DATA) private data: SelectedProductsPopinData
  ) {}

  ngOnInit(): void {
    this.selectedProducts = this.data.selectedProducts || [];
  }

  closeAndAddProducts(): void {
    this._dialogRef.close(this.selectedProducts);
  }

  continue(): void {
    this._dialogRef.close(this.selectedProducts);
    this.data.continue(this.selectedProducts);
  }

  removeItem(product: Product): void {
    this.selectedProducts = this.selectedProducts.filter((p: Product) => product.id !== p.id);
    if (this.selectedProducts.length === 0) {
      this._dialogRef.close([]);
    }
  }
}
