<div class="expansion-panel" [ngClass]="{ 'expansion-panel--expanded': expanded, 'expansion-panel--disabled': disabled }">
  <!--
    Header
   -->
  <div class="header" (click)="toggled()">
    <div class="header-content">
      <ng-content select="[header]"></ng-content>
    </div>
    <!--
      Expand icon
     -->
    <span *ngIf="withExpandIcon" class="expand-icon" [class.reversed]="expanded">
      <app-icon [name]="iconName.chevron_down"></app-icon>
    </span>
  </div>
  <!--
    Content
   -->
  <div class="content" [@slide]="expanded ? 'in' : 'out'" (@slide.done)="scrollIntoView($event)">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
