import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { MarketTerm, Term, TermScope } from '@app/core/models';
import { VetFacade } from '@app/store/vet/vet.facade';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  /**
   * Get applicable Onetrust terms
   */
  terms$: Observable<Term[] | undefined> = this.vetFacade.vet$.pipe(map((vet) => vet?.purposes?.terms || []));

  constructor(private vetFacade: VetFacade, @Inject(LOCALE_ID) protected localeId: string) {}

  /**
   * Get applicable Onetrust term
   */
  term$(scope: TermScope): Observable<Term | undefined> {
    return this.vetFacade.vet$.pipe(
      map((vet) => {
        return (vet?.purposes?.terms || []).find((term) => term.scope === scope);
      })
    );
  }

  /**
   * Get term url for scope
   */
  termUrl$(scope: TermScope): Observable<string> {
    return this.term$(scope).pipe(map((term) => term?.url || 'https://www.mars.com/legal'));
  }

  /**
   * Get instant terms for scope
   * Thoses terms are not stored in one trust
   * They are configued in the blob storage
   */
  legacyTerm$(scope: TermScope): Observable<MarketTerm | undefined> {
    return this.vetFacade.market$.pipe(
      map((market) => {
        let term: MarketTerm = { scope: null, url: null, translationKeyDesc: '', translationKeyUrl: '' };
        if (market?.terms?.length) {
          term = market.terms.find((t) => t.language === this.localeId && t.scope === scope) || market.terms.find((t) => t.scope === scope);
        }

        //fallback
        if (!term.translationKeyDesc) {
          term = { ...term, translationKeyDesc: 'i_accept' };
        }

        if (!term.translationKeyUrl) {
          term = { ...term, translationKeyUrl: 'terms_and_conditions' };
        }

        if (!term.url) {
          term = { ...term, url: 'https://www.mars.com/legal' };
        }
        return term;
      })
    );
  }
}
