import { map } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

import { AttributeType, FormField, InputType } from '@app/core/models';

import { Observable, of } from 'rxjs';
import * as objectPath from 'object-path';
import { Helper, MeasurementCodeType } from '@app/shared/utils';
import { LocaleContentEnum } from '@app/shared/data';
import { SystemPreferenceCode } from '@app/shared/utils/enums/system-preference-code';

@Component({
  selector: 'app-rc-attribute',
  templateUrl: './rc-attribute.component.html',
  styleUrls: ['./rc-attribute.component.scss'],
})
export class RcAttributeComponent implements OnInit {
  public readonly AttributeType = AttributeType;
  public readonly LocaleContentEnum = LocaleContentEnum;

  /**
   * Properties
   */

  private _object;
  @Input()
  set object(object: any) {
    this._object = object;
    this.setVal();
  }
  get object() {
    return this._object;
  }
  /**
   * attribute should have:
   * - a `path` leading to the field to be changed in object
   * - a optional `type` like 'date'
   */
  @Input() attribute: FormField;
  @Input() currentSystemPreferenceCode: SystemPreferenceCode;
  @Input() weightUnit?: MeasurementCodeType;

  val: any;
  attrVal: any;
  href: any;

  /**
   * Initializer
   */

  /**
   * Life Cycle
   */
  ngOnInit(): void {
    this.setVal();
  }

  getVal() {
    let val = objectPath.get(this.object, this.attribute.path);
    if (this.attribute.getter) {
      val = this.attribute.getter(val);
    }
    return val;
  }

  get(val): Observable<any> {
    if (!val && this.attribute) {
      return of('...');
    }
    // in case of a select, we want to display the label of the selected item and not the value stored in the form
    if (this.attribute.inputType === InputType.Select && this.attribute.items) {
      return this.attribute.items.pipe(
        map((items) => {
          const selectItem = items.find((item) => item[this.attribute.keyPath] === val);
          return selectItem ? selectItem[this.attribute.labelPath] : val;
        })
      );
    }
    switch (this.attribute.type) {
      case AttributeType.Age:
        if (val) {
          val = Helper.getPetAgeText(val, {});
        }
        break;
      case AttributeType.UserRole:
        if (val) {
          val = val
            .filter((q) => q.status === 'active')
            .map((q) => q.code)
            .join(', ');
        }
        break;
      case AttributeType.UserName:
        if (val) {
          val = `${val.firstName || ''} ${val.lastName || ''}`;
        }
        break;
      case AttributeType.Address:
        if (val) {
          val = `${val.address1 || ''} ${val.address2 || ''} ${val.zip || ''} ${val.city || ''}`;
        }
        break;
    }
    return of(val || '-');
  }

  getHref(link): string {
    switch (this.attribute.type) {
      case AttributeType.Link:
        if (link && !link.startsWith('http')) {
          link = `http://${link}`;
        }
        return link;
      case AttributeType.FbLink:
        return `http://facebook.com${link}`;
      default:
        return null;
    }
  }

  setVal(): void {
    if (this.attribute) {
      this.val = this.getVal();
      this.attrVal = this.get(this.val);
      this.href = this.getHref(this.val);
    }
  }
}
