import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Helper, SpeciesCode, DetectMobile } from '@app/shared/utils';
import { LanguageCode } from '@app/shared/utils/enums/language-code.enum';
import { translateKey } from '@app/shared/utils/static-helpers/translate';
import { CoreFacade } from '@app/store/core/core.facade';
import { environment } from '@env/environment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IconName } from '@app/shared/utils/icon/icons';

@Component({
  selector: 'app-bcs',
  templateUrl: './bcs.component.html',
  styleUrls: ['./bcs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BcsComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BcsComponent implements ControlValueAccessor, OnChanges {
  BCS_Title = '';

  @Input() value: number;
  @Input() speciesCode: SpeciesCode = SpeciesCode.Unknown;
  @Input() minimalBCS = 1;

  speciesCode$ = new BehaviorSubject<SpeciesCode>(this.speciesCode);
  /**
   * Url for bcs pdf
   */
  bcsPdfUrl$ = combineLatest([this.coreFacade.bcsLanguages$, this.speciesCode$]).pipe(
    filter(([bcsLanguages, speciesCode]) => !!bcsLanguages?.length && !!speciesCode),
    map(([bcsLanguages, speciesCode]) => {
      const language = bcsLanguages?.find((item) => item === this.locale) || LanguageCode.enGB;
      return `${environment.blobUrl}/bcs/bcs-${speciesCode}-${language}.pdf?${environment.blobQueryParams}`;
    })
  );

  bcsList = Helper.range(9, 1);
  DetectMobile = DetectMobile;

  constructor(@Inject(LOCALE_ID) protected locale: string, private coreFacade: CoreFacade, private changeDetectorRef: ChangeDetectorRef) {}

  /**
   * Trigger speciesCode BehabiorSubject to update BCS pdf url
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.speciesCode) {
      this.speciesCode$.next(changes.speciesCode.currentValue);
    }
  }

  /** FormControl functions **/
  propagateChange = (_: any) => {
    // empty
  };
  onTouchedFn = () => {
    // empty
  };

  writeValue(bcs: number) {
    this.value = bcs;
    this.BCS_Title = Helper.formatBCSText(bcs);
  }

  registerOnChange(fn: (...args: unknown[]) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (...args: unknown[]) => void) {
    this.onTouchedFn = fn;
  }
  /** End Form Control functions **/

  largeBcsImageUrl() {
    return IconName[`${this.speciesCode || 'dog'}_bcs_${this.value}_large`];
  }

  mediumBcsImageUrl() {
    return IconName[`${this.speciesCode || 'dog'}_bcs_${this.value}_medium`];
  }

  BCS_Text_1() {
    return translateKey(`bcs_${this.speciesCode || 'dog'}_${this.value}_txt_1`);
  }

  BCS_Text_2() {
    return translateKey(`bcs_${this.speciesCode || 'dog'}_${this.value}_txt_2`);
  }

  BCS_Text_3() {
    return translateKey(`bcs_${this.speciesCode || 'dog'}_${this.value}_txt_3`);
  }

  BCS_Text_4() {
    return translateKey(`bcs_${this.speciesCode || 'dog'}_${this.value}_txt_4`).includes('bcs_')
      ? ''
      : translateKey(`bcs_${this.speciesCode}_${this.value}_txt_4`);
  }

  BCS_Text_5() {
    return translateKey(`bcs_${this.speciesCode || 'dog'}_${this.value}_txt_5`).includes('bcs_')
      ? ''
      : translateKey(`bcs_${this.speciesCode || 'dog'}_${this.value}_txt_5`);
  }

  selectBCS(bcs: number) {
    if (bcs >= this.minimalBCS) {
      this.writeValue(bcs);
      this.propagateChange(bcs);
      this.onTouchedFn();
    }
  }
}
