import { Injectable } from '@angular/core';
import { Consultation, Order, Patient, Product } from '@app/core/models';
import { startDailyAllowance } from '@app/pages/daily-allowance/store/actions/daily-allowance-steps.actions';
import { startMufPersonalisedBag } from '@app/pages/muf-personalised-bag/store/actions/muf-personalised-bag-steps.actions';
import { startMufStarterKit } from '@app/pages/muf-starter-kit/store/actions/muf-starter-kit-steps.actions';
import { startRenalDetect } from '@app/pages/renal-detect/store/actions/renal-detect-steps.actions';
import { startSmartReco } from '@app/pages/smart-reco/store/actions/smart-reco-steps.actions';
import { startWeightManagement } from '@app/pages/weight-management/store/actions/weight-management-steps.actions';
import { DeliveryOrderFormValues } from '@app/shared/components/delivery-order/delivery-order';
import { MultifunctionPopinComponent } from '@app/shared/components/multifunction-popin/multifunction-popin.component';
import { Helper, Tool } from '@app/shared/utils';
import { AppFeature } from '@app/shared/utils/enums/feature.enum';
import { AppState } from '@app/store';
import {
  resetConsultationData,
  setConsultationLastConsultation,
  setConsultationPatient,
  setConsultationPetOwner,
  setConsultationSetSkipProductBook,
} from '@app/store/consultation/consultation.actions';
import { selectToolInfo, setTool } from '@app/store/core';
import { resetProductsData, setSelectedProduct } from '@app/store/products';
import { resetRecommendationRandomIds, resetShareRecommendationData } from '@app/store/share-recommendation';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, first, take, tap } from 'rxjs/operators';
import { MarketService } from '../utils/localization/market.service';
import { Router } from '@angular/router';
import { GTMService, startConsultationExistingPatientGtm } from '../tracking';
import { startConsultationGtm } from '@app/core/services/tracking';
import { AppRoutes } from '@app/shared/utils/enums/routes.enum';
import { resetPetGrowthChartData } from '@app/store/pet';
import { MatDialog } from '@angular/material/dialog';
import { selectCurrentBigMeasurementUnit } from '@app/store/vet';
import { ProductsFacade } from '@app/store/products/products.facade';

@Injectable()
export class StartFlowService {
  constructor(
    private readonly _dialog: MatDialog,
    private readonly store$: Store<AppState>,
    private readonly marketService: MarketService,
    private readonly router: Router,
    private readonly trackingService: GTMService,
    private productsFacade: ProductsFacade
  ) {}

  public startFlowNewPatient(tool: Tool): void {
    switch (tool) {
      case Tool.SmartReco:
        this.prepareDataNewPatient(Tool.SmartReco);
        this.store$.dispatch(startSmartReco({ withIdentification: true }));
        break;

      case Tool.FastReco:
        this.prepareDataNewPatient(Tool.FastReco);
        this.store$
          .select(selectToolInfo)
          .pipe(
            take(1),
            filter(({ prevTool }) => prevTool !== Tool.FastReco),
            tap(() => this.productsFacade.resetProductsData())
          )
          .subscribe();
        this.router.navigate([AppRoutes.PRODUCTS, AppRoutes.FAST_RECO]);
        break;

      case Tool.RenalDetect:
        this.prepareDataNewPatient(Tool.RenalDetect);
        this.store$.dispatch(startRenalDetect());
        break;
      case Tool.Rationing:
        this.store$.dispatch(resetRecommendationRandomIds());
        this.store$.dispatch(resetPetGrowthChartData());
        this.store$.dispatch(setTool({ tool: Tool.Rationing, tool_flow_id: Helper.randomId() }));
        this.trackingService.sendInteraction(startConsultationGtm(Tool.Rationing));
        this.router.navigate([AppRoutes.PRODUCTS, AppRoutes.DAILY_ALLOWANCE]);

        break;
      case Tool.WeightManagement:
        this.prepareDataNewPatient(Tool.WeightManagement);
        this.store$.dispatch(startWeightManagement({}));

        break;
      case Tool.Multifunction:
      case Tool.PersonalizedBag:
      case Tool.StarterKit:
        this.openMultifunction();
        break;
      default:
        break;
    }
  }

  public openMultifunction(): void {
    combineLatest([this.marketService.isEnabled$(AppFeature.MUF_SK), this.marketService.isEnabled$(AppFeature.MUF_PB)])
      .pipe(take(1))
      .subscribe(([starterKitEnabled, personnalizedBagsEnabled]) => {
        if (starterKitEnabled && personnalizedBagsEnabled) {
          this._dialog
            .open(MultifunctionPopinComponent, {
              panelClass: 'fullscreen',
            })
            .afterClosed()
            .pipe(
              first(),
              tap((tool) => {
                if (tool === Tool.PersonalizedBag) {
                  this.startMufPersonalisedBag();
                } else {
                  this.startMufStarterKit();
                }
              })
            )
            .subscribe();
        } else if (starterKitEnabled) {
          this.startMufStarterKit();
        } else if (personnalizedBagsEnabled) {
          this.startMufPersonalisedBag();
        }
      });
  }

  public startMufStarterKit(): void {
    this.prepareDataNewPatient(Tool.StarterKit);
    this.store$.dispatch(startMufStarterKit({}));
  }

  public startMufStarterKitRenew(products: Product[]): void {
    this.prepareDataNewPatient(Tool.StarterKit);
    this.store$.dispatch(startMufStarterKit({ renew: true, products }));
  }

  public startMufPersonalisedBagRenew(order: Order, patient: Patient, consultation: Consultation, products: Product[]): void {
    this.prepareDataExistingPatient(Tool.PersonalizedBag, patient, consultation);
    this.store$.dispatch(setConsultationPetOwner({ value: patient.owner }));
    this.store$.dispatch(
      setSelectedProduct({
        selectedProduct: {
          product: products[0] || null,
          packId: consultation?.visit?.recommendation?.nutritionData[0]?.product?.productDetail?.package?.sCode || null,
        },
      })
    );
    const deliveryValues: DeliveryOrderFormValues = {
      choice: order.deliverTo,
    };
    this.store$.dispatch(startMufPersonalisedBag({ renew: true, deliveryValues }));
  }

  public startMufPersonalisedBagExistingPatient(patient: Patient, lastConsultation: Consultation): void {
    this.prepareDataExistingPatient(Tool.PersonalizedBag, patient, lastConsultation);
    this.store$.dispatch(startMufPersonalisedBag({}));
  }

  public startMufPersonalisedBagNew(): void {
    this.prepareDataNewPatient(Tool.PersonalizedBag);
    this.store$.dispatch(startMufPersonalisedBag({}));
  }

  // in this use case, we dont reset consultation or products data
  // indeed, products are already selected and stored in the consultation store
  // consultation and products state were reset upon product book page setup (ngOnInit)
  public startNewDAFromProductBook(): void {
    this.trackingService.sendInteraction(startConsultationGtm(Tool.Rationing));
    this.store$.dispatch(resetShareRecommendationData());
    this.store$.dispatch(resetPetGrowthChartData());
    this.store$.dispatch(resetRecommendationRandomIds());
    this.store$.dispatch(setTool({ tool: Tool.Rationing, tool_flow_id: Helper.randomId() }));
    this.store$.dispatch(setConsultationSetSkipProductBook({ value: true }));
    this.store$.dispatch(startDailyAllowance({ withIdentification: true }));
  }

  /**
   *  we dont setTool and send startConsultationGtm because we have aleardy done it in startFlowNewPatient()
   *  we dont reset consultation or products data
   *  indeed, products are already selected and stored in the consultation store
   */
  public continueDAFromProductBook(): void {
    this.store$.dispatch(resetShareRecommendationData());
    this.store$.dispatch(resetPetGrowthChartData());
    this.store$.dispatch(setConsultationSetSkipProductBook({ value: true }));
    this.store$.dispatch(startDailyAllowance({ withIdentification: true }));
  }

  private startMufPersonalisedBag(): void {
    this.prepareDataNewPatient(Tool.PersonalizedBag);
    this.store$.dispatch(startMufPersonalisedBag({}));
  }

  private prepareDataNewPatient(tool: Tool) {
    this.trackingService.sendInteraction(startConsultationGtm(tool));
    this.store$.dispatch(resetPetGrowthChartData());
    this.store$.dispatch(resetConsultationData());
    this.store$.dispatch(resetProductsData());
    this.store$.dispatch(resetShareRecommendationData());
    this.store$.dispatch(resetRecommendationRandomIds());
    this.store$.dispatch(setTool({ tool, tool_flow_id: Helper.randomId() }));
  }

  private prepareDataExistingPatient(tool: Tool, patient: Patient, lastConsultation: Consultation) {
    this.store$
      .select(selectCurrentBigMeasurementUnit)
      .pipe(
        take(1),
        tap((currentBigMeasurementUnit) => {
          this.trackingService.sendInteraction(
            startConsultationExistingPatientGtm({ tool, patient, lastConsultation, currentBigMeasurementUnit })
          );
        })
      )
      .subscribe();
    this.store$.dispatch(resetPetGrowthChartData());
    this.store$.dispatch(resetConsultationData());
    this.store$.dispatch(resetProductsData());
    this.store$.dispatch(resetShareRecommendationData());
    this.store$.dispatch(resetRecommendationRandomIds());
    this.store$.dispatch(setConsultationPatient({ value: patient }));
    this.store$.dispatch(setConsultationLastConsultation({ value: lastConsultation }));
    this.store$.dispatch(setTool({ tool, tool_flow_id: Helper.randomId() }));
  }
}
