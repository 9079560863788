import { RcListComponent } from './rc-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [RcListComponent],
  imports: [CommonModule],
  exports: [RcListComponent],
})
export class RCListModule {}
