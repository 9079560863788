import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SettingsFormValues } from '@app/pages/account-page/container/account-page-models';
import { VetFacade } from '@app/store/vet';
import { RCSelectItem } from '@rc/ui';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-user-settings-form',
  templateUrl: './user-settings-form.component.html',
  styleUrls: ['./user-settings-form.component.scss'],
})
export class UserSettingsFormComponent implements OnInit {
  @Input() languagesItems: RCSelectItem[] = [];
  @Input() systemPreferenceItems: RCSelectItem[] = [];

  @Output() submitted = new EventEmitter<SettingsFormValues>();
  @Output() cancel = new EventEmitter();

  public settingsForm: IFormGroup<SettingsFormValues>;

  constructor(private vetFacade: VetFacade) {}

  ngOnInit(): void {
    combineLatest([this.vetFacade.language$, this.vetFacade.preferenceSystem$])
      .pipe(
        take(1),
        map(([language, preferenceSystem]) => {
          const formBuilder: IFormBuilder = new UntypedFormBuilder();
          this.settingsForm = formBuilder.group<SettingsFormValues>({
            language: [language],
            systemPreference: [preferenceSystem],
          });
        })
      )
      .subscribe();
  }
}
