<form [formGroup]="form" (ngSubmit)="submit()" class="weight-form">
  <!--
        Age warning to display if pet is not an adult
     -->
  <div class="age-warning" *ngIf="!isAdult">
    <div class="icon">
      <div class="rc-icon rc-icon--alert"></div>
    </div>
    <div id="bcsGrowthWarning" class="text">{{ 'pet-details_bcs-warning' | translateKey }}</div>
  </div>

  <div class="container">
    <!--
      BCS component
      includes picture, PDF, text & BCS selector
   -->
    <div class="container__bcs">
      <app-bcs formControlName="bcs" *ngIf="profile" [speciesCode]="profile.speciesCode" [minimalBCS]="minimalBcs"></app-bcs>
      <div class="ibw-not-valid" *ngIf="(isIBWValidSubject$ | async) === false">
        {{ 'digital-rationing_error-target-weight' | translateKey }}
      </div>
    </div>

    <!--
      Weight fields
   -->
    <div *ngIf="showWeightFields" class="weight-fields-container">
      <div class="weight-fields">
        <!--
          Current weight field
         -->
        <div class="field current-weight" id="weight-form-field-weight">
          <rc-field
            data-cs-mask
            type="number"
            formControlName="currentWeight"
            [forceTypeNumber]="true"
            [label]="'form-attribute_current-weight' | translateKey"
            [error]="currentWeightError"
          >
            <ng-container suffix>
              <span [class.error]="currentWeightError">{{ measureUnit }}</span>
            </ng-container>
          </rc-field>
        </div>

        <!--
          Target weight field
          Can be Ideal Body Weight or adult target weight depending on isAdult
        -->

        <!--
          Case 1: is adult, BCS is in [1, 2]
          -->
        <div class="profile-underweight" *ngIf="isAdultWithBcs12()">
          <app-icon class="profile-underweight__icon" [name]="IconName.warning"></app-icon>
          <span>{{ 'Weight-activity_underweight-pet_current-weight-used-for-DA' | translateKey }}</span>
        </div>

        <!--
          Case 2: is adult, IBW is set, bcs is in [3, 4, 5]
          -->
        <div class="ideal-body-weight" *ngIf="isAdultWithBcs345()">
          <span class="error" *ngIf="calculatedTargetWeightError; else hint">{{ calculatedTargetWeightError }}</span>
          <ng-template #hint>
            <ng-container *ngIf="calculatedTargetWeight">
              <span>{{ 'form-attribute_IBW' | translateKey }}: &nbsp;</span>
              <span id="idealBodyWeightValue">{{ calculatedTargetWeight }}&nbsp;{{ measureUnit }}</span>
            </ng-container>
          </ng-template>
        </div>

        <div class="profile-underweight with-ideal-body-weight" *ngIf="isAdultWithBcs3()">
          <app-icon class="profile-underweight__icon" [name]="IconName.warning"></app-icon>
          <span>{{ 'Weight-activity_underweight-pet_current-weight-used-for-DA-BCS-3' | translateKey }}</span>
        </div>

        <!--
        Case 3: is adult, targetWeight is set, bcs > 5
       -->
        <div
          class="ideal-body-weight-extra-info"
          [class.disabled]="form.controls.currentWeight.invalid"
          *ngIf="isAdultCwSetWithBcsGreaterThan5()"
        >
          <div class="target-weight-values">
            <div class="field" id="ideal-body-weight-block">
              <rc-field
                type="number"
                data-cs-mask
                formControlName="targetWeight"
                [forceTypeNumber]="true"
                [label]="'banner_target_weight' | translateKey"
                [error]="targetWeightError"
              >
                <ng-container suffix>
                  <span [class.error]="targetWeightError">{{ measureUnit }}</span>
                </ng-container>
              </rc-field>
            </div>
            <span class="error" *ngIf="calculatedTargetWeightError">
              {{ calculatedTargetWeightError }}
            </span>
          </div>

          <div class="target-weight-info-wrapper">
            <div class="target-weight-info">
              <div class="target-weight-info__title">
                <app-icon name="alert"></app-icon>
                <span>{{ 'for_information_subtitle' | translateKey }}</span>
              </div>
              <p>
                {{ 'target_weight-explanation-BCS-6-to-9' | translateKey }}
              </p>
            </div>
          </div>

          <div class="ideal-body-weight-info-wrapper" *ngIf="calculatedTargetWeight && !calculatedTargetWeightError">
            <div class="hint ideal-body-weight-info__title">
              <app-icon name="alert"></app-icon>
              <span>{{ 'form-attribute_IBW' | translateKey }}: &nbsp;</span>
              <span id="idealBodyWeightValue">{{ calculatedTargetWeight }}&nbsp;{{ measureUnit }}</span>
            </div>
            <div class="ideal-body-weight-usage">
              {{ 'ideal_body_weight_usage' | translateKey }}
            </div>
          </div>
        </div>

        <!--
        Case 4: is not adult, calculatedTargetWeight has been calculated
       -->
        <ng-container *appLet="youngPetMinMaxWeights$ | async as youngPetMinMaxWeights">
          <div class="target-weight" [class.disabled]="form.controls.currentWeight.invalid" *ngIf="!isAdult">
            <div class="target-weight-values">
              <div class="field" id="weight-form-field-target-weight">
                <rc-field
                  type="number"
                  data-cs-mask
                  formControlName="targetWeight"
                  [forceTypeNumber]="true"
                  [label]="'form-attribute_adult_target_weight' | translateKey"
                  [error]="targetWeightError"
                >
                  <ng-container suffix>
                    <span [class.error]="targetWeightError">
                      {{ measureUnit }}
                    </span>
                  </ng-container>
                </rc-field>
              </div>
              <!--
               API calculation error
              -->
              <span class="error" *ngIf="calculatedTargetWeightError; else hint">{{ calculatedTargetWeightError }}</span>
              <!--
                Hint to display under weight fields
               -->
              <ng-template #hint>
                <span class="hint">
                  <ng-container *ngIf="youngPetMinMaxWeights">
                    <span id="idealAdultWeightValue">{{ formatYoungPetMinMaxWeightMessage(youngPetMinMaxWeights) }}</span>
                  </ng-container>
                </span>
              </ng-template>
            </div>
            <div class="target-weight-info-wrapper">
              <ng-container *appLet="isTBWValidSubject$ | async as isTBWValid">
                <div class="target-weight-info" [ngClass]="{ 'tbw-not-valid': !isTBWValid }">
                  <div class="target-weight-info__title">
                    <app-icon name="alert"></app-icon>
                    <span>{{ 'for_information_subtitle' | translateKey }}</span>
                  </div>
                  <div *ngIf="isTBWValid; else weight_outside_range">
                    <div *ngIf="isPuppyWithBreedOrAnyKitten">
                      {{ 'info_set_adult_target_weight-puppy-kitten' | translateKey }}
                    </div>
                    <div *ngIf="isMixedBreedPuppy">
                      {{ 'info_set_target_body-weight_adult' | translateKey }}
                    </div>
                  </div>
                  <ng-template #weight_outside_range>
                    {{ 'DAC_adult-target-weight-outside-range' | translateKey }}
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <div class="fields-wrapper" *ngIf="isAdult">
          <div class="activity">
            <p class="activity__title">
              <app-activity-tooltip [speciesCode]="profile?.speciesCode"></app-activity-tooltip>
            </p>
            <rc-selectors
              [id]="'weight-form-activity'"
              [formControlName]="'petActivity'"
              [type]="RCSelectorsType.RADIO"
              [mode]="'grouped'"
              [items]="activityItems"
              [maxNumberGroupedItemsInHorizontal]="3"
            >
            </rc-selectors>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--
    Footer
    Submit is disabled if there is an API calculation or if form is invalid
   -->
  <div class="footer">
    <button
      class="rc-button rc-button--filled-small"
      id="validateWeight"
      type="submit"
      [disabled]="calculatingTargetWeight || form.invalid || (isIBWValidSubject$ | async) === false"
    >
      <span class="rc-button__title">{{ 'action_continue' | translateKey }}</span>
    </button>
  </div>
</form>
