import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Helper } from '@app/shared/utils';

@Component({
  selector: 'app-rc-gauge',
  templateUrl: './rc-gauge.component.html',
  styleUrls: ['./rc-gauge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RcGaugeComponent {
  @Input() title: string;
  @Input() hasIcon: boolean;
  @Input()
  set value(value: number) {
    this._value = value;
    this._progress(value);
  }
  get value(): number {
    return this._value;
  }

  public mouseOver = false;
  public fullWidth = 125;
  public height = 8;
  public coloredLineStart: number;
  public coloredLineEnd: number;
  public greyLineStart: number;
  public greyLineEnd: number;
  public width: number;
  public gradientOffset: string;
  public colorLineStartR = 171;
  public colorLineStartG = 205;
  public colorLineStartB = 117;
  public colorLineStart = 'rgb(171,205,117)';
  public colorLineFullEndR = 77;
  public colorLineFullEndG = 185;
  public colorLineFullEndB = 8;
  public colorLineEndR = 77;
  public colorLineEndG = 185;
  public colorLineEndB = 8;
  public colorLineEnd = 'rgb(77,185,8)';
  public linearId = `linear-${Helper.randomString(5)}`;

  private _value: number;

  constructor() {
    this._progress(0);
  }

  private _progress(value: number) {
    const progress = value / 100;
    this.width = progress * this.fullWidth;
    this.coloredLineStart = 0;
    this.coloredLineEnd = this.width;
    this.greyLineStart = 0;
    this.greyLineEnd = this.fullWidth;
    this.gradientOffset = value === 0 ? '100%' : `${100 / progress}%`;

    this.colorLineEndR = this.colorLineStartR + (this.colorLineFullEndR - this.colorLineStartR) * progress;
    this.colorLineEndG = this.colorLineStartG + (this.colorLineFullEndG - this.colorLineStartG) * progress;
    this.colorLineEndB = this.colorLineStartB + (this.colorLineFullEndB - this.colorLineStartB) * progress;
    this.colorLineEnd = `rgb(${this.colorLineEndR},${this.colorLineEndG},${this.colorLineEndB})`;
  }

  setMouseOver(value: boolean): void {
    this.mouseOver = value;
  }
}
