<app-rc-dialog-wrapper (closePopin)="dismiss()">
  <h2 class="details-popin_title">{{ 'new-DAC-flow_purchase_button' | translateKey }}</h2>

  <app-qr-code-display
    *ngIf="poLetterQrCode$ | async as poLetterQrCode"
    [qrCodeLink]="poLetterQrCode"
    [instructions]="'QRcode-popin_purchase_IVO' | translateKey"
  ></app-qr-code-display>

  <button class="purchase-button rc-button rc-button--filled-small" (click)="dismiss()" type="button">
    <span class="rc-button__title" i18n="@@action_cancel">{{ 'cancel' | titlecase }}</span>
  </button>
</app-rc-dialog-wrapper>
